/* eslint-disable no-sparse-arrays */
import { useInstitute } from "./useInstitute";
import { useLanguage } from "./useLanguage";

export const useStart = () => {
  const [, setInstitutes] = useInstitute([]);
  const [, setLanguage] = useLanguage([]);

  const handleChange = async (id, initialization) => {
    if (initialization) {
      setLanguage("get", "en");
      await setInstitutes("start", {
        instituteId: id,
        initialization
      });

      // await setInstitutes("get", {
      //   instituteId: id,
      //   initialization
      // });
      //await setInstitutes("getCurrent", id);
      // await setSessions("get", {
      //   isArchived: false,
      //   sessionName: "",
      //   instituteId: id,
      // });
      // await setStaff("get", {
      //   instituteId: id,
      // });
    } else {
      // setSessions("librarySessions", {
      //   isArchived: true,
      //   sessionName: "",
      //   instituteId: id,
      //   initialization
      // });
      // setInstitutes("onlineTestTakers", {
      //   userInstituteId: id
      // });
      // setSessions("liveSessions", {
      //   instituteId: id,
      // });
      // setAuth("logo");
      // setReports("onlineTakers");
      // setReports("allSession");
      // setReports("allLibrarySessionList");
      // setReports("initialLoadData");
    }
  };
  return [, handleChange];
};
