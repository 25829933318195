/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap-tabs";
import SessionInfo from "./components/SessionInfo";
import SessionRules from "./components/SessionRules";
import SessionThreshold from "./components/SessionThreshold";
import SessionSystemCheck from "./components/SessionSystemCheck";
import { awscontext } from "../../configs/awscontext";
import ScreenPanel from "../components/ScreenPanel";
import SessionStudents from "./components/SessionStudents";
import SessionMail from "./components/SessionMail";
import i18n from "../../configs/i18n";
import { withNamespaces } from "react-i18next";
import { MdRefresh } from "react-icons/md";
import { useSession } from "../../hooks/useSession";
import { store } from "react-notifications-component";

function SessionInformation(props) {
  const context = useContext(awscontext);
  const [tabKey, setKey] = useState(0);
  const [selectedSession, setSelectedSession] = useState({
    ...context.selectedSession,
  });
  const [rowCount, setRowCount] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [itemCount, setItemCount] = useState(context.sessionList.length);
  const [, setSession] = useSession([]);

  const setBackground = (sessionId) => {
    if (selectedSession && sessionId === selectedSession.sessionId)
      return "list-group-item list-group-item-action buttons ";
    return "list-group-item list-group-item-action";
  };

  useEffect(() => {
    if (selectedSession) {
      let activePageForSet = 0;
      for (let index = 0; index < context.sessionList.length; index++) {
        if (
          selectedSession.sessionId === context.sessionList[index].sessionId
        ) {
          activePageForSet = parseInt(index / 10) + 1;
        }
      }
      setActivePage(activePageForSet);
      // window.location.href = `/sessionInformation/${selectedSession.sessionId}`;
    }
  }, [selectedSession]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    context.setAdres("sessionList");
    context.setBarGroup("EXAMS");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setItemCount(context.sessionList.length);
  }, [context.sessionList]);

  useEffect(() => {
    const retrieveInformation = async () => {
      const splittedUrl = window.location.href.split("/");
      let foundSession = context.sessionList.find(
        (session) => session.sessionId === splittedUrl[splittedUrl.length - 1]
      );
      foundSession = await setSession("getById", {
        instituteId: foundSession.instituteId,
        sessionId: foundSession.sessionId,
      });
      context.setSelectedSession(foundSession);
      setSelectedSession(foundSession);
    };
    if (context.loaded) {
      retrieveInformation();
    }
  }, [context.loaded]); // eslint-disable-line react-hooks/exhaustive-deps

  const appropriateSessionProctoringType = (proctorServiceType) => {
    if (
      proctorServiceType === "Record_and_Review" ||
      proctorServiceType === "Record_and_Review_Lite" ||
      proctorServiceType === "Virtual_Classroom" ||
      proctorServiceType === "Offline"
    ) {
      return false;
    } else {
      return true;
    }
  };

  const retrieveSessionInformation = async (notify) => {
    await setSession("getById", {
      instituteId: selectedSession.instituteId,
      sessionId: selectedSession.sessionId,
    }).then((foundSession) => {
      const foundIndex = context.sessionList.findIndex(
        (session) => session.sessionId === foundSession.sessionId
      );
      context.sessionList[foundIndex] = foundSession;
      context.setSelectedSession(foundSession);
      setSelectedSession(foundSession);
      if (notify) {
        store.addNotification({
          title: null,
          message: i18n.t("Session_information_has_successfully_updated"),
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    });
  };

  const getInstanceState = (instanceState) => {
    if (instanceState === "ON_GOING") {
      return <span style={{ color: "#007bff" }}>{i18n.t("LIVE")}</span>;
    } else if (instanceState === "CONFIGURABLE") {
      return <span style={{ color: "#ffc107" }}>{i18n.t("INACTIVE")}</span>;
    } else if (instanceState === "INITIALIZING") {
      return <span style={{ color: "#6c757d" }}>{i18n.t("INITIALISING")}</span>;
    } else if (instanceState === "INVALID") {
      return <span style={{ color: "#dc3545" }}>{i18n.t("INVALID")}</span>;
    } else if (instanceState === "FINISHED") {
      return <span style={{ color: "#28a745" }}>{i18n.t("FINISHED")}</span>;
    }
  };

  const setSelectedSessionWithStudents = async (selectedSession) => {
    const selectedSessionWithStudents = await setSession("getById", {
      instituteId: selectedSession.instituteId,
      sessionId: selectedSession.sessionId,
    });
    setSelectedSession(selectedSessionWithStudents);
  };

  const sessionListPanel = () => {
    return (
      <div className="d-flex flex-column col-3 p-0">
        <ScreenPanel
          col="12"
          name={i18n.t("Session_List")}
          pagination="true"
          rowCount={rowCount}
          setRowCount={setRowCount}
          itemCount={itemCount}
          activePage={activePage}
          setActivePage={setActivePage}
        >
          <div className="list-group">
            {context.sessionList
              .slice((activePage - 1) * rowCount, activePage * rowCount)
              .map((session, i) => (
                <div key={i}>
                  <button
                    onClick={() => setSelectedSessionWithStudents(session)}
                    className={setBackground(session.sessionId)}
                  >
                    {session.sessionName}
                    {" - "}
                    {getInstanceState(session.instanceState)}
                    <br />
                    <b>{new Date(session.startDateTimeUnix).toDateString()}</b>
                  </button>
                </div>
              ))}
          </div>
        </ScreenPanel>
      </div>
    );
  };

  const detailedSelectionInformation = () => {
    //sessionList -> bir sessiona tıkladığımız zaman burası render oluyor
    return (
      <ScreenPanel name={selectedSession.sessionName} col="9">
        <div
          className="btn btn-light d-flex flex-column justify-content-center align-items-center"
          style={{
            border: "1px solid #29377e",
            borderRadius: 40,
            height: 30,
            width: 40,
            position: "absolute",
            right: 30,
          }}
          onClick={(e) => retrieveSessionInformation(e, true)}
        >
          <MdRefresh color="#29377e" size={20} />
        </div>
        <Tabs activeKey={tabKey} onSelect={(k) => setKey(k)}>
          <Tab label={i18n.t("Info")}>
            <SessionInfo
              selectedSession={selectedSession}
              setSelectedSession={setSelectedSession}
              appropriateSessionProctoringType={
                appropriateSessionProctoringType
              }
              {...props}
            />
          </Tab>
          <Tab label={i18n.t("System_Check_Information")}>
            <SessionSystemCheck
              SelectedSession={selectedSession}
              setSelectedSession={setSelectedSession}
              {...props}
            />
          </Tab>
          <Tab label={i18n.t("Exam_Instructions")}>
            <SessionRules
              SelectedSession={selectedSession}
              setSelectedSession={setSelectedSession}
              {...props}
            />
          </Tab>
          <Tab label={i18n.t("Test_takers")}>
            <SessionStudents
              SelectedSession={selectedSession}
              setSelectedSession={setSelectedSession}
              retrieveSessionInformation={retrieveSessionInformation}
              appropriateSessionProctoringType={
                appropriateSessionProctoringType
              }
              {...props}
            />
          </Tab>
          <Tab label={i18n.t("Severity_Threshold")}>
            <SessionThreshold
              SelectedSession={selectedSession}
              setSelectedSession={setSelectedSession}
              {...props}
            />
          </Tab>
          {/* sessionList -> Email */}
          {/* <Tab label={i18n.t("Email")}>
            <SessionMail
              SelectedSession={selectedSession}
              setSelectedSession={setSelectedSession}
            />
          </Tab> */}
        </Tabs>
      </ScreenPanel>
    );
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          {sessionListPanel()}
          {selectedSession ? detailedSelectionInformation() : ""}
        </div>
      </div>
    </React.Fragment>
  );
}
export default withNamespaces()(SessionInformation);
