import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export const VideoJS = (props) => {
  const playerRef = React.useRef(null);
  const {options, onReady} = props;
  const {videoRef, webcamEnded, onPlayVideo} = props;
  React.useEffect(() => {

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;

      if (!videoElement) return;

      const player = playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        onReady && onReady(player);
      });
      

    // You could update an existing player in the `else` block here
    // on prop change, for example:
    } else {
      // const player = playerRef.current;

      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  const onPlayedVideo = () => {
    if(onPlayVideo)
      onPlayVideo();
  };

  const onEndedVideo = () => {
    if(webcamEnded)
      webcamEnded();
  };

  return (
    <div data-vjs-player style={{ height: "100%" }}>
      <video onEnded={onEndedVideo} onPlay={onPlayedVideo} ref={videoRef} className='video-js vjs-big-play-centered vjs-16-9' crossOrigin='anonymous'/>
    </div>
  );
}

export default VideoJS;