/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useContext, useLayoutEffect } from "react";
import Card from "react-bootstrap/Card";
import "../../../assets/video.css";
import "../../../assets/input.css";
import { useStorage } from "../../../hooks/useStorage";
import { useStudentResult } from "../../../hooks/useStudentResult";
import ModalForm from "../../components/ModalForm";
import ResultView from "./ResultView";
import i18n from "../../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import FormInput from "../../components/FormInput";
import { awscontext } from "../../../configs/awscontext";
import { proctorcontext } from "../../../configs/proctorContext";
import FormSelect from "../../components/FormSelect";
import { useSession } from "../../../hooks/useSession";
import {
  FaArrowLeft,
  FaCheckCircle,
  FaPause,
  FaPlay,
  FaRegQuestionCircle,
} from "react-icons/fa";
import VideoJS from "./VideoJS";
import { IoMdCloseCircle } from "react-icons/io";
import GeneralComments from "./GeneralComments";
import axios from "axios";
import config from "../../../configs/awsconfig.json";
import ChatViewer from "./ChatViewer";
import studentModel from "../../../models/modelStudent.json";
import sessionModel from "../../../models/modelSession.json";
import { store } from "react-notifications-component";
import fileDownload from "js-file-download";
const reviewerMessages = require("../../../models/variables.json");

const LibraryStudentDetailed = (props) => {
  const [proctorChatMessages, setProctorChatMessages] = useState([]);
  const [technicalChatMessages, setTechnicalChatMessages] = useState([]);
  const [, setSession] = useSession();
  const [, studentResultOperation] = useStudentResult();
  const context = useContext(awscontext);
  const proContext = useContext(proctorcontext);
  const [camera, setCamera] = useState("webcam");
  const [readyMessage, setReadyMessage] = useState("");
  const webcamRef = useRef(null);
  const screenshareRef = useRef(null);
  const [videoCurrentTime, setVideoCurrentTime] = useState();
  const facePhotoRef = useRef(null);
  const mobileRef = useRef(null);
  const mobile2Ref = useRef(null);
  const mobile3Ref = useRef(null);
  const mobile4Ref = useRef(null);
  const idCardRef = useRef(null);
  const [duration, setDuration] = useState(new Date());
  const [showResult, setShowResult] = useState(false);
  const [showDecisionModal, setShowDecisionModal] = useState(false);
  const [command, setCommand] = useState([]);
  const canvasRef = useRef();
  const [videoPoint, setVideoPoint] = useState(0);
  const [chatAreaVisible, setChatAreaVisible] = useState(false);
  const [mobileCamCanPlay, setMobileCamCanPlay] = useState({
    mobile1: false,
    mobile2: false,
    mobile3: false,
    mobile4: false,
  });
  const [mobileOffsetTime, setMobileOffsetTime] = useState({
    web: {},
    screen: {},
    mobile1: {},
    mobile2: {},
    mobile3: {},
    mobile4: {},
  });
  // const [webcamStartDate, setWebcamStartDate] = useState();
  // const [screenShareStartDate, setScreenShareStartDate] = useState();
  // const [screenShareEndDate, setScreenShareEndDate] = useState();
  const [aiScore, setAiScore] = useState(0);
  const [foundStudent, setFoundStudent] = useState();
  const [webcamDuration, setWebcamDuration] = useState(0);
  const [screenDuration, setScreenDuration] = useState(0);
  const [mobileDuration, setMobileDuration] = useState(0);
  const [mobile1Duration, setMobile1Duration] = useState(0);
  const [mobile2Duration, setMobile2Duration] = useState(0);
  const [mobile3Duration, setMobile3Duration] = useState(0);
  const [uploadObject, listObjects, headObject, getBaseUrl] = useStorage(
    config.s3.streamFiles
  );
  const [selectedStudent, setSelectedStudent] = useState(
    props.location.state ? props.location.state.student : studentModel
  );
  const [selectedSession, setSelectedSession] = useState(
    props.location.state ? props.location.state.session : sessionModel
  );
  const [meetingInformation, setMeetingInformation] = useState(
    props.location.state ? props.location.state.meetingInformation : []
  );
  const [isProctor, setIsProctor] = useState(
    props.location.state ? props.location.state.isProctor : false
  );
  const [playerInfo, setPlayerInfo] = useState({ paused: true });
  const [isMobileEnded, setIsMobileEnded] = useState(false);
  const [isMobile2Ended, setIsMobile2Ended] = useState(false);
  const [isMobile3Ended, setIsMobile3Ended] = useState(false);
  const [isMobile4Ended, setIsMobile4Ended] = useState(false);

  const [webcamPromise, setWebcamPromise] = useState();
  const [screenSharePromise, setScreenSharePromise] = useState();
  const [mobilePromise, setMobilePromise] = useState();
  const [mobile2Promise, setMobile2Promise] = useState();
  const [mobile3Promise, setMobile3Promise] = useState();
  const [mobile4Promise, setMobile4Promise] = useState();

  const [includedStreams, setIncludedStreams] = useState({
    webcam: {
      enabled: false,
      duration: 0,
      offset: 0,
    },
    screen: {
      enabled: false,
      duration: 0,
      offset: 0,
    },
    mobile: {
      enabled: false,
      duration: 0,
      offset: 0,
    },
    mobile1: {
      enabled: false,
      duration: 0,
      offset: 0,
    },
    mobile2: {
      enabled: false,
      duration: 0,
      offset: 0,
    },
    mobile3: {
      enabled: false,
      duration: 0,
      offset: 0,
    },
  });
  // const [evaluationDesc, setEvaluationDesc] = useState(`${i18n.t("Other...")}`);
  const evaluationDesc = `${i18n.t("Other...")}`;
  const [evaluationDescText, setEvaluationDescText] = useState(
    selectedStudent ? selectedStudent.evaluationDescText : ""
  );
  const [studentResult, setStudentResult] = useState(null);
  const [videoSpeed, setVideoSpeed] = useState(1);
  const [getLoadingScreenShare, setLoadingScreenShare] = useState(false);
  const [getLoadingWebcam, setLoadingWebcam] = useState(false);
  const [getLoadingMobiles, setLoadingMobiles] = useState(false);
  const [customVideoWidth, setCustomVideoWidth] = useState(460);

  // useEffect(() => {
  //   console.log("webcamDuration => ", webcamDuration);
  //   console.log("mobileDuration => ", mobileDuration);
  //   console.log("screenDuration => ", screenDuration);
  //   console.log("diff => ", webcamDuration - mobileDuration);
  // }, [webcamDuration, mobileDuration, screenDuration]);


  useEffect(async () => {
    const retrieveChatMessages = async (params) => {
      try {
        const chatMessageResults = await axios.get(
          `${config.api.invokeUrl}/chats/${params}/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                Object.keys(localStorage).find((key) =>
                  key.includes("accessToken")
                )
              )}`,
            },
          }
        );
        return chatMessageResults.data.sort(function (a, b) {
          return new Date(a.Time) - new Date(b.Time);
        });
      } catch (err) {
        console.log(err);
      }
      return [];
    };
    const retrieveChatContentInformation = (
      selectedSession,
      selectedStudent
    ) => {
      retrieveChatMessages(
        `${selectedSession.sessionId}${selectedStudent.studentId}`
      ).then((chatMessages) => {
        setProctorChatMessages(chatMessages);
      });
      retrieveChatMessages(
        `${selectedSession.sessionId}${selectedStudent.studentId}-technical`
      ).then((chatMessages) => setTechnicalChatMessages(chatMessages));
    };
    const retrieveSessionContentInformation = async (
      selectedSession,
      selectedStudent
    ) => {
      const images = await listObjects(
        `${selectedSession.sessionId}${selectedStudent.studentId}/images`
      );
      let imageContents = images.Contents;
      imageContents = imageContents.filter((image) => image.Size > 0);
      imageContents.forEach((imageContent) => {
        try {
          if (imageContent.Key.includes("facephoto")) {
            facePhotoRef.current.src = `${getBaseUrl()}/${imageContent.Key}`;
          } else if (imageContent.Key.includes("idcard")) {
            idCardRef.current.src = `${getBaseUrl()}/${imageContent.Key}`;
          }
        } catch (err) {
          console.log(`An error has occurred: ${err}`);
        }
      });
      const videos = await listObjects(
        `${selectedSession.sessionId}${selectedStudent.studentId}/exam`
      );
      let examContents = videos.Contents;
      examContents = examContents.filter((image) => image.Size > 0);
      for (const examContent of examContents) {
        try {
          if (examContent.Key.includes("-webcam")) {
            webcamRef.current.src = `${getBaseUrl()}/${
              examContent.Key
              }`.replace("#", "%23");
          } else if (examContent.Key.includes("-screen")) {
            screenshareRef.current.src = `${getBaseUrl()}/${
              examContent.Key
              }`.replace("#", "%23");
          } else if (examContent.Key.includes("-mobile_0")) {
            mobileRef.current.src = `${getBaseUrl()}/${
              examContent.Key
              }`.replace("#", "%23");
          } else if (examContent.Key.includes("-mobile_1")) {
            mobile2Ref.current.src = `${getBaseUrl()}/${
              examContent.Key
              }`.replace("#", "%23");
          } else if (examContent.Key.includes("-mobile_2")) {
            mobile3Ref.current.src = `${getBaseUrl()}/${
              examContent.Key
              }`.replace("#", "%23");
          } else if (examContent.Key.includes("-mobile_3")) {
            mobile4Ref.current.src = `${getBaseUrl()}/${
              examContent.Key
              }`.replace("#", "%23");
          }
        } catch (err) {
          console.log(`An error has occurred: ${err}`);
        }
      }
    };
    const retrieveMeetingInformation = async (
      selectedSession,
      selectedStudent
    ) => {
      try {
        const res = await axios.post(
          `${config.api.invokeUrl}/meetings/query`,
          {
            sessionId: selectedSession.sessionId,
            studentId: selectedStudent.studentId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                Object.keys(localStorage).find((key) =>
                  key.includes("accessToken")
                )
              )}`,
            },
          }
        );
        setMeetingInformation(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    if (context.loaded) {
      const splittedUrl = window.location.href.split("/");
      let foundSession = context.sessionList.find(
        (session) => session.sessionId === splittedUrl[splittedUrl.length - 2]
      );
      context.setSelectedSession(foundSession);
      foundSession = await setSession("getById", {
        instituteId: foundSession.instituteId,
        sessionId: foundSession.sessionId,
      });
      let foundStudent = foundSession.studentList.find(
        (student) => student.studentId === splittedUrl[splittedUrl.length - 1]
      );
      if (!foundStudent) {
        const foundStudentProctor = foundSession.studentList.find((student) =>
          student.proctors.find(
            (proctor) =>
              proctor.proctorId === splittedUrl[splittedUrl.length - 1]
          )
        );
        if (foundStudentProctor) {
          foundStudent = foundStudentProctor.proctors.find(
            (proctor) =>
              proctor.proctorId === splittedUrl[splittedUrl.length - 1]
          );
          foundStudent.examRules = {
            mobileCameraUsage: false,
          };
          foundStudent.studentId = foundStudent.proctorId;
          foundStudent.name = foundStudent.proctorName;
          foundStudent.lastname = foundStudent.proctorLastName;
        }
        setIsProctor(true);
      } else {
        setIsProctor(false);
        setAiScore(Math.ceil(foundStudent.aiScore));
        setFoundStudent(foundStudent);
      }
      if (foundStudent) {
        setTimeout(() => {
          setSelectedStudent(foundStudent);
        }, 1000);
        // setEvaluationDesc(foundStudent.evaluationDesc);
        setEvaluationDescText(foundStudent.evaluationDescText);
        setSelectedSession(foundSession);
        retrieveMeetingInformation(foundSession, foundStudent);
        retrieveSessionContentInformation(foundSession, foundStudent);
        retrieveChatContentInformation(foundSession, foundStudent);
        retrieveStudentResults(foundSession, foundStudent);
      }
    }
  }, [context.loaded, aiScore]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (webcamRef.current) {
      webcamRef.current.oncanplay = () => {
        if (
          webcamRef.current &&
          webcamRef.current.duration &&
          webcamRef.current.duration !== Infinity
        ) {
          setWebcamDuration(webcamRef.current.duration);
        }
      };
    }
    if (screenshareRef.current) {
      screenshareRef.current.oncanplay = () => {
        if (
          screenshareRef.current &&
          screenshareRef.current.duration &&
          screenshareRef.current.duration !== Infinity
        ) {
          setScreenDuration(screenshareRef.current.duration);
        }
      };
    }
    if (mobileRef.current) {
      mobileRef.current.oncanplay = () => {
        if (
          mobileRef.current &&
          mobileRef.current.duration &&
          mobileRef.current.duration !== Infinity
        ) {
          setMobileDuration(mobileRef.current.duration);
        }
      };
    }
    if (mobile2Ref.current) {
      mobile2Ref.current.oncanplay = () => {
        if (
          mobile2Ref.current &&
          mobile2Ref.current.duration &&
          mobile2Ref.current.duration !== Infinity
        ) {
          setMobile1Duration(mobile2Ref.current.duration);
        }
      };
    }
    if (mobile3Ref.current) {
      mobile3Ref.current.oncanplay = () => {
        if (
          mobile3Ref.current &&
          mobile3Ref.current.duration &&
          mobile3Ref.current.duration !== Infinity
        ) {
          setMobile2Duration(mobile3Ref.current.duration);
        }
      };
    }
    if (mobile4Ref.current) {
      mobile4Ref.current.oncanplay = () => {
        if (
          mobile4Ref.current &&
          mobile4Ref.current.duration &&
          mobile4Ref.current.duration !== Infinity
        ) {
          setMobile3Duration(mobile4Ref.current.duration);
        }
      };
    }
    return () => {
      if (webcamRef.current) {
        webcamRef.current.oncanplay = null;
      }
      if (screenshareRef.current) {
        screenshareRef.current.oncanplay = null;
      }
      if (mobileRef.current) {
        mobileRef.current.oncanplay = null;
      }
      if (mobile2Ref.current) {
        mobile2Ref.current.oncanplay = null;
      }
      if (mobile3Ref.current) {
        mobile3Ref.current.oncanplay = null;
      }
      if (mobile4Ref.current) {
        mobile4Ref.current.oncanplay = null;
      }
    };
  }, [
    webcamRef.current,
    screenshareRef.current,
    mobile4Ref.current,
    mobile3Ref.current,
    mobile2Ref.current,
    mobileRef.current,
  ]);

  useEffect(() => {
    //TODOSYNC START
    // console.log("meetingInformation => ", meetingInformation);
    meetingInformation.forEach((meeting, index) => {
      if (meeting.meetingId.includes("-webcam")) {
        setMobileOffsetTime((prevState) => ({
          ...prevState,
          web: { startTime: meeting.startTime, endTime: meeting.endTime },
        }));
      } else if (meeting.meetingId.includes("-screenShare")) {
        setMobileOffsetTime((prevState) => ({
          ...prevState,
          screen: { startTime: meeting.startTime, endTime: meeting.endTime },
        }));
      } else if (meeting.meetingId.includes("-mobile_0")) {
        setMobileOffsetTime((prevState) => ({
          ...prevState,
          mobile1: { startTime: meeting.startTime, endTime: meeting.endTime },
        }));
      } else if (meeting.meetingId.includes("-mobile_1")) {
        setMobileOffsetTime((prevState) => ({
          ...prevState,
          mobile2: { startTime: meeting.startTime, endTime: meeting.endTime },
        }));
      } else if (meeting.meetingId.includes("-mobile_2")) {
        setMobileOffsetTime((prevState) => ({
          ...prevState,
          mobile3: { startTime: meeting.startTime, endTime: meeting.endTime },
        }));
      } else if (meeting.meetingId.includes("-mobile_3")) {
        setMobileOffsetTime((prevState) => ({
          ...prevState,
          mobile4: { startTime: meeting.startTime, endTime: meeting.endTime },
        }));
      }
    });

    //Burada Kaldın başlangıç farklarını setleyeceksin.
  }, [meetingInformation]);

  // useLayoutEffect(() => {
  //   if (webcamRef.current) {
  //     if (webcamRef.current.currentTime) {
  //       setTimeout(() => {
  //         const input = document.getElementById("general_input");
  //         if (screenDuration > webcamDuration) {
  //           input.value = screenshareRef.current.currentTime

  //           setVideoCurrentTime(screenshareRef.current.currentTime);
  //         } else if (webcamDuration > screenDuration) {
  //           input.value = webcamRef.current.currentTime
  //           setVideoCurrentTime(webcamRef.current.currentTime);
  //         }
  //       }, 100);
  //     }

  //   }
  // });
  useEffect(() => {
    let biggerVideo;
    let biggerCurrent;
    if (
      mobileOffsetTime &&
      webcamRef.current &&
      screenshareRef.current &&
      mobileRef.current &&
      mobileOffsetTime.web &&
      mobileOffsetTime.mobile1 &&
      mobileOffsetTime.screen
    ) {
      if (mobileOffsetTime.screen.startTime < mobileOffsetTime.web.startTime) {
        biggerCurrent = screenshareRef.current.currentTime;
        biggerVideo = mobileOffsetTime.screen.startTime;
      } else if (webcamDuration > screenDuration) {
        biggerCurrent = webcamRef.current.currentTime;
        biggerVideo = mobileOffsetTime.web.startTime;
      } else {
        biggerCurrent = webcamRef.current.currentTime;
        biggerVideo = mobileOffsetTime.web.startTime;
      }
      if (
        biggerCurrent >
        (mobileOffsetTime.mobile1.startTime - biggerVideo) / 1000
      ) {
        if (!playerInfo.paused) {
          setMobilePromise(mobileRef.current.play());
        }
      }

      if (
        biggerCurrent >
        (mobileOffsetTime.mobile2.startTime - biggerVideo) / 1000
      ) {
        if (!playerInfo.paused) {
          setMobile2Promise(mobile2Ref.current.play());
        }
      }

      if (
        biggerCurrent >
        (mobileOffsetTime.mobile3.startTime - biggerVideo) / 1000
      ) {
        if (!playerInfo.paused) {
          setMobile3Promise(mobile3Ref.current.play());
        }
      }

      if (
        biggerCurrent >
        (mobileOffsetTime.mobile4.startTime - biggerVideo) / 1000
      ) {
        if (!playerInfo.paused) {
          setMobile4Promise(mobile4Ref.current.play());
        }
      }
    }
  }, [videoCurrentTime, mobileOffsetTime]);
  useEffect(() => {
    //TODOSYNC BURA TEKRAR ELDEN GEÇECEk

    //Durationa göre değil start time'a göre diffler hesaplanacak
    if (mobileOffsetTime.web && webcamDuration && screenDuration) {
      if (webcamDuration > screenDuration) {
        const difference = webcamDuration - screenDuration;
        webcamRef.current.currentTime = difference;
        screenshareRef.current.currentTime = 0;
      } else {
        const difference = screenDuration - webcamDuration;
        screenshareRef.current.currentTime = difference;
        webcamRef.current.currentTime = 0;
      }
      const maxDuration = Math.max(webcamDuration, screenDuration);
      const input = document.getElementById("general_input");
      input.max = maxDuration;
      setIncludedStreams({
        ...includedStreams,
        webcam: {
          enabled: webcamDuration > 0 ? true : false,
          duration: webcamDuration,
        },
        screen: {
          enabled: screenDuration > 0 ? true : false,
          duration: screenDuration,
        },
        mobile: {
          enabled: mobileDuration > 0 ? true : false,
          duration: mobileDuration,
        },
        mobile1: {
          enabled: mobile1Duration > 0 ? true : false,
          duration: mobile1Duration,
        },
        mobile2: {
          enabled: mobile2Duration > 0 ? true : false,
          duration: mobile2Duration,
        },
        mobile3: {
          enabled: mobile3Duration > 0 ? true : false,
          duration: mobile3Duration,
        },
      });
    }
  }, [
    webcamDuration,
    screenDuration,
    mobileDuration,
    mobile1Duration,
    mobile2Duration,
    mobile3Duration,
    mobileOffsetTime.web,
    mobileOffsetTime.screen,
  ]);

  useEffect(() => {
    const input = document.getElementById("general_input");
    try {
      if (mobileOffsetTime.web.startTime > mobileOffsetTime.screen.startTime) {
        if (webcamRef.current) {
          webcamRef.current.ontimeupdate = (e) => {
            setVideoCurrentTime(e.target.currentTime);
            // input.value = e.target.currentTime;
          };
        }
      } else {
        if (screenshareRef.current) {
          screenshareRef.current.ontimeupdate = (e) => {
            setVideoCurrentTime(e.target.currentTime);
            // input.value = e.target.currentTime;
          };
        }
      }
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }, [mobileOffsetTime]);

  useEffect(() => {
    return async () => {
      if (
        selectedSession.sessionId &&
        selectedStudent.studentId &&
        !isProctor
      ) {
        const saveDuration = Math.floor(new Date().getTime() - duration);
        const param = {
          reviewingDuration: saveDuration,
          prevReviewingDuration: selectedStudent.reviewingDuration,
          studentId: selectedStudent.studentId,
          sessionId: selectedSession.sessionId,
          instituteId: context.userInstituteId,
        };
        await setSession("setReviewingTime", param, "setReviewingTime");
        const foundStudentIndex = selectedSession.studentList.findIndex(
          (student) => student.studentId === selectedStudent.studentId
        );
        const foundStudent = selectedSession.studentList[foundStudentIndex];
        if (foundStudent.reviewingDuration !== undefined) {
          foundStudent.reviewingDuration += saveDuration;
        } else {
          foundStudent.reviewingDuration = saveDuration;
        }
        selectedSession.studentList[foundStudentIndex] = foundStudent;
        context.setSelectedSession(selectedSession);
        const foundIndex = context.sessionList.findIndex(
          (session) => session.sessionId === selectedSession.sessionId
        );
        context.sessionList[foundIndex] = selectedSession;
        context.setSessionList(context.sessionList);
      }
    };
  }, [selectedSession, selectedStudent]);

  useEffect(() => {
    context.setAdres("libraryStudentDetailed");
    context.setBarGroup("EXAMS");
  }, []);

  useEffect(async () => {
    if (showResult === true) {
      if (
        selectedSession.proctorServiceType !== "Live_Proctoring_Lite" &&
        selectedSession.proctorServiceType !== "Record_and_Review_Lite"
      ) {
        var context = canvasRef.current.getContext("2d");
        let width = Math.ceil(
          (258 * webcamRef.current.videoWidth) / webcamRef.current.videoHeight
        );
        await setCustomVideoWidth(width);
        context.drawImage(webcamRef.current, 0, 0, width, 258);
      }
    }
  }, [showResult]);

  useEffect(() => {
    retrieveStudentResults(selectedSession, selectedStudent);
    if (webcamRef.current && screenshareRef.current) {
      webcamRef.current.playbackRate = videoSpeed;
      screenshareRef.current.playbackRate = videoSpeed;
      if (mobileRef.current) {
        mobileRef.current.playbackRate = videoSpeed;
      }
      if (mobile2Ref.current) {
        mobile2Ref.current.playbackRate = videoSpeed;
      }
      if (mobile3Ref.current) {
        mobile3Ref.current.playbackRate = videoSpeed;
      }
      if (mobile4Ref.current) {
        mobile4Ref.current.playbackRate = videoSpeed;
      }
    }
  }, [videoSpeed]);
  //VIDEO BAR COLOR SET FUNCTION
  useEffect(() => {
    const input = document.getElementById("general_input");
    let bar = "rgb(240, 240, 240)";
    let poinTime = 0;
    let webcamStart;
    let webDuration;

    if (studentResult) {
      meetingInformation.forEach((meeting) => {
        if (meeting.meetingId.includes("webcam")) {
          webcamStart = meeting.startTime;
          webDuration = (meeting.endTime - meeting.startTime) / 1000;
        }
      });

      studentResult.comments &&
        studentResult.comments.forEach((result) => {
          if (result.type === "AI") {
            poinTime = parseFloat(
              (parseInt(result.RecordTime) - parseInt(webcamStart)) / 1000
            );
            bar =
              bar +
              `,rgb(240, 240, 240) ${parseFloat(
                (100 * poinTime) / webDuration
              ) - 2}%,rgb(240, 240, 240) ,rgb(240, 240, 240) ${parseFloat(
                (100 * poinTime) / webDuration
              ) + 3}%,rgb(221, 127, 127),rgb(221, 127, 127)  ${parseFloat(
                (100 * poinTime) / webDuration
              ) + 3}%,rgb(221, 127, 127),rgb(221, 127, 127)  ${parseFloat(
                (100 * poinTime) / webDuration
              ) + 5}%,rgb(240, 240, 240),rgb(240, 240, 240) ${parseFloat(
                (100 * poinTime) / webDuration
              ) + 3}%`;
          } else {
            poinTime = parseFloat(
              (parseInt(result.time) - parseInt(webcamStart)) / 1000
            );
            bar =
              bar +
              `,rgb(240, 240, 240) ${parseFloat(
                (100 * poinTime) / webDuration
              ) - 2}%,rgb(240, 240, 240) ,rgb(240, 240, 240) ${parseFloat(
                (100 * poinTime) / webDuration
              ) + 3}%,rgb(231, 152, 36),rgb(231, 152, 36)  ${parseFloat(
                (100 * poinTime) / webDuration
              ) + 3}%,rgb(231, 152, 36),rgb(231, 152, 36)  ${parseFloat(
                (100 * poinTime) / webDuration
              ) + 7}%,rgb(240, 240, 240),rgb(240, 240, 240) ${parseFloat(
                (100 * poinTime) / webDuration
              ) + 3}%`;
          }
        });

      studentResult.comments &&
        studentResult.comments.forEach((result) => {
          if (result.type === "AI") {
            poinTime = parseFloat(
              (parseInt(result.RecordTime) - parseInt(webcamStart)) / 1000
            );
            bar =
              bar +
              `,rgb(240, 240, 240) ${parseFloat(
                (100 * poinTime) / webDuration
              ) - 2}%,rgb(240, 240, 240) ,rgb(240, 240, 240) ${parseFloat(
                (100 * poinTime) / webDuration
              ) + 3}%,rgb(221, 127, 127),rgb(221, 127, 127)  ${parseFloat(
                (100 * poinTime) / webDuration
              ) + 3}%,rgb(221, 127, 127),rgb(221, 127, 127)  ${parseFloat(
                (100 * poinTime) / webDuration
              ) + 5}%,rgb(240, 240, 240),rgb(240, 240, 240) ${parseFloat(
                (100 * poinTime) / webDuration
              ) + 3}%`;
          } else {
            poinTime = parseFloat(
              (parseInt(result.time) - parseInt(webcamStart)) / 1000
            );
            bar =
              bar +
              `,rgb(240, 240, 240) ${parseFloat(
                (100 * poinTime) / webDuration
              ) - 2}%,rgb(240, 240, 240) ,rgb(240, 240, 240) ${parseFloat(
                (100 * poinTime) / webDuration
              ) + 3}%,rgb(231, 152, 36),rgb(231, 152, 36)  ${parseFloat(
                (100 * poinTime) / webDuration
              ) + 3}%,rgb(231, 152, 36),rgb(231, 152, 36)  ${parseFloat(
                (100 * poinTime) / webDuration
              ) + 7}%,rgb(240, 240, 240),rgb(240, 240, 240) ${parseFloat(
                (100 * poinTime) / webDuration
              ) + 3}%`;
          }
        });
      if (selectedSession.proctorServiceType !== "Record_and_Review_Lite")
        input.style.backgroundImage = `linear-gradient(to right,${bar})`;
    }
  }, [studentResult]);

  const updateStudentComments = () => {
    let studentComments = selectedStudent.comments;
    if (!studentComments) {
      studentComments = [];
    }
    selectedStudent.comments = studentComments;
  };

  const retrieveStudentResults = async (selectedSession, selectedStudent) => {
    try {
      const res = await axios.get(
        `${config.api.invokeUrl}/studentResults/${selectedSession.sessionId}${selectedStudent.studentId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      setStudentResult(res.data);
    } catch (err) {
      console.log("Student Result not found");
    }
  };

  const retrieveDefaultStudentResults = () => {
    retrieveStudentResults(selectedSession, selectedStudent);
  };

  const calculateSingleDuration = (startTime, endTime) => {
    const startDateInfo = startTime.split(":");
    const startMins =
      parseInt(startDateInfo[0]) * 60 + parseInt(startDateInfo[1]);
    const endDateInfo = endTime.split(":");
    const endMins = parseInt(endDateInfo[0]) * 60 + parseInt(endDateInfo[1]);
    return endMins - startMins;
  };

  const calculateDetailedDateFromTime = (time) => {
    const dateTime = new Date(time);
    const date = dateTime.toDateString();
    const seconds =
      dateTime.getSeconds() < 10
        ? `0${dateTime.getSeconds()}`
        : dateTime.getSeconds();
    const hms = `${dateTime.getHours()}:${dateTime.getMinutes()}:${seconds}`;
    return [date, hms];
  };

  const calculateExamAttendTime = (studentMeetingInfo) => {
    if (!studentMeetingInfo || studentMeetingInfo.length === 0) {
      return "";
    }
    const studentMeeting = studentMeetingInfo[0];

    if (studentMeeting.startTime) {
      let meetingStartTime = Infinity;
      if (studentMeetingInfo.length > 1) {
        for (let i = 0; i < studentMeetingInfo.length; i++) {
          if (meetingStartTime > Number(studentMeetingInfo[i].startTime)) {
            meetingStartTime = Number(studentMeetingInfo[i].startTime);
          }
        }
      } else {
        meetingStartTime = Number(studentMeeting.startTime);
      }

      const [date, startHMS] = calculateDetailedDateFromTime(
        meetingStartTime
      );
      if (studentMeeting.endTime) {
        let meetingEndTime = -Infinity;
        if (studentMeetingInfo.length > 1) {
          for (let i = 0; i < studentMeetingInfo.length; i++) {
            if (meetingEndTime < Number(studentMeetingInfo[i].endTime)) {
              meetingEndTime = Number(studentMeetingInfo[i].endTime);
            }
          }
        } else {
          meetingEndTime = Number(studentMeeting.endTime);
        }
        
        const [, endHMS] = calculateDetailedDateFromTime(
          meetingEndTime
        );
        const duration = calculateSingleDuration(startHMS, endHMS);
        return (
          date +
          " - " +
          startHMS +
          "/" +
          endHMS +
          " (" +
          duration +
          " " +
          i18n.t("mins") +
          ")"
        );
      } else {
        return date + " - " + startHMS + "/~";
      }
    }
    return "~";
  };

  const changeCurrentTime = (seconds) => {
    setPlayerInfo((playerInfo) => ({
      ...playerInfo,
      paused: true,
    }));
    //CHANGE CURRENT TIME SYNC OPERATION
    if (webcamPromise !== undefined) {
      webcamPromise
        .then((_) => {
          webcamRef.current.pause();
        })
        .catch((error) => { });
      setWebcamPromise(undefined);
    }
    if (screenSharePromise !== undefined) {
      screenSharePromise
        .then((_) => {
          screenshareRef.current.pause();
        })
        .catch((error) => { });
      setScreenSharePromise(undefined);
    }
    if (selectedStudent.examRules.mobileCameraUsage) {
      if (mobilePromise !== undefined) {
        mobilePromise
          .then((_) => {
            mobileRef.current.pause();
          })
          .catch((error) => { });
        setMobilePromise(undefined);
      }
      if (mobile2Promise !== undefined) {
        mobile2Promise
          .then((_) => {
            mobile2Ref.current.pause();
          })
          .catch((error) => { });
        setMobile2Promise(undefined);
      }
      if (mobile3Promise !== undefined) {
        mobile3Promise
          .then((_) => {
            mobile3Ref.current.pause();
          })
          .catch((error) => { });
        setMobile3Promise(undefined);
      }
      if (mobile4Promise !== undefined) {
        mobile4Promise
          .then((_) => {
            mobile4Ref.current.pause();
          })
          .catch((error) => { });
        setMobile4Promise(undefined);
      }
    }
    webcamRef.current.currentTime = seconds;
    screenshareRef.current.currentTime = seconds;
    mobileRef.current.currentTime = seconds;
    const input = document.getElementById("general_input");

    //Burası değişecek
    if (includedStreams.webcam.duration > includedStreams.screen.duration) {
      webcamRef.current.currentTime = seconds;
      const difference =
        includedStreams.webcam.duration - includedStreams.screen.duration;
      if (seconds < difference) {
        if (
          selectedStudent.mobileCameraUsage ||
          selectedSession.systemCheck.mobileCameraUsage
        ) {
          const mobiledifference =
            (mobileOffsetTime.mobile1.startTime -
              mobileOffsetTime.web.startTime) /
            1000;
          if (seconds < mobiledifference) {
            // mobileRef.current.currentTime = 0;
            setMobileCamCanPlay((prevState) => ({
              ...prevState,
              mobile1: false,
            }));
            if (mobile2Ref.current) {
              // mobile2Ref.current.currentTime = 0;
              setMobileCamCanPlay((prevState) => ({
                ...prevState,
                mobile2: false,
              }));
              if (mobile3Ref.current) {
                // mobile3Ref.current.currentTime = 0;
                setMobileCamCanPlay((prevState) => ({
                  ...prevState,
                  mobile3: false,
                }));
                if (mobile4Ref.current) {
                  // mobile4Ref.current.currentTime = 0;
                  setMobileCamCanPlay((prevState) => ({
                    ...prevState,
                    mobile4: false,
                  }));
                }
              }
            }
          } else {
            // mobileRef.current.currentTime = seconds;
            setMobileCamCanPlay((prevState) => ({
              ...prevState,
              mobile1: true,
            }));
            if (mobile2Ref.current) {
              const mobile1difference =
                (mobileOffsetTime.mobile2.startTime -
                  mobileOffsetTime.web.startTime) /
                1000;
              mobile2Ref.current.currentTime = seconds - mobile1difference;
              setMobileCamCanPlay((prevState) => ({
                ...prevState,
                mobile2: true,
              }));
              if (mobile3Ref.current) {
                const mobile2difference =
                  (mobileOffsetTime.mobile3.startTime -
                    mobileOffsetTime.web.startTime) /
                  1000;
                mobile3Ref.current.currentTime = seconds - mobile2difference;
                setMobileCamCanPlay((prevState) => ({
                  ...prevState,
                  mobile3: true,
                }));
                if (mobile4Ref.current) {
                  const mobile3difference =
                    (mobileOffsetTime.mobile4.startTime -
                      mobileOffsetTime.web.startTime) /
                    1000;
                  mobile4Ref.current.currentTime = seconds - mobile3difference;
                  setMobileCamCanPlay((prevState) => ({
                    ...prevState,
                    mobile4: true,
                  }));
                }
              }
            }
          }
        }
        screenshareRef.current.currentTime = 0;
      } else {
        if (
          selectedStudent.mobileCameraUsage ||
          selectedSession.systemCheck.mobileCameraUsage
        ) {
          const mobiledifference =
            (mobileOffsetTime.mobile1.startTime -
              mobileOffsetTime.web.startTime) /
            1000;
          if (seconds < mobiledifference) {
            // mobileRef.current.currentTime = 0;
            setMobileCamCanPlay((prevState) => ({
              ...prevState,
              mobile1: false,
            }));
            if (mobile2Ref.current) {
              // mobile2Ref.current.currentTime = 0;
              setMobileCamCanPlay((prevState) => ({
                ...prevState,
                mobile2: false,
              }));
              if (mobile3Ref.current) {
                // mobile3Ref.current.currentTime = 0;
                setMobileCamCanPlay((prevState) => ({
                  ...prevState,
                  mobile3: false,
                }));
                if (mobile4Ref.current) {
                  // mobile4Ref.current.currentTime = 0;
                  setMobileCamCanPlay((prevState) => ({
                    ...prevState,
                    mobile4: false,
                  }));
                }
              }
            }
          } else {
            if (mobiledifference)
              // mobileRef.current.currentTime = seconds;
              setMobileCamCanPlay((prevState) => ({
                ...prevState,
                mobile1: true,
              }));
            if (mobile2Ref.current) {
              const mobile1difference =
                (mobileOffsetTime.mobile2.startTime -
                  mobileOffsetTime.web.startTime) /
                1000;
              mobile2Ref.current.currentTime = seconds - mobile1difference;
              setMobileCamCanPlay((prevState) => ({
                ...prevState,
                mobile2: true,
              }));
              if (mobile3Ref.current) {
                const mobile2difference =
                  (mobileOffsetTime.mobile3.startTime -
                    mobileOffsetTime.web.startTime) /
                  1000;
                mobile3Ref.current.currentTime = seconds - mobile2difference;
                setMobileCamCanPlay((prevState) => ({
                  ...prevState,
                  mobile3: true,
                }));
                if (mobile4Ref.current) {
                  const mobile3difference =
                    (mobileOffsetTime.mobile4.startTime -
                      mobileOffsetTime.web.startTime) /
                    1000;
                  mobile4Ref.current.currentTime = seconds - mobile3difference;
                  setMobileCamCanPlay((prevState) => ({
                    ...prevState,
                    mobile4: true,
                  }));
                }
              }
            }
          }
        }
        screenshareRef.current.currentTime = seconds - difference;
      }
      // input.value = webcamRef.current.currentTime;
    } else {
      screenshareRef.current.currentTime = seconds;
      const difference =
        includedStreams.screen.duration - includedStreams.webcam.duration;
      if (seconds < difference) {
        if (
          selectedStudent.mobileCameraUsage ||
          selectedSession.systemCheck.mobileCameraUsage
        ) {
          const mobiledifference =
            (mobileOffsetTime.mobile1.startTime -
              mobileOffsetTime.screen.startTime) /
            1000;
          if (seconds < mobiledifference) {
            // mobileRef.current.currentTime = 0;
            setMobileCamCanPlay((prevState) => ({
              ...prevState,
              mobile1: false,
            }));
            if (mobile2Ref.current) {
              // mobile2Ref.current.currentTime = 0;
              setMobileCamCanPlay((prevState) => ({
                ...prevState,
                mobile2: false,
              }));
              if (mobile3Ref.current) {
                // mobile3Ref.current.currentTime = 0;
                setMobileCamCanPlay((prevState) => ({
                  ...prevState,
                  mobile3: false,
                }));
                if (mobile4Ref.current) {
                  // mobile4Ref.current.currentTime = 0;
                  setMobileCamCanPlay((prevState) => ({
                    ...prevState,
                    mobile4: false,
                  }));
                }
              }
            }
          } else {
            if (mobiledifference)
              // mobileRef.current.currentTime = seconds;
              setMobileCamCanPlay((prevState) => ({
                ...prevState,
                mobile1: true,
              }));
            if (mobile2Ref.current) {
              const mobile1difference =
                (mobileOffsetTime.mobile2.startTime -
                  mobileOffsetTime.screen.startTime) /
                1000;
              mobile2Ref.current.currentTime = seconds - mobile1difference;
              setMobileCamCanPlay((prevState) => ({
                ...prevState,
                mobile2: true,
              }));
              if (mobile3Ref.current) {
                const mobile2difference =
                  (mobileOffsetTime.mobile3.startTime -
                    mobileOffsetTime.screen.startTime) /
                  1000;
                mobile3Ref.current.currentTime = seconds - mobile2difference;
                setMobileCamCanPlay((prevState) => ({
                  ...prevState,
                  mobile3: true,
                }));
                if (mobile4Ref.current) {
                  const mobile3difference =
                    (mobileOffsetTime.mobile4.startTime -
                      mobileOffsetTime.screen.startTime) /
                    1000;
                  mobile4Ref.current.currentTime = seconds - mobile3difference;
                  setMobileCamCanPlay((prevState) => ({
                    ...prevState,
                    mobile4: true,
                  }));
                }
              }
            }
          }
        }
        webcamRef.current.currentTime = 0;
      } else {
        if (
          selectedStudent.mobileCameraUsage ||
          selectedSession.systemCheck.mobileCameraUsage
        ) {
          const mobiledifference =
            (mobileOffsetTime.mobile1.startTime -
              mobileOffsetTime.screen.startTime) /
            1000;
          if (seconds < mobiledifference) {
            // mobileRef.current.currentTime = 0;
            setMobileCamCanPlay((prevState) => ({
              ...prevState,
              mobile1: false,
            }));
            if (mobile2Ref.current) {
              // mobile2Ref.current.currentTime = 0;
              setMobileCamCanPlay((prevState) => ({
                ...prevState,
                mobile2: false,
              }));
              if (mobile3Ref.current) {
                // mobile3Ref.current.currentTime = 0;
                setMobileCamCanPlay((prevState) => ({
                  ...prevState,
                  mobile3: false,
                }));
                if (mobile4Ref.current) {
                  // mobile4Ref.current.currentTime = 0;
                  setMobileCamCanPlay((prevState) => ({
                    ...prevState,
                    mobile4: false,
                  }));
                }
              }
            }
          } else {
            if (mobiledifference)
              // mobileRef.current.currentTime = seconds;
              setMobileCamCanPlay((prevState) => ({
                ...prevState,
                mobile1: true,
              }));

            if (mobile2Ref.current) {
              const mobile1difference =
                (mobileOffsetTime.mobile2.startTime -
                  mobileOffsetTime.screen.startTime) /
                1000;
              mobile2Ref.current.currentTime = seconds - mobile1difference;
              setMobileCamCanPlay((prevState) => ({
                ...prevState,
                mobile2: true,
              }));
              if (mobile3Ref.current) {
                const mobile2difference =
                  (mobileOffsetTime.mobile3.startTime -
                    mobileOffsetTime.screen.startTime) /
                  1000;
                mobile3Ref.current.currentTime = seconds - mobile2difference;
                setMobileCamCanPlay((prevState) => ({
                  ...prevState,
                  mobile3: true,
                }));
                if (mobile4Ref.current) {
                  const mobile3difference =
                    (mobileOffsetTime.mobile4.startTime -
                      mobileOffsetTime.screen.startTime) /
                    1000;
                  mobile4Ref.current.currentTime = seconds - mobile3difference;
                  setMobileCamCanPlay((prevState) => ({
                    ...prevState,
                    mobile4: true,
                  }));
                }
              }
            }
          }
        }
        webcamRef.current.currentTime = seconds - difference;
      }

      // input.value = screenshareRef.current.currentTime;
    }
    setPlayerInfo((playerInfo) => ({
      ...playerInfo,
      paused: false,
    }));
    if (selectedStudent.examRules.mobileCameraUsage) {
      webcamRef.current.oncanplay = () => {
        screenshareRef.current.oncanplay = () => {
          if (selectedSession.allowedOutsourceCamera < 2) {
            setWebcamPromise(webcamRef.current.play());
            setScreenSharePromise(screenshareRef.current.play());
            // if (mobileCamCanPlay.mobile1) {
            setMobilePromise(mobileRef.current.play());
            // }
          } else if (selectedSession.allowedOutsourceCamera < 3) {
            setWebcamPromise(webcamRef.current.play());
            setScreenSharePromise(screenshareRef.current.play());
            if (mobileCamCanPlay.mobile1) {
              setMobilePromise(mobileRef.current.play());
            }
            if (mobileCamCanPlay.mobile2) {
              setMobile2Promise(mobile2Ref.current.play());
            }
          } else if (selectedSession.allowedOutsourceCamera < 4) {
            setWebcamPromise(webcamRef.current.play());
            setScreenSharePromise(screenshareRef.current.play());
            if (mobileCamCanPlay.mobile1) {
              setMobilePromise(mobileRef.current.play());
            }

            if (mobileCamCanPlay.mobile2) {
              setMobile2Promise(mobile2Ref.current.play());
            }
            if (mobileCamCanPlay.mobile3) {
              setMobile3Promise(mobile3Ref.current.play());
            }
          } else if (selectedSession.allowedOutsourceCamera < 5) {
            setWebcamPromise(webcamRef.current.play());
            setScreenSharePromise(screenshareRef.current.play());
            if (mobileCamCanPlay.mobile1) {
              setMobilePromise(mobileRef.current.play());
            }

            if (mobileCamCanPlay.mobile2) {
              setMobile2Promise(mobile2Ref.current.play());
            }
            if (mobileCamCanPlay.mobile3) {
              setMobile3Promise(mobile3Ref.current.play());
            }
            if (mobileCamCanPlay.mobile4) {
              setMobile4Promise(mobile4Ref.current.play());
            }
          }
        };
      };
    } else {
      webcamRef.current.oncanplay = () => {
        screenshareRef.current.oncanplay = () => {
          setWebcamPromise(webcamRef.current.play());
          setScreenSharePromise(screenshareRef.current.play());
        };
      };
    }
  };

  const handleClose = () => {
    setShowResult(false);
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const saveCommand = async () => {
    const file = dataURLtoFile(
      canvasRef.current.toDataURL(),
      `${new Date().getTime()}.png`
    );
    await uploadObject(
      file,
      file.name,
      `${selectedSession.sessionId}${selectedStudent.studentId}/detections/`
    );
    const webcamMeeting = meetingInformation.find((meetingInfo) =>
      meetingInfo.meetingId.includes("webcam")
    );
    const screenShareMeeting = meetingInformation.find((meetingInfo) =>
      meetingInfo.meetingId.includes("screenShare")
    );
    const value = document.getElementById("general_input").value;
    let calculatedTimestamp;
    if (webcamMeeting.startTime < screenShareMeeting.startTime) {
      calculatedTimestamp = webcamMeeting.startTime + value * 1000;
    } else {
      calculatedTimestamp = screenShareMeeting.startTime + value * 1000;
    }
    const params = {
      meetingId: `${selectedSession.sessionId}${selectedStudent.studentId}`,
      comments: [
        {
          text: readyMessage === "Other..." ? command : readyMessage,
          image: `${selectedSession.sessionId}${selectedStudent.studentId}/detections/${file.name}`,
          type: "Reviewer",
          proctor: context.user.email,
          proctorFullName: `${context.user.name} ${context.user.lastname}`,
          time: calculatedTimestamp,
          source: camera,
        },
      ],
    };
    if (studentResult) {
      params.type = "ADD_COMMENT";
      await studentResultOperation("UPDATE", params);
    } else {
      await studentResultOperation("CREATE", params);
    }
    retrieveStudentResults(selectedSession, selectedStudent);
    setShowResult(false);
  };

  const openCommand = () => {
    setShowResult(true);
  };

  const downloadWebcam = async () => {
    setLoadingWebcam(true);
    try {
      await axios
        .get(
          `${getBaseUrl()}/${selectedSession.sessionId}${
            selectedStudent.studentId
          }/exam/${selectedSession.sessionId}${
            selectedStudent.studentId
          }-webcam.webm`,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          fileDownload(
            res.data,
            `${selectedSession.sessionId}${selectedStudent.studentId}-webcam.webm`
          );
        });
    } catch (error) {
      console.log("Webcam", error.message);
    }
    setLoadingWebcam(false);
  };

  // useEffect(() => {
  //   console.log("screenShareStartDate => ", screenShareStartDate);
  //   console.log("webcamStartDate => ", webcamStartDate);
  //   console.log("screenShareEndDate => ", screenShareEndDate);
  //   // console.log("diff => ", (screenShareStartDate - webcamStartDate) / 1000);
  //   console.log("diff => ", (screenShareEndDate-screenShareStartDate) / 1000);
  //   console.log("diff TIME => ", webcamRef.current.currentTime);
  //   console.log("tesst sol", webcamRef.current.duration - ((screenShareEndDate-screenShareStartDate) / 1000));
  //   console.log("tesst sağ", webcamRef.current.currentTime);
  // }, [screenShareStartDate, webcamStartDate])


  const downloadScreenShare = async () => {
    setLoadingScreenShare(true);
    try {
      await axios
        .get(
          `${getBaseUrl()}/${selectedSession.sessionId}${
            selectedStudent.studentId
          }/exam/${selectedSession.sessionId}${
            selectedStudent.studentId
          }-screenShare.webm`,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          fileDownload(
            res.data,
            `${selectedSession.sessionId}${selectedStudent.studentId}-screenShare.webm`
          );
        });
    } catch (error) {
      console.log("Screen Share", error.message);
    }
    setLoadingScreenShare(false);
  };

  const downloadMobileCam = async () => {
    setLoadingMobiles(true);
    try {
      for (
        let index = 0;
        index < selectedSession.allowedOutsourceCamera;
        index++
      ) {
        await axios
          .get(
            `${getBaseUrl()}/${selectedSession.sessionId}${
              selectedStudent.studentId
            }/exam/${selectedSession.sessionId}${
              selectedStudent.studentId
            }-mobile_${index}.webm`,
            {
              responseType: "blob",
            }
          )
          .then((res) => {
            fileDownload(
              res.data,
              `${selectedSession.sessionId}${selectedStudent.studentId}-mobile_${index}.webm`
            );
          });
      }
    } catch (error) {
      console.log("Mobile", error.message);
    }
    setLoadingMobiles(false);
  };

  let myTimeout;
  let counter = 1;
  const checkSync = async () => {
    // console.log("-------checkSync çalışıyor--------");

    let webcamStartTime;
    let screenShareStartTime;
    let mobile0StartTime;
    let mobile1StartTime;
    let mobile2StartTime;
    let mobile3StartTime;
    let webcamEndTime;
    let screenShareEndTime;
    let mobile0EndTime;
    let mobile1EndTime;
    let mobile2EndTime;
    let mobile3EndTime;
    meetingInformation.forEach(async (meeting, index) => {
      if (meeting.meetingId === `${selectedSession.sessionId}${selectedStudent.studentId}-webcam`) {
        webcamStartTime = meeting.startTime;
        webcamEndTime = meeting.endTime;
      } else if (meeting.meetingId === `${selectedSession.sessionId}${selectedStudent.studentId}-screenShare`) {
        screenShareStartTime = meeting.startTime;
        screenShareEndTime = meeting.endTime;
      } else if (meeting.meetingId === `${selectedSession.sessionId}${selectedStudent.studentId}-mobile_0`) {
        mobile0StartTime = meeting.startTime;
        mobile0EndTime = meeting.endTime;
      } else if (meeting.meetingId === `${selectedSession.sessionId}${selectedStudent.studentId}-mobile_1`) {
        mobile1StartTime = meeting.startTime;
        mobile1EndTime = meeting.endTime;
      } else if (meeting.meetingId === `${selectedSession.sessionId}${selectedStudent.studentId}-mobile_2`) {
        mobile2StartTime = meeting.startTime;
        mobile2EndTime = meeting.endTime;
      } else if (meeting.meetingId === `${selectedSession.sessionId}${selectedStudent.studentId}-mobile_3`) {
        mobile3StartTime = meeting.startTime;
        mobile3EndTime = meeting.endTime;
      }
    });
    var webcamStartDate = new Date(webcamStartTime);
    // setWebcamStartDate(webcamStartDate);
    var screenShareStartDate = new Date(screenShareStartTime);
    // setScreenShareStartDate(screenShareStartDate);
    var mobile0StartDate = new Date(mobile0StartTime);
    var mobile1StartDate = new Date(mobile1StartTime);
    var mobile2StartDate = new Date(mobile2StartTime);
    var mobile3StartDate = new Date(mobile3StartTime);

    var webcamEndDate = new Date(webcamEndTime);
    var screenShareEndDate = new Date(screenShareEndTime);
    // setScreenShareEndDate(screenShareEndDate);
    var mobile0EndDate = new Date(mobile0EndTime);
    var mobile1EndDate = new Date(mobile1EndTime);

    console.log("webcam Startdate => ", webcamStartDate);
    console.log("screen Startdate => ", screenShareStartDate);
    console.log("mobil0 Startdate => ", mobile0StartDate);
    // console.log("mobil1 Startdate => ", mobile1StartDate);

    // console.log("webcam Enddate => ", webcamEndDate);
    // console.log("screen Enddate => ", screenShareEndDate);
    // console.log("mobil0 Enddate => ", mobile0EndDate);
    // console.log("mobil1 Enddate => ", mobile1EndDate);

    console.log("webcam duration DB => ", (webcamEndDate.getTime() - webcamStartDate.getTime()) / 1000);
    console.log("screen duration DB => ", (screenShareEndDate.getTime() - screenShareStartDate.getTime()) / 1000);
    console.log("mobil0 duration DB => ", (mobile0EndDate.getTime() - mobile0StartDate.getTime()) / 1000);
    // console.log("mobil1 duration DB => ", (mobile1EndDate.getTime() - mobile1StartDate.getTime()) / 1000);
    // if (webcamRef.current.duration - ((screenShareEndDate-screenShareStartDate) / 1000) < webcamRef.current.currentTime){
    //   setScreenSharePromise(screenshareRef.current.play());
    // } else {
    //   console.log("PAUSE");
    //   screenshareRef.current.pause();
    //   setScreenSharePromise(undefined);
    // }
    if (selectedStudent.examRules.mobileCameraUsage) {//sınavda mobil varsa
      if (!mobile4Ref.current && !mobile3Ref.current && !mobile2Ref.current) {//sınavda 1 mobil var
        let diffMobileWebcam = ((mobile0StartDate.getTime() - webcamStartDate.getTime()) / 1000);
        console.log("diffMobileWebcam => ", diffMobileWebcam);
        console.log("webcam.CurrentTime => ", webcamRef.current.currentTime);
        console.log("mobile.CurrentTime => ", mobileRef.current.currentTime);
        if ((webcamRef.current.currentTime > diffMobileWebcam)) {//mobil-1 oynaması gerekiyor
          console.log("mobil oynaması gerek");
          if ((((webcamRef.current.currentTime - diffMobileWebcam) - mobileRef.current.currentTime) > 1))
            //mobileRef.current.currentTime = webcamRef.current.currentTime;//mobili olması gereken yere sürükler
            if (webcamRef.current.readyState !== 4 || screenshareRef.current.readyState !== 4 ||
              mobileRef.current.readyState !== 4) {//herhangi biri hazır değilse videoları durdurur.
              console.log("if");
              counter = 2;
              webcamRef.current.pause();
              screenshareRef.current.pause();
              mobileRef.current.pause();
              setPlayerInfo((playerInfo) => ({
                ...playerInfo,
                paused: true,
              }));
            }
            else if (playerInfo.paused && counter === 2) {//webcam & screenShare & mobil are ready to play
              console.log("else");
              counter = 1;
              setWebcamPromise(webcamRef.current.play());
              setScreenSharePromise(screenshareRef.current.play());
              setMobilePromise(mobileRef.current.play());
              setPlayerInfo((playerInfo) => ({
                ...playerInfo,
                paused: false,
              }));
            }
        } else {//mobil-1 oynamaması gerekiyor
          if (mobilePromise !== undefined) {
            mobilePromise
              .then((_) => {
                mobileRef.current.pause();
              })
              .catch((error) => { });
            setMobilePromise(undefined);
          }
          // mobileRef.current.currentTime = 0;
        }
      } else if (!mobile4Ref.current && !mobile3Ref.current) {//sınavda 2 mobil varsa
        let diffMobileWebcam =
          (mobile0StartDate.getTime() - webcamStartDate.getTime()) / 1000;
        if (webcamRef.current.currentTime > diffMobileWebcam) {
          //mobil oynaması gerekiyor
          if (
            webcamRef.current.currentTime -
            diffMobileWebcam -
            mobileRef.current.currentTime >
            1
          ) { }
          // mobileRef.current.currentTime =
          //   webcamRef.current.currentTime; //mobili olması gereken yere sürükler
        } else {
          //mobil-1 oynamaması gerekiyor
          if (mobilePromise !== undefined) {
            mobilePromise
              .then((_) => {
                mobileRef.current.pause();
              })
              .catch((error) => { });
            setMobilePromise(undefined);
          }
          // mobileRef.current.currentTime = 0;
        }
        let diffMobile2Webcam =
          (mobile1StartDate.getTime() - webcamStartDate.getTime()) / 1000;
        if (webcamRef.current.currentTime > diffMobile2Webcam) {
          //mobil2 oynaması gerekiyor
          if (
            webcamRef.current.currentTime -
            diffMobile2Webcam -
            mobile2Ref.current.currentTime >
            1
          )
            mobile2Ref.current.currentTime =
              webcamRef.current.currentTime - diffMobile2Webcam; //mobil2'yi olması gereken yere sürükler
          if (
            webcamRef.current.readyMessage === 4 ||
            screenshareRef.current.readyState !== 4 ||
            mobileRef.current.readyState !== 4 ||
            mobile2Ref.current.readyState !== 4
          ) {
            counter = 2;
            webcamRef.current.pause();
            screenshareRef.current.pause();
            mobileRef.current.pause();
            mobile2Ref.current.pause();
            setPlayerInfo((playerInfo) => ({
              ...playerInfo,
              paused: true,
            }));
          } else if (playerInfo.paused && counter === 2) {
            counter = 1;
            setWebcamPromise(webcamRef.current.play());
            setScreenSharePromise(screenshareRef.current.play());
            setMobilePromise(mobileRef.current.play());
            setMobile2Promise(mobile2Ref.current.play());
            setPlayerInfo((playerInfo) => ({
              ...playerInfo,
              paused: false,
            }));
          }
        } else {
          //mobil2 oynamaması gerekiyor
          if (mobile2Promise !== undefined) {
            mobile2Promise
              .then((_) => {
                mobile2Ref.current.pause();
              })
              .catch((error) => { });
            setMobile2Promise(undefined);
          }
          // mobile2Ref.current.currentTime = 0;
        }
      } else if (!mobile4Ref.current) {
        //sınavda 3 mobil varsa
        let diffMobileWebcam =
          (mobile0StartDate.getTime() - webcamStartDate.getTime()) / 1000;
        if (webcamRef.current.currentTime > diffMobileWebcam) {
          //mobil oynaması gerekiyor
          if (
            webcamRef.current.currentTime -
            diffMobileWebcam -
            mobileRef.current.currentTime >
            1
          ) { }
          // mobileRef.current.currentTime =
          //   webcamRef.current.currentTime; //mobili olması gereken yere sürükler
        } else {
          //mobil-1 oynamaması gerekiyor
          if (mobilePromise !== undefined) {
            mobilePromise
              .then((_) => {
                mobileRef.current.pause();
              })
              .catch((error) => { });
            setMobilePromise(undefined);
          }
          // mobileRef.current.currentTime = 0;
        }
        let diffMobile2Webcam =
          (mobile1StartDate.getTime() - webcamStartDate.getTime()) / 1000;
        if (webcamRef.current.currentTime > diffMobile2Webcam) {
          //mobil-2 oynaması gerekiyor
          if (
            webcamRef.current.currentTime -
            diffMobile2Webcam -
            mobile2Ref.current.currentTime >
            1
          )
            mobile2Ref.current.currentTime =
              webcamRef.current.currentTime - diffMobile2Webcam; //mobili olması gereken yere sürükler
        } else {
          //mobil-2 oynamaması gerekiyor
          if (mobile2Promise !== undefined) {
            mobile2Promise
              .then((_) => {
                mobile2Ref.current.pause();
              })
              .catch((error) => { });
            setMobile2Promise(undefined);
          }
          // mobile2Ref.current.currentTime = 0;
        }
        let diffMobile3Webcam =
          (mobile2StartDate.getTime() - webcamStartDate.getTime()) / 1000;
        if (webcamRef.current.currentTime > diffMobile3Webcam) {
          //mobil3 oynaması gerekiyor
          if (
            webcamRef.current.currentTime -
            diffMobile3Webcam -
            mobile3Ref.current.currentTime >
            1
          )
            mobile3Ref.current.currentTime =
              webcamRef.current.currentTime - diffMobile3Webcam;
          if (
            webcamRef.current.readyMessage === 4 ||
            screenshareRef.current.readyState !== 4 ||
            mobileRef.current.readyState !== 4 ||
            mobile2Ref.current.readyState !== 4 ||
            mobile3Ref.current.readyState !== 4
          ) {
            counter = 2;
            webcamRef.current.pause();
            screenshareRef.current.pause();
            mobileRef.current.pause();
            mobile2Ref.current.pause();
            mobile3Ref.current.pause();
            setPlayerInfo((playerInfo) => ({
              ...playerInfo,
              paused: true,
            }));
          } else if (playerInfo.paused && counter === 2) {
            counter = 1;
            setWebcamPromise(webcamRef.current.play());
            setScreenSharePromise(screenshareRef.current.play());
            setMobilePromise(mobileRef.current.play());
            setMobile2Promise(mobile2Ref.current.play());
            setMobile3Promise(mobile3Ref.current.play());
            setPlayerInfo((playerInfo) => ({
              ...playerInfo,
              paused: false,
            }));
          }
        } else {
          //mobil3 oynamaması gerekiyor
          if (mobile3Promise !== undefined) {
            mobile3Promise
              .then((_) => {
                mobile3Ref.current.pause();
              })
              .catch((error) => { });
            setMobile3Promise(undefined);
          }
          // mobile3Ref.current.currentTime = 0;
        }
      } else {
        //sınavda 4 mobil varsa
        let diffMobileWebcam =
          (mobile0StartDate.getTime() - webcamStartDate.getTime()) / 1000;
        if (webcamRef.current.currentTime > diffMobileWebcam) {
          //mobil oynaması gerekiyor
          if (
            webcamRef.current.currentTime -
            diffMobileWebcam -
            mobileRef.current.currentTime >
            1
          ) { }
          // mobileRef.current.currentTime =
          //   webcamRef.current.currentTime; //mobili olması gereken yere sürükler
        } else {
          //mobil-1 oynamaması gerekiyor
          if (mobilePromise !== undefined) {
            mobilePromise
              .then((_) => {
                mobileRef.current.pause();
              })
              .catch((error) => { });
            setMobilePromise(undefined);
          }
          // mobileRef.current.currentTime = 0;
        }
        let diffMobile2Webcam =
          (mobile1StartDate.getTime() - webcamStartDate.getTime()) / 1000;
        if (webcamRef.current.currentTime > diffMobile2Webcam) {
          //mobil-2 oynaması gerekiyor
          if (
            webcamRef.current.currentTime -
            diffMobile2Webcam -
            mobile2Ref.current.currentTime >
            1
          )
            mobile2Ref.current.currentTime =
              webcamRef.current.currentTime - diffMobile2Webcam; //mobili olması gereken yere sürükler
        } else {
          //mobil-2 oynamaması gerekiyor
          if (mobile2Promise !== undefined) {
            mobile2Promise
              .then((_) => {
                mobile2Ref.current.pause();
              })
              .catch((error) => { });
            setMobile2Promise(undefined);
          }
          // mobile2Ref.current.currentTime = 0;
        }
        let diffMobile3Webcam =
          (mobile3StartDate.getTime() - webcamStartDate.getTime()) / 1000;
        if (webcamRef.current.currentTime > diffMobile3Webcam) {
          //mobil-2 oynaması gerekiyor
          if (
            webcamRef.current.currentTime -
            diffMobile3Webcam -
            mobile3Ref.current.currentTime >
            1
          )
            mobile3Ref.current.currentTime =
              webcamRef.current.currentTime - diffMobile3Webcam; //mobili olması gereken yere sürükler
        } else {
          //mobil-2 oynamaması gerekiyor
          if (mobile3Promise !== undefined) {
            mobile3Promise
              .then((_) => {
                mobile3Ref.current.pause();
              })
              .catch((error) => { });
            setMobile3Promise(undefined);
          }
          // mobile3Ref.current.currentTime = 0;
        }
        let diffMobile4Webcam =
          (mobile3StartDate.getTime() - webcamStartDate.getTime()) / 1000;
        if (webcamRef.current.currentTime > diffMobile4Webcam) {
          //mobil4 oynaması gerekiyor
          if (
            webcamRef.current.currentTime -
            diffMobile4Webcam -
            mobile4Ref.current.currentTime >
            1
          )
            mobile4Ref.current.currentTime =
              webcamRef.current.currentTime - diffMobile4Webcam;
          if (
            webcamRef.current.readyMessage === 4 ||
            screenshareRef.current.readyState !== 4 ||
            mobileRef.current.readyState !== 4 ||
            mobile2Ref.current.readyState !== 4 ||
            mobile3Ref.current.readyState !== 4 ||
            mobile4Ref.current.readyState !== 4
          ) {
            counter = 2;
            webcamRef.current.pause();
            screenshareRef.current.pause();
            mobileRef.current.pause();
            mobile2Ref.current.pause();
            mobile3Ref.current.pause();
            mobile4Ref.current.pause();
            setPlayerInfo((playerInfo) => ({
              ...playerInfo,
              paused: true,
            }));
          } else if (playerInfo.paused && counter === 2) {
            counter = 1;
            setWebcamPromise(webcamRef.current.play());
            setScreenSharePromise(screenshareRef.current.play());
            setMobilePromise(mobileRef.current.play());
            setMobile2Promise(mobile2Ref.current.play());
            setMobile3Promise(mobile3Ref.current.play());
            setMobile4Promise(mobile4Ref.current.play());
            setPlayerInfo((playerInfo) => ({
              ...playerInfo,
              paused: false,
            }));
          }
        } else {
          //mobil4 oynamaması gerekiyor
          if (mobile4Promise !== undefined) {
            mobile4Promise
              .then((_) => {
                mobile4Ref.current.pause();
              })
              .catch((error) => { });
            setMobile4Promise(undefined);
          }
          // mobile4Ref.current.currentTime = 0;
        }
      }
    } else {
      //sınavda mobil yoksa
      if (
        webcamRef.current.readyState !== 4 ||
        screenshareRef.current.readyState !== 4
      ) {
        //webcam or screenShare is not ready to play
        counter = 2;
        webcamRef.current.pause();
        screenshareRef.current.pause();
        setPlayerInfo((playerInfo) => ({
          ...playerInfo,
          paused: true,
        }));
      } else if (playerInfo.paused && counter === 2) {
        //webcam & screenShare are ready to play
        counter = 1;
        setWebcamPromise(webcamRef.current.play());
        setScreenSharePromise(screenshareRef.current.play());
        setPlayerInfo((playerInfo) => ({
          ...playerInfo,
          paused: false,
        }));
      }
    }
    const input = document.getElementById("general_input");
    input.value = webcamRef.current.currentTime;
    myTimeout = setTimeout(checkSync, 1000);
  };

  useEffect(() => {
    checkSync();
    return () => {
      clearTimeout(myTimeout);
    };
  }, []);
  const playAllVideos = () => {
    if (playerInfo.paused) {
      if (webcamRef.current.readyState === 4) {
        if (screenshareRef.current.readyState === 4) {
          if (selectedStudent.examRules.mobileCameraUsage) {
            if (mobileRef.current.readyState === 4) {
              setWebcamPromise(webcamRef.current.play());
              setScreenSharePromise(screenshareRef.current.play());
              setMobilePromise(mobileRef.current.play());
              //TODOSYNC STOP
              if (mobileCamCanPlay.mobile1) {
                // setMobilePromise(mobileRef.current.play());
                if (
                  mobile2Ref.current &&
                  mobile2Ref.current.readyState === 4 &&
                  mobileCamCanPlay.mobile2 === true
                ) {
                  setMobile2Promise(mobile2Ref.current.play());
                }
                if (
                  mobile3Ref.current &&
                  mobile3Ref.current.readyState === 4 &&
                  mobileCamCanPlay.mobile3 === true
                ) {
                  setMobile3Promise(mobile3Ref.current.play());
                }
                if (
                  mobile4Ref.current &&
                  mobile4Ref.current.readyState === 4 &&
                  mobileCamCanPlay.mobile4 === true
                ) {
                  setMobile4Promise(mobile4Ref.current.play());
                }
              }
            } else {
              store.addNotification({
                title: i18n.t("Mobile_Camera_not_ready"),
                message: i18n.t("Mobile_Camera_recording_is_not_ready_yet"),
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 5000,
                  onScreen: true,
                },
              });
              setPlayerInfo((playerInfo) => ({
                ...playerInfo,
                paused: true,
              }));
              return;
            }
          } else {
            setWebcamPromise(webcamRef.current.play());
            setScreenSharePromise(screenshareRef.current.play());
          }
        } else {
          store.addNotification({
            title: i18n.t("Screen_Share_not_ready"),
            message: i18n.t("Screen_Share_recording_is_not_ready_yet"),
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          setPlayerInfo((playerInfo) => ({
            ...playerInfo,
            paused: true,
          }));
          return;
        }
      } else {
        store.addNotification({
          title: i18n.t("Webcam_not_ready"),
          message: i18n.t("Webcam_recording_is_not_ready_yet"),
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        setPlayerInfo((playerInfo) => ({
          ...playerInfo,
          paused: true,
        }));
        return;
      }
    } else {
      if (webcamPromise !== undefined) {
        webcamPromise
          .then((_) => {
            webcamRef.current.pause();
          })
          .catch((error) => { });
        setWebcamPromise(undefined);
      }
      if (screenSharePromise !== undefined) {
        screenSharePromise
          .then((_) => {
            screenshareRef.current.pause();
          })
          .catch((error) => { });
        setScreenSharePromise(undefined);
      }
      if (selectedStudent.examRules.mobileCameraUsage) {
        if (mobilePromise !== undefined) {
          mobilePromise
            .then((_) => {
              mobileRef.current.pause();
            })
            .catch((error) => { });
          setMobilePromise(undefined);
        }
        if (mobile2Promise !== undefined) {
          mobile2Promise
            .then((_) => {
              mobile2Ref.current.pause();
            })
            .catch((error) => { });
          setMobile2Promise(undefined);
        }
        if (mobile3Promise !== undefined) {
          mobile3Promise
            .then((_) => {
              mobile3Ref.current.pause();
            })
            .catch((error) => { });
          setMobile3Promise(undefined);
        }
        if (mobile4Promise !== undefined) {
          mobile4Promise
            .then((_) => {
              mobile4Ref.current.pause();
            })
            .catch((error) => { });
          setMobile4Promise(undefined);
        }
      }
    }
    setPlayerInfo((playerInfo) => ({
      ...playerInfo,
      paused: !playerInfo.paused,
    }));
  };

  const videoControls = () => {
    return (
      <div className="d-flex flex-row" style={{ marginTop: 7 }}>
        <div
          style={{
            height: 30,
            width: 30,
            // borderWidth: 0,
            // backgroundColor: "transparent",
          }}
          onClick={playAllVideos}
        >
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            {playerInfo.paused ? (
              <FaPlay color={"#235a93"} size={20} />
            ) : (
              <FaPause color={"#235a93"} size={20} />
            )}
          </div>
        </div>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: 30, width: "100%" }}
        >
          {/* TODOÇUBUK Burada */}
          <input
            className="w-100"
            id="general_input"
            type="range"
            min="0"
            onChange={(e) => currentPoint(e.target.value)}
            onClick={(e) => {
              changeCurrentTime(parseInt(e.target.value));
            }}
          />
        </div>
        {/* {videoCurrentTime ? Math.ceil(videoCurrentTime) % 60 : null} */}
        {/* test */}
        <div
          style={{
            height: 30,
            width: 50,
          }}
        >
          <div
            className="d-flex flex-column justify-content-center align-items-center h-100 text-center"
            onClick={() => {
              const newVideoSpeed = videoSpeed * 2;
              if (newVideoSpeed > 16) {
                setVideoSpeed(1);
              } else {
                setVideoSpeed(newVideoSpeed);
              }
            }}
          >
            <button
              style={{
                height: 20,
                width: 30,
                borderRadius: 10,
                backgroundColor: "#235a93",
                color: "white",
                border: "1px solid #235a93",
              }}
            >
              {`${videoSpeed}x`}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const currentPoint = (point) => {
    setVideoPoint(parseInt(point));
  };

  const commandArea = () => {
    return (
      <React.Fragment>
        <div style={{ marginBottom: 10 }}>
          <button
            className={context.theme.btn_save_full}
            type="button"
            onClick={downloadWebcam}
            disabled={getLoadingWebcam}
          >
            {getLoadingWebcam ? (
              <span>{i18n.t("Preparing_Video_Waiting")}</span>
            ) : (
              i18n.t("Download_Webcam")
            )}
          </button>
        </div>
        <div style={{ marginBottom: 10 }}>
          <button
            className={context.theme.btn_save_full}
            onClick={downloadScreenShare}
            disabled={getLoadingScreenShare}
          >
            {getLoadingScreenShare ? (
              <span>{i18n.t("Preparing_Video_Waiting")}</span>
            ) : (
              i18n.t("Download_Screen_Share")
            )}
          </button>
        </div>
        <div style={{ marginBottom: 10 }}>
          <button
            className={context.theme.btn_save_full}
            onClick={downloadMobileCam}
            disabled={getLoadingMobiles}
          >
            {getLoadingMobiles ? (
              <span>{i18n.t("Preparing_Video_Waiting")}</span>
            ) : (
              i18n.t("Download_Mobile_Cameras")
            )}
          </button>
        </div>
        <div style={{ marginBottom: 10 }}>
          <button className={context.theme.btn_save_full} onClick={openCommand}>
            {i18n.t("Add_Comment_For_Selected_Screen")}
          </button>
        </div>
      </React.Fragment>
    );
  };

  const webcamEnded = async () => {
    setPlayerInfo((playerInfo) => ({
      ...playerInfo,
      paused: true,
    }));
    if (mobileRef.current) {
      if (mobilePromise !== undefined) {
        mobilePromise
          .then((_) => {
            mobileRef.current.pause();
          })
          .catch((error) => { });
        setMobilePromise(undefined);
      }
      setIsMobileEnded(true);
    }
    if (mobile2Ref.current) {
      if (mobile2Promise !== undefined) {
        mobile2Promise
          .then((_) => {
            mobile2Ref.current.pause();
          })
          .catch((error) => { });
        setMobile2Promise(undefined);
      }
      setIsMobile2Ended(true);
    }
    if (mobile3Ref.current) {
      if (mobile3Promise !== undefined) {
        mobile3Promise
          .then((_) => {
            mobile3Ref.current.pause();
          })
          .catch((error) => { });
        setMobile3Promise(undefined);
      }
      setIsMobile3Ended(true);
    }
    if (mobile4Ref.current) {
      if (mobile4Promise !== undefined) {
        mobile4Promise
          .then((_) => {
            mobile4Ref.current.pause();
          })
          .catch((error) => { });
        setMobile4Promise(undefined);
      }
      setIsMobile4Ended(true);
    }
  };

  const onPlayVideo = () => {
    if (isMobileEnded) {
      // mobileRef.current.currentTime = 0;
      setIsMobileEnded(false);
    }
    if (isMobile2Ended) {
      // mobileRef.current.currentTime = 0;
      setIsMobile2Ended(false);
    }
    if (isMobile3Ended) {
      // mobileRef.current.currentTime = 0;
      setIsMobile3Ended(false);
    }
    if (isMobile4Ended) {
      // mobileRef.current.currentTime = 0;
      setIsMobile4Ended(false);
    }
  };

  const videoPlayers = () => {
    return (
      <div className="d-flex flex-row" style={{ backgroundColor: "black" }}>
        <div className="col-6 p-0 m-0">
          <VideoJS
            options={videoJsOptionsWebcam}
            onReady={handlePlayerReady}
            videoRef={webcamRef}
            webcamEnded={webcamEnded}
          />
        </div>
        <div className="col-6 p-0 m-0">
          <VideoJS
            options={videoJsOptionsScreen}
            onReady={handlePlayerReady}
            videoRef={screenshareRef}
            onPlayVideo={onPlayVideo}
          />
        </div>
      </div>
    );
  };

  const videoPlayers3 = () => {
    return (
      <div className="d-flex flex-row" style={{ backgroundColor: "black" }}>
        <div className="col-8 p-0 m-0">
          <VideoJS
            options={videoJsOptionsWebcam}
            onReady={handlePlayerReady}
            videoRef={webcamRef}
            webcamEnded={webcamEnded}
          />
        </div>
        <div className="d-flex flex-column col-4 p-0 m-0">
          <VideoJS
            options={videoJsOptionsMobile}
            onReady={handlePlayerReady}
            videoRef={mobileRef}
          />
          <VideoJS
            options={videoJsOptionsScreen}
            onReady={handlePlayerReady}
            videoRef={screenshareRef}
            onPlayVideo={onPlayVideo}
          />
        </div>
      </div>
    );
  };

  const videoPlayers4 = () => {
    return (
      <div className="d-flex flex-column" style={{ backgroundColor: "black" }}>
        <div className="d-flex flex-row p-0 m-0">
          <div className="col-6 p-0 m-0">
            <VideoJS
              options={videoJsOptionsWebcam}
              onReady={handlePlayerReady}
              videoRef={webcamRef}
              webcamEnded={webcamEnded}
            />
          </div>
          <div className="col-6 p-0 m-0">
            <VideoJS
              options={videoJsOptionsMobile}
              className="p-0"
              onReady={handlePlayerReady}
              videoRef={mobileRef}
            />
          </div>
        </div>
        <div className="d-flex flex-row p-0 m-0">
          <div className="col-6 p-0 m-0">
            <VideoJS
              options={videoJsOptionsScreen}
              onReady={handlePlayerReady}
              videoRef={screenshareRef}
              onPlayVideo={onPlayVideo}
            />
          </div>
          <div className="col-6 p-0 m-0">
            <VideoJS
              options={videoJsOptionsMobile2}
              onReady={handlePlayerReady}
              videoRef={mobile2Ref}
            />
          </div>
        </div>
      </div>
    );
  };

  const videoPlayers5 = () => {
    return (
      <div className="d-flex flex-column">
        <div className="d-flex flex-row col p-0 m-0">
          <div className="col-6 p-0 m-0">
            <VideoJS
              options={videoJsOptionsWebcam}
              onReady={handlePlayerReady}
              videoRef={webcamRef}
              webcamEnded={webcamEnded}
            />
          </div>
          <div className="col-6 p-0 m-0">
            <VideoJS
              options={videoJsOptionsMobile}
              onReady={handlePlayerReady}
              videoRef={mobileRef}
            />
          </div>
        </div>

        <div className="d-flex flex-row col p-0 m-0">
          <div className="col-6 p-0 m-0">
            <VideoJS
              options={videoJsOptionsScreen}
              onReady={handlePlayerReady}
              videoRef={screenshareRef}
              onPlayVideo={onPlayVideo}
            />
          </div>
          <div className="col-3 p-0 m-0">
            <VideoJS
              options={videoJsOptionsMobile2}
              onReady={handlePlayerReady}
              videoRef={mobile2Ref}
            />
          </div>
          <div className="col-3 p-0 m-0">
            <VideoJS
              options={videoJsOptionsMobile3}
              onReady={handlePlayerReady}
              videoRef={mobile3Ref}
            />
          </div>
        </div>
      </div>
    );
  };

  const videoPlayers6 = () => {
    return (
      <div className="d-flex flex-column" style={{ backgroundColor: "black" }}>
        <div className="d-flex flex-row col p-0 m-0">
          <div className="col-6 p-0 m-0">
            <VideoJS
              options={videoJsOptionsWebcam}
              onReady={handlePlayerReady}
              videoRef={webcamRef}
              webcamEnded={webcamEnded}
            />
          </div>
          <div className="col-3 p-0 m-0">
            <VideoJS
              options={videoJsOptionsMobile}
              onReady={handlePlayerReady}
              videoRef={mobileRef}
            />
          </div>
          <div className="col-3 p-0 m-0">
            <VideoJS
              options={videoJsOptionsMobile2}
              onReady={handlePlayerReady}
              videoRef={mobile2Ref}
            />
          </div>
        </div>

        <div className="d-flex flex-row col p-0 m-0">
          <div className="col-6 p-0 m-0">
            <VideoJS
              options={videoJsOptionsScreen}
              onReady={handlePlayerReady}
              videoRef={screenshareRef}
              onPlayVideo={onPlayVideo}
            />
          </div>
          <div className="col-3 p-0 m-0">
            <VideoJS
              options={videoJsOptionsMobile3}
              onReady={handlePlayerReady}
              videoRef={mobile3Ref}
            />
          </div>
          <div className="col-3 p-0 m-0">
            <VideoJS
              options={videoJsOptionsMobile4}
              onReady={handlePlayerReady}
              videoRef={mobile4Ref}
            />
          </div>
        </div>
      </div>
    );
  };

  const goToList = () => {
    props.history.push(`/libraryDetailed/${selectedSession.sessionId}`);
  };

  const titlePanel = () => {
    return (
      <Card.Title className="container-fluid">
        <div className="row justify-content-between pl-4 pr-5 pt-4 pb-1">
          <div className="pt-2">
            <div className="d-flex flex-row align-items-center">
              <button
                className={`${context.theme.btn_edit_small} d-none d-sm-inline-block shadow-medium mr-3`}
                type="button"
                onClick={goToList}
              >
                <FaArrowLeft />
              </button>
              <div className="d-flex flex-column">
                <div>{`${selectedStudent.name} ${selectedStudent.lastname}`}</div>
                <div style={{ marginTop: 5 }}>{`${calculateExamAttendTime(
                  meetingInformation
                )}`}</div>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <h4 style={{ marginRight: 10 }}>
              <b>{i18n.t("Mode")}: </b>
              {i18n.t(selectedSession.proctorServiceType)}
            </h4>

            {!isProctor &&
              selectedStudent.studentId &&
              selectedSession.sessionId &&
              selectedStudent.aiScore && (
                <div
                  className="btn text-center"
                  style={{
                    backgroundColor:
                      selectedStudent.aiScore < parseInt(selectedSession.score.cheaterThreshold) - 10 ? "#27d227" :
                        selectedStudent.aiScore > parseInt(selectedSession.score.cheaterThreshold) - 10 ?
                          selectedStudent.aiScore < parseInt(selectedSession.score.cheaterThreshold) ? "#f2c84c" : "red" : "red",
                    marginRight: 10,
                    height: "40px",
                    borderRadius: "12px",
                    color: "white",
                  }}
                >
                  <h4>
                    AI:{" "}
                    {(Math.round(
                      selectedStudent.aiScore
                    ) &&
                      Math.round(
                        selectedStudent.aiScore
                      )) ||
                      0}
                  </h4>
                </div>
              )}
          </div>
        </div>
      </Card.Title>
    );
  };

  const resultClickEvent = (time) => {
    changeCurrentTime(time);
  };

  const proctorEvaluation = () => {
    if (studentResult && studentResult.proctorEvaluationStatus) {
      let icon;
      let borderValue;
      if (studentResult.proctorEvaluationStatus === "Nothing Suspicious") {
        icon = <FaCheckCircle size={30} color="#28a745" />;
        borderValue = "border border-success";
      } else if (
        studentResult.proctorEvaluationStatus === "Unethical Behavior"
      ) {
        icon = <IoMdCloseCircle size={30} color="#bd2130" />;
        borderValue = "border border-danger";
      } else if (
        studentResult.proctorEvaluationStatus === "Something_Suspicious"
      ) {
        icon = <FaRegQuestionCircle size={30} color="#e0a800" />;
        borderValue = "border border-warning";
      }
      return (
        <div className={`d-flex flex-column bg-light ${borderValue} col-md-12`}>
          <div className="col-md-12">{i18n.t("Proctor_Conclusion")}</div>
          <div className="d-flex flex-row align-items-center mb-1">
            <div className="col-auto p-0">{icon}</div>
            <div className="col">
              {studentResult.proctorEvaluationDescription}
            </div>
          </div>
        </div>
        // </div>
      );
    } else {
      return "";
    }
  };

  const resultList = () => {
    return (
      <div className="col col-auto">
        <div
          className="card-body pt-0 mt-0"
          style={{
            width: "400px",
            overflow: "auto",
            minHeight: "1px",
            maxHeight: "800px",
          }}
        >
          <div
            className="p-4 mb-3"
            style={{
              borderRadius: "5px",
              backgroundColor: "#FEFEFE",
              border: "1px solid #E5E5E5",
            }}
          >
            <ResultView
              event={resultClickEvent}
              retrieveDefaultStudentResults={retrieveDefaultStudentResults}
              studentResult={studentResult}
              meetingInformation={meetingInformation}
            />
          </div>
          <hr />
        </div>
        <br />
        <div className="row pl-4"></div>
        <br />
        <div
          className="card-body pt-0 mt-0"
          style={{
            width: "400px",
          }}
        >
          {studentInfoPanel()}
        </div>
      </div>
    );
  };

  const studentInfoPanel = () => {
    return (
      <div className="row">
        <div>
          <Card className="col-md-12 p-0">
            <Card.Img
              ref={facePhotoRef}
              variant="bottom"
              style={{
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 5,
              }}
            />
          </Card>
          <Card className="col-md-12 p-0">
            <Card.Img
              ref={idCardRef}
              variant="bottom"
              style={{
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 5,
              }}
            />
          </Card>
        </div>
      </div>
    );
  };

  const chatAreas = () => {
    return (
      <React.Fragment>
        <button
          className="btn buttonOriginal mb-3"
          onClick={() => setChatAreaVisible(!chatAreaVisible)}
        >
          {chatAreaVisible !== true
            ? i18n.t("Show_Chats")
            : i18n.t("Hide_Chats")}
        </button>
        {chatAreaVisible === true ? (
          <div className="container-fluid mb-3">
            <div className="row">
              {selectedSession.proctorServiceType === "Record_and_Review" ||
                selectedSession.proctorServiceType ===
                "Record_and_Review_Lite" ? null : (
                <ChatViewer
                  title={i18n.t("Proctor_Chat")}
                  event={resultClickEvent}
                  chats={proctorChatMessages}
                  meetingInformation={meetingInformation}
                />
              )}
              <ChatViewer
                title={i18n.t("Technical_Chat")}
                event={resultClickEvent}
                chats={technicalChatMessages}
                meetingInformation={meetingInformation}
              />
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  const handleShowDecisionModal = () => {
    setShowDecisionModal(true);
  };

  const handleCloseDecisionModal = () => {
    setShowDecisionModal(false);
  };

  const saveEvaluation = async (evaluation) => {
    var studentEvaluation = selectedSession.studentList.find(
      (s) => s.studentId === selectedStudent.studentId
    );
    studentEvaluation.evaluation = evaluation;
    studentEvaluation.evaluationDesc = evaluationDesc;
    studentEvaluation.evaluationDescText = evaluationDescText;
    context.setSelectedSession(selectedSession);
    const param = {
      evaluation: evaluation,
      evaluationDesc: evaluationDesc,
      evaluationDescText: evaluationDescText,
      section: "setEvaluation",
      studentId: selectedStudent.studentId,
      sessionId: selectedSession.sessionId,
      instituteId: selectedSession.instituteId,
    };
    await setSession("setEvaluation", param, "setEvaluation");
    goToList();
  };

  const changeComment = (value, source) => {
    setEvaluationDescText(value);
    // if (source === true) {
    //   setEvaluationDesc(value);
    // }
  };

  const RenderVideoPlayer = () => {
    if (selectedStudent.examRules.mobileCameraUsage) {
      if (selectedSession.allowedOutsourceCamera === 1) {
        return videoPlayers3();
      } else if (selectedSession.allowedOutsourceCamera === 2) {
        return videoPlayers4();
      } else if (selectedSession.allowedOutsourceCamera === 3) {
        return videoPlayers5();
      } else if (selectedSession.allowedOutsourceCamera === 4) {
        return videoPlayers6();
      } else {
        return videoPlayers();
      }
    } else {
      return videoPlayers();
    }
  };

  const playerRef = React.useRef(null);

  const videoJsOptionsWebcam = {
    autoplay: false,
    controls: false,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: `${getBaseUrl()}/${selectedSession.sessionId}${
          selectedStudent.studentId
        }/exam/${selectedSession.sessionId}${
          selectedStudent.studentId
          }-webcam.webm`,
        type: "video/mp4",
      },
    ],
  };

  const videoJsOptionsScreen = {
    autoplay: false,
    controls: false,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: `${getBaseUrl()}/${selectedSession.sessionId}${
          selectedStudent.studentId
        }/exam/${selectedSession.sessionId}${
          selectedStudent.studentId
          }-screenShare.webm`,
        type: "video/mp4",
      },
    ],
  };

  const videoJsOptionsMobile = {
    autoplay: false,
    controls: false,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: `${getBaseUrl()}/${selectedSession.sessionId}${
          selectedStudent.studentId
        }/exam/${selectedSession.sessionId}${
          selectedStudent.studentId
          }-mobile_0.webm`,
        type: "video/mp4",
      },
    ],
  };

  const videoJsOptionsMobile2 = {
    autoplay: false,
    controls: false,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: `${getBaseUrl()}/${selectedSession.sessionId}${
          selectedStudent.studentId
        }/exam/${selectedSession.sessionId}${
          selectedStudent.studentId
          }-mobile_1.webm`,
        type: "video/mp4",
      },
    ],
  };

  const videoJsOptionsMobile3 = {
    autoplay: false,
    controls: false,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: `${getBaseUrl()}/${selectedSession.sessionId}${
          selectedStudent.studentId
        }/exam/${selectedSession.sessionId}${
          selectedStudent.studentId
          }-mobile_2.webm`,
        type: "video/mp4",
      },
    ],
  };

  const videoJsOptionsMobile4 = {
    autoplay: false,
    controls: false,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: `${getBaseUrl()}/${selectedSession.sessionId}${
          selectedStudent.studentId
        }/exam/${selectedSession.sessionId}${
          selectedStudent.studentId
          }-mobile_3.webm`,
        type: "video/mp4",
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => { });

    player.on("dispose", () => { });
  };

  const mainContent = () => {
    return (
      <React.Fragment>
        <div className="container-fluid" border="0">
          {titlePanel()}
          <div className="container-fluid border-0">
            <div className="row">
              <div className="col">
                {selectedSession.proctorServiceType ===
                  "Live_Proctoring_Lite" ||
                  selectedSession.proctorServiceType ===
                  "Record_and_Review_Lite" ? null : (
                  <div>
                    {RenderVideoPlayer()}
                    {videoControls()}
                  </div>
                )}
                <div className="d-flex flex-row mt-3">
                  <div
                    className="col-md-9 border mb-1 p-2"
                    style={{ minHeight: 100 }}
                  >
                    <GeneralComments
                      setReadyMessage={setReadyMessage}
                      evaluationDesc={evaluationDesc}
                      // setEvaluationDesc={setEvaluationDesc}
                      evaluationDescText={evaluationDescText}
                      setEvaluationDescText={setEvaluationDescText}
                      changeComment={changeComment}
                      sessionInformation={selectedSession}
                      selectedStudent={selectedStudent}
                      readyMessage={readyMessage}
                      reviewerMessages={reviewerMessages}
                      updateStudentComments={updateStudentComments}
                      setSession={setSession}
                      goToList={goToList}
                      handleShowDecisionModal={handleShowDecisionModal}
                    />
                  </div>
                  <div className="col-md-3">
                    {selectedSession.proctorServiceType ===
                      "Live_Proctoring" ? (
                      commandArea()
                    ) : selectedSession.proctorServiceType ===
                      "Record_and_Review" ? (
                      commandArea()
                    ) : selectedSession.proctorServiceType === "Offline" ? (
                      commandArea()
                    ) : selectedSession.proctorServiceType ===
                      "Live_Proctoring_Plus" ? (
                      commandArea()
                    ) : selectedSession.proctorServiceType ===
                      "Virtual_Classroom" ? (
                      commandArea()
                    ) : (
                      <div style={{ marginBottom: 10 }}>
                        <button
                          className={context.theme.btn_save_full}
                          onClick={openCommand}
                        >
                          {i18n.t("Add_Comment_For_Selected_Screen")}
                        </button>
                      </div>
                    )}
                    {proctorEvaluation()}
                  </div>
                </div>
                <br />
                <br />
                {chatAreas()}
              </div>
              {resultList()}
            </div>
          </div>
        </div>
        <ModalForm
          show={showResult}
          handleClose={handleClose}
          Title={`${i18n.t("Add_a_comment_for")} ${selectedStudent.name} ${
            selectedStudent.lastname
            } `}
        >
          {selectedSession.proctorServiceType === "Live_Proctoring_Lite" ||
            selectedSession.proctorServiceType ===
            "Record_and_Review_Lite" ? null : (
            <div style={{ textAlign: "center" }}>
              <canvas
                id="canvas"
                ref={canvasRef}
                width={`${customVideoWidth < 466 ? customVideoWidth : 465}`}
                height="258"
              ></canvas>
            </div>
          )}

          <FormSelect
            name="readyMessage"
            label={i18n.t("Comments")}
            onChange={(e) => setReadyMessage(e.target.value)}
            value={readyMessage}
            data={reviewerMessages.ReviewerMessages}
            dataValue="text"
            dataText="value"
            required={true}
          />

          {readyMessage === i18n.t("Other...") ? (
            <FormInput
              col="12"
              value={command}
              onChange={(e) => setCommand(e.target.value)}
              type="text"
            />
          ) : null}
          <button className={context.theme.btn_save} onClick={saveCommand}>
            {i18n.t("Send")}
          </button>
        </ModalForm>
        <ModalForm
          show={showDecisionModal}
          handleClose={handleCloseDecisionModal}
          Title={`${i18n.t("Decision")} ${selectedStudent.name} ${
            selectedStudent.lastname
            } `}
        >
          <Card.Body className="ml-4 d-flex justify-content-center">
            <div class="d-flex flex-wrap">
              <row className="d-flex justify-content-center">
                {i18n.t("Nothing_Suspicious")}
              </row>
              <br></br>
              <br></br>
              <row className="d-flex justify-content-center">
                <FaCheckCircle
                  className="btn btn-success ml-2 flex-grow-1 w-100"
                  style={{ height: 80 }}
                  onClick={() => saveEvaluation("Nothing Suspicious")}
                />
              </row>
            </div>

            <div class="d-flex flex-wrap">
              <row className="d-flex justify-content-center">
                {i18n.t("Unethical_Behavior")}
              </row>
              <br></br>
              <br></br>
              <row className="d-flex justify-content-center">
                <IoMdCloseCircle
                  className="btn btn-danger ml-2 flex-grow-1 w-100"
                  style={{ height: 80 }}
                  onClick={() => saveEvaluation("Unethical Behavior")}
                />
              </row>
            </div>
            <div class="d-flex flex-wrap">
              <row className="d-flex justify-content-center">
                {i18n.t("Something_Suspicious")}
              </row>
              <br></br>
              <br></br>
              <row className="d-flex justify-content-center">
                {" "}
                <FaRegQuestionCircle
                  className="btn btn-warning ml-2 flex-grow-1 w-100"
                  style={{ height: 80 }}
                  onClick={() => saveEvaluation("Something Suspicious")}
                />
              </row>
            </div>
          </Card.Body>
        </ModalForm>
      </React.Fragment>
    );
  };

  return mainContent();
};
export default withNamespaces()(LibraryStudentDetailed);
