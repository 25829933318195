import React from "react";
import i18n from "../../configs/i18n";
import { withNamespaces } from "react-i18next";
function FormSelect(props) {
  return (
    <div className={`form-group ${props.col ? "col-" + props.col : ""} p-1`}>
      <label htmlFor="Settings.replicatingData" className="form-label">
        {props.label}
      </label>
      <select
        className="form-select w-100 btn border"
        name={props.name}
        onChange={props.onChange}
        value={props.value}
        // defaultValue={props.data.length > 0 ? props.data[0].dataValue : ""}
        required={props.required}
      >
        <option value="" hidden>
          {i18n.t("Choose")}
        </option>
        {props.data ? (
          <React.Fragment>
            {props.data.map((data, i) => (
              <option
                value={data[props.dataValue]}
                key={i}
                disabled={data.disabled}
              >
                {props.tz === "timezone" ? data[props.dataText] : i18n.t(data[props.dataText])}
              </option>
            ))}
          </React.Fragment>
        ) : null}
      </select>
    </div>
  );
}
export default withNamespaces()(FormSelect);
