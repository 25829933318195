import { useState, useContext } from "react";
import axios from "axios";
import { awscontext } from "../configs/awscontext";
const config = require("../configs/awsconfig.json");

export const useReport = () => {
  const context = useContext(awscontext);
  const [result, setResult] = useState([]);

  const getData = async () => {
    try {
      const res = await axios.get(`${config.api.invokeUrl}/reports`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Object.keys(localStorage).find((key) => key.includes("accessToken"))
          )}`,
        },
      });
      context.setReportList(res.data);
      setResult(res.data);
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
    }
  };
  const post = async (params) => {
    try {
      await axios.post(`${config.api.invokeUrl}/reports`, params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Object.keys(localStorage).find((key) => key.includes("accessToken"))
          )}`,
        },
      });
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
    }
  };
  const patch = async (params) => {
    try {
      await axios.patch(`${config.api.invokeUrl}/reports`, params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Object.keys(localStorage).find((key) => key.includes("accessToken"))
          )}`,
        },
      });
      await query(params);
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
    }
  };
  const deleteReport = async (params) => {
    try {
      await axios.delete(`${config.api.invokeUrl}/reports/${params.reportId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Object.keys(localStorage).find((key) => key.includes("accessToken"))
          )}`,
        },
      });
      await query(params);
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
    }
  };
  const query = async (params) => {
    try {
      const res = await axios.post(
        `${config.api.invokeUrl}/reports/query`,
        params,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      res.data.sort((a, b) => -1 * (a.createdAt - b.createdAt));
      // res.data.sort((a, b) => -1 * a.status.localeCompare(b.status));
      context.setReportList(res.data);
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
      context.setReportList([]);
    }
  };
  const dashboardChart = async () => {
    try {
      const res = await axios.get(`${config.api.invokeUrl}/dashboard`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Object.keys(localStorage).find((key) => key.includes("accessToken"))
          )}`,
        },
      });
      context.setDashboardData(res.data);
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
      setResult([]);
    }
  };
  const instituteDashboardChart = async (params) => {
    try {
      const res = await axios.get(
        `${config.api.invokeUrl}/institutes/${params.instituteId}/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      context.setDashboardData(res.data);
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
    }
  };
  const allSession = async () => {
    try {
      const res = await axios.get(`${config.api.invokeUrl}/lives`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Object.keys(localStorage).find((key) => key.includes("accessToken"))
          )}`,
        },
      });
      context.setUnArchivedSessionList(res.data);
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };
  const allLibrarySessionList = async () => {
    try {
      const res = await axios.get(`${config.api.invokeUrl}/librarylives`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Object.keys(localStorage).find((key) => key.includes("accessToken"))
          )}`,
        },
      });
      context.setMeetingArchivedSessionList(res.data);
      setResult(res.data);
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };
  const onlineTakers = async () => {
    try {
      const res = await axios.get(`${config.api.invokeUrl}/onlineusers`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Object.keys(localStorage).find((key) => key.includes("accessToken"))
          )}`,
        },
      });
      context.setOnlineTestTakerList(res.data);
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };
  const liveSessions = async () => {
    try {
      var result = await axios.get(
        `${config.api.invokeUrl}/institutes/${context.userInstituteId}/sessions/live`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      context.setLiveSessionList(result.data);
      setResult("success");
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };
  const librarySessions = async () => {
    try {
      var results = await axios.get(
        `${config.api.invokeUrl}/institutes/${context.userInstituteId}/librarysessions`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      context.setLibrarySessionList(results.data);
      setResult("success");
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };

  const initialLoadData = async (params = null) => {
    if (context.userRole.includes("Super Admin")) {
      await dashboardChart();
    } else {
      await instituteDashboardChart({
        instituteId: params ? params.instituteId : context.userInstituteId
      });
    }
  };

  const handleChange = async (type, params) => {
    switch (type) {
      case "get":
        await getData();
        break;
      case "post":
        await post(params);
        break;
      case "patch":
        await patch(params);
        break;
      case "query":
        await query(params);
        break;
      case "delete":
        await deleteReport(params);
        break;
      case "dashboardChart":
        await dashboardChart();
        break;
      case "instituteDashboardChart":
        await instituteDashboardChart(params);
        break;
      case "allSession":
        await allSession();
        break;
      case "allLibrarySessionList":
        await allLibrarySessionList();
        break;
      case "onlineTakers":
        await onlineTakers();
        break;
      case "liveSessions":
        await liveSessions();
        break;
      case "librarySessions":
        await librarySessions();
        break;
      case "initialLoadData":
        await initialLoadData(params? params : null);
        break;
      default:
        break;
    }
  };
  return [result, handleChange];
};
