/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState, useRef } from "react";
import { awscontext } from "../../configs/awscontext";
import { proctorcontext } from "../../configs/proctorContext";
import ScreenPanel from "../components/ScreenPanel";
import i18n from "../../configs/i18n";
import { withNamespaces } from "react-i18next";
import { FaCheckCircle, FaRegQuestionCircle } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { useStorage } from "../../hooks/useStorage";
import { useStudentResult } from "../../hooks/useStudentResult";
import axios from "axios";
import config from "../../configs/awsconfig.json";
const reviewerMessages = require("../../models/variables.json");

function ProctoringFinish(props) {
  const context = useContext(awscontext);
  const proccontext = useContext(proctorcontext);
  const [studentList, setStudentList] = useState(
    proccontext.proctoringSession.studentList
  );
  const [, setCommentSave] = useStudentResult("");
  const [, setSelectedStudent] = useState(props.student ? props.student : []);
  const [, listObjects, , getBaseUrl] = useStorage(config.s3.streamFiles);
  const [studentCommentMap, setStudentCommentMap] = useState([]);
  // const [studentResult, setStudentResult] = useState("");
  // const [commentSave2, setCommentSave2] = useState("");  

  useEffect(async () => {
    context.setAdres("proctor");
    context.setBarGroup("PROCTOR");
    setSelectedStudent(getPhotos());

    if(proccontext.stream.audio){//trackler kapatılmıştır.
      proccontext.stream.audio.getAudioTracks()[0].stop()
    }

    proccontext.sessions.forEach((session) => {//sessionlar kapatılmıştır.
      session.disconnect();
    });

    /*
    * somewhere insdie your code you call
    * */
  }, []);
  useEffect(() => {
    for (const student of studentList) {
      const meetingId = `${proccontext.proctoringSession.sessionId}${student.studentId}`;
      updateSingleStudentCommentMap(student.studentId, meetingId);
    }
  }, [studentList]);

  const updateSingleStudentCommentMap = async (studentId, meetingId) => {
    const newCommentResult = await getStudentCommentResults(meetingId); 
    setStudentCommentMap((prevStudentCommentMap) => ({
      ...prevStudentCommentMap,
      [studentId]: newCommentResult.comments,
    }));

  };

  const getStudentCommentResults = async (meetingId) => {
    try {
      const res = await axios.get(
        `${config.api.invokeUrl}/studentResults/${meetingId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );

      if (res.data) {
        return res.data;
      }
    } catch (err) {
      console.log(err);
    }
    return { comments: [] };
  };

  const getPhotos = async () => {
    proccontext.proctoringSession.studentList.forEach((element, i) => {
      element = getPhotoFromS3(element, i);
    });
    setStudentList(studentList);
    return proccontext.proctoringSession.studentList;
  };

  const getPhotoFromS3 = async (element) => {
    const images = await listObjects(
      `${proccontext.proctoringSession.sessionId}${element.studentId}/images`
    );
    let imageContents = images.Contents;
    imageContents = imageContents.filter((image) => image.Size > 0);
    imageContents.forEach((imageContent) => {
      try {
        if (imageContent.Key.includes("facephoto")) {
          element.photo = `${getBaseUrl()}/${imageContent.Key}`;
          document.getElementById("img" + element.studentId).src =
            element.photo;
          //facePhotoRef.current.src = `${getBaseUrl()}/${imageContent.Key}`;
        }
      } catch (err) {
        console.log(`An error has occurred: ${err}`);
      }
    });
    return element;
  };

  const updateStudentComments = async (e, studentId) => {
    e.preventDefault();

    var textComp = document.getElementById("text" + studentId);
    let value = textComp.value;
    const params = {
      meetingId: proccontext.proctoringSession.sessionId + studentId,
      type: "EVALUATION_DESCRIPTION",
      proctorEvaluationDescription: value,
    };
    await setCommentSave("UPDATE", params);

    const listStudent = JSON.parse(JSON.stringify(studentList));
    listStudent.forEach((element) => {
      if (element.studentId === studentId) {
        element.proctorcomment = value;
      }
    });

    setStudentList(listStudent);
    setStepNo(studentId);
  };

  const updateStudentEvaluationStatus = async (e, studentId, value) => {
    e.preventDefault();
    const params = {
      meetingId: proccontext.proctoringSession.sessionId + studentId,
      type: "EVALUATION_STATUS",
      proctorEvaluationStatus: value,
    };
    await setCommentSave("UPDATE", params);
    const listStudent = JSON.parse(JSON.stringify(studentList));
    listStudent.forEach((element) => {
      if (element.studentId === studentId) {
        element.proctorResult = value;
      }
    });

    setStudentList(listStudent);
    setStepNo(studentId, true);
  };

  const setReadyMessage = (studentId) => {
    var selectComp = document.getElementsByName("select" + studentId);
    var textComp = document.getElementById("text" + studentId);
    if (selectComp[0].value === "Other...") {
      textComp.style.display = "";
    } else {
      textComp.style.display = "none";
    }
  };
  const setStepNo = (studentId, isFinish) => {
    if (isFinish) {
      document.getElementById("div" + studentId).style.display = "none";
      document.getElementById("form" + studentId).style.display = "none";
      document.getElementById("result" + studentId).style.display = "";
    } else {
      document.getElementById("div" + studentId).style.display = "";
      document.getElementById("form" + studentId).style.display = "none";
    }
  };

  const outputEvaluationResult = (student) => {
    const selectedStudent = studentList.find(
      (s) => s.studentId === student.studentId
    );

    if (selectedStudent.proctorResult === "Nothing Suspicious") {
      return <FaCheckCircle size={50} color="#28a745" />;
    } else if (selectedStudent.proctorResult === "Unethical Behavior") {
      return <IoMdCloseCircle size={50} color="#bd2130" />;
    } else if (selectedStudent.proctorResult === "Something Suspicious") {
      return <FaRegQuestionCircle size={50} color="#e0a800" />;
    }
  };

  const retrieveSelectedStudentComments = (student) => {
    const studentComment = studentCommentMap[student.studentId];
    const renderStudentCommentItem = [];
    if (studentComment && studentComment.comments) {
      studentComment.comments.forEach((comment) => {
        renderStudentCommentItem.push(
          <div key={comment.time} className="d-flex flex-row">
            <div className="font-weight-bold text-center col-md-3">
              {`${new Date(comment.time).toLocaleTimeString()}`}
            </div>
            {comment.text === "Other..." ? (
              <div className="col-md-9">{comment.otherText}</div>
            ) : (
              <div className="col-md-9">{comment.text}</div>
            )}
          </div>
        );
      });
    }

    return (
      <div>
        <span
          style={{
            fontStyle: "italic",
            color: renderStudentCommentItem.length > 0 ? "red" : "green",
          }}
        >
          {student.name} {student.lastname}
        </span>

        {renderStudentCommentItem}
      </div>
    );
  };

  const constructAttendedStudentList = () => {
    const attendedStudents = [];
    for (const student of studentList) {
      // if (student.isJoin === true) {
      attendedStudents.push(
        <React.Fragment>
          <div className="container-fluid">
            <div className="d-flex flex-row justify-content-center align-items-center">
              <div className="col-md-3">
                <img id={`img${student.studentId}`} alt="" width={"100%"} />
              </div>

              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <div id={`form${student.studentId}`}>
                    <textarea
                      className="w-100"
                      id={`text${student.studentId}`}
                      style={{
                        borderRadius: 10,
                        padding: 10,
                      }}
                    />
                    <button
                      style={{
                        marginTop: 10,
                        // display: student.isJoin === true ? "" : "none",
                      }}
                      className="btn buttons w-100"
                      onClick={(e) =>
                        updateStudentComments(e, student.studentId)
                      }
                    >
                      {i18n.t("Save_General_Comment_Proctors")}
                    </button>
                  </div>

                  <div
                    id={`div${student.studentId}`}
                    style={{ display: "none" }}
                  >
                    <div>
                      Decision for {student.name} {student.lastname}
                    </div>
                    <div className="mt-3 row">
                      <div className="pl-4 pr-4">
                        <p>{i18n.t("Nothing_Suspicious")}</p>
                        <div className="pl-2">
                          <FaCheckCircle
                            className="btn btn-success"
                            style={
                              {
                                // display: student.isJoin === true ? "" : "none",
                              }
                            }
                            onClick={(e) =>
                              updateStudentEvaluationStatus(
                                e,
                                student.studentId,
                                "Nothing Suspicious"
                              )
                            }
                            size={80}
                          />
                        </div>
                      </div>
                      <div className="pl-4 pr-4">
                        <p>{i18n.t("Unethical_Behavior")}</p>
                        <IoMdCloseCircle
                          className="btn btn-danger p-2 ml-2"
                          style={
                            {
                              // display: student.isJoin === true ? "" : "none",
                            }
                          }
                          onClick={(e) =>
                            updateStudentEvaluationStatus(
                              e,
                              student.studentId,
                              "Unethical Behavior"
                            )
                          }
                          size={80}
                        />
                      </div>
                      <div className="pl-4 pr-4">
                        <p> {i18n.t("Something_Suspicious")}</p>
                        <FaRegQuestionCircle
                          className="btn btn-warning p-2 ml-3"
                          style={
                            {
                              // display: student.isJoin === true ? "" : "none",
                            }
                          }
                          onClick={(e) =>
                            updateStudentEvaluationStatus(
                              e,
                              student.studentId,
                              "Something Suspicious"
                            )
                          }
                          size={80}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    id={`result${student.studentId}`}
                    style={{ display: "none" }}
                    className="col"
                  >
                    <div>
                      Decision for {student.name} {student.lastname}
                    </div>
                    <div className="row ml-5">
                      <div className="alert alert-secondary mt-3 lead row w-100">
                        {outputEvaluationResult(student)}
                        <div className="ml-3">
                          <h3>{student.proctorResult}</h3>
                          <h5>{student.proctorcomment}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                {retrieveSelectedStudentComments(student)}
              </div>
            </div>
            <hr />
          </div>
        </React.Fragment>
      );
      // }
    }
    return attendedStudents;
  };

  return (
    <React.Fragment>
      <div className="text-center" style={{ fontSize: 24, fontWeight: "bold" }}>
        Please provide a concluding comment for your session(s)
      </div>
      <ScreenPanel name={i18n.t("Post_Exam_Decision")} col="12">
        {constructAttendedStudentList()}
      </ScreenPanel>
    </React.Fragment>
  );
}
export default withNamespaces()(ProctoringFinish);
