import { useState, useContext } from "react";
import axios from "axios";
import { awscontext } from "../configs/awscontext";
import uniqid from "uniqid";
import { useSession } from "./useSession";
import { useStaff } from "./useStaff";
import { useReport } from "./useReport";

const instituteModel = require("../models/modelInstitute.json");
const config = require("../configs/awsconfig.json");

export const useInstitute = () => {
  const context = useContext(awscontext);
  const [result, setResult] = useState([]);
  const [, setSessions] = useSession([]);
  const [, setStaff] = useStaff([]);
  const [, setReports] = useReport([]);
  const setStart = async (params) => {
    getData(params);
    getCurrent(params.instituteId);
    setSessions("get", {
      isArchived: false,
      sessionName: "",
      instituteId: params.instituteId,
    });
    setStaff("get", params);
    setSessions("librarySessions", params);
    getOnlineTestTakers({ userInstituteId: params.instituteId });
    setSessions("liveSessions", {
      instituteId: params.instituteId,
    });
    setReports("onlineTakers");
    setReports("allSession");
    setReports("initialLoadData", params);
    await setReports("allLibrarySessionList");
  }

  const getData = async (params) => {
    try {
      const res = await axios.get(`${config.api.invokeUrl}/institutes`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Object.keys(localStorage).find((key) => key.includes("accessToken"))
          )}`,
        },
      });
      await res.data.forEach((element) => {
        if (!element.warnings) element.warnings = instituteModel.warnings;
        if (!element.settings) element.settings = instituteModel.settings;
      });
      setResult(res.data);
      await context.setInstituteList(res.data);
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
    }
  };

  const deleteInstitute = async (params) => {
    try {
      await axios.delete(`${config.api.invokeUrl}/institutes/${params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Object.keys(localStorage).find((key) => key.includes("accessToken"))
          )}`,
        },
      });
      await getData();
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
    }
  };
  const postInstitute = async (params) => {
    try {
      const newparams = { ...params, id: uniqid() };
      await axios.post(`${config.api.invokeUrl}/institutes`, newparams, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Object.keys(localStorage).find((key) => key.includes("accessToken"))
          )}`,
        },
      });
      await getData();
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
    }
  };
  const patchInstitute = async (params) => {
    try {
      await axios.patch(
        `${config.api.invokeUrl}/institutes/${params.id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      await getData();
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
    }
  };
  const patchAiSettingsInstitute = async (params, response) => {
    console.log(params);
    try {
      const result = await axios.patch(
        `${config.api.invokeUrl}/institutes/${params.id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      response.statusCode = result.status;
      response.body = result.data;
      await getData();
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
    }
  };
  const getCurrent = async (params) => {
    try {
      const res = await axios.get(
        `${config.api.invokeUrl}/institutes/${params}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      if (!res.data.warnings) res.data.warnings = instituteModel.warnings;
      if (!res.data.settings) res.data.settings = instituteModel.settings;
      context.setCurrentInstitute(res.data);
      localStorage.setItem("instituteName", res.data.instituteName);
      context.setInstitute(res.data.instituteName, params);
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
    }
  };

  const getOnlineTestTakers = async (params) => {
    try {
      const res = await axios.get(
        `${config.api.invokeUrl}/institutes/${params.userInstituteId}/onlineTestTakers`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      context.setInstituteOnlineTestTakerList(res.data);
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };

  const handleChange = async (type, params, response) => {
    if (type === "get") {
      await getData(params);
    } else if (type === "set") {
      await setResult(params);
    } else if (type === "delete") {
      await deleteInstitute(params);
    } else if (type === "post") {
      await postInstitute(params);
    } else if (type === "patch") {
      await patchInstitute(params);
    } else if (type === "getCurrent") {
      await getCurrent(params);
    } else if (type === "onlineTestTakers") {
      await getOnlineTestTakers(params);
    } else if (type === "aiSettings") {
      await patchAiSettingsInstitute(params, response);
    } else if (type === "start") {
      await setStart(params);
    }
  };
  return [result, handleChange];
};
