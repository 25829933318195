import React, { useContext, useEffect, useState } from "react";
import { awscontext } from "../../configs/awscontext";
import ScreenPanel from "../components/ScreenPanel";
import i18n from "../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import { Row } from "react-bootstrap";
import { useReport } from "../../hooks/useReport";
import { useInput } from "../../hooks/useInput";
import FormSelect from "../components/FormSelect";
import FormInput from "../components/FormInput";
import variables from "../../models/variables.json";
import axios from "axios";
import config from "../../configs/awsconfig.json";
import LoadingIcon from "../components/LoadingIcon";
import ReactLoading from "react-loading";
import { FaFilePdf, FaFileCsv, FaSync, FaTimes } from "react-icons/fa";
import pdfMake from "pdfmake/build/pdfmake.js";
import pdfFonts from "pdfmake/build/vfs_fonts.js";
import { useStorage } from "../../hooks/useStorage";
import fileDownload from "js-file-download";
import uniqid from "uniqid";
import { store } from "react-notifications-component";
import { useSession } from "../../hooks/useSession";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function Report(props) {
  const context = useContext(awscontext);
  const [, reportOperations] = useReport();
  const [, setSession] = useSession();
  const [instituteList, setInstituteList] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [inputs, setInputs] = useInput({
    startDateTimeUnix: undefined,
    endDateTimeUnix: undefined,
    sessionType: undefined,
    instituteId: context.userRole.includes("Admin")
      ? context.userInstituteId
      : "ANY_INSTITUTE",
    sessionId: "ANY_SESSION",
  });
  const [rowCount, setRowCount] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [itemCount, setItemCount] = useState(context.reportList.length);
  const [list, setList] = useState(context.reportList);
  const [pureList, setPureList] = useState(context.reportList);
  const [isLoaded, setIsLoaded] = useState(false);
  const [uploadObject, , , getBaseUrl] = useStorage(config.s3.files);
  const DAY_INCREMENT = 1000 * 60 * 60 * 24;
  const nowUtc = new Date()
    .toJSON()
    .slice(0, 10)
    .replace(/-/g, "-");

  useEffect(() => {
    setItemCount(context.reportList.length);
    setPureList(context.reportList);
    setList(context.reportList);
  }, [context.reportList]);

  useEffect(() => {
    context.setAdres("report");
    context.setBarGroup("REPORTS");

    const updateReportToRetry = async (report) => {
      await reportOperations("patch", {
        reportId: report.reportId,
        status: "RETRY",
        userId: context.user.username,
      });
    };

    const constructReportInformation = async () => {
      const reportList = await queryReportList({
        userId: localStorage.getItem("username"),
      });
      for (const report of reportList) {
        if (report.status === "INITIALISED")
          await updateReportToRetry(report).then(() => {
            report.status = "RETRY";
          });
      }
      // reportList.sort((a, b) => -1 * a.status.localeCompare(b.status));
      reportList.sort((a, b) => -1 * (a.createdAt - b.createdAt));
      context.setReportList(reportList);
      if (context.userRole.includes("Super Admin")) {
        const res = await axios.get(`${config.api.invokeUrl}/institutes`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        });
        setInstituteList(res.data);
      } else if (context.userRole.includes("Admin")) {
        const foundInstitute = context.instituteList.find(
          (institute) => institute.id === context.userInstituteId
        );
        setInstituteList([foundInstitute]);
      }
      const foundSessionList = await querySessionList(
        localStorage.getItem("instituteId")
      );
      setSessionList(foundSessionList);
      setIsLoaded(true);
    };
    if (context.loaded) {
      constructReportInformation();
    }
  }, [context.loaded]); // eslint-disable-line react-hooks/exhaustive-deps

  const queryReportList = async (params) => {
    try {
      const res = await axios.post(
        `${config.api.invokeUrl}/reports/query`,
        params,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
    return [];
  };

  const setDateTimeUnix = (type, date) => {
    const dateArray = date.split("-");
    const dateTimeUnix = new Date(
      Date.UTC(dateArray[0], parseInt(dateArray[1]) - 1, dateArray[2], 0, 0, 0)
    ).getTime();
    setInputs((prevInputs) => ({
      ...prevInputs,
      [type]: dateTimeUnix,
    }));
  };

  const querySessionList = async (instituteId) => {
    try {
      const querySessionResult = await axios.post(
        `${config.api.invokeUrl}/institutes/${instituteId}/sessions/query`,
        {
          isArchived: false,
          sessionName: "",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      return querySessionResult.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleReport = async (e) => {
    e.preventDefault();
    let instituteName;
    if (inputs.instituteId !== "ANY_INSTITUTE") {
      instituteName = instituteList.filter(
        (institute) => institute.id === inputs.instituteId
      )[0].instituteName;
    }
    let sessionName;
    if (inputs.sessionId !== "ANY_SESSION") {
      inputs.startDateTimeUnix = null;
      inputs.endDateTimeUnix = null;
      sessionName = sessionList.filter(
        (session) => session.sessionId === inputs.sessionId
      )[0].sessionName;
    }
    const params = {
      ...inputs,
      userId: context.user.username,
      instituteName,
      sessionName,
      status: "WAITING",
      reportId: uniqid(),
      createdAt: new Date().getTime(),
    };
    await reportOperations("post", params).then(() => {
      store.addNotification({
        title: null,
        message: `Report with ${i18n.t(
          inputs.sessionType
        )} type has successfully created`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    });
    await reportOperations("query", {
      userId: context.user.username,
    });
    await constructReportData(params);
  };

  const msToTime = (ms) => {
    if (!ms) {
      return "NOT_REVIEWED";
    }
    let seconds = (ms / 1000).toFixed(1);
    let minutes = (ms / (1000 * 60)).toFixed(1);
    let hours = (ms / (1000 * 60 * 60)).toFixed(1);
    let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
    if (seconds < 60) return seconds + " Sec";
    else if (minutes < 60) return minutes + " Min";
    else if (hours < 24) return hours + " Hrs";
    else return days + " Days";
  };

  const constructInstituteData = () => {
    const instituteData = [];
    if (!context.userRole.includes("Admin")) {
      instituteData.push({
        value: "ANY_INSTITUTE",
        text: "ANY INSTITUTE",
      });
    }

    for (const institute of instituteList) {
      instituteData.push({
        value: institute.id,
        text: institute.instituteName,
      });
    }
    return instituteData;
  };

  const constructSessionData = () => {
    const sessionData = [
      {
        value: "ANY_SESSION",
        text: "ANY SESSION",
      },
    ];
    if (inputs.sessionType === "REGISTERED_SESSIONS") {
      for (const session of sessionList) {
        if (session.instanceState !== "FINISHED") {
          if (inputs.instituteId === "") {
            sessionData.push({
              value: session.sessionId,
              text: session.sessionName,
            });
          } else if (inputs.instituteId === session.instituteId) {
            sessionData.push({
              value: session.sessionId,
              text: session.sessionName,
            });
          }
        }
        // let found = false;
        // for (const finishedSession of finishedSessionList) {
        //   if (finishedSession.sessionName === session.sessionName) {
        //     found = true;
        //     break;
        //   }
        // }
      }
    } else if (
      inputs.sessionType === "FINISHED_SESSIONS" ||
      inputs.sessionType === "RAG_REPORT"
    ) {
      for (const session of sessionList) {
        if (inputs.instituteId === "") {
          sessionData.push({
            value: session.sessionId,
            text: session.sessionName,
          });
        } else if (inputs.instituteId === session.instituteId) {
          sessionData.push({
            value: session.sessionId,
            text: session.sessionName,
          });
        }
      }
    }
    return sessionData;
  };

  const constructStudentResultComments = (studentResult) => {
    let result = "";
    if (studentResult && studentResult.comments) {
      for (const comment of studentResult.comments) {
        if (comment.text === "Other...") {
          result += `*${comment.otherText.replace(",", ";")}* `;
        } else {
          result += `*${comment.text.replace(",", ";")}* `;
        }
      }
    } else {
      result = "NO_COMMENTS_MADE";
    }
    return result;
  };

  const constructStudentProctorValue = (student) => {
    let result = "";
    if (student.proctors && student.proctors.length > 0) {
      for (const proctor of student.proctors) {
        result += `*${proctor.proctorFullName.replace(",", ";")}* `;
      }
    } else {
      result = "NO_PROCTORS_ASSIGNED";
    }
    return result;
  };

  const constructStudentProctorEvaluation = (studentResult) => {
    let result = "";
    if (
      studentResult &&
      studentResult.proctorEvaluationStatus &&
      studentResult.proctorEvaluationDescription
    ) {
      result = `*Evaluation: ${studentResult.proctorEvaluationStatus.replace(
        ",",
        ";"
      )}* *Description: ${studentResult.proctorEvaluationDescription.replace(
        ",",
        ";"
      )}*`;
    } else {
      result = "NO_PROCTOR_EVALUATION_MADE";
    }
    return result;
  };

  const calculateRoleCommentsCount = (studentResult, role) => {
    let result = 0;
    if (studentResult && studentResult.comments) {
      const proctorComments = studentResult.comments.filter(
        (comment) => comment.type === role
      );
      result = proctorComments.length;
    }
    return result;
  };

  const constructRoleCommentsValue = (studentResult, role) => {
    let result = "";
    if (studentResult && studentResult.comments) {
      for (const comment of studentResult.comments) {
        if (comment.type === role) {
          if (comment.text === "Other...") {
            result += `*${comment.otherText.replace(",", ";")}* `;
          } else {
            result += `*${comment.text.replace(",", ";")}* `;
          }
        }
      }
    } else {
      result = "NO_COMMENTS_MADE";
    }
    return result;
  };

  const calculateEstimatedSeatUsage = (student) => {
    if (student.examExtraDuration === 0) {
      return 1;
    } else {
      return 1 + Math.ceil(student.examExtraDuration / 60);
    }
  };

  const constructReportData = async (report) => {
    if (report.status === "INITIALISED") {
      store.addNotification({
        title: "Please Wait",
        message: `Created report with type ${i18n.t(
          report.sessionType
        )} is currently being generated.`,
        type: "warning",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } else {
      await reportOperations("patch", {
        reportId: report.reportId,
        status: "INITIALISED",
        userId: context.user.username,
      });
      let reportInstituteList = [];
      if (report.instituteId === "ANY_INSTITUTE") {
        reportInstituteList = instituteList;
      } else {
        const foundInstitute = instituteList.find(
          (institute) => institute.id === report.instituteId
        );
        reportInstituteList.push(foundInstitute);
      }
      let reportSessionList = [];
      const constructedReportData = [];
      for (const currentInstitute of reportInstituteList) {
        const foundSessionList = await querySessionList(currentInstitute.id);
        if (report.sessionId === "ANY_SESSION") {
          reportSessionList = foundSessionList;
        } else {
          const foundSession = foundSessionList.find(
            (session) => session.sessionId === report.sessionId
          );
          reportSessionList.push(foundSession);
        }
        for (let currentSession of reportSessionList) {
          currentSession = await setSession("getById", {
            sessionId: currentSession.sessionId,
            instituteId: currentInstitute.id,
          });
          if (
            report.sessionId !== "ANY_SESSION" ||
            (currentSession.startDateTimeUnix >= report.startDateTimeUnix &&
              currentSession.endDateTimeUnix <=
              report.endDateTimeUnix + DAY_INCREMENT)
          ) {
            if (currentSession.studentList) {
              const meetingListResult = await axios.post(
                `${config.api.invokeUrl}/meetings/query`,
                {
                  sessionId: currentSession.sessionId,
                  studentId: "",
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      Object.keys(localStorage).find((key) =>
                        key.includes("accessToken")
                      )
                    )}`,
                  },
                }
              );
              const meetingList = meetingListResult.data;
              const studentResultListResult = await axios.post(
                `${config.api.invokeUrl}/studentResults/query`,
                {
                  meetingId: `${currentSession.sessionId}`,
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      Object.keys(localStorage).find((key) =>
                        key.includes("accessToken")
                      )
                    )}`,
                  },
                }
              );
              const studentResultList = studentResultListResult.data;
              for (const currentStudent of currentSession.studentList) {
                const studentMeetingInformation = meetingList.find(
                  (meeting) => meeting.studentId === currentStudent.studentId
                );
                let studentDuration = `NO_DURATION`;
                
                if (studentMeetingInformation) {
                  if (studentMeetingInformation.state === "KICKED") {
                    currentStudent.meetingStatus = "KICKED";
                  } else if (studentMeetingInformation.endTime) {
                    currentStudent.meetingStatus = "FINISHED";
                  } else {
                    currentStudent.meetingStatus = "IN EXAM";
                  }
                } else if (currentStudent.preSystemCheck) {
                  let correctSystemChecks = 0;
                  for (const key in currentStudent.preSystemCheck) {
                    if (currentStudent.preSystemCheck[key]) correctSystemChecks++;
                  }
                  let numberOfKeys = Object.keys(currentStudent.preSystemCheck).length;

                  if (correctSystemChecks === numberOfKeys) {
                    currentStudent.meetingStatus = "NOT STARTED";
                  } else {
                    currentStudent.meetingStatus = "REGISTERED";
                  }
                }
                
                if (
                  studentMeetingInformation &&
                  studentMeetingInformation.startTime &&
                  studentMeetingInformation.endTime
                ) {
                  studentDuration = `${(
                    (studentMeetingInformation.endTime -
                      studentMeetingInformation.startTime) /
                    1000 /
                    60
                  ).toFixed(2)} mins`;
                }

                const studentResult = studentResultList.find(
                  (studentResult) =>
                    studentResult.meetingId ===
                    `${currentSession.sessionId}${currentStudent.studentId}`
                );
                constructedReportData.push({
                  "INSITUTE ID": currentInstitute.id,
                  "INSITUTE NAME": currentInstitute.instituteName,
                  "SESSION NAME": currentSession.sessionName,
                  "SESSION TYPE": currentSession.proctorServiceType,
                  "SESSION LINK": `${config.api.base_url}/libraryDetailed/${currentSession.sessionId}/${currentStudent.studentId}`,
                  "SESSION START DATE": new Date(
                    currentSession.startDateTimeUnix
                  )
                    .toLocaleString()
                    .replace(",", ""),
                  "SESSION LAST START DATE": new Date(
                    currentSession.lastStartDateTimeUnix
                  )
                    .toLocaleString()
                    .replace(",", ""),
                  "SESSION END DATE": new Date(currentSession.endDateTimeUnix)
                    .toLocaleString()
                    .replace(",", ""),
                  "STUDENT ID": currentStudent.studentId,
                  "STUDENT FULL NAME": `${currentStudent.name} ${currentStudent.lastname}`,
                  "STUDENT EMAIL": `${currentStudent.email}`,
                  "STUDENT SESSION START DATE": studentMeetingInformation
                    ? new Date(studentMeetingInformation.startTime)
                      .toLocaleString()
                      .replace(",", "")
                    : "NO_START_DATE",
                  "STUDENT SESSION END DATE": studentMeetingInformation
                    ? new Date(studentMeetingInformation.endTime)
                      .toLocaleString()
                      .replace(",", "")
                    : "NO_END_DATE",
                  "STUDENT SESSION DURATION": studentDuration,
                  "STUDENT SYSTEM CHECK LINK": `${config.api.student_base_url}/preExamSystemCheck/${currentInstitute.id}/${currentSession.sessionId}/${currentStudent.studentId}`,
                  "STUDENT SESSION LINK": `${config.api.student_base_url}/${currentInstitute.id}/${currentSession.sessionId}/${currentStudent.studentId}`,
                  "STUDENT STATUS": currentStudent.meetingStatus,
                  "REVIEW STATUS": currentStudent.evaluation
                    ? currentStudent.evaluation
                    : "NOT_REVIEWED",
                  "REVIEW TEXT": currentStudent.evaluationDesc
                    ? currentStudent.evaluationDesc
                    : "NOT_REVIEWED",
                });
                if (report.sessionType === "REGISTERED_SESSIONS") {
                  constructedReportData[constructedReportData.length - 1][
                    "ESTIMATED SEAT USAGE"
                  ] = calculateEstimatedSeatUsage(currentStudent);
                  constructedReportData[constructedReportData.length - 1][
                    "STATUS"
                  ] = "???";
                  constructedReportData[constructedReportData.length - 1][
                    "SESSION STATUS"
                  ] = currentSession.instanceState;
                } else if (report.sessionType === "FINISHED_SESSIONS") {
                  constructedReportData[constructedReportData.length - 1][
                    "COMMENTS COUNT"
                  ] = studentResult
                      ? studentResult.comments
                        ? studentResult.comments.length
                        : 0
                      : 0;
                  constructedReportData[constructedReportData.length - 1][
                    "COMMENTS"
                  ] = constructStudentResultComments(studentResult);
                } else if (report.sessionType === "RAG_REPORT") {
                  constructedReportData[constructedReportData.length - 1][
                    "PROCTOR FULL NAME"
                  ] = constructStudentProctorValue(currentStudent);
                  constructedReportData[constructedReportData.length - 1][
                    "PROCTOR EVALUATION"
                  ] = constructStudentProctorEvaluation(studentResult);
                  constructedReportData[constructedReportData.length - 1][
                    "PROCTOR COMMENTS COUNT"
                  ] = calculateRoleCommentsCount(studentResult, "Proctor");
                  constructedReportData[constructedReportData.length - 1][
                    "PROCTOR COMMENTS"
                  ] = constructRoleCommentsValue(studentResult, "Proctor");
                  constructedReportData[constructedReportData.length - 1][
                    "REWIEWER COMMENTS COUNT"
                  ] = calculateRoleCommentsCount(studentResult, "Reviewer");
                  constructedReportData[constructedReportData.length - 1][
                    "REWIEWER COMMENTS"
                  ] = constructRoleCommentsValue(studentResult, "Reviewer");
                  constructedReportData[constructedReportData.length - 1][
                    "REVIEWING DURATION"
                  ] = msToTime(currentStudent.reviewingDuration);
                  constructedReportData[constructedReportData.length - 1][
                    "SUPERVISOR COMMENTS COUNT"
                  ] = calculateRoleCommentsCount(studentResult, "Supervisor");
                  constructedReportData[constructedReportData.length - 1][
                    "SUPERVISOR COMMENTS"
                  ] = constructRoleCommentsValue(studentResult, "Supervisor");
                }
              }
            }
          }
        }
      }
      await reportOperations("patch", {
        reportId: report.reportId,
        status: "COMPLETED",
        userId: context.user.username,
      });
      if (constructedReportData.length > 0) {
        convertJSONToCSV(constructedReportData, report.reportId);
        convertJSONToPDF(constructedReportData, report.reportId);
      }
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  function convertToCSV(jsonArray) {
    var array =
      typeof jsonArray !== "object" ? JSON.parse(jsonArray) : jsonArray;
    var str = "";
    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line !== "") line += ",";

        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }
  function convertJSONToCSV(jsonArray, reportId) {
    const headers = {};
    const keys = Object.keys(jsonArray[0]);
    for (const key of keys) {
      headers[key] = key;
    }
    jsonArray.unshift(headers);

    var csv = convertToCSV(jsonArray);
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const fileReader = new FileReader();
    fileReader.onload = function (e) {
      uploadToS3(e.target.result, reportId, "csv");
    };
    fileReader.readAsDataURL(blob);
  }

  const convertJSONToPDF = (jsonArray, reportId) => {
    const document = {
      content: [],
    };
    jsonArray.forEach((json) => {
      const keys = Object.keys(json);
      document.content.push({
        text: `##############################################################`,
        style: "header",
        alignment: "center",
        margin: [0, 20],
      });
      for (const key of keys) {
        document.content.push({
          text: [
            { text: `${key}:`, bold: true },
            { text: "\t" },
            { text: `${json[key]}` },
          ],
          style: "subheader",
          margin: [0, 2],
        });
      }
    });
    pdfMake.createPdf(document).getDataUrl((result) => {
      uploadToS3(result, reportId, "pdf");
    });
  };

  const uploadToS3 = async (result, reportId, type) => {
    const file = dataURLtoFile(result, `${reportId}.${type}`);
    await uploadObject(file, file.name, `reports/${context.user.username}/`);
  };

  const downloadDocument = async (type, reportType, reportId) => {
    await axios
      .get(
        `${getBaseUrl()}/reports/${context.user.username}/${reportId}.${type}`,
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        fileDownload(res.data, `${reportType}.${type}`);
      });
  };

  const deleteReport = async (report) => {
    const params = {
      reportId: report.reportId,
      userId: context.user.username,
    };
    setIsLoaded(false);
    await reportOperations("delete", params).then(() => {
      store.addNotification({
        title: "Please Wait",
        message: `Report with ${i18n.t(
          report.sessionType
        )} type has successfully deleted`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      setIsLoaded(true);
    });
  };

  const findReportStatusTextColor = (status) => {
    if (status === "COMPLETED") {
      return "green";
    } else if (status === "WAITING") {
      return "blue";
    } else if (status === "INITIALISED") {
      return "red";
    } else if (status === "RETRY") {
      return "orange";
    }
  };

  const renderDownloadIcons = (report) => {
    if (report.status === "COMPLETED") {
      return (
        <div className="d-flex flex-row justify-content-around align-items-center">
          <FaFilePdf
            size={20}
            color={"#B30B00"}
            onClick={() =>
              downloadDocument("pdf", report.sessionType, report.reportId)
            }
          />
          <FaFileCsv
            size={20}
            color={"#057212"}
            onClick={() =>
              downloadDocument("csv", report.sessionType, report.reportId)
            }
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const handleInstituteChange = async (e) => {
    setInputs(e);
    const foundSessionList = await querySessionList(e.target.value);
    setSessionList(foundSessionList);
  };

  const handleReportTypeChange = (e) => {
    document.getElementById("endDate").value = undefined;
    document.getElementById("startDate").value = undefined;
    // document.getElementById("startDate").value = undefined;
    // const endDateInput = document.getElementById("endDate");
    // if (e.target.value === "REGISTERED_SESSIONS") {
    //   endDateInput.max = null;
    // } else {
    //   const currentDate = new Date();
    //   const constructedEndDate = `${currentDate.getUTCFullYear()}-${currentDate.getMonth() +
    //     1}-${currentDate.getDate()}`;

    //   endDateInput.max = constructedEndDate;
    // }
    setInputs(e);
  };

  const mainContent = () => {
    return (
      <React.Fragment>
        <ScreenPanel col="10" name="General_Report">
          <form onSubmit={handleReport}>
            <Row>
              <FormSelect
                col="4"
                name="sessionType"
                label={i18n.t("Select_Report_Type")}
                onChange={handleReportTypeChange}
                value={inputs.sessionType}
                data={variables.sessionType}
                dataValue="value"
                dataText="text"
                required={true}
              />

              <FormInput
                col="4"
                id="startDate"
                name="startDate"
                text={i18n.t("Start_Date")}
                value={inputs.startDate}
                onChange={(e) =>
                  setDateTimeUnix("startDateTimeUnix", e.target.value)
                }
                type="date"
                required={inputs.sessionId === "ANY_SESSION" ? true : false}
                disabled={
                  (inputs.sessionId === "ANY_SESSION" ? false : true) ||
                  !inputs.sessionType
                }
                min={inputs.sessionType === "REGISTERED_SESSIONS" ? nowUtc : ""}
                max={
                  inputs.sessionType === "FINISHED_SESSIONS" ||
                    inputs.sessionType === "RAG_REPORT"
                    ? nowUtc
                    : ""
                }
              />

              <FormInput
                col="4"
                id="endDate"
                name="endDate"
                text={i18n.t("End_Date")}
                value={inputs.endDate}
                onChange={(e) =>
                  setDateTimeUnix("endDateTimeUnix", e.target.value)
                }
                type="date"
                required={inputs.sessionId === "ANY_SESSION" ? true : false}
                disabled={
                  (inputs.sessionId === "ANY_SESSION" ? false : true) ||
                  !inputs.sessionType
                }
                min={
                  inputs.startDateTimeUnix
                    ? new Date(inputs.startDateTimeUnix)
                      .toJSON()
                      .slice(0, 10)
                      .replace(/-/g, "-")
                    : inputs.sessionType === "REGISTERED_SESSIONS"
                      ? nowUtc
                      : ""
                }
                max={
                  inputs.sessionType === "FINISHED_SESSIONS" ||
                    inputs.sessionType === "RAG_REPORT"
                    ? nowUtc
                    : ""
                }
              />
              <FormSelect
                col="4"
                name="instituteId"
                label={i18n.t("Select_Institute")}
                onChange={handleInstituteChange}
                value={inputs.instituteId}
                data={constructInstituteData()}
                dataValue="value"
                dataText="text"
                required={true}
              />
              <FormSelect
                col="4"
                name="sessionId"
                label={i18n.t("Select_Sessions")}
                onChange={setInputs}
                value={inputs.sessionId}
                data={constructSessionData()}
                dataValue="value"
                dataText="text"
                required={true}
              />

              <div className="d-flex flex-column justify-content-center align-items-center form-group col-4">
                <button
                  className="btn buttons"
                  style={{ width: "100%" }}
                  type={"submit"}
                >
                  {i18n.t("Generate_Report")}
                </button>
              </div>
            </Row>
          </form>
        </ScreenPanel>
        <ScreenPanel
          col="10"
          name="Reports"
          panels="true"
          pagination="true"
          rowCount={rowCount}
          setRowCount={setRowCount}
          itemCount={itemCount}
          activePage={activePage}
          setActivePage={setActivePage}
          pureList={pureList}
          list={list}
          setList={setList}
        >
          <table className={context.theme.table_style}>
            <thead>
              <tr className={`${context.theme.color} text-center`}>
                <th onClick={() => context.sortTableData(list, "reportId")}>
                  {i18n.t("Report_Id")}
                </th>
                <th onClick={() => context.sortTableData(list, "sessionType")}>
                  {i18n.t("Select_Report_Type")}
                </th>
                <th onClick={() => context.sortTableData(list, "v")}>
                  {i18n.t("Institute_Name")}
                </th>
                <th onClick={() => context.sortTableData(list, "sessionName")}>
                  {i18n.t("Session_Name")}
                </th>
                <th onClick={() => context.sortTableData(list, "status")}>
                  {i18n.t("Status")}
                </th>
                <th
                  onClick={() =>
                    context.sortTableData(list, "startDateTimeUnix")
                  }
                >
                  {i18n.t("Start_Date")}
                </th>
                <th
                  onClick={() => context.sortTableData(list, "endDateTimeUnix")}
                >
                  {i18n.t("End_Date")}
                </th>
                <th onClick={() => context.sortTableData(list, "reportId")}>
                  {i18n.t("Download")}
                </th>
                <th onClick={() => context.sortTableData(list, "reportId")}>
                  {i18n.t("Action")}
                </th>
              </tr>
            </thead>
            {isLoaded ? (
              <tbody>
                {list
                  .slice((activePage - 1) * rowCount, activePage * rowCount)
                  .map((report, key) => (
                    <tr key={key} className="text-center">
                      <td>{report.reportId}</td>
                      <td>{i18n.t(report.sessionType)}</td>
                      <td>{report.instituteName}</td>
                      <td>{report.sessionName}</td>
                      <td
                        style={{
                          color: findReportStatusTextColor(report.status),
                        }}
                      >
                        {i18n.t(report.status)}
                      </td>
                      <td>
                        {report.startDateTimeUnix
                          ? new Date(
                            report.startDateTimeUnix
                          ).toLocaleDateString()
                          : null}
                      </td>
                      <td>
                        {report.endDateTimeUnix
                          ? new Date(
                            report.endDateTimeUnix
                          ).toLocaleDateString()
                          : null}
                      </td>
                      <td>{renderDownloadIcons(report)}</td>
                      <td>
                        <div className="d-flex flex-row justify-content-around align-items-center">
                          <FaSync
                            size={20}
                            onClick={() => constructReportData(report)}
                            color={"#29377e"}
                          />
                          <FaTimes
                            size={20}
                            color={"red"}
                            onClick={() => deleteReport(report)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            ) : null}
          </table>
          {isLoaded ? null : (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <ReactLoading type="bars" color="#29377e" />
            </div>
          )}
        </ScreenPanel>
      </React.Fragment>
    );
  };

  return context.loaded ? mainContent() : <LoadingIcon />;
}
export default withNamespaces()(Report);
