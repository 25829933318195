/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import Chat, { Bubble, useMessages } from "@chatui/core";
import "@chatui/core/dist/index.css";
import { withNamespaces } from "react-i18next";
// import { proctorcontext } from "../../../configs/proctorContext";

function ChatViewer(props) {
  const { messages, appendMsg, resetList, setTyping } = useMessages([]);
  const meetingInformation = props.meetingInformation;
  // const proccontext = useContext(proctorcontext);
  // const sessionId = props.sessionId;

  useEffect(() => {
    //ChatFooter
    document.getElementsByClassName("ChatFooter").forEach((element) => {
      element.style.display = "none";
    });
    resetList();
    if (props.chats) {
      props.chats.forEach((element) => {
        var d = new Date(element.Time);
        var n =
          coltrolZiro(d.getHours() + "") +
          ":" +
          coltrolZiro(d.getMinutes() + "") +
          ":" +
          coltrolZiro(d.getSeconds() + "");
        const position = element.Role === "Student" ? "right" : "left";
        appendMsg({
          type: "text",
          content: {
            text: element.Person + " (" + n + "): " + element.Message,
            timestamp: element.Time,
          },
          position: position,
        });
      });
    }
  }, []);

  const coltrolZiro = (value) => {
    if (value.length === 1) return "0" + value;
    return value;
  };

  function handleSend(type, val) {
    // on reviewer page, chat component is only read only...
  }

  const millisToMinutesAndSeconds = (millis) => {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    //ES6 interpolated literals/template literals
    //If seconds is less than 10 put a zero in front.
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleChatTextClick = (time) => {
    const webcamMeeting = meetingInformation.find((meetingInfo) =>
      meetingInfo.meetingId.includes("webcam")
    );
    const screenShareMeeting = meetingInformation.find((meetingInfo) =>
      meetingInfo.meetingId.includes("screenShare")
    );
    let startTime;

    if (webcamMeeting.startTime < screenShareMeeting.startTime) {
      startTime = webcamMeeting.startTime;
    } else {
      startTime = screenShareMeeting.startTime;
    }
    const difference = parseInt(time) - parseInt(startTime);
    props.event(difference / 1000);
  };

  function renderMessageContent(msg) {
    const { content } = msg;
    return (
      <div
        onClick={() => {
          handleChatTextClick(content.timestamp);
        }}
      >
        <Bubble content={content.text} />
      </div>
    );
  }

  return (
    <div className="col">
      <div className="text-center" style={{ fontWeight: "bold", fontSize: 16 }}>
        {props.title}
      </div>
      <div className="col p-0" style={{ height: 446 }}>
        <Chat
          messages={messages}
          renderMessageContent={renderMessageContent}
          placeholder="Write your message..."
          locale="en-US"
          onSend={handleSend}
        />
      </div>
    </div>
  );
}
export default withNamespaces()(ChatViewer);
