import React, { useState, useEffect, useContext } from "react";
import i18n from "../../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import { awscontext } from "../../../configs/awscontext";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";

function DonutBar() {
  const context = useContext(awscontext);
  const [reportData, setReportData] = useState(context.dashboardData);
  const [instData, setInstData] = useState([]);
  const [barData, setBarData] = useState([]);

  useEffect(() => {
    prepareData();
  }, [reportData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setReportData(context.dashboardData);
  }, [context.dashboardData]);

  const prepareData = () => {
    const institutePiePre = [];
    const barDataPre = [];
    if (context.userRole.includes("Super Admin")) {
      reportData.forEach((report) => {
        if (report.Sessions) {
          const liveSessionList = report.Sessions.filter(
            (session) => session.instanceState === "ON_GOING"
          );
          const inActiveSessionList = report.Sessions.filter(
            (session) => session.instanceState === "CONFIGURABLE"
          );
          const finishedSessionList = report.Sessions.filter(
            (session) => session.instanceState === "FINISHED"
          );
          let currentInstituteAbbreviation = "";
          if (report.instituteName !== undefined) {
            const instituteNameWords = report.instituteName.split(" ");
            instituteNameWords.forEach((word, index) => {
              if (index === 0) {
                currentInstituteAbbreviation += word;
              } else {
                currentInstituteAbbreviation += ` ${word.substring(0, 1)}.`;
              }
            });
          }

          institutePiePre.push({
            id: currentInstituteAbbreviation,
            label: report.instituteName,
            value: report.Sessions.length,
          });
          barDataPre.push({
            institute: currentInstituteAbbreviation,
            "Inactive Sessions": inActiveSessionList.length,
            "Live Sessions": liveSessionList.length,
            "Finished Sessions": finishedSessionList.length,
          });
        }
      });
      setInstData(institutePiePre);
    } else if (context.userRole.includes("Admin")) {
      const liveSessionList = reportData.filter(
        (session) => session.instanceState === "ON_GOING"
      );
      const inActiveSessionList = reportData.filter(
        (session) => session.instanceState === "CONFIGURABLE"
      );
      const finishedSessionList = reportData.filter(
        (session) => session.instanceState === "FINISHED"
      );

      barDataPre.push({
        institute: context.userInstituteName,
        "Inactive Sessions": inActiveSessionList.length,
        "Live Sessions": liveSessionList.length,
        "Finished Sessions": finishedSessionList.length,
      });
    }

    setBarData(barDataPre);
  };

  return (
    <div className="d-flex flex-row justify-content-center m-4">
      {context.userRole.includes("Super Admin") ? (
        <div className="col-5">
          <div className="container-fluid">
            <div className="container-fluid">
              <div className="card mt-4 mb-4 rounded">
                <div
                  className="card-header py-3 dark-blue"
                  style={{ fontFamily: "verdana" }}
                >
                  <h5>{i18n.t("Institute_List")}</h5>
                </div>
                <div className="card-body">
                  <div style={{ height: 450, backgroundColor: "white" }}>
                    <ResponsivePie
                      data={instData}
                      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                      innerRadius={0.5}
                      padAngle={0.7}
                      cornerRadius={3}
                      activeOuterRadiusOffset={8}
                      colors={{ scheme: "accent" }}
                      borderWidth={2}
                      borderColor={{
                        from: "color",
                        modifiers: [["brighter", 0.2]],
                      }}
                      arcLinkLabelsSkipAngle={6}
                      arcLinkLabelsTextColor="#333333"
                      arcLinkLabelsThickness={2}
                      arcLinkLabelsColor="#766f6f"
                      arcLabelsSkipAngle={10}
                      arcLabelsTextColor={{
                        from: "color",
                        modifiers: [["darker", 2]],
                      }}
                      defs={[
                        {
                          id: "dots",
                          type: "patternDots",
                          background: "inherit",
                          color: "rgba(255, 255, 255, 0.3)",
                          size: 4,
                          padding: 1,
                          stagger: true,
                        },
                        {
                          id: "lines",
                          type: "patternLines",
                          background: "inherit",
                          color: "rgba(255, 255, 255, 0.3)",
                          rotation: -45,
                          lineWidth: 6,
                          spacing: 10,
                        },
                      ]}
                      fill={[
                        {
                          match: {
                            id: "ruby",
                          },
                          id: "dots",
                        },
                        {
                          match: {
                            id: "c",
                          },
                          id: "dots",
                        },
                        {
                          match: {
                            id: "go",
                          },
                          id: "dots",
                        },
                        {
                          match: {
                            id: "python",
                          },
                          id: "dots",
                        },
                        {
                          match: {
                            id: "scala",
                          },
                          id: "lines",
                        },
                        {
                          match: {
                            id: "lisp",
                          },
                          id: "lines",
                        },
                        {
                          match: {
                            id: "elixir",
                          },
                          id: "lines",
                        },
                        {
                          match: {
                            id: "javascript",
                          },
                          id: "lines",
                        },
                      ]}
                      transitionMode="startAngle"
                      legends={[]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="col-7">
        <div className="container-fluid">
          <div className="container-fluid">
            <div className="card mt-4 mb-4">
              <div
                className="card-header py-3 dark-blue"
                style={{ fontFamily: "verdana" }}
              >
                <h5>{i18n.t("Session_List")}</h5>
              </div>
              <div className="card-body">
                <div style={{ height: 450, backgroundColor: "white" }}>
                  <ResponsiveBar
                    data={barData}
                    keys={[
                      "Inactive Sessions",
                      "Live Sessions",
                      "Finished Sessions",
                    ]}
                    indexBy="institute"
                    margin={{ top: 50, right: 140, bottom: 50, left: 60 }}
                    padding={0.3}
                    valueScale={{ type: "linear" }}
                    indexScale={{ type: "band", round: true }}
                    valueFormat={{ format: "", enabled: false }}
                    colors={{ scheme: "paired" }}
                    defs={[
                      {
                        id: "dots",
                        type: "patternDots",
                        background: "inherit",
                        color: "#38bcb2",
                        size: 4,
                        padding: 1,
                        stagger: true,
                      },
                      {
                        id: "lines",
                        type: "patternLines",
                        background: "inherit",
                        color: "#eed312",
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10,
                      },
                    ]}
                    fill={[
                      {
                        match: {
                          id: "fries",
                        },
                        id: "dots",
                      },
                      {
                        match: {
                          id: "sandwich",
                        },
                        id: "lines",
                      },
                    ]}
                    borderRadius={5}
                    borderWidth={2}
                    borderColor={{ theme: "background" }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: "Institute",
                      legendPosition: "middle",
                      legendOffset: 32,
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: "Total Session Count",
                      legendPosition: "middle",
                      legendOffset: -40,
                    }}
                    labelSkipWidth={13}
                    labelSkipHeight={9}
                    labelTextColor={{
                      from: "color",
                      modifiers: [["darker", "1.6"]],
                    }}
                    legends={[
                      {
                        dataFrom: "keys",
                        anchor: "bottom-right",
                        direction: "column",
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: "left-to-right",
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                          {
                            on: "hover",
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withNamespaces()(DonutBar);
