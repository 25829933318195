/* eslint-disable no-unused-vars */

import React, { useState, useContext, useEffect } from "react";
import { useInput } from "../../../hooks/useInput";
import { useSession } from "../../../hooks/useSession";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import { awscontext } from "../../../configs/awscontext";
import i18n from "../../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FaTrashAlt } from "react-icons/fa";
import OpenViduConnector from "../../../utils/OpenViduConnector";
import { store } from "react-notifications-component";
import config from "../../../configs/awsconfig.json";
import axios from "axios";

const variables = require("../../../models/variables.json");
function StudentPrivateRules(props) {
  const context = useContext(awscontext);
  const [inputs, setInputs] = useInput({ ...props.Student });
  const [selectedProctorMail, setSelectedProctorMail] = useState("");
  const [selectedSession, setSelectedSession] = useState({ ...props.Session });
  const [studentProctorList, setStudentProctorList] = useState([]);
  const [, setSession] = useSession([]);
  useEffect(() => {
    if (props.Student.proctors !== undefined) {
      setStudentProctorList(props.Student.proctors);
    }
    return () => setInputs("");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateProctorHandler = async (type, proctor) => {
    let newStudentProctorList = [];
    if (type === "add") {
      const existingProctor = studentProctorList.find(
        (studentProctor) => studentProctor.proctorMail === selectedProctorMail
      );
      if (!existingProctor) {
        newStudentProctorList = studentProctorList;
        const foundProctor = context.availbleProctors.find(
          (availableProctor) =>
            availableProctor.proctorMail === selectedProctorMail
        );
        foundProctor.sessionIncludeTime = new Date().getTime();
        newStudentProctorList.push(foundProctor);
        selectedSession.studentList.forEach((student) => {
          if (student.studentId === inputs.studentId) {
            student.proctors = newStudentProctorList;
          }
        });
        setStudentProctorList(newStudentProctorList);
      }
    } else if (type === "remove") {
      const existingProctor = studentProctorList.find(
        (studentProctor) => studentProctor.proctorMail === proctor.proctorMail
      );
      if (existingProctor) {
        newStudentProctorList = studentProctorList.filter((p) => p !== proctor);
        selectedSession.studentList.forEach((student) => {
          if (student.studentId === inputs.studentId) {
            student.proctors = newStudentProctorList;
          }
        });
        setStudentProctorList(newStudentProctorList);
      }
    }

    const params = {
      isArchived: false,
      sessionName: "",
      sessionId: selectedSession.sessionId,
      studentId: inputs.studentId,
      proctorList: newStudentProctorList,
      instituteId: selectedSession.instituteId,
    };

    const response = {
      statusCode: 0,
      body: null,
    };
    await setSession("patch", params, "proctorInfo", response);
  };

  const updateStatusMeetingInformation = async () => {
    const meetingId = `${selectedSession.sessionId}${inputs.studentId}-webcam`;
    try {
      await axios.patch(
        `${config.api.invokeUrl}${config.aws.gateway.endpoints.meetings.context}/${meetingId}/${context.userInstituteId}`,
        { state: "KICKED" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
    } catch (err) {
      console.log("Error setting the student state: ", err);
    }
  };

  const kickStudentFromSession = async () => {
    const openviduConnector = new OpenViduConnector();
    const sessionProperties = {
      customSessionId: `${selectedSession.sessionId}${inputs.studentId}-webcam`,
    };
    await openviduConnector.createSession(sessionProperties);
    const session = openviduConnector.initializeSession();
    const connectionProperties = {
      role: "SUBSCRIBER",
    };
    const connection = await openviduConnector.connectToSession(
      sessionProperties.customSessionId,
      connectionProperties
    );
    await openviduConnector.joinSession(
      session,
      connection,
      `${context.user.roles[0].role} | ${context.user.name} ${context.user.lastname}`,
      null
    );
    session
      .signal({
        data: JSON.stringify({
          state: "EXAM_FORCE_FINISHED_BY_ADMIN",
          meetingId: sessionProperties.customSessionId,
          proctorId: context.user.username,
        }),
        type: "system",
      })
      .then(() => {
        updateStatusMeetingInformation()
          .then(() => {
            store.addNotification({
              title: null,
              message: `Student ${inputs.name} ${inputs.lastname} has successfully disqualified from the session`,
              type: "info",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
            props.handleClose();
          })
          .catch((err) => {
            store.addNotification({
              title: null,
              message: `Student ${inputs.name} ${inputs.lastname} cannot be kicked out from the session, please try again`,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          });
      })
      .catch((error) => {
        console.error("err:", error);
      });
    session.disconnect();
  };

  const patchStudent = async () => {
    //await props.retrieveSessionInformation(false);
    const updatedStudentList = [];
    updatedStudentList.push(inputs);
    const studentId = inputs.studentId; //proctoru silinecek olan student'ın ID'si
    let proctorList;
    selectedSession.studentList.forEach((student) => { //güncel proctorList bulunur
      if (student.studentId === studentId) {
        proctorList = student.proctors;
      }
    });
    const params = {
      isArchived: false,
      sessionName: "",
      sessionId: selectedSession.sessionId,
      studentList: updatedStudentList,
      instituteId: selectedSession.instituteId,
    };
    params.studentList[0].proctors = proctorList; //güncel proctorList patch atılacak fonksiyona gönderilir
    const response = {
      statusCode: 0,
      body: null,
    };
    await setSession("patchStudent", params, "updateSingleStudent", response);
    if (response.statusCode === 204) {
      store.addNotification({
        title: null,
        message: `Session ${selectedSession.sessionName} has successfully updated`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      props.handleClose();
    } else if (response.statusCode === 210) {
      store.addNotification({
        title: null,
        message: `${response.body}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  const handleSystemCheckLinkStatus = async (e, source) => {
    e.preventDefault();
    try {
      const params = {
        studentId: inputs.studentId,
        sessionId: selectedSession.sessionId,
        source: source,
        section: "systemCheckLinkStatus",
        value: e.target.value,
      };
      await axios.patch(
        `${config.api.invokeUrl}/institutes/${selectedSession.instituteId}/sessions/${selectedSession.sessionId}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      store.addNotification({
        title: null,
        message: `Successfully updated link status for student ${inputs.name} ${inputs.lastname}`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } catch (err) {
      store.addNotification({
        title: null,
        message: `Could not update link status for student ${inputs.name} ${inputs.lastname}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  const proctorArea = () => {
    return (
      <React.Fragment>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await updateProctorHandler("add");
          }}
        >
          <div className="row">
            <div className="col-8">
              <FormSelect
                col="12"
                name="selectedProctorMail"
                label={i18n.t("Proctors")}
                onChange={(e) => setSelectedProctorMail(e.target.value)}
                value={selectedProctorMail}
                data={context.availbleProctors}
                dataValue="proctorMail"
                dataText="proctorFullName"
                required={true}
              />
            </div>
            <div className="col-4">
              <button
                className={`${context.theme.btn_save_small} w-100`}
                // style={{ marginTop: 28, height: 36 }}
                type="submit"
              >
                {i18n.t("Add_Proctor")}
              </button>
            </div>
          </div>
        </form>

        <div className="d-flex flex-row">
          {studentProctorList.map((proc, i) => (
            <div
              key={i}
              className="d-flex flex-row align-items-center text-center mr-3"
            >
              <FaTrashAlt
                onClick={(e) => {
                  e.preventDefault();
                  updateProctorHandler("remove", proc);
                }}
                cursor="pointer"
              />
              <div className="pl-1 mt-auto mb-auto">{proc.proctorFullName}</div>
            </div>
          ))}
        </div>

        <hr />
      </React.Fragment>
    );
  };

  const extraRulesArea = () => {
    return (
      <FormGroup>
        <div className="row">
          <div className="col-12">
            <FormControlLabel
              name="examRules.mobileCameraUsage"
              control={
                <Switch
                  checked={inputs.examRules.mobileCameraUsage}
                  onChange={(e) =>
                    setInputs(e, !inputs.examRules.mobileCameraUsage)
                  }
                />
              }
              label={i18n.t("Mobile_Camera_Usage")}
              color="primary"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <FormControlLabel
              name="examRules.internet"
              control={
                <Switch
                  checked={inputs.examRules.internet}
                  onChange={(e) => setInputs(e, !inputs.examRules.internet)}
                />
              }
              label={i18n.t("Use_Internet")}
              color="primary"
            />
          </div>
          {inputs.examRules.internet === true ? (
            <div className="col-8">
              <input
                className="form-control"
                name="examRules.internetDesc"
                value={inputs.examRules.internetDesc}
                onChange={setInputs}
                type="text"
              />
            </div>
          ) : null}
        </div>
        <div className="row">
          <div className="col-4">
            <FormControlLabel
              name="examRules.calculator"
              control={
                <Switch
                  checked={inputs.examRules.calculator}
                  onChange={(e) => setInputs(e, !inputs.examRules.calculator)}
                />
              }
              label={i18n.t("Use_Calculator")}
              color="primary"
            />
          </div>
          {inputs.examRules.calculator === true ? (
            <div className="col-8">
              <input
                className="form-control"
                name="examRules.calculatorDesc"
                value={inputs.examRules.calculatorDesc}
                onChange={setInputs}
                type="text"
              />
            </div>
          ) : null}
        </div>
        <div className="row">
          <div className="col-4">
            <FormControlLabel
              name="examRules.application"
              control={
                <Switch
                  checked={inputs.examRules.application}
                  onChange={(e) => setInputs(e, !inputs.examRules.application)}
                />
              }
              label={i18n.t("External_Application")}
              color="primary"
            />
          </div>
          {inputs.examRules.application === true ? (
            <div className="col-8">
              <input
                className="form-control"
                name="examRules.applicationDesc"
                value={inputs.examRules.applicationDesc}
                onChange={setInputs}
                type="text"
              />
            </div>
          ) : null}
        </div>
        <div className="row">
          <div className="col-4">
            <FormControlLabel
              name="examRules.penPapers"
              control={
                <Switch
                  checked={inputs.examRules.penPapers}
                  onChange={(e) => setInputs(e, !inputs.examRules.penPapers)}
                />
              }
              label={i18n.t("Pen_or_Papers")}
              color="primary"
            />
          </div>
          {inputs.examRules.penPapers === true ? (
            <div className="col-8">
              <input
                className="form-control"
                name="examRules.penPapersDesc"
                value={inputs.examRules.penPapersDesc}
                onChange={setInputs}
                type="text"
              />
            </div>
          ) : null}
        </div>
        <div className="row">
          <div className="col-4">
            <FormControlLabel
              name="examRules.textbooks"
              control={
                <Switch
                  checked={inputs.examRules.textbooks}
                  onChange={(e) => setInputs(e, !inputs.examRules.textbooks)}
                />
              }
              label={i18n.t("Use_Textbooks")}
              color="primary"
            />
          </div>
          {inputs.examRules.textbooks === true ? (
            <div className="col-8">
              <input
                className="form-control"
                name="examRules.textbooksDesc"
                value={inputs.examRules.textbooksDesc}
                onChange={setInputs}
                type="text"
              />
            </div>
          ) : null}
        </div>
        <div className="row">
          <div className="col-4">
            <FormControlLabel
              name="examRules.additionalMaterials"
              control={
                <Switch
                  checked={inputs.examRules.additionalMaterials}
                  onChange={(e) =>
                    setInputs(e, !inputs.examRules.additionalMaterials)
                  }
                />
              }
              label={i18n.t("Additional_Materials")}
              color="primary"
            />
          </div>
          {inputs.examRules.additionalMaterials === true ? (
            <div className="col-8">
              <input
                className="form-control"
                name="examRules.additionalMaterialsDesc"
                value={inputs.examRules.additionalMaterialsDesc}
                onChange={setInputs}
                type="text"
              />
            </div>
          ) : null}
        </div>
        <div className="row">
          <div className="col-4">
            <FormControlLabel
              name="examRules.keyCode"
              control={
                <Switch
                  checked={inputs.examRules.keyCode}
                  onChange={(e) =>
                    setInputs(e, !inputs.examRules.keyCode)
                  }
                />
              }
              label={i18n.t("keyCode")}
              color="primary"
            />
          </div>
          {inputs.examRules.keyCode === true ? (
            <div className="col-8">
              <input
                className="form-control"
                name="examRules.keyCodeDesc"
                value={inputs.examRules.keyCodeDesc}
                onChange={setInputs}
                type="text"
              />
            </div>
          ) : null}
        </div>

      </FormGroup>
    );
  };

  const durationArea = () => {
    return (
      <React.Fragment>
        <div className="d-flex flex-column">
          <div className="d-flex flex-row">
            <FormSelect
              col="6"
              name="systemCheckLinkStatus"
              label={i18n.t("System_Check_Link_Status")}
              onChange={(e) => {
                handleSystemCheckLinkStatus(e, "PC");
                setInputs(e);
              }}
              value={inputs.systemCheckLinkStatus}
              data={variables.SystemCheckLinkStatus}
              dataValue="value"
              dataText="text"
            />
            {inputs.examRules.mobileCameraUsage ? (
              <FormSelect
                col="6"
                name="mobileSystemCheckLinkStatus"
                label={i18n.t("Mobile_System_Check_Link_Status")}
                onChange={(e) => {
                  handleSystemCheckLinkStatus(e, "MOBILE");
                  setInputs(e);
                }}
                value={inputs.mobileSystemCheckLinkStatus}
                data={variables.SystemCheckLinkStatus}
                dataValue="value"
                dataText="text"
              />
            ) : null}
          </div>
          <div className="d-flex flex-row">
            <FormSelect
              col="3"
              name="examExtraDuration"
              label={i18n.t("Extra_Time")}
              onChange={setInputs}
              value={inputs.examExtraDuration}
              data={variables.ExamExtraDuration}
              dataValue="value"
              dataText="value"
            />
            <FormInput
              col="9"
              name="examExtraDurationDesc"
              text={i18n.t("Reason")}
              value={inputs.examExtraDurationDesc}
              onChange={setInputs}
              type="text"
            />
          </div>
          <FormInput
            col="12"
            name="systemCheckUrl"
            text={i18n.t("Test_Taker_System_Check_URL")}
            value={`${config.api.student_base_url}/preExamSystemCheck/${selectedSession.instituteId}/${selectedSession.sessionId}/${inputs.studentId}`}
            onChange={setInputs}
            type="text"
          />
          <FormInput
            col="12"
            name="examUrl"
            text={i18n.t("Test_Taker_Exam_URL")}
            value={`${config.api.student_base_url}/${selectedSession.instituteId}/${selectedSession.sessionId}/${inputs.studentId}`}
            onChange={setInputs}
            type="text"
          />
        </div>
      </React.Fragment>
    );
  };

  const kindlyFinish = () => {
    const data = {
      instituteId: selectedSession.instituteId,
      sessionId: `${selectedSession.sessionId}${inputs.studentId}`,
      isFinished: true,
    };
    axios.post(
      `${config.api.invokeUrl}${config.openvidu.kms.endpoints.session.context}${config.openvidu.kms.endpoints.session.close.context}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Object.keys(localStorage).find((key) =>
              key.includes("accessToken")
            )
          )}`,
        },
      }
    ).then(() => {
      store.addNotification({
        title: null,
        message: i18n.t("Student's status has changed to FINISHED successfully."),
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    })
    .catch((err) => {
      console.log("Error occured, ", err);
    });
  };

  return (
    <div className="d-flex flex-column">
      {props.appropriateSessionProctoringType(
        selectedSession.proctorServiceType
      )
        ? proctorArea()
        : null}
      <div className="d-flex flex-row justify-content-center align-items-center">
        <div className="col-md-6">{extraRulesArea()}</div>
        <div style={{ borderLeft: "1px solid #dee2e6", height: "300px" }}></div>
        <div className="col-md-6">{durationArea()}</div>
      </div>
      <hr />
      <div
        className={`d-flex ${inputs.meetingStatus === "IN EXAM"
          ? "justify-content-between"
          : "justify-content-end"
          }`}
      >
        {inputs.meetingStatus === "IN EXAM" ? (
          <>
            <button
              onClick={kickStudentFromSession}
              className={context.theme.btn_delete}
              type="submit"
            >
              {i18n.t("Disqualify")}
            </button>
            <button
              onClick={kindlyFinish}
              className={context.theme.btn_delete}
              type="submit"
            >
              {i18n.t("Kindly Finish")}
            </button>
          </>
        ) : null}

        <button
          onClick={patchStudent}
          className={context.theme.btn_save}
          type="submit"
        >
          {i18n.t("Save")}
        </button>
      </div>
    </div>
  );
}
export default withNamespaces()(StudentPrivateRules);
