/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { awscontext } from "../../configs/awscontext";
import Statistics from "./components/Statistics";
import DonutBar from "./components/DonutBar";
import i18n from "../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
// import { useReport } from "../../hooks/useReport";
import {
  FaClipboardCheck,
  FaFileAlt,
  FaUniversity,
  FaUsers,
} from "react-icons/fa";
const statisticData = [
  {
    name: "LIVE_SESSIONS",
    value: "0",
  },
  {
    name: "ONLINE_Test_takers",
    value: "0",
  },
  {
    name: "COMPLETED_SESSIONS",
    value: "0",
  },
  {
    name: "INSTITUTES",
    value: "0",
  },
];

let data = {
  labels: [],
  datasets: [
    {
      data: ["10", "10", "10", "10", "10", "10", "10", "10"],
      backgroundColor: [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#00FFFF",
        "#FF00FF",
        "#FFCCFF",
        "#FF00FF",
        "#FFCCFF",
      ],
      hoverBackgroundColor: [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#00FFFF",
        "#FF00FF",
        "#FFCCFF",
        "#FF00FF",
        "#FFCCFF",
      ],
    },
  ],
};

function DashboardSA(props) {
  const context = useContext(awscontext);
  const [instData, setInstData] = useState(null);
  const [, setInstDataAllSession] = useState([]);
  const [, setInstDataOnlineTakers] = useState([]);
  const [, setInstDataAllLibrarySession] = useState([]);

  useEffect(() => {
    context.setAdres("admin");
    context.setBarGroup("HOME");
    const dataInst = [];
    context.instituteList.map((institute) =>
      dataInst.push(institute.instituteName)
    );
    data.labels = dataInst;
    setInstData(data);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (context.instituteList.length !== 0) {
      const dataInst = [];
      context.instituteList.map((institute) =>
        dataInst.push(institute.instituteName)
      );
      data.labels = dataInst;
      setInstData(data);
      statisticData[3].value = context.instituteList.length;
    }
  }, [context.instituteList]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   setReports("allSession", "");
  //   setInstDataAllSession(
  //     (statisticData[0].value = context.unarchivedsessionList.length)
  //   );
  // }, [instDataAllSession]);

  // useEffect(() => {
  //   setReports("onlineTakers", "");
  //   setInstDataOnlineTakers(
  //     (statisticData[1].value = context.onlineTestTakerList.length)
  //   );
  // }, [instDataOnlineTakers]);

  // useEffect(() => {
  //   setReports("allLibrarySessionList", "");
  //   setInstDataAllLibrarySession(
  //     (statisticData[2].value = context.meetingArchivedSessionList.length)
  //   );
  // }, [instDataAllLibrarySession]);

  useEffect(() => {
    setInstDataAllSession(
      (statisticData[0].value = context.unarchivedsessionList.length)
    );
  }, [context.unarchivedsessionList]);

  useEffect(() => {
    setInstDataOnlineTakers(
      (statisticData[1].value = context.onlineTestTakerList.length)
    );
  }, [context.onlineTestTakerList]);

  useEffect(() => {
    setInstDataAllLibrarySession(
      (statisticData[2].value = context.meetingArchivedSessionList.length)
    );
  }, [context.meetingArchivedSessionList]);

  return (
    <React.Fragment>
      <div className="d-flex flex-row justify-content-around mb-5 ">
        <Statistics
          key="0"
          name={i18n.t("LIVE_SESSIONS")}
          value={context.unarchivedsessionList.length}
          renderIcon={FaFileAlt}
          onClick={() => {
            props.history.push({
              pathname: `/liveSessionList`,
            });
          }}
        />

        <Statistics
          key="1"
          name={i18n.t("ONLINE_Test_takers")}
          value={context.onlineTestTakerList.length}
          renderIcon={FaUsers}
          onClick={() => {
            props.history.push({
              pathname: `/liveStudentList`,
            });
          }}
        />
        <Statistics
          key="2"
          name={i18n.t("COMPLETED_SESSIONS")}
          value={context.meetingArchivedSessionList.length}
          renderIcon={FaClipboardCheck}
          onClick={() => {
            props.history.push({
              pathname: `/library`,
            });
          }}
        />
        <Statistics
          key="3"
          name={i18n.t("INSTITUTES")}
          value={context.instituteList.length}
          renderIcon={FaUniversity}
          onClick={() => {
            props.history.push({
              pathname: `/instituteList`,
            });
          }}
        />
      </div>

      {instData ? <DonutBar instData={instData} /> : ""}
    </React.Fragment>
  );
}
export default withNamespaces()(DashboardSA);
