/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { awscontext } from "../../configs/awscontext";
import { useSession } from "../../hooks/useSession";
import ModalForm from "../components/ModalForm";
import ScreenPanel from "../components/ScreenPanel";
import SessionTable from "./components/SessionTable";
import i18n from "../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import LoadingIcon from "../components/LoadingIcon";
import { store } from "react-notifications-component";

function SessionList(props) {
  const context = useContext(awscontext);
  const [, setSessions] = useSession([]);
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [id, setId] = useState(false);
  const [rowCount, setRowCount] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [itemCount, setItemCount] = useState(context.sessionList.length);
  const [list, setList] = useState(context.sessionList);
  const [pureList, setPureList] = useState(context.sessionList);

  useEffect(() => {
    context.setAdres("sessionList");
    context.setBarGroup("EXAMS");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const retrieveSessionInformation = async () => {
      const instituteId = localStorage.getItem("instituteId");
      await setSessions("get", {
        sessionName: "",
        instituteId,
        isArchived: false,
      }).then((sessionList) => {
        context.setSessionList(sessionList);
      });
    };
    retrieveSessionInformation();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDelete = (id) => {
    setId(id);
    setShowDelete(true);
  };

  useEffect(() => {
    if (props.location.state && props.location.state.status === "CREATED_NEW") {
      store.addNotification({
        title: null,
        //message: `Session ${props.location.state.sessionName} has successfully created`,
        message: i18n.t("Session_has_successfully_created"),
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.state]);

  const handleCloseConfirmOK = () => {
    setShowDelete(false);
    setSessions("delete", id);
  };

  const handleClose = () => {
    setShow(false);
  };

  const showExamDetail = (selectedSession) => {
    context.setSelectedSession(selectedSession);
    props.history.push({
      pathname: `/sessionInformation/${selectedSession.sessionId}`,
    });
  };

  useEffect(() => {
    setItemCount(context.sessionList.length);
    setList(context.sessionList);
    setPureList(context.sessionList);
    setRowCount(10);
  }, [context.sessionList]);

  useEffect(() => {
    setItemCount(list.length);
  }, [list]);

  const updateDatas = () => {
    setSessions("get", {
      isArchived: false,
      sessionName: "",
      instituteId: context.userInstituteId,
    });
  };

  const mainContent = () => {
    return (
      <React.Fragment>
        <ScreenPanel
          name={i18n.t("Session_List")}
          panels="true"
          pagination="true"
          rowCount={rowCount}
          setRowCount={setRowCount}
          itemCount={itemCount}
          activePage={activePage}
          setActivePage={setActivePage}
          pureList={pureList}
          list={list}
          setList={setList}
          updateDatas={updateDatas}
        >
          <SessionTable
            Sessions={context.sessionList}
            showExamDetail={showExamDetail}
            handleDelete={handleDelete}
            setRowCount={setRowCount}
            rowCount={rowCount}
            itemCount={itemCount}
            activePage={activePage}
            setActivePage={setActivePage}
            list={list}
          />
        </ScreenPanel>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {context.loaded ? mainContent() : <LoadingIcon />}
      <ModalForm show={show} handleClose={handleClose} />
      <ModalForm
        show={showDelete}
        handleClose={handleClose}
        handleDelete={handleCloseConfirmOK}
        Title={i18n.t("Confirm_Delete")}
        Body={i18n.t("Are_you_sure_you_want_to_delete_this_item?")}
      />
    </React.Fragment>
  );
}
export default withNamespaces()(SessionList);
