/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { awscontext } from "../../../configs/awscontext";
import ReactLoading from "react-loading";
import { Doughnut } from "react-chartjs-2";
import Card from "react-bootstrap/Card";
import ScreenPanel from "../../components/ScreenPanel";
import i18n from "../../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import axios from "axios";
import config from "../../../configs/awsconfig.json";
import LoadingIcon from "../../components/LoadingIcon";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useSession } from "../../../hooks/useSession";

function LibraryDetailed(props) {
  const context = useContext(awscontext);
  const [
    studentMeetingInformationList,
    setStudentMeetingInformationList,
  ] = useState({});
  const [isPageReady, setIsPageReady] = useState(false);
  const [selectedSession, setSelectedSession] = useState(
    context.selectedSession
  );
  const [pureList, setPureList] = useState(context.selectedSession.studentList);
  const [list, setList] = useState(context.selectedSession.studentList);
  const [sessions, setSessions] = useSession();
  const [pureListProctor, setPureListProctor] = useState([]);
  const [listProctor, setListProctor] = useState([]);
  const [doughnutData, setDoughnutData] = useState([]);

  useEffect(() => {
    context.setAdres("library");
    context.setBarGroup("EXAMS");
    setPureList(context.selectedSession.studentList);
    setList(context.selectedSession.studentList);
  }, []); // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedSession(context.selectedSession);
  }, [context.selectedSession]);

  useEffect(async () => {
    const getDistinctProctorList = (selectedSession) => {
      const proctorList = {};
      if (selectedSession && selectedSession.studentList) {
        for (const student of selectedSession.studentList) {
          if (student.proctors && student.proctors.length > 0) {
            for (const proctor of student.proctors) {
              if (proctor.proctorId) {
                proctorList[proctor.proctorId] = proctor;
              }
            }
          }
        }
      }
      return proctorList;
    };
    const constructStudentMeetingInformationList = async (selectedSession) => {
      let meetingInformationList = {};
      const result = await axios.post(
        `${config.api.invokeUrl}/meetings/query`,
        {
          sessionId: selectedSession.sessionId,
          studentId: "",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      const testTakerMeetingInformationList = result.data;
      for (const student of selectedSession.studentList) {
        try {
          const foundTestTakerMeeting = testTakerMeetingInformationList.filter(
            (testTakerMeeting) =>
              testTakerMeeting.studentId === student.studentId
          );
          meetingInformationList[student.studentId] = foundTestTakerMeeting;
        } catch (err) {
          console.log("Error when calling the meeting query: ", err);
          throw err;
        }
      }
      if (
        (context.userRole.includes("Admin") ||
          context.userRole.includes("Super Admin")) &&
        selectedSession.proctorRecording
      ) {
        const proctorList = getDistinctProctorList(selectedSession);
        for (const proctorId of Object.keys(proctorList)) {
          try {
            const foundTestTakerMeeting = testTakerMeetingInformationList.filter(
              (testTakerMeeting) => testTakerMeeting.studentId === proctorId
            );
            meetingInformationList[proctorId] = foundTestTakerMeeting;
          } catch (err) {
            console.log("Error when calling the meeting query: ", err);
            throw err;
          }
        }
      }
      setStudentMeetingInformationList(meetingInformationList);
      setIsPageReady(true);
    };

    if (context.loaded) {
      const splittedUrl = window.location.href.split("/");
      const foundSession = context.sessionList.find(
        (session) => session.sessionId === splittedUrl[splittedUrl.length - 1]
      );
      if (foundSession) {
        const data = await setSessions("getById", {
          instituteId: foundSession.instituteId,
          sessionId: foundSession.sessionId,
        });
        setSelectedSession(data);
        context.setSelectedSession(data);
        constructStudentMeetingInformationList(data);
      }
    }
  }, [context.loaded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedSession) {
      setPureList(selectedSession.studentList);
      setList(selectedSession.studentList);
      const proctorList = [];
      if (selectedSession && selectedSession.studentList) {
        for (const student of selectedSession.studentList) {
          if (student.proctors && student.proctors.length > 0) {
            for (const proctor of student.proctors) {
              const isAdded = proctorList.find(
                (p) => p.proctorId === proctor.proctorId
              );
              if (!isAdded) {
                const addNewProctor = { ...proctor, proctor: proctor };
                proctorList.push(addNewProctor);
              }
            }
          }
        }
      }
      setPureListProctor(proctorList);
      setListProctor(proctorList);
    }
  }, [selectedSession]);

  useEffect(() => {
    const constructCommentsData = async () => {
      let data = [];
      const studentList = list;
      if (studentList) {
        let woComments = 0;
        let wComments = 0;
        let wCommentUnfinishedExam = 0;
        for (let i = 0; i < studentList.length; i++) {
          const studentResults = await retrieveStudentResults(
            selectedSession,
            studentList[i]
          );
          if (studentResults && studentResults.comments) {
            const comments = studentResults.comments;
            const foundComment = comments.find(
              (comment) => comment.type !== "AI"
            );
            const studentMeetingInformation =
              studentMeetingInformationList[studentList[i].studentId];
            if (studentMeetingInformation) {
              const webcamStudentMeetingInformation = studentMeetingInformation.find(
                (studentMeeting) => studentMeeting.meetingId.includes("webcam")
              );
              if (webcamStudentMeetingInformation) {
                if (foundComment) {
                  if (webcamStudentMeetingInformation.endTime) {
                    wComments++;
                  } else {
                    wCommentUnfinishedExam++;
                  }
                } else {
                  woComments++;
                }
              } else {
                woComments++;
              }
            } else {
              woComments++;
            }
          }
        }
        data = {
          datasets: [
            {
              data: [woComments, wComments, wCommentUnfinishedExam],
              backgroundColor: ["#55A744", "red", "orange"],
            },
          ],
          labels: [
            i18n.t("Test_taker_with_no_comments"),
            i18n.t("Test_taker_with_comments"),
            i18n.t("Test_taker_with_comments_in_unfinished_exam"),
          ],
        };
      }
      return data;
    };
    constructCommentsData().then((data) => {
      setDoughnutData(data);
    });
  }, [studentMeetingInformationList]);

  const checkAttendance = (id, role) => {
    if (role === "Proctor" && !selectedSession.proctorRecording) {
      return i18n.t("Not_Recorded");
    }
    const studentMeetingInfo = studentMeetingInformationList[id];
    if (!studentMeetingInfo) {
      return "";
    }
    if (studentMeetingInfo.length === 0) {
      return <FaTimes color="red" size={24} />;
    }
    const studentMeeting = studentMeetingInfo[0];
    if (studentMeeting.endTime) {
      return <FaCheck color="green" size={24} />;
    }
    return (
      <ReactLoading type="bubbles" color="blue" height={"40%"} width={"40%"} />
    );
  };

  const showStudentDetail = (student, isProctor) => {
    if (checkAttendanceInfo(student.studentId) === true) {
      const meetingInformation =
        studentMeetingInformationList[student.studentId];
      props.history.push(
        `/libraryStudentDetailed/${selectedSession.sessionId}/${student.studentId}`,
        {
          session: selectedSession,
          meetingInformation,
          student,
          isProctor,
        }
      );
    }
  };

  const checkAttendanceInfo = (id) => {
    const studentMeetingInfo = studentMeetingInformationList[id];
    if (!studentMeetingInfo) {
      return false;
    }
    if (studentMeetingInfo.length === 0) {
      return false;
    }
    const studentMeeting = studentMeetingInfo[0];
    if (studentMeeting.endTime) {
      return true;
    }
    return false;
  };

  const calculateSingleDuration = (startTime, endTime) => {
    const startDateInfo = startTime.split(":");
    const startMins =
      parseInt(startDateInfo[0]) * 60 + parseInt(startDateInfo[1]);
    const endDateInfo = endTime.split(":");
    const endMins = parseInt(endDateInfo[0]) * 60 + parseInt(endDateInfo[1]);
    return endMins - startMins;
  };

  const calculateDetailedDateFromTime = (time) => {
    const dateTime = new Date(time);
    const date = dateTime.toDateString();
    const seconds =
      dateTime.getSeconds() < 10
        ? `0${dateTime.getSeconds()}`
        : dateTime.getSeconds();
    const hms = `${dateTime.getHours()}:${dateTime.getMinutes()}:${seconds}`;
    return [date, hms];
  };

  const calculateExamAttendTime = (id) => {
    const studentMeetingInfo = studentMeetingInformationList[id];
    if (!studentMeetingInfo || studentMeetingInfo.length === 0) {
      return "";
    }
    const studentMeeting = studentMeetingInfo[0];

    if (studentMeeting.startTime) {
      let meetingStartTime = Infinity;
      if (studentMeetingInfo.length > 1) {
        for (let i = 0; i < studentMeetingInfo.length; i++) {
          if (meetingStartTime > Number(studentMeetingInfo[i].startTime)) {
            meetingStartTime = Number(studentMeetingInfo[i].startTime);
          }
        }
      } else {
        meetingStartTime = Number(studentMeeting.startTime);
      }

      const [date, startHMS] = calculateDetailedDateFromTime(
        meetingStartTime
      );

      if (studentMeeting.endTime) {
        let meetingEndTime = -Infinity;
        if (studentMeetingInfo.length > 1) {
          for (let i = 0; i < studentMeetingInfo.length; i++) {
            if (meetingEndTime < Number(studentMeetingInfo[i].endTime)) {
              meetingEndTime = Number(studentMeetingInfo[i].endTime);
            }
          }
        } else {
          meetingEndTime = Number(studentMeeting.endTime);
        }
        
        const [, endHMS] = calculateDetailedDateFromTime(
          meetingEndTime
        );
        const duration = calculateSingleDuration(startHMS, endHMS);
        return (
          date +
          " - " +
          startHMS +
          "/" +
          endHMS +
          " (" +
          duration +
          " " +
          i18n.t("mins") +
          ")"
        );
      } else {
        return date + " - " + startHMS + "/~";
      }
    }
    return "~";
  };

  const constructExamAttendeesData = () => {
    let data = [];
    let attendedCount = 0;
    if (Object.keys(studentMeetingInformationList).length > 0) {
      for (const [, studentMeetingInfo] of Object.entries(
        studentMeetingInformationList
      )) {
        if (studentMeetingInfo.length > 0) {
          const studentMeeting = studentMeetingInfo[0];
          if (studentMeeting.startTime) {
            attendedCount++;
          }
        }
      }
    }
    data = {
      datasets: [
        {
          data: [
            attendedCount,
            Object.keys(studentMeetingInformationList).length - attendedCount,
          ],
          backgroundColor: ["#5e8bb5", "red"],
        },
      ],
      labels: [i18n.t("Attended"), i18n.t("Unattended")],
    };
    return data;
  };

  const retrieveStudentResults = async (selectedSession, selectedStudent) => {
    try {
      const res = await axios.get(
        `${config.api.invokeUrl}/studentResults/${selectedSession.sessionId}${selectedStudent.studentId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      return res.data;
    } catch (err) {
      console.log("Student Result not found");
    }
  };

  const calculateAverageDuration = () => {
    if (Object.keys(studentMeetingInformationList).length === 0) {
      return "~";
    }
    let totalDuration = 0;
    let count = 0;
    for (const [, studentMeetingInfo] of Object.entries(
      studentMeetingInformationList
    )) {
      if (studentMeetingInfo.length > 0) {
        const studentMeeting = studentMeetingInfo[0];
        if (studentMeeting.startTime && studentMeeting.endTime) {
          const [, startHMS] = calculateDetailedDateFromTime(
            studentMeeting.startTime
          );
          const [, endHMS] = calculateDetailedDateFromTime(
            studentMeeting.endTime
          );
          const duration = calculateSingleDuration(startHMS, endHMS);
          totalDuration += duration;
          count++;
        }
      }
    }
    if (count === 0) {
      return "~";
    }
    const result = (totalDuration / count).toFixed(0);
    return result + " " + i18n.t("mins");
  };

  const calculateAverageReviewDuration = () => {
    let result = 0;
    selectedSession.studentList.forEach((student) => {
      if (student.reviewingDuration) {
        result += student.reviewingDuration;
      }
    });

    return (result / 1000 / 60).toFixed(0) + " " + i18n.t("mins");
  };

  const backgroundStyle = (evaluationStatus) => {
    if (evaluationStatus === "Nothing Suspicious")
      return {
        backgroundColor: "green",
        color: "white",
        textAlign: "center",
      };
    else if (evaluationStatus === "Something Suspicious")
      return {
        backgroundColor: "yellow",
        color: "black",
        textAlign: "center",
      };
    else if (evaluationStatus === "Unethical Behavior")
      return {
        backgroundColor: "red",
        color: "white",
        textAlign: "center",
      };
    else
      return {
        textAlign: "center",
      };
  };

  const msToTime = (ms) => {
    if (!ms) {
      return "~";
    }
    let seconds = (ms / 1000).toFixed(1);
    let minutes = (ms / (1000 * 60)).toFixed(1);
    let hours = (ms / (1000 * 60 * 60)).toFixed(1);
    let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
    if (seconds < 60) return seconds + " Sec";
    else if (minutes < 60) return minutes + " Min";
    else if (hours < 24) return hours + " Hrs";
    else return days + " Days";
  };

  const appropriateSessionProctoringType = (proctorServiceType) => {
    if (
      proctorServiceType === "Record_and_Review" ||
      proctorServiceType === "Record_and_Review_Lite"
    ) {
      return false;
    } else {
      return true;
    }
  };

  const constructStudentListTable = () => {
    return (
      <ScreenPanel
        name={i18n.t("Test_takers")}
        panels="true"
        col="12"
        pureList={pureList}
        list={list}
        setList={setList}
      >
        <table
          className={context.theme.table_style}
          id="table"
          width="100%"
          cellSpacing="0"
        >
          <thead>
            <tr>
              <th style={{ width: "5%" }}>{i18n.t("Attendance")}</th>
              <th style={{ width: "15%" }}>{i18n.t("Name_Lastname")}</th>
              <th style={{ width: "20%" }}>{i18n.t("Examination_Time")}</th>
              <th style={{ width: "20%" }}>{i18n.t("Email")}</th>
              <th style={{ width: "5%" }}>{i18n.t("Score")}</th>
              <th style={{ width: "5%", textAlign: "center" }}>
                {i18n.t("Evaluation")}
              </th>
              <th style={{ width: "5%", textAlign: "center" }}>
                {i18n.t("Reviewing")}
              </th>

              {context.userRole.includes("Admin") ||
                context.userRole.includes("Super Admin") ? (
                <th style={{ width: "15%", textAlign: "center" }}>
                  {i18n.t("Reviewing_Duration")}
                </th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {list.map((student, index) => (
              <tr
                key={index}
                className="text-black bg-white"
                onClick={() => showStudentDetail(student, false)}
              >
                <td align="center" valign="middle">
                  {checkAttendance(student.studentId)}
                </td>
                <td>
                  {student.name} {student.lastname}
                </td>
                <td>{calculateExamAttendTime(student.studentId)}</td>
                <td>{student.email}</td>
                <td style={{ textAlign: "center", color: parseInt(student.aiScore) < parseInt(selectedSession.score.cheaterThreshold) - 10 ? "green" : parseInt(student.aiScore) > parseInt(selectedSession.score.cheaterThreshold) - 10 && parseInt(student.aiScore) < parseInt(selectedSession.score.cheaterThreshold) ? "#f2c84c" : "red" }}>{Math.ceil(student.aiScore) || 0} </td>
                <td style={backgroundStyle(student.evaluation)}>
                  {i18n.t(student.evaluation)}
                </td>
                <td style={{ textAlign: "center" }}>
                  {student.evaluation ? i18n.t("Finish") : ""}
                </td>
                {context.userRole.includes("Admin") ||
                  context.userRole.includes("Super Admin") ? (
                  <td style={{ textAlign: "center" }}>
                    {msToTime(student.reviewingDuration)}
                  </td>
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
      </ScreenPanel>
    );
  };

  const constructProctorListTable = () => {
    return (
      <ScreenPanel
        name={i18n.t("Proctors")}
        panels="true"
        col="12"
        pureList={pureListProctor}
        list={listProctor}
        setList={setListProctor}
      >
        <table
          className={context.theme.table_style}
          id="table"
          width="100%"
          cellSpacing="0"
        >
          <thead>
            <tr>
              <th style={{ width: "5%" }}>{i18n.t("Attendance")}</th>
              <th style={{ width: "15%" }}>{i18n.t("Fullname")}</th>
              <th style={{ width: "20%" }}>{i18n.t("Examination_Time")}</th>
              <th style={{ width: "20%" }}>{i18n.t("Email")}</th>


              {context.userRole.includes("Admin") ||
                context.userRole.includes("Super Admin") ? (
                <th style={{ width: "15%", textAlign: "center" }}>
                  {i18n.t("Reviewing_Duration")}
                </th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {listProctor.map((proctor, index) => (
              <tr
                key={index}
                className="text-black bg-white"
                onClick={() => {
                  if (selectedSession.proctorRecording) {
                    const student = {
                      studentId: proctor.proctorId,
                      name: proctor.proctorName,
                      lastname: proctor.proctorLastName,
                      email: proctor.proctorEmail,
                      aiScore: 0,
                      examRules: {
                        mobileCameraUsage: false,
                      },
                    };
                    showStudentDetail(student, true);
                  }
                }}
              >
                <td align="center" valign="middle">
                  {checkAttendance(proctor.proctorId, "Proctor")}
                </td>
                <td>
                  {proctor.proctorName} {proctor.proctorLastName}
                </td>
                <td>{calculateExamAttendTime(proctor.proctorId)}</td>
                <td>{proctor.proctorMail}</td>
                {context.userRole.includes("Admin") ||
                  context.userRole.includes("Super Admin") ? (
                  <td style={{ textAlign: "center" }}>
                    {msToTime(proctor.reviewingDuration)}
                  </td>
                ) : (
                  ""
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </ScreenPanel>
    );
  };

  const mainContent = () => {
    return (
      <div className="d-flex flex-column">
        <Card className="col" style={{ border: "0px" }}>
          <Card.Header style={{ backgroundColor: "#ffffff" }}>
            <div
              className="d-flex justify-content-around align-items-center"
              style={{ marginBottom: 40, borderBottom: "1px solid black" }}
            >
              <div style={{ fontSize: 30 }}>{selectedSession.sessionName}</div>
              <div
                style={{ fontSize: 30, fontStyle: "italic", color: "#5e8bb5" }}
              >
                {i18n.t(selectedSession.proctorServiceType)}
              </div>
            </div>
            <div className="row" style={{ backgroundColor: "#ffffff" }}>
              <div
                className="col-md-4"
                style={{
                  border: "0px",
                  textAlign: "center",
                  marginBottom: 10,
                }}
              >
                <div style={{ fontWeight: "bold" }}>{i18n.t("Attendance")}</div>
                <br></br>
                <Doughnut data={constructExamAttendeesData()} height={180} />
              </div>
              <div
                className="col-md-4"
                style={{ border: "0px", textAlign: "center" }}
              >
                <div style={{ fontWeight: "bold" }}>{i18n.t("Comments")}</div>
                <Doughnut data={doughnutData} height={200} />
              </div>
              <div className="col-md-4">
                <div
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    height: "100%",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>
                    {i18n.t("Session_Time")}
                  </div>
                  <div
                    style={{
                      flex: 1,
                      fontSize: "30px",
                    }}
                  >
                    {new Date(
                      context.selectedSession.startDateTimeUnix
                    ).toDateString()}
                  </div>
                  <div
                    style={{
                      flex: 1,
                      fontSize: "30px",
                    }}
                  >
                    (
                    {new Date(
                      context.selectedSession.startDateTimeUnix
                    ).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}{" "}
                    -{" "}
                    {new Date(
                      context.selectedSession.endDateTimeUnix
                    ).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    )
                  </div>
                  <div style={{ fontWeight: "bold", marginTop: 10 }}>
                    {i18n.t("Average_Time")}
                  </div>
                  <div>({i18n.t("Attended_Test_takers_Only")})</div>
                  <div
                    style={{
                      flex: 1,
                      color: "#5e8bb5",
                      fontSize: "50px",
                    }}
                  >
                    {calculateAverageDuration()}
                  </div>
                  <div style={{ fontWeight: "bold" }}>
                    {i18n.t("Average_Review_Time")}
                  </div>
                  <div
                    style={{
                      flex: 1,
                      color: "#5e8bb5",
                      fontSize: "50px",
                    }}
                  >
                    {calculateAverageReviewDuration()}
                  </div>
                </div>
              </div>
            </div>
            {constructStudentListTable()}
            {appropriateSessionProctoringType(
              selectedSession.proctorServiceType
            )
              ? constructProctorListTable()
              : null}
          </Card.Header>
        </Card>
        <br></br>
      </div>
    );
  };

  return isPageReady ? mainContent() : <LoadingIcon />;
}
export default withNamespaces()(LibraryDetailed);
