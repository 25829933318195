/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import { useSession } from "../../../hooks/useSession";
import { useInput } from "../../../hooks/useInput";
import i18n from "../../../configs/i18n";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { awscontext } from "../../../configs/awscontext";
import { store } from "react-notifications-component";

export default function SessionSystemCheck(props) {
  const context = useContext(awscontext);
  const [inputs, setInputs] = useInput({ ...props.SelectedSession });
  const [, setSession] = useSession();

  useEffect(() => {
    setInputs({ ...props.SelectedSession });
  }, [props.SelectedSession]); // eslint-disable-line react-hooks/exhaustive-deps

  const saveChangeInfo = async (e) => {
    e.preventDefault();
    if (
      inputs.proctorServiceType === "Live_Proctoring_Plus" &&
      !inputs.systemCheck.mobileCameraUsage
    ) {
      store.addNotification({
        title: null,
        message: "LP Plus proctor mode has to have mobile camera on",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } else {
      const params = {
        isArchived: false,
        instituteId: inputs.instituteId,
        sessionId: inputs.sessionId,
        sessionName: "",
        systemCheck: inputs.systemCheck,
      };
      const response = {
        statusCode: 0,
        body: null,
      };
      await setSession("patch", params, "systemCheck", response);
      if (response.statusCode === 204) {
        store.addNotification({
          title: null,
          message: `Session ${inputs.sessionName}'s system check steps has successfully updated`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      } else if (response.statusCode === 210) {
        store.addNotification({
          title: null,
          message: `${response.body}`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    }
  };

  return (
    <React.Fragment>
      <FormGroup style={{ marginTop: 15 }}>
        <div className="col">
          <FormControlLabel
            name="systemCheck.mobileCameraUsage"
            control={
              <Switch
                checked={inputs.systemCheck.mobileCameraUsage}
                onChange={(e) =>
                  setInputs(e, !inputs.systemCheck.mobileCameraUsage)
                }
              />
            }
            label={i18n.t("Mobile_Camera_Usage")}
            color="primary"
          />
        </div>
        <div className="col">
          <FormControlLabel
            name="systemCheck.additionalDisplayCheck"
            control={
              <Switch
                checked={inputs.systemCheck.additionalDisplayCheck}
                onChange={(e) =>
                  setInputs(e, !inputs.systemCheck.additionalDisplayCheck)
                }
              />
            }
            label={i18n.t("Additional_Display_Check")}
            color="primary"
          />
        </div>
        <div className="col">
          <FormControlLabel
            name="systemCheck.toiletBreakPermission"
            control={
              <Switch
                checked={inputs.systemCheck.toiletBreakPermission}
                onChange={(e) =>
                  setInputs(e, !inputs.systemCheck.toiletBreakPermission)
                }
              />
            }
            label={i18n.t("Toilet_Break_Permission")}
            color="primary"
          />
        </div>
        <div className="col">
          <FormControlLabel
            name="systemCheck.preExamMaterialCheck"
            control={
              <Switch
                checked={inputs.systemCheck.preExamMaterialCheck}
                onChange={(e) =>
                  setInputs(e, !inputs.systemCheck.preExamMaterialCheck)
                }
              />
            }
            label={i18n.t("Pre_Exam_Material_Check")}
            color="primary"
          />
        </div>

        <div className="col">
          <FormControlLabel
            name="systemCheck.postExamRoomScan"
            control={
              <Switch
                checked={inputs.systemCheck.postExamRoomScan}
                onChange={(e) =>
                  setInputs(e, !inputs.systemCheck.postExamRoomScan)
                }
              />
            }
            label={i18n.t("Post_Exam_Room_Scan")}
            color="primary"
          />
        </div>
        <div className="col">
          <FormControlLabel
            name="systemCheck.postExamMaterialCheck"
            control={
              <Switch
                checked={inputs.systemCheck.postExamMaterialCheck}
                onChange={(e) =>
                  setInputs(e, !inputs.systemCheck.postExamMaterialCheck)
                }
              />
            }
            label={i18n.t("Post_Exam_Material_Check")}
            color="primary"
          />
        </div>
      </FormGroup>
      <div className="d-flex flex-fill">
        <button onClick={saveChangeInfo} className={context.theme.btn_save}>
          {i18n.t("Save")}
        </button>
      </div>
    </React.Fragment>
  );
}
