import { useState, useContext } from "react";
import axios from "axios";
import { awscontext } from "../configs/awscontext";
import { proctorcontext } from "../configs/proctorContext";
import NameGenerator from "node-random-name";
import uniqid from "uniqid";
const modelSession = require("../models/modelSession.json");
const config = require("../configs/awsconfig.json");

export const useSession = () => {
  const context = useContext(awscontext);
  const procontext = useContext(proctorcontext);
  const [result, setResult] = useState([]);

  const getData = async (params) => {
    let insId = params.instituteId;
    // instituteLiveStudents(params);

    if (context.userInstituteId === null) {
      insId = localStorage.getItem("instituteId");
      params.instituteId = insId;
    }
    try {
      const res = await axios.post(
        `${config.api.invokeUrl}/institutes/${insId}/sessions/query`,
        params,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );

      let sessions = res.data;

      // sessions.studentList.sort(
      //   (a, b) => a.sessionIncludeTime - b.sessionIncludeTime
      // );
      sortTableData(sessions);

      sessions.forEach((session) => {
        if (!session.examRules) session.examRules = modelSession.examRules;
        if (!session.score) session.score = modelSession.score;
        if (!session.studentList) session.studentList = [];
      });

      if (params.isArchived) {
        context.setArchivedSessionList(sessions);
      } else {
        context.setSessionList(sessions);
      }
      setResult(sessions);
      return sessions;
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };
  const deleteSession = async (params) => {
    try {
      await axios.delete(
        `${config.api.invokeUrl}/institutes/${params.instituteId}/sessions/${params.sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      await getData({
        sessionName: "",
        instituteId: params.instituteId,
        isArchived: false,
      });
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };
  const postSession = async (params) => {
    try {
      await axios.post(
        `${config.api.invokeUrl}/institutes/${params.instituteId}/sessions`,
        params,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      await getData({
        sessionName: "",
        instituteId: params.instituteId,
        isArchived: false,
      });
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };
  const patchSession = async (params, section, response) => {
    try {
      if (params.studentList) {
        params.studentList.forEach((student) => delete student.meetingStatus);
      }
      params.section = section;

      const result = await axios.patch(
        `${config.api.invokeUrl}/institutes/${params.instituteId}/sessions/${params.sessionId}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      response.statusCode = result.status;
      response.body = result.data;
      params.isArchived = false;

      await getData({
        sessionName: "",
        instituteId: params.instituteId,
        isArchived: false,
      });
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };

  const patchStudent = async (params, section, response) => {
    try {
      if (params.studentList) {
        params.studentList.forEach((student) => delete student.meetingStatus);
      }
      params.section = section;
      const result = await axios.patch(
        `${config.api.invokeUrl}/institutes/${params.instituteId}/sessions/${params.sessionId}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      response.statusCode = result.status;
      response.body = result.data;
      params.isArchived = false;

      await getData({
        sessionName: "",
        instituteId: params.instituteId,
        isArchived: false,
      });
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };

  const randomSession = async (params, section, response) => {
    if (params.studentList) {
      params.studentList.forEach((student) => delete student.meetingStatus);
    }
    const prevSelectedSession = JSON.parse(JSON.stringify(params));
    const newStudent = [];
    if (!section) {
      const name = NameGenerator({ first: true });
      const lastname = NameGenerator({ last: true });
      newStudent.push({
        studentId: uniqid(),
        sessionId: prevSelectedSession.sessionId,
        instituteId: prevSelectedSession.instituteId,
        sessionIncludeTime: new Date().getTime(),
        name,
        lastname,
        email: `${name.toLowerCase()}.${lastname.toLowerCase()}@demo.com`,
        examRules: {
          internet: prevSelectedSession.examRules.internet,
          internetDesc: prevSelectedSession.examRules.internetDesc
            ? prevSelectedSession.examRules.internetDesc
            : "",
          calculator: prevSelectedSession.examRules.calculator,
          calculatorDesc: prevSelectedSession.examRules.calculatorDesc
            ? prevSelectedSession.examRules.calculatorDesc
            : "",
          application: prevSelectedSession.examRules.application,
          applicationDesc: prevSelectedSession.examRules.applicationDesc
            ? prevSelectedSession.examRules.applicationDesc
            : "",
          penPapers: prevSelectedSession.examRules.penPapers,
          penPapersDesc: prevSelectedSession.examRules.penPapersDesc
            ? prevSelectedSession.examRules.penPapersDesc
            : "",
          textbooks: prevSelectedSession.examRules.textbooks,
          textbooksDesc: prevSelectedSession.examRules.textbooksDesc
            ? prevSelectedSession.examRules.textbooksDesc
            : "",
          additionalMaterials:
            prevSelectedSession.examRules.additionalMaterials,
          additionalMaterialsDesc: prevSelectedSession.examRules
            .additionalMaterialsDesc
            ? prevSelectedSession.examRules.additionalMaterialsDesc
            : "",
          mobileCameraUsage: prevSelectedSession.systemCheck.mobileCameraUsage,
        },
        preSystemCheck: {
          internetConnectionStatus: false,
          numberOfDisplaysValidity: false,
          webcamShareable: false,
          screenShareable: false,
          audioShareable: false,
          preExamRoomScan: false,
          preExamMaterialCheck: false,
          toiletBreakPermission: false,
        },
        systemCheckLinkStatus: "ACTIVE",
        mobileSystemCheckLinkStatus: "ACTIVE",
        examUrl: "",
        examExtraDuration: 0,
        examExtraDurationDesc: "",
        proctors: [],
      });
      prevSelectedSession.studentList = newStudent;
    }

    try {
      prevSelectedSession.section = "addStudentList";
      prevSelectedSession.isArchived = false;
      const result = await axios.patch(
        `${config.api.invokeUrl}/institutes/${prevSelectedSession.instituteId}/sessions/${prevSelectedSession.sessionId}`,
        prevSelectedSession,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      response.statusCode = result.status;
      response.body = result.data;
      // newStudent.forEach((element) => {
      //   params.studentList.push(element);
      // });
      if (section) {
        response.value= prevSelectedSession.studentList
      } else {

        response.value = newStudent;
      }

      getData({
        sessionName: "",
        instituteId: params.instituteId,
        isArchived: false,
      });

      setResult("success");
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };

  // const randomSession1000 = async (params, section, response) => {
  //   if (params.studentList) {
  //     params.studentList.forEach((student) => delete student.meetingStatus);
  //   }
  //   const prevSelectedSession = JSON.parse(JSON.stringify(params));
  //   if (!section) {
  //     const newStudent = [];
  //     for (let index = 0; index < 50; index++) {
  //       const name = NameGenerator({ first: true });
  //       const lastname = NameGenerator({ last: true });
  //       const newStd = {
  //         studentId: uniqid(),
  //         name: `${index}${name}`,
  //         lastname: `${index}${lastname}`,
  //         email: `${name.toLowerCase()}.${lastname.toLowerCase()}@demo.com`,
  //         examRules: {
  //           internet: prevSelectedSession.examRules.internet,
  //           internetDesc: prevSelectedSession.examRules.internetDesc
  //             ? prevSelectedSession.examRules.internetDesc
  //             : "",
  //           calculator: prevSelectedSession.examRules.calculator,
  //           calculatorDesc: prevSelectedSession.examRules.calculatorDesc
  //             ? prevSelectedSession.examRules.calculatorDesc
  //             : "",
  //           application: prevSelectedSession.examRules.application,
  //           applicationDesc: prevSelectedSession.examRules.applicationDesc
  //             ? prevSelectedSession.examRules.applicationDesc
  //             : "",
  //           penPapers: prevSelectedSession.examRules.penPapers,
  //           penPapersDesc: prevSelectedSession.examRules.penPapersDesc
  //             ? prevSelectedSession.examRules.penPapersDesc
  //             : "",
  //           textbooks: prevSelectedSession.examRules.textbooks,
  //           textbooksDesc: prevSelectedSession.examRules.textbooksDesc
  //             ? prevSelectedSession.examRules.textbooksDesc
  //             : "",
  //           additionalMaterials:
  //             prevSelectedSession.examRules.additionalMaterials,
  //           additionalMaterialsDesc: prevSelectedSession.examRules
  //             .additionalMaterialsDesc
  //             ? prevSelectedSession.examRules.additionalMaterialsDesc
  //             : "",
  //           mobileCameraUsage:
  //             prevSelectedSession.systemCheck.mobileCameraUsage,
  //         },
  //         preSystemCheck: {
  //           internetConnectionStatus: false,
  //           numberOfDisplaysValidity: false,
  //           webcamShareable: false,
  //           screenShareable: false,
  //           audioShareable: false,
  //           preExamRoomScan: false,
  //           preExamMaterialCheck: false,
  //           toiletBreakPermission: false,
  //         },
  //         systemCheckLinkStatus: "ACTIVE",
  //         mobileSystemCheckLinkStatus: "ACTIVE",
  //         examUrl: "",
  //         examExtraDuration: 0,
  //         examExtraDurationDesc: "",
  //         proctors: [],
  //       };

  //       newStudent.push(newStd);
  //     }
  //     prevSelectedSession.studentList = newStudent;

  //     try {
  //       prevSelectedSession.section = "updateStudent";
  //       prevSelectedSession.isArchived = false;

  //       const result = await axios.patch(
  //         `${config.api.invokeUrl}/institutes/${prevSelectedSession.instituteId}/sessions/${prevSelectedSession.sessionId}`,
  //         prevSelectedSession,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem(
  //               Object.keys(localStorage).find((key) =>
  //                 key.includes("accessToken")
  //               )
  //             )}`,
  //           },
  //         }
  //       );
  //       response.statusCode = result.status;
  //       response.body = result.data;
  //       newStudent.forEach((element) => {
  //         params.studentList.push(element);
  //       });
  //       response.value = params;

  //       await getData({
  //         sessionName: "",
  //         instituteId: params.instituteId,
  //         isArchived: false,
  //       });

  //       setResult("success");
  //     } catch (err) {
  //       setResult(`An error has occurred: ${err}`);
  //       console.log(`An error has occurred: ${err}`);
  //     }
  //   }
  // };

  const setReviewingTime = async (params, section) => {
    try {
      params.section = section;
      await axios.patch(
        `${config.api.invokeUrl}/institutes/${params.instituteId}/sessions/${params.sessionId}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      await getData({
        sessionName: "",
        instituteId: params.instituteId,
        isArchived: false,
      });
      setResult("success");
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };
  const setEvaluation = async (params, section) => {
    try {
      params.section = section;
      await axios.patch(
        `${config.api.invokeUrl}/institutes/${params.instituteId}/sessions/${params.sessionId}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      await getData({
        sessionName: "",
        instituteId: params.instituteId,
        isArchived: false,
      });
      setResult("success");
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };

  //Calculate AI Score
  const patchAIScore = async (params, section, response) => {
    try {
      params.section = section;
      let result = await axios.patch(
        `${config.api.invokeUrl}/institutes/${params.instituteId}/students`,
        params,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );

      response = result;

      if (section === "GET_AI_SCORE") {
        let studentlo = { studentId: result.data.Item.studentId, aiScore: result.data.Item.aiScore }
        procontext.setStudentAIScore(studentlo);
      } else if (section === "EVALUATE_AI_COMMENTS") {
        console.log("result", result)
        let updatedSession = context.selectedSession;
        response.statusCode = result.status;
        response.body = result.data;
        updatedSession.studentList.find((stu) => stu.studentId === params.studentId).aiScore = result.data.aiScore;
        //WARN alttaki satır çalışmıyor
        context.setSelectedSession(updatedSession);
      }
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${result}`);
    }
  };
  const liveSessions = async (params) => {
    try {
      var result = await axios.get(
        `${config.api.invokeUrl}/institutes/${params.instituteId}/sessions/live`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      context.setLiveSessionList(result.data);
      setResult("success");
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };
  const librarySessions = async (params) => {
    try {
      var result = await axios.get(
        `${config.api.invokeUrl}/institutes/${params.instituteId}/librarysessions`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      // const filteredLibrarySession = result.data.filter(
      //   (session) => session.proctorServiceType !== "Record_and_Review_Lite"
      // );
      //console.log(result.data);
      const filteredLibrarySession = result.data;
      sortTableData(filteredLibrarySession);
      context.setLibrarySessionList(filteredLibrarySession);
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };
  const getById = async (params) => {
    try {
      const res = await axios.get(
        `${config.api.invokeUrl}/institutes/${params.instituteId}/sessions/${params.sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      if (res.data.length > 0) {
        let sessionInfo = res.data[0];
        setResult(sessionInfo);
        return sessionInfo;
      } else {
        setResult(null);
      }
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };
  const instituteLiveStudents = async (params) => {
    try {
      var result = await axios.get(
        `${config.api.invokeUrl}/onlineusers/${params.instituteId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      return result.data;
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };

  const handleChange = async (type, params, section, response) => {
    switch (type) {
      case "get":
        return await getData(params);
      case "getById":
        return await getById(params);
      case "instituteLiveStudents":
        return await instituteLiveStudents(params);
      case "delete":
        await deleteSession(params);
        break;
      case "post":
        await postSession(params);
        break;
      case "patch":
        await patchSession(params, section, response);
        break;
      case "random":
        await randomSession(params, section, response);
        break;
      case "setEvaluation":
        await setEvaluation(params, section);
        break;
      case "liveSessions":
        await liveSessions(params);
        break;
      case "librarySessions":
        await librarySessions(params);
        break;
      case "setReviewingTime":
        await setReviewingTime(params, section);
        break;
      case "patchStudent":
        await patchStudent(params, section, response);
        break;
      case "patchAIScore":
        await patchAIScore(params, section, response);
        break;
      // case "randomSession1000":
      //   await randomSession1000(params, section, response);
      //   break;
      default:
        break;
    }
  };
  return [result, handleChange];
};

const sortTableData = (table) => {
  let directionx = false;
  function compareInt(a, b) {
    if (a.startDateTimeUnix < b.startDateTimeUnix) {
      return directionx ? -1 : 1;
    }
    if (a.startDateTimeUnix > b.startDateTimeUnix) {
      return directionx ? 1 : -1;
    }
    return 0;
  }
  table.sort(compareInt);
};
