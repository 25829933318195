import { useState, useContext } from "react";
import axios from "axios";
import { proctorcontext } from "../configs/proctorContext";
const config = require("../configs/awsconfig.json");

export const useStudentResult = () => {
  const proccontext = useContext(proctorcontext);
  const [result, setResult] = useState([]);

  const getData = async (meetingId) => {
    try {
      const res = await axios.get(
        `${config.api.invokeUrl}/studentResults/${meetingId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      if (res.data) {
        const sessionComments = res.data.comments;
        proccontext.setCommentList(sessionComments, res.data);
        setResult(res.data);
      } else {
        proccontext.setCommentList([]);
        setResult([]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const createStudentResult = async (params) => {
    try {
      await axios.post(`${config.api.invokeUrl}/studentResults`, params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Object.keys(localStorage).find((key) => key.includes("accessToken"))
          )}`,
        },
      });
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  };

  const updateStudentResult = async (params) => {
    try {
      await axios.patch(
        `${config.api.invokeUrl}/studentResults/${params.meetingId}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  };

  const handleChange = async (type, params) => {
    switch (type) {
      case "GET":
        await getData(params);
        break;
      case "CREATE":
        await createStudentResult(params);
        break;
      case "UPDATE":
        await updateStudentResult(params);
        break;
      default:
        break;
    }
  };
  return [result, handleChange];
};
