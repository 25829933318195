import { useState } from 'react';

export const useSearch = () => {
    const [result, setResult] = useState("");
    const handleChange = async (table, input) => {
        if (table !== null) {
            var filter, tr, td, i, txtValue, tds, j;
            filter = input.toUpperCase();
            tr = table.getElementsByTagName("tr");
            for (i = 0; i < tr.length; i++) {
                tds = tr[i].getElementsByTagName("td");
                txtValue = "";
                for (j = 0; j < tds.length; j++) {
                    td = tds[j];
                    if (td) {
                        txtValue += td.textContent || td.innerText;
                    }
                }
                if (txtValue) {
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        tr[i].style.display = "";
                    }
                    else {
                        //  tr[i].style.display = "none";
                    }
                }
            }
            setResult(table);
        }
    }
    

    return [result, handleChange];
}