/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  FaArrowLeft,
  FaCheckCircle,
  FaCommentDots,
  FaRegQuestionCircle,
} from "react-icons/fa";
import i18n from "../../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
// import FormSelect from "../../components/FormSelect";
import { IoMdCloseCircle } from "react-icons/io";

function GeneralComments(props) {
  const [orderNo, setOrderNo] = useState(0);
  const [evaluation, setEvaluation] = useState(null);

  useEffect(() => {
    if (props.selectedStudent.evaluation) {
      setEvaluation(props.selectedStudent.evaluation);
      setOrderNo(3);
    }
  }, [props.selectedStudent]);

  const reviewerConst = () => {
    setOrderNo(2);
    updateStudentComments();
    props.handleShowDecisionModal();
  };

  const updateStudentComments = () => {
    let studentComments = props.selectedStudent.comments;
    if (!studentComments) {
      studentComments = [];
    }
    props.selectedStudent.comments = studentComments;
  };

  const outputEvaluationResult = () => {
    if (evaluation === "Nothing Suspicious") {
      return <FaCheckCircle size={50} color="#28a745" />;
    } else if (evaluation === "Unethical Behavior") {
      return <IoMdCloseCircle size={50} color="#bd2130" />;
    } else if (evaluation === "Something Suspicious") {
      return <FaRegQuestionCircle size={50} color="#e0a800" />;
    }
  };

  const RenderEvaluationStep = () => {
    switch (orderNo) {
      case 0:
        return (
          <div className="row w-100">
            <button
              style={{
                marginTop: 0,
                borderRadius: 10,
              }}
              className="btn buttons m-2 ml-5 w-100"
              onClick={() => setOrderNo(1)}
            >
              {i18n.t("Save_General_Comment")}
            </button>
          </div>
        );
      case 1:
        return (
          <div className="d-flex flex-column w-100 mt-3 ">
            <div className="d-flex flex-row ">
              <button
                className="btn col-md-1"
                type="button"
                onClick={() => setOrderNo(0)}
              >
                <FaArrowLeft />
              </button>
              {/* <FormSelect
                name="student_commentsList"
                label={i18n.t("Comments")}
                onChange={(e) => props.changeComment(e.target.value, true)}
                value={props.evaluationDesc}
                data={props.reviewerMessages.ReviewerMessages}
                dataValue="text"
                dataText="value"
                required={true}
                col={props.evaluationDesc === i18n.t("Other...") ? 6 : 11}
                // className="w-100"
              /> */}
              {props.evaluationDesc === i18n.t("Other...") ? (
                <div className="col-md-10 mb-3">
                  <textarea
                    onChange={(e) => props.changeComment(e.target.value, false)}
                    className="form-control w-100"
                    id="student_comments"
                    style={{ borderRadius: 10, padding: 10 }}
                  />
                </div>
              ) : null}
            </div>
            <button
              style={{
                marginTop: 5,
                borderRadius: 10,
              }}
              className="btn buttons w-100"
              onClick={reviewerConst}
            >
              {i18n.t("Save_General_Comment")}
            </button>
          </div>
        );
      case 2:
        return (
          <div className="d-flex flex-row w-100 justify-content-center align-items-center">
            <div
              className="btn col-md-1"
              type="button"
              onClick={() => setOrderNo(0)}
            >
              <FaArrowLeft />
            </div>
            <div className="alert alert-secondary mt-3 lead w-100">
              {/* {props.evaluationDesc} */}
              {props.evaluationDesc === i18n.t("Other...") ? (
                <div>{props.evaluationDescText}</div>
              ) : null}
            </div>
            <button
              className="btn col-md-1"
              type="button"
              onClick={props.handleShowDecisionModal}
            >
              <FaCommentDots />
            </button>
          </div>
        );
      case 3:
        return (
          <div className="d-flex flex-column col-md-12">
            <div>
              <button
                className="btn buttons w-100 mt-2 mb-2"
                onClick={() => setOrderNo(1)}
              >
                {i18n.t("Reconsider")}
              </button>
            </div>

            <div className="d-flex flex-row justify-content-around align-items-center">
              {outputEvaluationResult()}
              <div className="alert alert-secondary mt-3 lead col-md-11">
                {/* {props.evaluationDesc} */}
                {props.evaluationDesc === i18n.t("Other...") ? (
                  <div>{props.evaluationDescText}</div>
                ) : null}
              </div>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  return RenderEvaluationStep();
}
export default withNamespaces()(GeneralComments);
