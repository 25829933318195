/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import Chat, { Bubble, useMessages, Tabs, Tab } from "@chatui/core";
import "@chatui/core/dist/index.css";
import { withNamespaces } from "react-i18next";

function ChatCompForSuperAdmin(props) {
  const { messages, appendMsg, resetList, setTyping } = useMessages([]);
  const sessionId = props.sessionId;
  const [chatPanelVisible, setChatPanelVisible] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [
    constructTabStudentListState,
    setConstructTabStudentListState,
  ] = useState(0);

  useEffect(() => {
    setConstructTabStudentListState(constructTabStudentList());
  }, []);

  useEffect(() => {
    setConstructTabStudentListState(constructTabStudentList());
  }, [props.checkedStudentList, props.currentChatMessages]);

  useEffect(() => {
    let indexValue = 0;
    for (let i = 0; i < props.checkedStudentList.length; i++) {
      if (
        props.chatSession.sessionId.includes(props.checkedStudentList[i].studentId)
      ) {
        indexValue = i;
        break;
      }
    }
    
    onChange(indexValue);
  }, [props.chatSession, props.checkedStudentList]);

  function handleSend(type, val) {
    props.sendTextMessage(val);
    if (type === "text" && val.trim()) {
      setTyping(true);
      resetList();
      const meetingId = `${sessionId}${props.checkedStudentList[tabIndex].studentId}`;
      const messageInfo = {
        type: "text",
        content: { text: val },
        position: "right",
        meetingId,
      };

      props.messageList.push(messageInfo);
      props.setMessageList(props.messageList);
      props.setCurrentChatMessages(
        props.messageList.filter(
          (message) =>
            message.meetingId ===
            `${sessionId}${props.checkedStudentList[tabIndex].studentId}`
        )
      );

      props.saveChatMessageToDynamo(val, meetingId);
    }
  }

  function renderMessageContent(msg) {
    const { content } = msg;
    return <Bubble content={content.text} />;
  }

  const onChange = (i) => {
    setTabIndex(i);
    if (
      props.meetingSessionList &&
      props.meetingSessionList[
      `${sessionId}${props.checkedStudentList[i].studentId}-webcam`
      ]
    ) {
      const currentChatSession =
        props.meetingSessionList[
          `${sessionId}${props.checkedStudentList[i].studentId}-webcam`
        ].session;
      props.setChatSession(currentChatSession);
    }
    // resetList();
    props.setCurrentChatMessages(
      props.messageList.filter(
        (message) =>
          message.meetingId ===
          `${sessionId}${props.checkedStudentList[i].studentId}`
      )
    );
  };

  const constructTabStudentList = () => {
    const tabStudentList = [];
    for (const student of props.checkedStudentList) {
      tabStudentList.push(
        <Tab
          label={`${student.name} ${student.lastname}`}
          key={student.studentId}
        >
          <div className="col p-0" style={{ height: 446 }}>
            <Chat
              messages={props.currentChatMessages}
              renderMessageContent={renderMessageContent}
              placeholder="Write your message..."
              locale="en-US"
              onSend={handleSend}
            />
          </div>
        </Tab>
      );
    }
    return tabStudentList;
  };

  return (
    <div className="col">
      {chatPanelVisible &&
        <div style={{ marginBottom: 45 }}>
          <Tabs onChange={onChange} index={tabIndex} scrollable>
            {constructTabStudentListState}
          </Tabs>
        </div>
      }

      <div
        className="d-flex align-items-center justify-content-start"
        onClick={() => {
          setChatPanelVisible(!chatPanelVisible);
        }}
        style={{
          position: "absolute",
          width: 188,
          height: 44,
          bottom: 0,
          right: 15,
          backgroundColor: "#29377e",
          border: "1px solid #29377e",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          color: "white",
          fontSize: 16,
          cursor: "pointer",
          paddingLeft: 15,
        }}
      >
        <span>{chatPanelVisible ? "Click to close" : "Student Chat"}</span>
      </div>
    </div>
  );
}
export default withNamespaces()(ChatCompForSuperAdmin);
