/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { awscontext } from "../../../configs/awscontext";
import { proctorcontext } from "../../../configs/proctorContext";
import { useStudentResult } from "../../../hooks/useStudentResult";
import { useStorage } from "../../../hooks/useStorage";
import i18n from "../../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import ModalForm from "../../components/ModalForm";
import {
  FaComments,
  FaDesktop,
  FaExpandAlt,
  FaMobileAlt,
  FaPen,
  FaPhone,
  FaVideo,
  FaVolumeMute,
  FaVolumeUp,
} from "react-icons/fa";
import FormSelect from "../../components/FormSelect";
import variables from "../../../models/variables.json";
import config from "../../../configs/awsconfig.json";
import { store } from "react-notifications-component";
import { useSession } from "../../../hooks/useSession";
import { data } from "jquery";

function OneMeeting(props) {
  const [, setStudentResult] = useStudentResult("");
  const [, setSession] = useSession();
  const canvasRef = useRef();
  const webcamRef = useRef();
  const screenRef = useRef();
  const mobileRef = useRef();
  const context = useContext(awscontext);
  const proccontext = useContext(proctorcontext);
  const sessionId = props.SessionId;
  const showToProctor = props.showToProctor;
  const isAIactive= props.isAIactive;
  const student = props.Student;
  const [camera, setCamera] = useState(props.cam ? props.cam : student.camera);
  const meetingId = sessionId + student.studentId;
  const [show, setShow] = useState(false);
  const [comment, setComment] = useState(null);
  const [otherComment, setOtherComment] = useState(null);
  const [uploadObject] = useStorage(config.s3.streamFiles);
  const proctorServiceType = props.proctorServiceType;
  const [proctorAssistedData, setProctorAssistedData] = useState(null);
  const [notifyCounter, setNotifyCounter] = useState({
    counter: 0,
    notifyType: 0,
  });

  const [defaultWarnings, setDefaultWarnings] = useState({
    studentAbsent: "Test Taker Absent",
    secondPerson: "Second Person Visible",
    studentReplaced: "Test Taker Substituted",
    gazeDirection: "Looking Away from The Screen",
    voiceDetected: "Microphone Voice",
    windowChange: "Exam Page Lost",
    phoneIsUse: "Use of Smartphone",
    forbiddenHardware: "Unauthorized Devices Present",
  });
  const [customVideoWidth, setCustomVideoWidth] = useState(460);
  const [isSocketOneActive, setIsSocketOneActive] = useState(false);
  const [isSocketTwoActive, setIsSocketTwoActive] = useState(false);

  useEffect(() => {
    console.log("mount oldu");
    const { defaultWarning, warnings } = context.currentInstitute;
    if (warnings && defaultWarning) {
      setDefaultWarnings(warnings[defaultWarning]);
    }
  }, []);

  useEffect(() => {
    if (student.muted === undefined) voice(false);
    else {
      if (student.muted === true) voice(false);
      else if (student.muted === false) voice(true);
    }
  }, []);

  useEffect(async () => {
    // let res = undefined;
    // const instituteId = context.sessionList.find(
    //   (item) => item.sessionId === sessionId
    // ).instituteId;
    // try {
    //   res = await axios.get(
    //     `${config.api.invokeUrl}/institutes/${instituteId}/sessions/${sessionId}`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${localStorage.getItem(
    //           Object.keys(localStorage).find((key) =>
    //             key.includes("accessToken")
    //           )
    //         )}`,
    //       },
    //     }
    //   );
    // } catch (err) {
    //   console.log(err);
    // }
    if (isAIactive && showToProctor) {
      let foundStudent = props.checkedStudentList.find(item => item.studentId === student.studentId);
      if (notifyCounter.notifyType === 1 && foundStudent) {
        store.addNotification({
          title: null,
          message: `${student.name} ${student.lastname}: ${defaultWarnings.studentAbsent}`,
          type: "danger",
          insert: "top",
          container: "top-left",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3500,
            onScreen: true,
          },
        });
        return;
      }
      if (notifyCounter.notifyType === 2 && foundStudent) {
        store.addNotification({
          title: null,
          message: `${student.name} ${student.lastname}: ${defaultWarnings.secondPerson}`,
          type: "danger",
          insert: "top",
          container: "top-left",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3500,
            onScreen: true,
          },
        });
        return;
      }
      if (notifyCounter.notifyType === 3 && foundStudent) {
        store.addNotification({
          title: null,
          message: `${student.name} ${student.lastname}: ${defaultWarnings.studentReplaced}`,
          type: "warning",
          insert: "top",
          container: "top-left",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3500,
            onScreen: true,
          },
        });
        return;
      }
      if (notifyCounter.notifyType === 4 && foundStudent) {
        store.addNotification({
          title: null,
          message: `${student.name} ${student.lastname}: ${defaultWarnings.gazeDirection}`,
          type: "warning",
          insert: "top",
          container: "top-left",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3500,
            onScreen: true,
          },
        });
        return;
      }
      if (notifyCounter.notifyType === 5 && foundStudent) {
        store.addNotification({
          title: null,
          message: `${student.name} ${student.lastname}: ${defaultWarnings.voiceDetected}`,
          type: "warning",
          insert: "top",
          container: "top-left",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3500,
            onScreen: true,
          },
        });
        return;
      }
      if (notifyCounter.notifyType === 6 && foundStudent) {
        store.addNotification({
          title: null,
          message: `${student.name} ${student.lastname}: ${defaultWarnings.windowChange}`,
          type: "danger",
          insert: "top",
          container: "top-left",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3500,
            onScreen: true,
          },
        });
        return;
      }
      if (notifyCounter.notifyType === 7 && foundStudent) {
        store.addNotification({
          title: null,
          message: `${student.name} ${student.lastname}: ${defaultWarnings.phoneIsUse}`,
          type: "danger",
          insert: "top",
          container: "top-left",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3500,
            onScreen: true,
          },
        });
        return;
      }
      if (notifyCounter.notifyType === 8 && foundStudent) {
        store.addNotification({
          title: null,
          message: `${student.name} ${student.lastname}: ${defaultWarnings.forbiddenHardware}`,
          type: "danger",
          insert: "top",
          container: "top-left",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3500,
            onScreen: true,
          },
        });
        return;
      }
    }
  }, [notifyCounter]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setNotifyCounter((prev) => {
        return {
          counter: prev.counter + 1,
          notifyType: 0,
        };
      });
      // Send Axios request here
    }, 5000)

    return () => clearTimeout(delayDebounceFn)
  }, [notifyCounter.notifyType])
  useEffect(() => {
    if (props.webcamMeetingInformation) {
      // if(student.online){
      //   props.webcamMeetingInformation.stream.on('publisherStartSpeaking', (event) => {
      //       setNotifyCounter((prev) => {
      //         return {
      //           counter: prev.counter + 1,
      //           notifyType: 5,
      //           prevnotify: prev.notifyType
      //         };
      //       });

      //   });
      // }
      // if (context.userRole.includes("Super Admin")) {
        if (!isSocketOneActive) {
          setIsSocketOneActive(true);
          props.webcamMeetingInformation.session.on("signal:ai_result", (event) => {
            const data = JSON.parse(event.data);
            const params = {
              sessionId: sessionId,
              studentId: student.studentId,
              instituteId: context.sessionList.find(
                (item) => item.sessionId === sessionId
              ).instituteId,
              section: "GET_AI_SCORE",
              comment: data,
            };
            const response = {
              statusCode: 0,
              body: null,
            };
            setSession("patchAIScore", params, "GET_AI_SCORE", response);
            if (data["AI_RESULT_VOICE_DETECTED"]) {
              setNotifyCounter((prev) => {
                return {
                  counter: prev.counter + 1,
                  notifyType: 5,
                };
              });
            }

            if (data["AI_RESULT_EXAM_PAGE_LOST"]) {
              setNotifyCounter((prev) => {
                return {
                  counter: prev.counter + 1,
                  notifyType: 6,
                };
              });
            }
          });
        }
      // } else {
      //   props.webcamMeetingInformation.session.on("signal:ai_result", (event) => {
      //     const data = JSON.parse(event.data);
      //     const params = {
      //       sessionId: sessionId,
      //       studentId: student.studentId,
      //       instituteId: context.sessionList.find(
      //         (item) => item.sessionId === sessionId
      //       ).instituteId,
      //       section: "GET_AI_SCORE",
      //       comment: data,
      //     };
      //     const response = {
      //       statusCode: 0,
      //       body: null,
      //     };
      //     setSession("patchAIScore", params, "GET_AI_SCORE", response);
      //     if (data["AI_RESULT_VOICE_DETECTED"]) {
      //       setNotifyCounter((prev) => {
      //         return {
      //           counter: prev.counter + 1,
      //           notifyType: 5,
      //         };
      //       });
      //     }

      //     if (data["AI_RESULT_EXAM_PAGE_LOST"]) {
      //       setNotifyCounter((prev) => {
      //         return {
      //           counter: prev.counter + 1,
      //           notifyType: 6,
      //         };
      //       });
      //     }
      //   });
      // }
      // if (context.userRole.includes("Super Admin")) {
        if (!isSocketTwoActive) {
          // console.log("test5 socket2");
          setIsSocketTwoActive(true);
          props.webcamMeetingInformation.session.on("ai_result", (event) => {
            const data = JSON.parse(event.data);
            const params = {
              sessionId: sessionId,
              studentId: student.studentId,
              instituteId: context.sessionList.find(
                (item) => item.sessionId === sessionId
              ).instituteId,
              section: "GET_AI_SCORE",
              comment: data,
            };
            const response = {
              statusCode: 0,
              body: null,
            };
            setSession("patchAIScore", params, "GET_AI_SCORE", response);
            if (showToProctor) {
              if (data["AI_RESULT_TEST_TAKER_ABSENT"]) {
                setNotifyCounter((prev) => {
                  return {
                    counter: prev.counter + 1,
                    notifyType: 1,
                  };
                });
                // store.addNotification({
                //   title: null,
                //   message: `${student.name} ${student.lastname} is not on screen`,
                //   type: "danger",
                //   insert: "top",
                //   container: "top-right",
                //   animationIn: ["animate__animated", "animate__fadeIn"],
                //   animationOut: ["animate__animated", "animate__fadeOut"],
                //   dismiss: {
                //     duration: 5000,
                //     onScreen: true,
                //   },
                // });
              } else if (data["AI_RESULT_SMARTPHONE_DETECTED"]) {
                setNotifyCounter((prev) => {
                  return {
                    counter: prev.counter + 1,
                    notifyType: 7,
                  };
                });
                // store.addNotification({
                //   title: null,
                //   message: `${student.name} ${student.lastname} is not on screen`,
                //   type: "danger",
                //   insert: "top",
                //   container: "top-right",
                //   animationIn: ["animate__animated", "animate__fadeIn"],
                //   animationOut: ["animate__animated", "animate__fadeOut"],
                //   dismiss: {
                //     duration: 5000,
                //     onScreen: true,
                //   },
                // });
              } else if (data["AI_RESULT_HEADPHONE_DETECTED"]) {
                setNotifyCounter((prev) => {
                  return {
                    counter: prev.counter + 1,
                    notifyType: 8,
                  };
                });
                // store.addNotification({
                //   title: null,
                //   message: `${student.name} ${student.lastname} is not on screen`,
                //   type: "danger",
                //   insert: "top",
                //   container: "top-right",
                //   animationIn: ["animate__animated", "animate__fadeIn"],
                //   animationOut: ["animate__animated", "animate__fadeOut"],
                //   dismiss: {
                //     duration: 5000,
                //     onScreen: true,
                //   },
                // });
              } else if (data["AI_RESULT_SECOND_PERSON_VISIBLE"]) {
                setNotifyCounter((prev) => {
                  return {
                    counter: prev.counter + 1,
                    notifyType: 2,
                  };
                });
                // store.addNotification({
                //   title: null,
                //   message: `${student.name} ${student.lastname} has more than one person on screen`,
                //   type: "danger",
                //   insert: "top",
                //   container: "top-right",
                //   animationIn: ["animate__animated", "animate__fadeIn"],
                //   animationOut: ["animate__animated", "animate__fadeOut"],
                //   dismiss: {
                //     duration: 5000,
                //     onScreen: true,
                //   },
                // });
              } else if (data["AI_RESULT_TEST_TAKER_SUBSTITUTED"]) {

                setNotifyCounter((prev) => {
                  return {
                    counter: prev.counter + 1,
                    notifyType: 3,
                  };
                });
                // store.addNotification({
                //   title: null,
                //   message: `${student.name} ${student.lastname} does not match with the template`,
                //   type: "warning",
                //   insert: "top",
                //   container: "top-right",
                //   animationIn: ["animate__animated", "animate__fadeIn"],
                //   animationOut: ["animate__animated", "animate__fadeOut"],
                //   dismiss: {
                //     duration: 5000,
                //     onScreen: true,
                //   },
                // });
              } else if (data["AI_RESULT_LOOKING_AWAY"]) {
                setNotifyCounter((prev) => {
                  return {
                    counter: prev.counter + 1,
                    notifyType: 4,
                  };
                });
              }
            }
          });
        }
      // } else {
      //   props.webcamMeetingInformation.session.on("ai_result", (event) => {
      //     const data = JSON.parse(event.data);
      //     const params = {
      //       sessionId: sessionId,
      //       studentId: student.studentId,
      //       instituteId: context.sessionList.find(
      //         (item) => item.sessionId === sessionId
      //       ).instituteId,
      //       section: "GET_AI_SCORE",
      //       comment: data,
      //     };
      //     const response = {
      //       statusCode: 0,
      //       body: null,
      //     };
      //     setSession("patchAIScore", params, "GET_AI_SCORE", response);
      //     if (showToProctor) {
      //       if (data["AI_RESULT_TEST_TAKER_ABSENT"]) {
      //         setNotifyCounter((prev) => {
      //           return {
      //             counter: prev.counter + 1,
      //             notifyType: 1,
      //           };
      //         });
      //       } else if (data["AI_RESULT_SMARTPHONE_DETECTED"]) {
      //         setNotifyCounter((prev) => {
      //           return {
      //             counter: prev.counter + 1,
      //             notifyType: 7,
      //           };
      //         });
      //       } else if (data["AI_RESULT_HEADPHONE_DETECTED"]) {
      //         setNotifyCounter((prev) => {
      //           return {
      //             counter: prev.counter + 1,
      //             notifyType: 8,
      //           };
      //         });
      //       } else if (data["AI_RESULT_SECOND_PERSON_VISIBLE"]) {
      //         setNotifyCounter((prev) => {
      //           return {
      //             counter: prev.counter + 1,
      //             notifyType: 2,
      //           };
      //         });
      //       } else if (data["AI_RESULT_TEST_TAKER_SUBSTITUTED"]) {
      //         setNotifyCounter((prev) => {
      //           return {
      //             counter: prev.counter + 1,
      //             notifyType: 3,
      //           };
      //         });
      //       } else if (data["AI_RESULT_LOOKING_AWAY"]) {
      //         setNotifyCounter((prev) => {
      //           return {
      //             counter: prev.counter + 1,
      //             notifyType: 4,
      //           };
      //         });
      //       }
      //     }
      //   });
      // }
      // props.webcamMeetingInformation.session.on("signal:proctor", (event) => {
      //   const data = JSON.parse(event.data);
      //   setProctorAssistedData(data);
      // });
      // if (!context.userRole.includes("Super Admin")) {
      //   let foundWebcamSubscriber = proccontext.webcamSubscriberList.find((webcamSubscriber) =>
      //     webcamSubscriber.stream.session.sessionId === `${meetingId}-webcam`);
      //   if (!foundWebcamSubscriber) {
      //     var subscriber = props.webcamMeetingInformation.session.subscribe(
      //       props.webcamMeetingInformation.stream,
      //       undefined
      //     );
      //     subscriber.addVideoElement(document.getElementById(`webcam_video_div${meetingId}`));
      //     proccontext.setWebcamSubscriber(subscriber);
      //   }
      // }
      if (proccontext.webcamSubscriberList.length > 0) {
        proccontext.webcamSubscriberList.forEach(
          (webcamSubscriber, index) => {
            if (webcamSubscriber) {
              var subscriberMeetingId = webcamSubscriber.stream.session.sessionId.replace("-webcam", "");
              if (subscriberMeetingId === meetingId) {
                webcamSubscriber.addVideoElement(
                  document.getElementById(`webcam_video_div${meetingId}`)
                );
              }
            }
          }
        )
      }
    }
  }, [props.webcamMeetingInformation, props.trigger, isSocketOneActive, isSocketTwoActive]);

  useEffect(() => {
    if (props.screenShareMeetingInformation) {
      props.screenShareMeetingInformation.session.on(
        "signal:proctor_4",
        (event) => {
          const data = JSON.parse(event.data);
          setProctorAssistedData(data);
        }
      );
      props.screenShareMeetingInformation.session.on(
        "signal:proctor_6",
        (event) => {
          const data = JSON.parse(event.data);
          setProctorAssistedData(data);
        }
      );
      // if (!context.userRole.includes("Super Admin")) {
      //   let foundScreenShareSubscriber = proccontext.screenShareSubscriberList.find((screenShareSubscriber) =>
      //     screenShareSubscriber.stream.session.sessionId === `${meetingId}-screenShare`);
      //   if (!foundScreenShareSubscriber) {
      //     var subscriber = props.screenShareMeetingInformation.session.subscribe(
      //       props.screenShareMeetingInformation.stream,
      //       undefined
      //     );
      //     subscriber.addVideoElement(document.getElementById(`screenShare_video_div${meetingId}`));
      //     proccontext.setScreenShareSubscriber(subscriber);
      //   }
      // }
      if (proccontext.screenShareSubscriberList.length > 0) {
        proccontext.screenShareSubscriberList.forEach(
          (screenShareSubscriber, index) => {
            if (screenShareSubscriber) {
              var subscriberMeetingId = screenShareSubscriber.stream.session.sessionId.replace("-screenShare", "");
              if (subscriberMeetingId === meetingId) {
                screenShareSubscriber.addVideoElement(
                  document.getElementById(`screenShare_video_div${meetingId}`)
                );
              }
            }
          }
        )
      }
    }
  }, [props.screenShareMeetingInformation, props.trigger]);

  useEffect(() => {
    if (
      props.mobileMeetingInformation &&
      props.mobileMeetingInformation.length > 0
    ) {
      if (!context.userRole.includes("Super Admin")) {
        props.mobileMeetingInformation.forEach(
          (mobileMeeting, mobileMeetingIndex) => {
            if (mobileMeeting) {
              let foundMobileSubscriber = proccontext.mobileSubscriberList.find((mobileSubscriber) =>
                mobileSubscriber.stream.session.sessionId === `${meetingId}-mobile_${mobileMeetingIndex}`);
              if (!foundMobileSubscriber) {
                var subscriber = mobileMeeting.session.subscribe(
                  mobileMeeting.stream,
                  undefined
                );
                subscriber.addVideoElement(document.getElementById(
                  `mobile_${props.mobileIndex[mobileMeetingIndex]}_video_div${meetingId}`));
                proccontext.setMobileSubscriberList(subscriber);
              }
            }
          }
        );
      }
      if (proccontext.mobileSubscriberList.length > 0) {
        proccontext.mobileSubscriberList.forEach(
          (mobileSubscriber, index) => {
            if (mobileSubscriber) {
              debugger;
              let mobileParseIndex = mobileSubscriber.stream.session.sessionId.substring(mobileSubscriber.stream.session.sessionId.length - 1);
              var mobileSubscriberMeetingId = mobileSubscriber.stream.session.sessionId
                .replace("-mobile_0", "")
                .replace("-mobile_1", "")
                .replace("-mobile_2", "")
                .replace("-mobile_3", "");
              if (mobileSubscriberMeetingId === meetingId) {
                mobileSubscriber.addVideoElement(document.getElementById(`mobile_${mobileParseIndex}_video_div${meetingId}`));
              }
            }
          }
        )
      }
    }
  }, [props.mobileMeetingInformation, props.trigger]);

  useEffect(async () => {
    if (show) {
      var context = canvasRef.current.getContext("2d");
      if (camera === "webcam") {
        let width = Math.ceil(
          (258 * webcamRef.current.videoWidth) / webcamRef.current.videoHeight
        );
        await setCustomVideoWidth(width);
        context.drawImage(webcamRef.current, 0, 0, width, 258);
      } else if (camera === "screenShare") {
        let width = Math.ceil(
          (258 * screenRef.current.videoWidth) / screenRef.current.videoHeight
        );
        await setCustomVideoWidth(width);
        context.drawImage(screenRef.current, 0, 0, width, 258);
      } else if (camera === `mobile_0`) {
        let width = Math.ceil(
          (258 * mobileRef.current.videoWidth) / mobileRef.current.videoHeight
        );
        await setCustomVideoWidth(width);
        context.drawImage(mobileRef.current, 0, 0, width, 258);
      } else {
        //Blank screen burası
        props.mobileMeetingInformation.forEach(
          async (mobileMeeting, mobileMeetingIndex) => {
            if (mobileMeeting) {
              const webcamVideo = document.getElementById(
                `mobile_${props.mobileIndex[mobileMeetingIndex]}_video_div${meetingId}`
              );
              let width = Math.ceil(
                (258 * webcamVideo.videoWidth) / webcamVideo.videoHeight
              );
              await setCustomVideoWidth(width);
              context.drawImage(webcamVideo, 0, 0, width, 258);
            }
          }
        );
      }
    }
  }, [show]);

  useEffect(() => {
    if (!student.online) {
      const webcamVideo = document.getElementById(
        `webcam_video_div${meetingId}`
      );
      if (webcamVideo) {
        voice(false);
      }
    }
  }, [student.online]);

  const changeCurrentChatSession = () => {
    if (!props.chatPanelVisible) {
      props.setChatSession(props.webcamMeetingInformation.session);
      props.setChatPanelVisible(true);
    } else {
      if (
        props.chatSession.sessionId ===
        props.webcamMeetingInformation.session.sessionId
      ) {
        props.setChatPanelVisible(false);
      } else {
        props.setChatSession(props.webcamMeetingInformation.session);
      }
    }
  };

  const handleClose = () => {
    setShow(false);
    setComment(null);
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const saveCommand = async () => {
    const file = dataURLtoFile(
      canvasRef.current.toDataURL(),
      `${new Date().getTime()}.png`
    );
    await uploadObject(
      file,
      file.name,
      `${sessionId}${student.studentId}/detections/`
    );

    const checkUserRole = (role) => {
      const userRole = context.userRole;
      if (userRole) {
        const parsedUserRole = JSON.parse(userRole);
        const foundRole = parsedUserRole.find(
          (singleRole) => singleRole.role === role
        );
        if (foundRole) {
          return true;
        }
      }
      return false;
    };

    const params = {
      meetingId: `${sessionId}${student.studentId}`,
      type: "ADD_COMMENT",
      comments: [
        {
          text: comment,
          otherText: otherComment,
          image: `${sessionId}${student.studentId}/detections/${file.name}`,
          type: checkUserRole("Proctor") ? "Proctor" : "Supervisor", //JSON.parse(context.userRole)[0].role,
          proctor: context.user.email,
          proctorFullName: context.userInfo,
          time: new Date().getTime(),
          source: camera,
        },
      ],
    };
    await setStudentResult("UPDATE", params)
      .then(() => {
        props.updateSingleStudentCommentMap(
          student.studentId,
          params.meetingId
        );
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openCommand = () => {
    setShow(true);
  };

  const voice = (voiceState) => {
    if (voiceState !== undefined) {
      if (voiceState) {
        proccontext.storeMuted(false, student.studentId);
      } else {
        proccontext.storeMuted(true, student.studentId);
      }
    } else {
      if (student.muted) {
        proccontext.storeMuted(false, student.studentId);
      } else {
        proccontext.storeMuted(true, student.studentId);
      }
    }
  };

  const call = async () => {
    let studentCallInformation = props.callInformations && props.callInformations.find((callInformation) =>
      callInformation.studentId === student.studentId
    );
    if (studentCallInformation.status === "FINISHED") {
      let studentAldreadyCalled = props.callInformations && props.callInformations.find((callInformation) =>
        callInformation.status === "ACCEPTED" || callInformation.status === "WAITING"
      );
      if (studentAldreadyCalled) {
        // console.log("ttt => Lütfen studentAldreadyCalled => kapat", studentAldreadyCalled);
        store.addNotification({
          title: null,
          message: `Please end the call with ${studentAldreadyCalled.name} ${studentAldreadyCalled.lastname} before calling another student!`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3500,
            onScreen: true,
          },
        });
      } else {
        await props.setCallInformations((callInformations) =>
          callInformations.filter((callInfo) => {
            if (callInfo.studentId === student.studentId) {
              callInfo.enabled = true;
              callInfo.status = "WAITING";
            }
            return callInfo;
          })
        );
        // console.log("ttt => props.callInformations111 => ", props.callInformations);
        voice(true);
      }
    } else {
      props.webcamMeetingInformation.session
        .signal({
          data: JSON.stringify({
            state: "PROCTOR_CALL_ENDED",
          }),
          type: "system",
        })
        .then(() => {
          console.log("Proctor call ended");
        })
        .catch((error) => {
          console.error("err:", error);
        });
      await props.setCallInformations((callInformations) =>
        callInformations.filter((callInfo) => {
          if (callInfo.studentId === student.studentId) {
            callInfo.enabled = false;
            callInfo.status = "FINISHED";
          }
          return callInfo;
        })
      );
      // console.log("ttt => props.callInformations222 => ", props.callInformations);
      voice(false);
    }
  };
  const getCallColor = () => {
    let studentCallInfo = props.callInformations && props.callInformations.find((callInformation) =>
      callInformation.studentId === student.studentId
    );
    if (studentCallInfo && studentCallInfo.status === "FINISHED") {
      return "red";
    } else if (studentCallInfo && studentCallInfo.status === "WAITING") {
      return "orange";
    } else if (studentCallInfo && studentCallInfo.status === "ACCEPTED") {
      return "green";
    }
  };

  const expandDetailed = async (studentId) => {
    proccontext.setDetail(studentId, camera);
    await setStudentResult("GET", sessionId + studentId);
  };

  return (
    <React.Fragment>
      <div
        id={`div_${camera}${meetingId}`}
        style={{ display: student.show === true ? "" : "none" }}
        className={`col-${props.col ? props.col : proccontext.students.row
          } m-0 p-0`}
      >
        <div className="card-body m-0 p-0">
          <h5 className="card-title m-0 p-0">
            <div className={`btn w-100`}>
              {student.name} {student.lastname} -
              {camera === "webcam"
                ? " Webcam"
                : camera === "screenShare"
                  ? " Screen Share"
                  : camera === `mobile_${props.mobileIndex}`
                    ? ` Mobile ${props.mobileIndex * 1 + 1} ${props.disconnected}`
                    : ""}
            </div>
          </h5>
          <div
            style={{
              backgroundColor: "rgba(0,0,0,.03)",
              display: camera !== "webcam" ? "none" : "",
            }}
            id={`webcam_div${meetingId}`}
          >
            {props.webcamMeetingInformation ? (
              <video
                ref={webcamRef}
                muted={student.muted}
                id={`webcam_video_div${meetingId}`}
                height={250}
                className="mt-auto mb-auto"
                onDoubleClick={props.fullScreen}
              />
            ) : null}
          </div>
          <div
            style={{
              backgroundColor: "rgba(0,0,0,.03)",
              display: camera !== "screenShare" ? "none" : "",
            }}
            id={`screenshare_div${meetingId}`}
          >
            {props.screenShareMeetingInformation ? (
              <video
                ref={screenRef}
                muted
                id={`screenShare_video_div${meetingId}`}
                height={250}
                className="mt-auto mb-auto"
                onDoubleClick={props.fullScreen}
              />
            ) : null}
          </div>

          {props.mobileMeetingInformation &&
            props.mobileMeetingInformation.length > 0
            ? props.mobileMeetingInformation.map(
              (mobileMeeting, mobileMeetingIndex) => {
                return (
                  <div
                    style={{
                      backgroundColor: "rgba(0,0,0,.03)",
                      display:
                        camera !==
                          `mobile_${props.mobileIndex[mobileMeetingIndex]}`
                          ? "none"
                          : "",
                    }}
                    id={`mobile_${props.mobileIndex[mobileMeetingIndex]}_div${meetingId}`}
                  >
                    <video
                      ref={mobileRef}
                      muted
                      id={`mobile_${props.mobileIndex[mobileMeetingIndex]}_video_div${meetingId}`}
                      height={250}
                      className="mt-auto mb-auto"
                      onDoubleClick={props.fullScreen}
                    />
                  </div>
                );
              }
            )
            : null}

          <div className="card-footer">
          {/* <div className="card-footer d-flex p-2" style={{justifyContent: "center"}}> */}
            <small className="text-muted">
              {props.webcamMeetingInformation ? (
                <Link
                  to="#"
                  className="card-link"
                  onClick={() => setCamera("webcam")}
                >
                  <FaVideo color={camera === "webcam" ? "red" : null} />
                </Link>
              ) : null}
              {props.screenShareMeetingInformation ? (
                <Link
                  to="#"
                  className="card-link"
                  onClick={() => setCamera("screenShare")}
                >
                  <FaDesktop color={camera === "screenShare" ? "red" : null} />
                </Link>
              ) : null}
              {props.mobileMeetingInformation &&
                props.mobileMeetingInformation.length > 0
                ? props.mobileMeetingInformation.map(
                  (mobileMeeting, mobileMeetingIndex) => {
                    return (
                      <Link
                        to="#"
                        className="card-link"
                        onClick={() =>
                          setCamera(
                            `mobile_${props.mobileIndex[mobileMeetingIndex]}`
                          )
                        }
                      >
                        <FaMobileAlt
                          color={
                            camera ===
                              `mobile_${props.mobileIndex[mobileMeetingIndex]}`
                              ? "red"
                              : null
                          }
                        />
                      </Link>
                    );
                  }
                )
                : null}
              <Link
                to="#"
                className="card-link"
                onClick={() => expandDetailed(student.studentId)}
              >
                <FaExpandAlt />
              </Link>
              {camera === "webcam" &&
                !context.userRole.includes("Super Admin") &&
                props.appropriateSessionProctoringType(proctorServiceType) ? (
                <Link
                  to="#"
                  className="card-link"
                  onClick={changeCurrentChatSession}
                >
                  <FaComments />
                </Link>
              ) : null}

              {props.appropriateSessionProctoringType(proctorServiceType) ? (
                <Link
                  to="#"
                  className="card-link"
                  onClick={() => openCommand()}
                >
                  <FaPen />
                </Link>
              ) : null}

              {camera === "webcam"  ? (
                <Link to="#" className="card-link" onClick={() => voice()}>
                  {student.icon === "muted" ? (
                    <FaVolumeMute />
                  ) : (
                    <FaVolumeUp color={"red"} />
                  )}
                </Link>
              ) : null}
              {camera === "webcam" &&
                props.appropriateSessionProctoringType(proctorServiceType) &&
                context.userRole.includes("Proctor") ? (
                <Link to="#" className="card-link" onClick={() => call()}>
                  <FaPhone color={getCallColor()} />
                </Link>
              ) : null}
            </small>
            {camera === "screenShare" && proctorAssistedData !== null ? (
              <div className="d-flex flex-row align-items-center justify-content-center mt-2">
                <div className="col-md-5 text-center">
                  Waiting for{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {proctorAssistedData.text}
                  </span>{" "}
                  Approval
                </div>
                <div className="col-md-7 d-flex flex-row justify-content-around align-items-center">
                  <button
                    className="col-md-5 btn btn-success ml-2 mr-2"
                    style={{ fontSize: 12 }}
                    onClick={() => {
                      const data = {
                        mainState: proctorAssistedData.mainState,
                        status: "APPROVED",
                        type: proctorAssistedData.type,
                        pageState: proctorAssistedData.pageState,
                      };
                      props.screenShareMeetingInformation.session
                        .signal({
                          data: JSON.stringify(data),
                          type: `proctor_${proctorAssistedData.mainState}`,
                        })
                        .then(() => {
                          setProctorAssistedData(null);
                        })
                        .catch((error) => {
                          console.error("Err:", error);
                        });
                    }}
                  >
                    Approve
                  </button>
                  <button
                    className="col-md-5 btn btn-danger ml-2 mr-2"
                    style={{ fontSize: 12 }}
                    onClick={() => {
                      const data = {
                        status: "DENIED",
                        type: proctorAssistedData.type,
                        text: proctorAssistedData.text,
                        pageState: proctorAssistedData.pageState,
                      };
                      props.screenShareMeetingInformation.session
                        .signal({
                          data: JSON.stringify(data),
                          type: `proctor_${proctorAssistedData.mainState}`,
                        })
                        .then(() => {
                          setProctorAssistedData(null);
                        })
                        .catch((error) => {
                          console.error("Err:", error);
                        });
                    }}
                  >
                    Deny
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <ModalForm
        show={show}
        handleClose={handleClose}
        Title={`${i18n.t("Add_a_comment_for")} ${student.name} ${student.lastname
          }`}
      >
        <div style={{ textAlign: "center" }}>
          <canvas
            ref={canvasRef}
            width={`${customVideoWidth < 466 ? customVideoWidth : 465}`}
            height="258"
          ></canvas>
        </div>
        <FormSelect
          name="student_commentsList"
          label={i18n.t("Comments")}
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          data={variables.ReviewerMessages}
          dataValue="text"
          dataText="value"
          required={true}
          col={12}
        // className="w-100"
        />
        {comment === i18n.t("Other...") ? (
          <div className="col-md-12 mb-3">
            <textarea
              onChange={(e) => setOtherComment(e.target.value)}
              className="form-control"
              style={{ borderRadius: 10 }}
            />
          </div>
        ) : null}
        <button className={context.theme.btn_save} onClick={saveCommand}>
          {i18n.t("Send")}
        </button>
      </ModalForm>
    </React.Fragment>
  );
}
export default withNamespaces()(OneMeeting);
