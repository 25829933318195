import React, { useState, useEffect, useRef ,useContext } from "react";
import SystemCheckItem from "./SystemCheckItem";
import { FaMicrophone } from "react-icons/fa";
import AudioAnalyser from "./audio/AudioAnalyser";
import { Modal, Form, Button } from "react-bootstrap";
import { store } from "react-notifications-component";
import i18n from "../../../src/configs/i18n";
import { proctorcontext } from "../../configs/proctorContext";
import { withNamespaces } from "react-i18next";
function CheckAudio(props) {
  const stream = useRef(null);
  const [info, setInfo] = useState({
    title: i18n.t("Check_Audio"),
    ready: i18n.t("WAITING"),
    infoCover: "col-6",
    waitMessage: i18n.t("Please_wait_We_are_checking_your_microphone"),
    description: i18n.t(
      "This_step_will_check_whether_there_is_a_microphone_connected_and_working"
    ),
    descriptionCover: "col-6",
    buttonText: i18n.t("Next_Step"),
  });
  
  const proccontex = useContext(proctorcontext);
  const [currentIcon, setCurrentIcon] = useState(
    <FaMicrophone size={150} color={info.ready === "OK" ? "#29377e" : "gray"} />
  );
  const [deviceList, setDeviceList] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [show, setShow] = useState(false);
  let currentDeviceId = null;

  useEffect(() => {
    const getAllAudioSources = async () => {
      const audio=await navigator.mediaDevices.getUserMedia({ audio: true });
      const stream = {audio:audio,webcam:""}
      proccontex.setStream(stream)
      const enumeratedDevices = await navigator.mediaDevices.enumerateDevices();

      setDeviceList(
        enumeratedDevices.filter(
          (device) =>
            device.kind === "audioinput" &&
            device.deviceId !== "default" &&
            device.deviceId !== "communications"
        )
      );
      setShow(true);
    };
    getAllAudioSources();
  }, []);

  useEffect(() => {
    const findAudioSource = async () => {
      const node = window.getComputedStyle(
        document.getElementById("webcam_div")
      );
      const width =
        parseFloat(node.getPropertyValue("width")) -
        (parseFloat(node.getPropertyValue("padding-left")) +
          parseFloat(node.getPropertyValue("padding-right")));
      const height =
        parseFloat(node.getPropertyValue("height")) -
        (parseFloat(node.getPropertyValue("padding-top")) +
          parseFloat(node.getPropertyValue("padding-bottom")));
      let stream;
      let audioState = "HARDWARE_NOT_EXIST";
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: {
            deviceId: selectedDevice.deviceId,
          },
        });
        audioState = "OK";
      } catch (err) {
        audioState = "PERMISSION_DENIED";
      }
      if (audioState === "OK") {
        setInfo((info) => ({
          ...info,
          ready: "OK",
          waitMessage: i18n.t("AudioWaitMessageSuccess"),
        }));
        setCurrentIcon(
          <AudioAnalyser
            audio={stream}
            width={(width / 12) * 9}
            height={height}
          />
        );
      } else if (audioState === "HARDWARE_NOT_EXIST") {
        setInfo((info) => ({
          ...info,
          ready: i18n.t("FAILED"),
          waitMessage: i18n.t(
            "No_microphone_detected_Please_plug_connect_a_microphone_and_repeat_the_system_check"
          ),
        }));
      } else if (audioState === "PERMISSION_DENIED") {
        setInfo((info) => ({
          ...info,
          ready: i18n.t("FAILED"),
          waitMessage: i18n.t(
            "Microphone_detected_but_the_permissions_are_denied_Please_check_your_Microphone_Privacy_Settings"
          ),
        }));
      }
      return stream;
    };
    if (selectedDevice) {
      findAudioSource().then((data) => {
        stream.current = data;
      });
    }

    return () => {
      if (stream.current) {
        stream.current.getTracks().forEach(function (track) {
          if (track.readyState === "live") {
            track.stop();
          }
        });
      }
    };
  }, [selectedDevice]);

  const renderVideoInputDeviceList = () => {
    const renderDeviceList = [];
    deviceList.forEach((device, index) => {
      renderDeviceList.push(
        <div
          key={index}
          className="d-flex flex-column text-center"
          style={{
            marginTop: 10,
            marginBottom: 10,
            border: "1px solid #29377e",
            borderRadius: 10,
            padding: 10,
            color: "#29377e",
          }}
        >
          <Form.Check
            name="audioinput"
            type={"radio"}
            id={device.deviceId}
            label={device.label}
          />
        </div>
      );
    });
    return (
      <Form
        onChange={(e) => {
          currentDeviceId = e.target.id;
        }}
      >
        <div key={`audio-input-device-form`}>{renderDeviceList}</div>
      </Form>
    );
  };

  const renderModalFooter = () => {
    return (
      <Button
        variant="primary"
        style={{ backgroundColor: "#29377e", borderColor: "#29377e" }}
        onClick={() => {
          if (currentDeviceId) {
            setSelectedDevice(
              deviceList.find((device) => device.deviceId === currentDeviceId)
            );
            setShow(false);
          } else {
            store.addNotification({
              title: null,
              message: i18n.t("Please_select_an_appropriate_audio_resource"),
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          }
        }}
      >
        {i18n.t("Select")}
      </Button>
    );
  };

  const handleClose = () => {
    setShow(false);
  };

  const buttonHandler = () => {
    props.setSelectedDevice((prevSelectedDevice) => ({
      ...prevSelectedDevice,
      audio: selectedDevice,
    }));
    props.buttonHandler();
  };

  return (
    <React.Fragment>
      <SystemCheckItem
        Icon={currentIcon}
        info={info}
        buttonHandler={buttonHandler}
        next={props.next}
        prev={props.prev}
        handleRepeatClick={props.handleRepeatClick}
        repeatableButton={true}
        configuration={props.configuration}
        currentStep={props.currentStep}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <div
            className="m-auto text-center"
            style={{ fontWeight: "bold", color: "#29377e" }}
          >
            {i18n.t("Please_select_your_audio_source_from_the_list_below")}
          </div>
        </Modal.Header>
        <Modal.Body>{renderVideoInputDeviceList()}</Modal.Body>
        <Modal.Footer>{renderModalFooter()}</Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
export default withNamespaces()(CheckAudio);
