/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useLanguage } from "../../hooks/useLanguage";
import { awscontext } from "../../configs/awscontext";
import { useStorage } from "../../hooks/useStorage";
import ModalForm from "../components/ModalForm";
import FormSelect from "../components/FormSelect";
import UploadDetail from "./components/UploadDetail";
import ScreenPanel from "../components/ScreenPanel";
import i18n from "../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import axios from "axios";
import fileDownload from "js-file-download";
import { store } from "react-notifications-component";
const config = require("../../configs/awsconfig.json");
const langList = require("../../configs/langNameList.json");
const configI18 = require("../../../src/configs/i18n");

function UploadLanguage() {
  const context = useContext(awscontext);
  const [, setLanguage] = useLanguage([]);
  const [uploadObject, , , getBaseUrl] = useStorage(config.s3.files);
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [file, setFile] = useState(null);
  const [showLangs, setShowLangs] = useState(false);
  const [langs, setLangs] = useState([]);
  const [selectedLang, setSelectedLang] = useState({});

  useEffect(async () => {
    await axios
      .get(`${config.api.invokeUrl}/languages`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Object.keys(localStorage).find((key) => key.includes("accessToken"))
          )}`,
        },
      })
      .then((response) => {
        setLangs(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [showLangs, showDelete]);

  useEffect(() => {
    context.setAdres("uploadLanguage");
    context.setBarGroup("SETTINGS");
  }, []);
  const onFileUpload = async () => {
    if (Object.keys(selectedLang).length === 0) {
      store.addNotification({
        title: null,
        message: i18n.t("Please_Choose_the_Language_to_Upload"),
        type: "warning",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      return;
    }

    try {
      await uploadObject(
        file,
        selectedLang.isoCode + ".json",
        "assets/languages/"
      );
      await setLanguage("post", selectedLang);
      store.addNotification({
        title: null,
        message: i18n.t("Language_Uploaded_Successfully"),
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } catch (error) {
      store.addNotification({
        title: null,
        message: `${error.message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };
  const onFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const handlerSelect = (e) => {
    const selectedCode = e.target.value;
    const selectedLang = langList.laguages.find(
      (I) => I.isoCode === selectedCode
    );
    setSelectedLang(selectedLang);
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleShowLangsModalClose = () => {
    setShowLangs(false);
  };

  const handleShowDeleteClose = () => {
    setShowDelete(false);
  };

  const addNewLanguage = async () => {
    await axios
      .get(`${getBaseUrl()}/assets/languages/tmps.json`, {
        responseType: "blob",
      })
      .then(async (res) => {
        try {
          await uploadObject(
            res.data,
            selectedLang.isoCode + ".json",
            "assets/languages/"
          );
          await setLanguage("post", selectedLang);
          store.addNotification({
            title: null,
            message: i18n.t("New language succesfully added."),
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          setShowLangs(false);
          setSelectedLang({});
        } catch (error) {
          console.log(error);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteLang = () => {
    if (Object.keys(selectedLang).length !== 0) {
      setShowDelete(true);
    } else {
      store.addNotification({
        title: null,
        message: i18n.t("Please Choose the Language to Delete"),
        type: "warning",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  const handleDeleteLanguageConfirm = async () => {
    await axios
      .delete(`${config.api.invokeUrl}/languages/${selectedLang.isoCode}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Object.keys(localStorage).find((key) => key.includes("accessToken"))
          )}`,
        },
      })
      .then(async (response) => {
        if (response.status === 210) {
          store.addNotification({
            title: null,
            message: i18n.t(`${selectedLang.Name} language cannot delete.`),
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        } else if (response.status === 200) {
          store.addNotification({
            title: null,
            message: i18n.t(
              `${selectedLang.Name} language successfully deleted.`
            ),
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
        setShowDelete(false);
        setSelectedLang({});
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const downloadDocument = async () => {
    if (Object.keys(selectedLang).length === 0) {
      store.addNotification({
        title: null,
        message: i18n.t("Please_Select_Language"),
        type: "warning",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      return;
    }
    try {
      await axios
        .get(`${getBaseUrl()}/assets/languages/${selectedLang.isoCode}.json`, {
          responseType: "blob",
        })
        .then((res) => {
          fileDownload(res.data, selectedLang.isoCode + ".json");
        });
    } catch (error) {
      await axios
        .get(`${getBaseUrl()}/assets/languages/tmps.json`, {
          responseType: "blob",
        })
        .then((res) => {
          fileDownload(res.data, selectedLang.isoCode + ".json");
        });
    }
  };

  return (
    <React.Fragment>
      <ScreenPanel name="Languages" col="6">
        <row>
          <ModalForm
            show={showDelete}
            handleClose={handleShowDeleteClose}
            handleDelete={handleDeleteLanguageConfirm}
            Title={i18n.t("Confirm_Delete")}
            Body={i18n.t("Are_you_sure_you_want_to_delete_this_item?")}
          />

          <div className=" d-flex justify-content-end mb-2">
            <button
              onClick={deleteLang}
              className={context.theme.btn_save_small}
            >
              {i18n.t("Delete")}
            </button>

            <button
              onClick={() => {
                setShowLangs(true);
              }}
              className={context.theme.btn_save_small + " ml-3"}
            >
              {i18n.t("Add_New_Language")}
            </button>
          </div>

          <ModalForm
            show={showLangs}
            handleClose={handleShowLangsModalClose}
            Title={i18n.t(`Add_New_Language`)}
          >
            <div className="col">
              <FormSelect
                name="lang"
                label={i18n.t("Choose_Language")}
                onChange={handlerSelect}
                data={langList.laguages}
                dataValue="isoCode"
                dataText="Name"
                required={true}
              />
            </div>
            <button
              className={context.theme.btn_save + " ml-3"}
              onClick={addNewLanguage}
            >
              {i18n.t("Add")}
            </button>
          </ModalForm>

          <div className="col">
            <FormSelect
              name="lang"
              label={i18n.t("Choose_Language")}
              onChange={handlerSelect}
              data={langs}
              dataValue="langCode"
              dataText="langName"
              required={true}
            />
          </div>

          <div className="d-flex justify-content-between mb-5">
            <div className="d-flex">
              <button
                onClick={downloadDocument}
                className={context.theme.btn_save_small}
              >
                {i18n.t("Download_Language_Template")}
              </button>
            </div>

            <div className="d-flex">
              <label
                className={context.theme.btn_save_small + " m-0"}
                htmlFor="filePicker"
              >
                {i18n.t("Choose_Modifed_File")}
              </label>
              <input
                id="filePicker"
                type="file"
                style={{ visibility: "hidden", width: 0 }}
                onChange={onFileChange}
                // className={context.theme.btn_save}
              />
            </div>

            <div className="d-flex">
              <button
                onClick={onFileUpload}
                className={context.theme.btn_save_small}
                disabled={file ? false : true}
              >
                {i18n.t("Upload_File")}
              </button>
            </div>
          </div>
        </row>

        <row>
          {/* {context.languages.map((lang) => (
            <div className="row align-items-center">
              <div className="col-8">
                <div className="col-6">
                  <h5>en</h5>
                </div>
              </div>
              <div className="col-4">
                <ExternalLink
                  href={`${config.api.s3}/assets/languages/en.json`}
                >
                  <FaDownload size={112} />
                </ExternalLink>
              </div>
            </div>
          ))} */}
        </row>
        <div className="col">{file ? <UploadDetail file={file} /> : ""}</div>
        <br></br>
      </ScreenPanel>
      <ModalForm show={show} handleClose={handleClose} />
    </React.Fragment>
  );
}
export default withNamespaces()(UploadLanguage);
