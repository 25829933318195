/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { useInstitute } from "../../hooks/useInstitute";
import { useInput } from "../../hooks/useInput";
import FormInput from "../components/FormInput";
import ModalForm from "../components/ModalForm";
import FormSelect from "../components/FormSelect";
import { awscontext } from "../../configs/awscontext";
import SessionThreshold from "../session/components/SessionThreshold";
import ScreenPanel from "../components/ScreenPanel";
import i18n from "../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import EmailSettings from "../session/components/EmailSettings";
import { Tab, Tabs } from "react-bootstrap-tabs";
import { store } from "react-notifications-component";
import axios from "axios";
import timezones from "../../configs/timezones.json";
import { input } from "aws-amplify";
const config = require("../../configs/awsconfig.json");
const instituteModel = require("../../models/modelInstitute.json");
const variables = require("../../models/variables.json");

const themeList = [
  { name: "Sky" },
  { name: "Soft" },
  { name: "Purple" },
  { name: "Blue" },
];

function InstituteTask(props) {
  const context = useContext(awscontext);
  const [inputs, setInputs] = useInput(
    props.location.pathname.includes("generalSettings")
      ? { ...context.currentInstitute }
      : props.location.state
      ? { ...props.location.state.selectedInstitute }
      : { ...instituteModel, section: "main" }
  );
  const [title, setTitle] = useState("");
  const [btnSaveTitle, setBtnSaveTitle] = useState("");
  const [btnDeleteTitle, setBtnDeleteTitle] = useState("");
  const [btnDeleteVisible, setBtnDeleteVisible] = useState(false);
  const [, setInstitutes] = useInstitute([]);
  const [page, setPage] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [languages, setLanguage] = useState([]);

  const getLanguageEvent = async () => {
    try {
      const result = await axios.get(`${config.api.invokeUrl}/languages`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Object.keys(localStorage).find((key) => key.includes("accessToken"))
          )}`,
        },
      });
      setLanguage(result.data);
    } catch (err) {
      console.log(`Unable to API: ${err}`);
    }
  };

  useEffect(() => {
    getLanguageEvent();
    if (props.location.pathname.includes("createInstitute")) {
      setPage("createInstitute");
      context.setAdres("createInstitute");
      context.setBarGroup("INSTITUTES");
      setTitle("Create_Institute");
      setBtnSaveTitle("Create_Institute");
    }
    if (props.location.pathname.includes("updateInstitute")) {
      setPage("updateInstitute");
      context.setAdres("updateInstitute");
      context.setBarGroup("INSTITUTES");
      setTitle("Update");
      setBtnDeleteVisible(true);
      setBtnDeleteTitle("Delete");
      setBtnSaveTitle("Update");
    }
    if (props.location.pathname.includes("generalSettings")) {
      setPage("generalSettings");
      context.setAdres("generalSettings");
      context.setBarGroup("SETTINGS");
      setTitle("General_Settings");
      setBtnSaveTitle("Update");
    }
    if (props.location.pathname.includes("deleteInstitute")) {
      setPage("deleteInstitute");
      context.setAdres("deleteInstitute");
      context.setBarGroup("INSTITUTES");
      setTitle("Delete");
      setBtnSaveTitle("Delete");
    }
    return () => setInputs("");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const handleEvent = async (e) => {
    e.preventDefault();
    if (props.location.pathname.includes("createInstitute")) {
      await setInstitutes("post", inputs);
      props.history.push(`/instituteList`);
      store.addNotification({
        title: null,
        //message: `Institute ${inputs.instituteName} has successfully created`,
        message: i18n.t("Institute_has_successfully_created"),
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } else if (
      props.location.pathname.includes("updateInstitute") ||
      props.location.pathname.includes("generalSettings")
    ) {
      if (inputs.settings.videoDuration < 30) {
        store.addNotification({
          title: null,
          message: "Data expiry duration cannot be less than 30 days",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        return;
      }
      await setInstitutes("patch", inputs);
      props.history.push({
        pathname: "instituteList",
        state: {
          status: "CREATED_NEW",
          instituteName: inputs.instituteName,
        },
      });
    }
  };

  const handleClose = () => {
    setShowDelete(false);
  };

  const handleDeleteConfirm = async () => {
    await setInstitutes("delete", inputs);
    props.history.push(`/instituteList`);
    store.addNotification({
      title: null,
      //message: `Institute ${inputs.instituteName} has successfully deleted`,
      message: i18n.t("Institute_has_successfully_deleted"),
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
    setShowDelete(false);
  };

  return (
    <React.Fragment>
      <ScreenPanel name={i18n.t(title)} col="10">
        {page !== "generalSettings" ? (
          <React.Fragment>
            <FormInput
              name="instituteName"
              text={i18n.t("Institute_Name")}
              value={inputs.instituteName}
              onChange={setInputs}
              type="text"
              required={true}
            />
            <FormSelect
              name="language"
              label={i18n.t("Languages")}
              onChange={setInputs}
              value={inputs.language}
              data={languages}
              dataValue="langCode"
              dataText="langCode"
              required={true}
            />
            <FormSelect
              name="timezoneOffset"
              label={i18n.t("timezoneOffset")}
              onChange={setInputs}
              // value={inputs.timezoneOffset}
              data={timezones}
              dataValue="offset"
              dataText="text"
              required={true}
              tz={"timezone"}
            />

            <div className="d-flex flex-fill justify-content-between">
              <button className={context.theme.btn_save} onClick={handleEvent}>
                {i18n.t(btnSaveTitle)}
              </button>
              {btnDeleteVisible === true ? (
                <button
                  className={context.theme.btn_delete}
                  onClick={() => setShowDelete(true)}
                >
                  {i18n.t(btnDeleteTitle)}
                </button>
              ) : null}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Tabs>
              <Tab label={i18n.t("General_Settings")}>
                <div style={{ marginTop: 20 }}>
                  {/* <FormSelect
                    name="settings.theme"
                    label={i18n.t("Choose_Your_Smartest_Proctor_Theme")}
                    onChange={setInputs}
                    value={inputs.settings.theme ? inputs.settings.theme : ""}
                    data={themeList}
                    dataValue="name"
                    dataText="name"
                    required={true}
                  /> */}
                  <FormInput
                    name="settings.preExamSystemCheckLink"
                    text={i18n.t("Pre_Exam_System_Check_Link")}
                    value={inputs.settings.preExamSystemCheckLink}
                    onChange={setInputs}
                    type="text"
                    required={true}
                  />
                  <FormInput
                    name="settings.videoDuration"
                    text={i18n.t("Data_Expiry_Duration")}
                    value={inputs.settings.videoDuration}
                    onChange={setInputs}
                    type="number"
                    required={true}
                    min={31}
                  />
                  <FormSelect
                    name="settings.replicatingData"
                    label={i18n.t("Data_Transfer")}
                    onChange={setInputs}
                    value={inputs.settings.replicatingData}
                    data={variables.ReplicatingData}
                    dataValue="value"
                    dataText="value"
                    required={true}
                  />
                  <FormSelect
                    name="timezoneOffset"
                    label={i18n.t("timezoneOffset")}
                    onChange={setInputs}
                    // value={inputs.timezoneOffset}
                    data={timezones}
                    dataValue="offset"
                    dataText="text"
                    required={true}
                    tz={"timezone"}
                  />
                </div>
                <div className="d-flex flex-fill justify-content-between">
                  <button
                    className={context.theme.btn_save}
                    onClick={handleEvent}
                  >
                    {i18n.t(btnSaveTitle)}
                  </button>
                  {btnDeleteVisible === true ? (
                    <button
                      className={context.theme.btn_delete}
                      onClick={() => setShowDelete(true)}
                    >
                      {i18n.t(btnDeleteTitle)}
                    </button>
                  ) : null}
                </div>
              </Tab>
              <Tab label={i18n.t("Email_Settings")}>
                <EmailSettings />
              </Tab>
              <Tab label={i18n.t("AI_Settings")}>
                <SessionThreshold
                  thisIsInstitue={true}
                  InstitueInput={inputs}
                  {...props}
                />
              </Tab>
            </Tabs>
          </React.Fragment>
        )}
      </ScreenPanel>
      <ModalForm
        show={showDelete}
        handleClose={handleClose}
        handleDelete={handleDeleteConfirm}
        Title={i18n.t("Confirm_Delete")}
        Body={i18n.t("Are_you_sure_you_want_to_delete_this_item?")}
      />
    </React.Fragment>
  );
}
export default withNamespaces()(InstituteTask);
