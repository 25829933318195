/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import { awscontext } from "../../../configs/awscontext";
import i18n from "../../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";

function SessionTable(props) {
  const context = useContext(awscontext);

  const getInstanceState = (instanceState) => {
    if (instanceState === "ON_GOING") {
      return <td style={{ color: "#007bff" }}>{i18n.t("LIVE")}</td>;
    } else if (instanceState === "CONFIGURABLE") {
      return <td style={{ color: "#ffc107" }}>{i18n.t("INACTIVE")}</td>;
    } else if (instanceState === "INITIALIZING") {
      return <td style={{ color: "#6c757d" }}>{i18n.t("INITIALISING")}</td>;
    } else if (instanceState === "INVALID") {
      return <td style={{ color: "#dc3545" }}>{i18n.t("INVALID")}</td>;
    } else if (instanceState === "FINISHED") {
      return <td style={{ color: "#28a745" }}>{i18n.t("FINISHED")}</td>;
    }
  };

  return (
    <table className={context.theme.table_style} id="table">
      <thead>
        <tr className={context.theme.color}>
          <th onClick={() => context.sortTableData(props.list, "sessionId")}>{i18n.t("Session_Id")}</th>
          <th onClick={() => context.sortTableData(props.list, "sessionName")}>{i18n.t("Session_Name")}</th>
          <th onClick={() => context.sortTableData(props.list, "startDateTimeUnix", "int")}>{i18n.t("Date")}</th>
          <th onClick={() => context.sortTableData(props.list, "proctorServiceType")}>{i18n.t("Proctor_Mode")}</th>
          <th onClick={() => context.sortTableData(props.list, "useLdb")}>{i18n.t("LDB")}</th>
          <th onClick={() => context.sortTableData(props.list, "examDuration", "int")}>{i18n.t("Duration_Min")}</th>
          <th onClick={() => context.sortTableData(props.list, "studentList", "int")}>{i18n.t("Test_taker_Count")}</th>
          <th onClick={() => context.sortTableData(props.list, "instanceState")}>{i18n.t("Session_Status")}</th>
        </tr>
      </thead>
      <tbody>
        {props.list
          ? props.list
            .slice(
              (props.activePage - 1) * props.rowCount,
              props.activePage * props.rowCount
            )
            .map((session, i) => (
              <tr key={i} onClick={() => props.showExamDetail(session)}>
                <td>{session.sessionId}</td>
                <td>{session.sessionName}</td>
                <td>{new Date(session.startDateTimeUnix).toDateString()}</td>
                {session.proctorServiceType ? (
                  <td>
                    {i18n.t(session.proctorServiceType.replaceAll(" ", "_"))}
                  </td>
                ) : (
                  <td></td>
                )}
                <td>{session.useLdb ? session.useLdb.toString() : ""}</td>
                <td>{session.examDuration}</td>
                <td>
                  {session.studentList ? session.studentList.length : "0"}
                </td>
                {getInstanceState(session.instanceState)}
              </tr>
            ))
          : ""}
      </tbody>
    </table>
  );
}
export default withNamespaces()(SessionTable);
