/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { useInput } from "../../../hooks/useInput";
import { useSession } from "../../../hooks/useSession";
import { awscontext } from "../../../configs/awscontext";
import axios from "axios";
import i18n from "../../../configs/i18n";
import { withNamespaces } from "react-i18next";
import FormSelect from "../../components/FormSelect";
import { EditorState, ContentState, convertToRaw, Modifier, } from "draft-js";
import RichTextEditor from "./RichTextEditor";
import config from "../../../configs/awsconfig.json";
import LoadingIcon from "../../../app/components/LoadingIcon"
import Switch from "@material-ui/core/Switch";
import { store } from "react-notifications-component";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

function EmailSettings(props) {
  const context = useContext(awscontext);
  const [inputs, setInputs] = useInput({ ...props.SelectedSession });
  const [sessions, setSession] = useSession();
  const [selectedId, setSelectedId] = useState(0);
  const initialState = () => EditorState.createEmpty();
  const [editorState, setEditorState] = useState(initialState);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [isEnable, setIsEnable] = useState(false);
  const instituteId = context.currentInstitute.id;

  const [labels, setLabels] = useState([
    { labelName: "[FIRST NAME]", labelId: "firstName" },
    { labelName: "[LAST NAME]", labelId: "lastName" },
    { labelName: "[EXAM NAME]", labelId: "examName" },
    { labelName: "[START DATE TIME]", labelId: "startDate" },
    { labelName: "[LAST START DATE TIME]", labelId: "startTime" },
    { labelName: "[END DATE TIME]", labelId: "startTime" },
    { labelName: "[DURATION]", labelId: "duration" },
    { labelName: "[ORGANIZATION NAME]", labelId: "organizationName" },
    { labelName: "[PRE-SYSTEM CHECK LINK]", labelId: "preSystemCheckLink" },
    { labelName: "[EXAM LINK]", labelId: "examLink" },
  ]);

  useEffect(() => {
    getEmailTemplates();
    renderTemplateEmail();
  }, []);

  const getEmailTemplates = async () => {
    await axios.get(`${config.api.invokeUrl}/emailTemplates/${instituteId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          Object.keys(localStorage).find((key) => key.includes("accessToken"))
        )}`,
      },
    }).then(async (response) => {
      await setEmailTemplates(response.data.sort((email1, email2) => email1.name.localeCompare(email2.name, 'en')));
      const contentBlock = htmlToDraft(response.data.sort((email1, email2) => email1.name.localeCompare(email2.name, 'en'))[0].description);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        await setEditorState(EditorState.createWithContent(contentState));
      }
      setIsEnable(response.data.sort((email1, email2) => email1.name.localeCompare(email2.name, 'en'))[0].isEnable);
    }).catch((e) => {
      console.log(e);
    });
  }

  const updateTemplate = async () => {
    const htmlFormat = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    let params = {
      description: htmlFormat,
      isEnable: isEnable
    }
    await axios.patch(
      `${config.api.invokeUrl}/emailTemplates/${instituteId}/${emailTemplates[selectedId].templateId}`,
      params,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Object.keys(localStorage).find((key) =>
              key.includes("accessToken")
            )
          )}`,
        },
      }
    )
      .then(async (response) => {
        if (response.status === 200) {
          emailTemplates[selectedId] = response.data.updatedEmail
          store.addNotification({
            title: null,
            message: response.data.statusText,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        } else {
          store.addNotification({
            title: null,
            message: response.data.statusText,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    setInputs({ ...props.SelectedSession });
    return () => setInputs("");
  }, [props.SelectedSession]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEmail = (index) => {
    setSelectedId(index);
    const contentBlock = htmlToDraft(emailTemplates[index].description);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      setEditorState(EditorState.createWithContent(contentState));
    }
    setIsEnable(emailTemplates[index].isEnable);
  }

  const insertText = (text, editorValue) => {
    const currentContent = editorValue.getCurrentContent();
    const currentSelection = editorValue.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      text
    );

    const newEditorState = EditorState.push(
      editorValue,
      newContent,
      "insert-characters"
    );
    return EditorState.forceSelection(
      newEditorState,
      newContent.getSelectionAfter()
    );
  };

  const handleChange = (event) => {
    sendTextToEditor(event.target.value);
  }

  const sendTextToEditor = (text) => {
    setEditorState(insertText(text, editorState));
  };

  const renderTemplateEmail = () => {
    return (
      <div>
        <div className="row my-3">
          <div className="col-8 pl-4" style={{ fontSize: "24px", }}>
            {emailTemplates.length === 0 ? "" : emailTemplates[selectedId].name}
          </div>
          <div className="col-4 d-flex justify-content-center align-items-center" style={{ fontSize: "15px" }}>
            <span className="d-flex">{i18n.t("Disable")} </span>
            <Switch
              checked={emailTemplates.length === 0 ? false : isEnable}
              onChange={() => setIsEnable(!isEnable)}
            />
            <span className="d-flex"> {i18n.t("Enable")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-9">
            <RichTextEditor
              editorState={editorState}
              setEditorState={setEditorState}
            />
          </div>
          <div className="col-3">
            <FormSelect
              name="selectedLabel"
              label={i18n.t("Place_Holders")}
              onChange={handleChange}
              data={labels}
              dataValue="labelName"
              dataText="labelName"
            />
          </div>
        </div>
      </div>
    );
  }

  const setBackground = (index) => {
    if (index === selectedId)
      return "list-group-item list-group-item-action buttons ";
    return "list-group-item list-group-item-action ";
  };

  const makeRender = () => {
    return (
      <div>
        <div className="row my-4">
          <div className="col-lg-3 mb-3 mb-lg-0">
            <div className="border rounded border-secondary" style={{ width: "100%", height: "100%", }}>
              <table className={context.theme.table_style} id="table">
                <thead>
                  <tr className={context.theme.color}>
                    <th>{i18n.t("Email_Templates")}</th>
                  </tr>
                </thead>
                <tbody>
                  {emailTemplates
                    .map((email, index) => (
                      <tr key={index} onClick={() => handleEmail(index)}>
                        <td className={setBackground(index)}>{email.name}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-lg-9 mt-3 mt-lg-0">
            <div className="border rounded border-secondary p-3" style={{ width: "100%", height: "100%", }}>
              {renderTemplateEmail()}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-9"></div>
          <div className="col-3 mb-4">
            <button className="btn buttons" style={{ width: "100%", }} onClick={() => { updateTemplate(); }}>
              {i18n.t("Update")}
            </button>
          </div>
        </div>
      </div>
    )
  }
  return emailTemplates.length ? makeRender() : <LoadingIcon />;
}

export default withNamespaces()(EmailSettings);