/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSearch } from "../../hooks/useSearch";
import Paginations from "./Paginations";
import SearchBar from "./SearchBar";
import i18n from "../../configs/i18n";
import { withNamespaces } from "react-i18next";

function ScreenPanel(props) {
  const [, setSearchData] = useSearch([]);
  const [searchInput, setSearchInput] = useState("");
  const [, setCol] = useState("10");
  const [maxWidth, setmaxWidth] = useState(1300);

  const searchFunction = (e) => {
    setSearchInput(e.target.value);
  };
  const searchClear = (e) => {
    setSearchInput("");
  };
  useEffect(() => {
    if (props.panels === "true") {
      const table = document.getElementById("table");
      setSearchData(table, searchInput);
    }
  }, [searchInput]); // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.col) {
      if (props.col === "12") setmaxWidth(2000);
      else if (props.col === "10") setmaxWidth(1300);
      else if (props.col === "6") setmaxWidth(700);
      setCol(props.col);
    }
  }, []); // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <div
        className={`d-flex flex-column col-${props.col} p-0 `}
        style={{ maxWidth: `${maxWidth}px`, minWidth: "300px" }}
      >
        <div className="container-fluid d-flex justify-content-center ">
          <div
            className="card mb-4 w-100 bg-white table-all "
            style={{ border: "none" }}
          >
            <div className="pt-2 p-3 pl-4 pr-4">
              <div className="p-0 d-flex flex-row text-center justify-content-between col-md-12">
                <div
                  className="d-flex flex-column p-0"
                  style={{ width: "300px" }}
                  onClick={props.onClick}
                >
                  {props.name ? <span
                    className="rounded"
                    style={{
                      textAlign: "start",
                      fontSize: "12.5pt",
                      color: "#29377e ",
                      backgroundColor: "#ebeff7",
                      border: "1px solid none",
                      padding: "10px",
                      paddingLeft: "15px",
                    }}
                  >
                    <b> {i18n.t(props.name)}</b>
                  </span> : ""}
                </div>
                {props.panels ? (
                  <React.Fragment>
                    <div className="p-0 col-md-5 ">
                      <SearchBar
                        searchFunction={searchFunction}
                        searchClear={searchClear}
                        value={searchInput}
                        {...props}
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="card-body p-0 mt-0">
              <div
                className="table-responsive p-4 mb-3"
                style={{
                  borderRadius: "10px",
                  backgroundColor: "#FEFEFE",
                  border: props.name ? "1px solid #29377e " : "1px solid #29377e ",
                }}
              >
                {props.children}
              </div>
              {props.pagination ? (
                <React.Fragment>{<Paginations {...props} />}</React.Fragment>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default withNamespaces()(ScreenPanel);
