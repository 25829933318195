import React from "react";
import i18n from "../../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
function UploadDetail(prop) {
  return (
    <div className="container-fluid">
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h2>{i18n.t("File_Details")}</h2>
        </div>
        <div className="card-body">
          <p>
            <b>{i18n.t("File_Name")}:</b> {prop.file.name}
          </p>
          <p>
            <b>{i18n.t("File_Type")}:</b> {prop.file.type}
          </p>
          <p>
            <b>{i18n.t("Size")}:</b> {prop.file.size} bytes
          </p>
          <p>
            <b>{i18n.t("Last_Modified")}: </b>
            {prop.file.lastModifiedDate.toDateString()}
          </p>
        </div>
      </div>
    </div>
  );
}
export default withNamespaces()(UploadDetail);
