/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { awscontext } from "../../configs/awscontext";
import DashboardA from "./DashboardA";
import DashboardSA from "./DashboardSA";

export default function Dashboard(props) {
  const context = useContext(awscontext);
  return (
    <React.Fragment>
      {context.userRole.includes("Super Admin") ? (
        <DashboardSA {...props} />
      ) : context.userRole.includes("Admin") ? (
        <DashboardA {...props} />
      ) : null}
    </React.Fragment>
  );
}
