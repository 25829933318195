/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { useSession } from "../../hooks/useSession";
import { useInput } from "../../hooks/useInput";
import FormInput from "../components/FormInput";
import ModalForm from "../components/ModalForm";
import FormSelect from "../components/FormSelect";
import { awscontext } from "../../configs/awscontext";
import ScreenPanel from "../components/ScreenPanel";
import i18n from "../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Modal } from "react-bootstrap";
import { FaRecordVinyl } from "react-icons/fa";
import { Tab, Tabs } from "react-bootstrap-tabs";
import uniqid from "uniqid";
import { store } from "react-notifications-component";
import { input } from "aws-amplify";

const sessionModel = require("../../models/modelSession.json");
const variables = require("../../models/variables.json");

function SessionTask(props) {
  const context = useContext(awscontext);
  const [inputs, setInputs] = useInput(
    props.location.pathname.includes("generalSettings")
      ? { ...context.currentInstitute }
      : props.location.state
        ? { ...props.location.state.selectedSession }
        : {
          ...sessionModel,
          sessionId: uniqid(),
          mail: false,
          instituteId: context.userInstituteId,
          instituteName: context.userInstituteName,
          proctorServiceType: variables.ProctorServiceTypes[0].value,
          onboardingProcess: variables.onboardingProcess[0].value,
        }
  );
  const [state, setState] = useState({
    useLdb: false,
    proctorRecording: false,
    mail: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [btnSaveTitle, setBtnSaveTitle] = useState("");
  const [, setSession] = useSession([]);
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const unix10Min = 600000;
  const unix15Min = 900000;
  const unix2Hour = 7200000;

  useEffect(() => {
    let unix10MinAheadFromNow = Date.now() + unix10Min;
    let unix25MinAheadFromNow = Date.now() + unix10Min + unix15Min;
    let unix2Hour10MinAheadFromNow = Date.now() + unix2Hour + unix10Min;
    setInputs({
      ...inputs,
      startDateTimeUnix: unix10MinAheadFromNow,
      endDateTimeUnix: unix2Hour10MinAheadFromNow,
      examDuration: 120,
    });

    if (props.location.pathname.includes("createSession")) {
      context.setAdres("createSession");
      context.setBarGroup("EXAMS");
      setTitle("Create_Session");
      setBtnSaveTitle("Create_Session");
    }

    if (props.location.pathname.includes("deleteSession")) {
      context.setAdres("deleteSession");
      context.setBarGroup("EXAMS");
      setTitle("Delete");
      setBtnSaveTitle("Delete");
    }
    return () => setInputs("");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSwitchCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleCreateSession = async (e) => {
    e.preventDefault();
    if (props.location.pathname.includes("createSession")) {
      if (!inputs.startDateTimeUnix) {
        store.addNotification({
          title: null,
          message: i18n.t("Please_enter_a_valid_start_date_and_time"),
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      } else if (!inputs.endDateTimeUnix) {
        store.addNotification({
          title: null,
          message: i18n.t("Please_enter_a_valid_end_date_and_time"),
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
      // else if (!inputs.lastStartDateTimeUnix) {
      //   store.addNotification({
      //     title: null,
      //     message: i18n.t("Please_enter_a_valid_last_start_date_and_time"),
      //     type: "danger",
      //     insert: "top",
      //     container: "top-right",
      //     animationIn: ["animate__animated", "animate__fadeIn"],
      //     animationOut: ["animate__animated", "animate__fadeOut"],
      //     dismiss: {
      //       duration: 5000,
      //       onScreen: true,
      //     },
      //   });
      // }
      else if (
        (inputs.startDateTimeUnix - new Date().getTime()) / 60000 <
        5
      ) {
        store.addNotification({
          title: null,
          message: i18n.t("The_start_date_has_to_be_5_minutes_ahead"),
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      } else if (inputs.endDateTimeUnix <= inputs.startDateTimeUnix) {
        store.addNotification({
          title: null,
          message: i18n.t(
            "Please_enter_a_valid_end_time_The_provided_input_cannot_be_less_than_the_start_time"
          ),
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      } else if (inputs.endDateTimeUnix <= inputs.lastStartDateTimeUnix) {
        store.addNotification({
          title: null,
          message: i18n.t(
            "Please_enter_a_valid_end_time_The_provided_input_cannot_be_less_than_the_last_start_time"
          ),
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      } else if (inputs.lastStartDateTimeUnix < inputs.startDateTimeUnix) {
        store.addNotification({
          title: null,
          message: i18n.t(
            "Please_enter_a_valid_last_start_time_The_provided_input_cannot_be_less_than_the_last_start_time"
          ),
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      } else {
        inputs.sessionName = inputs.sessionName.trim();
        inputs.examUrl = inputs.examUrl.trim();
        inputs.useLdb = state.useLdb;
        inputs.mail = state.mail;
        // Last Start Time Setted End Time
        if (!inputs.lastStartDateTimeUnix) {
          inputs.lastStartDateTimeUnix = inputs.endDateTimeUnix - 100;
        }
        else {
          if (inputs.lastStartDateTimeUnix === inputs.endDateTimeUnix) {
            inputs.lastStartDateTimeUnix = inputs.lastStartDateTimeUnix - 100;
          }
        }

        if (inputs.useLdb) inputs.iframe = true;
        inputs.proctorRecording = state.proctorRecording;
        if (!appropriateSessionProctoringType(inputs.proctorRecording)) {
          inputs.onboardingProcess = variables.onboardingProcess[0].value;
        }
        if (inputs.proctorServiceType === "Record_and_Review_Lite" && inputs.proctorServiceType === "Live_Proctoring_Lite") {
          inputs.isAIactive = true;
        }
        inputs.allowedOutsourceCamera = parseInt(inputs.allowedOutsourceCamera);
        if (inputs.proctorServiceType === "Live_Proctoring_Plus") {
          inputs.systemCheck.mobileCameraUsage = true;
        }
        console.log("Session eklenmeden önce", inputs)
        await setSession("post", inputs);
        props.history.push({
          pathname: "/sessionList",
          state: { status: "CREATED_NEW", sessionName: inputs.sessionName },
        });
      }
    }
  };

  const handleClose = () => {
    setShow(false);
    setShowDelete(false);
  };

  const handleDeleteConfirm = () => {
    setShowDelete(false);
    handleCreateSession();
  };

  const constructProctoringModeModalData = () => {
    const proctoringRender = [];
    for (const proctoringType of variables.ProctorServiceTypes) {
      proctoringRender.push(
        <div
          key={proctoringType}
          className="d-flex flex-row justify-content-center align-items-center text-center"
          style={{
            paddingBottom: 10,
            paddingTop: 10,
            border: "1px blue solid",
          }}
          onClick={(e) => {
            setInputs((prevInputs) => ({
              ...prevInputs,
              proctorServiceType: e.target.id,
            }));
            setShowModal(false);
          }}
        >
          <FaRecordVinyl color="green" size={15} style={{ margin: 10 }} />
          <div id={proctoringType.value}>{i18n.t(proctoringType.value)}</div>
        </div>
      );
    }
    return proctoringRender;
  };

  const appropriateSessionProctoringType = (proctorServiceType) => {
    if (
      proctorServiceType === "Record_and_Review" ||
      proctorServiceType === "Record_and_Review_Lite" ||
      proctorServiceType === "Offline"
    ) {
      return false;
    } else {
      return true;
    }
  };

  const calculateLocalDateTime = (timestamp) => {
    const currentDate = new Date(timestamp ? timestamp : new Date().getTime());

    let currentMonth;
    if (currentDate.getMonth() + 1 < 10) {
      currentMonth = `0${currentDate.getMonth() + 1}`;
    } else {
      currentMonth = `${currentDate.getMonth() + 1}`;
    }
    let currentDay;
    if (currentDate.getDate() < 10) {
      currentDay = `0${currentDate.getDate()}`;
    } else {
      currentDay = currentDate.getDate();
    }
    let currentHour;
    if (currentDate.getHours() < 10) {
      currentHour = `0${currentDate.getHours()}`;
    } else {
      currentHour = currentDate.getHours();
    }
    let currentMinute;
    if (currentDate.getMinutes() < 10) {
      currentMinute = `0${currentDate.getMinutes()}`;
    } else {
      currentMinute = currentDate.getMinutes();
    }
    return [
      `${currentDate.getFullYear()}-${currentMonth}-${currentDay}`,
      `${currentHour}:${currentMinute}`,
    ];
  };

  const calculateUnixTimeFromTime = (inputDate, inputTime) => {
    const dateArray = inputDate.split("-");
    const timeArray = inputTime.split(":");
    const offsetHourDifference = parseInt(new Date().getTimezoneOffset() / 60);
    const offsetMinuteDifference =
      new Date().getTimezoneOffset() - offsetHourDifference * 60;
    const timeHour = parseInt(timeArray[0]) + offsetHourDifference;
    const timeMinute = parseInt(timeArray[1]) + offsetMinuteDifference;
    return new Date(
      Date.UTC(
        dateArray[0],
        parseInt(dateArray[1]) - 1,
        dateArray[2],
        timeHour,
        timeMinute,
        0
      )
    ).getTime();
  };

  const timeOnChange = (timestamp, value, inputType, inputKey) => {
    let unix;
    if (inputType === "date") {
      const currentTime = calculateLocalDateTime(timestamp)[1];
      unix = calculateUnixTimeFromTime(value, currentTime);
    } else if (inputType === "time") {
      const currentDate = calculateLocalDateTime(timestamp)[0];
      unix = calculateUnixTimeFromTime(currentDate, value);
    }
    let examDuration = inputs.examDuration;
    if (inputKey === "endDateTimeUnix" || inputKey === "sessionEndDate") {
      examDuration = calculateDuration(inputs.startDateTimeUnix, unix);
    } else if (inputKey === "startDateTimeUnix" || inputKey === "sessionDate") {
      examDuration = calculateDuration(unix, inputs.endDateTimeUnix);
    }
    if (
      inputKey === "startDateTimeUnix" &&
      !inputs.lastStartDateTimeUnix &&
      !inputs.endDateTimeUnix
    ) {
      setInputs({
        ...inputs,
        examDuration,
        [inputKey]: unix,
        lastStartDateTimeUnix: unix,
        endDateTimeUnix: unix,
      });
    } else {
      setInputs({ ...inputs, examDuration, [inputKey]: unix });
    }
  };

  const durationOnChange = (value) => {
    const unixDifference = value * 60 * 1000;
    const endDateTimeUnix = inputs.startDateTimeUnix + unixDifference;
    setInputs({ ...inputs, endDateTimeUnix, examDuration: value });
  };

  const calculateDuration = (startUnix, endUnix) => {
    let unixStartMinBase = Math.floor(startUnix / 60 / 1000);
    let unixEndMinBase = Math.floor(endUnix / 60 / 1000);
    return unixEndMinBase - unixStartMinBase;
  };

  const sessionInformationContent = () => {
    return (
      <form className="row" onSubmit={handleCreateSession}>
        <FormInput
          col="5"
          name="sessionName"
          text={i18n.t("Session_Name")}
          value={inputs.sessionName}
          onChange={setInputs}
          type="text"
          required={true}
        />

        <FormSelect
          col="4"
          name="proctorServiceType"
          label={i18n.t("Proctor_Mode")}
          onChange={setInputs}
          value={inputs.proctorServiceType}
          data={variables.ProctorServiceTypes}
          dataValue="value"
          dataText="value"
          //dataText="text"
          required={true}
        />
        {appropriateSessionProctoringType(inputs.proctorServiceType) ? (
          <div className="form-group col-1" id="proctorRecording">
            <label>{i18n.t("Proctor_Recording")}</label>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={state.proctorRecording}
                  onChange={handleSwitchCheck}
                  name="proctorRecording"
                />
              }
              labelPlacement="top"
            />
          </div>
        ) : null}

        <div className="form-group col-1">
          <label>LDB</label>
          <FormControlLabel
            value="end"
            control={
              <Switch
                color="primary"
                checked={state.useLdb}
                onChange={handleSwitchCheck}
                name="useLdb"
              />
            }
            labelPlacement="end"
          />
        </div>

        {/* <div className="form-group col-1">
          <label>Mail</label>
          <FormControlLabel
            value="end"
            control={
              <Switch
                color="primary"
                checked={state.mail}
                onChange={handleSwitchCheck}
                name="mail"
              />
            }
            labelPlacement="end"
          />
        </div> */}

        <FormInput
          col="3"
          name="sessionDate"
          text={i18n.t("Start_Date")}
          value={calculateLocalDateTime(inputs.startDateTimeUnix)[0]}
          onChange={(e) =>
            timeOnChange(
              inputs.startDateTimeUnix,
              e.target.value,
              "date",
              "startDateTimeUnix"
            )
          }
          type="date"
          required={true}
        />
        <FormInput
          col="3"
          name="sessionEndDate"
          text={i18n.t("End_Date")}
          value={calculateLocalDateTime(inputs.endDateTimeUnix)[0]}
          onChange={(e) =>
            timeOnChange(
              inputs.endDateTimeUnix,
              e.target.value,
              "date",
              "endDateTimeUnix"
            )
          }
          type="date"
          required={true}
          disabled={inputs.startDateTimeUnix ? false : true}
        />

        {state.useLdb ? null : (
          <FormSelect
            col="2"
            name="iframe"
            label={i18n.t("Select_Frame_Type")}
            onChange={setInputs}
            value={inputs.iframe}
            data={variables.IFrame}
            dataValue="value"
            dataText="text"
            required={true}
          />
        )}

        {appropriateSessionProctoringType(inputs.proctorServiceType) ? (
          <FormSelect
            col="3"
            name="onboardingProcess"
            label={i18n.t("Onboarding_Process")}
            onChange={setInputs}
            value={inputs.onboardingProcess}
            data={variables.onboardingProcess}
            dataValue="value"
            dataText="text"
            required={true}
          />
        ) : null}

        {inputs.proctorServiceType === "Live_Proctoring_Plus" ? (
          <FormSelect
            col="1"
            name="allowedOutsourceCamera"
            label={i18n.t("Number_Of_Mobile_Cam")}
            onChange={setInputs}
            value={inputs.allowedOutsourceCamera}
            data={variables.allowedOutsourceCamera}
            dataValue="value"
            dataText="text"
            required={true}
          />
        ) : null}

        <div className="col-md-12 row">
          <FormInput
            col="3"
            name="startTime"
            text={i18n.t("Start_Time")}
            value={calculateLocalDateTime(inputs.startDateTimeUnix)[1]}
            onChange={(e) =>
              timeOnChange(
                inputs.startDateTimeUnix,
                e.target.value,
                "time",
                "startDateTimeUnix"
              )
            }
            type="time"
            required={true}
          />
          <FormInput
            col="3"
            name="lastStartTime"
            text={i18n.t("Last_Start_Time")}
            value={inputs.lastStartDateTimeUnix ? calculateLocalDateTime(inputs.lastStartDateTimeUnix)[1] : ''}
            onChange={(e) =>
              timeOnChange(
                inputs.startDateTimeUnix,
                e.target.value,
                "time",
                "lastStartDateTimeUnix"
              )
            }
            type="time"
            disabled={inputs.startDateTimeUnix ? false : true}
          />
          <FormInput
            col="3"
            name="endTime"
            text={i18n.t("End_Time")}
            value={calculateLocalDateTime(inputs.endDateTimeUnix)[1]}
            onChange={(e) =>
              timeOnChange(
                inputs.startDateTimeUnix,
                e.target.value,
                "time",
                "endDateTimeUnix"
              )
            }
            type="time"
            required={true}
            disabled={inputs.startDateTimeUnix ? false : true}
          />
          <FormInput
            col="3"
            name="examDuration"
            text={i18n.t("Duration_Min")}
            value={inputs.examDuration}
            onChange={(e) => durationOnChange(e.target.value)}
            type="number"
            disabled={
              inputs.startDateTimeUnix && inputs.endDateTimeUnix ? false : true
            }
          />
        </div>
        <FormInput
          col="12"
          name="examUrl"
          text={i18n.t("Exam_URL")}
          value={inputs.examUrl}
          onChange={setInputs}
          type="text"
          required={true}
        />
        <div className="col">
          <button className={context.theme.btn_save} type="Submit">
            {i18n.t(btnSaveTitle)}
          </button>
        </div>
      </form>
    );
  };

  const systemCheckInformationContent = () => {
    return (
      <React.Fragment>
        <div className="col">
          <FormControlLabel
            name="systemCheck.mobileCameraUsage"
            control={
              <Switch
                checked={inputs.systemCheck.mobileCameraUsage}
                onChange={(e) =>
                  setInputs(e, !inputs.systemCheck.mobileCameraUsage)
                }
              />
            }
            label={i18n.t("Mobile_Camera_Usage")}
            color="primary"
          />
        </div>
        <div className="col">
          <FormControlLabel
            name="systemCheck.additionalDisplayCheck"
            control={
              <Switch
                checked={inputs.systemCheck.additionalDisplayCheck}
                onChange={(e) =>
                  setInputs(e, !inputs.systemCheck.additionalDisplayCheck)
                }
              />
            }
            label={i18n.t("Additional_Display_Check")}
            color="primary"
          />
        </div>
        <div className="col">
          <FormControlLabel
            name="systemCheck.toiletBreakPermission"
            control={
              <Switch
                checked={inputs.systemCheck.toiletBreakPermission}
                onChange={(e) =>
                  setInputs(e, !inputs.systemCheck.toiletBreakPermission)
                }
              />
            }
            label={i18n.t("Toilet_Break_Permission")}
            color="primary"
          />
        </div>
        <div className="col">
          <FormControlLabel
            name="systemCheck.preExamMaterialCheck"
            control={
              <Switch
                checked={inputs.systemCheck.preExamMaterialCheck}
                onChange={(e) =>
                  setInputs(e, !inputs.systemCheck.preExamMaterialCheck)
                }
              />
            }
            label={i18n.t("Pre_Exam_Material_Check")}
            color="primary"
          />
        </div>
        <div className="col">
          <FormControlLabel
            name="systemCheck.postExamRoomScan"
            control={
              <Switch
                checked={inputs.systemCheck.postExamRoomScan}
                onChange={(e) =>
                  setInputs(e, !inputs.systemCheck.postExamRoomScan)
                }
              />
            }
            label={i18n.t("Post_Exam_Room_Scan")}
            color="primary"
          />
        </div>
        <div className="col">
          <FormControlLabel
            name="systemCheck.postExamMaterialCheck"
            control={
              <Switch
                checked={inputs.systemCheck.postExamMaterialCheck}
                onChange={(e) =>
                  setInputs(e, !inputs.systemCheck.postExamMaterialCheck)
                }
              />
            }
            label={i18n.t("Post_Exam_Material_Check")}
            color="primary"
          />
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <ScreenPanel name={title} col="9">
        <Tabs>
          <Tab label={i18n.t("Session_Information")} style={{ marginTop: 15 }}>
            {sessionInformationContent()}
          </Tab>
          <Tab
            label={i18n.t("System_Check_Information")}
            style={{ marginTop: 15 }}
          >
            {systemCheckInformationContent()}
          </Tab>
        </Tabs>
      </ScreenPanel>
      <ModalForm show={show} handleClose={handleClose} />
      <ModalForm
        show={showDelete}
        handleClose={handleClose}
        handleDelete={handleDeleteConfirm}
        Title={i18n.t("Confirm_Delete")}
        Body={i18n.t("Are_you_sure_you_want_to_delete_this_item?")}
      />
    </React.Fragment>
  );
}
export default withNamespaces()(SessionTask);
