/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { withNamespaces } from "react-i18next";
import { awscontext } from "../configs/awscontext";

function SystemCheck(props) {
  const context = useContext(awscontext);
  const configuration = props.configuration;
  const selectedSession = props.selectedSession;
  const [currentStep, setCurrentStep] = useState(0);
  const [currentElement, setCurrentElement] = useState(
    configuration[currentStep]
  );
  const [count, setCount] = useState(0);

  useEffect(() => {
    context.setAdres("proctoring");
    context.setBarGroup("PROCTOR");
  }, []);

  const buttonHandler = () => {
    const newCurrentStep = currentStep + 1;
    if (newCurrentStep === configuration.length) {
      props.history.push(`/proctor/${selectedSession.sessionId}`);
      props.setPageState(2);
    } else {
      setCurrentStep(newCurrentStep);
    }
  };

  useEffect(() => {
    setCurrentElement(configuration[currentStep]);
  }, [currentStep]);

  const handleRepeatClick = () => {
    setCount((prevCount) => prevCount + 1);
  };

  return (
    <div
      className="d-flex flex-column justify-content-start align-items-center text-center currentSetupContent"
      key={count}
    >
      <currentElement.render
        count={count}
        next={currentElement.next}
        prev={currentElement.prev}
        currentStep={currentStep}
        buttonHandler={buttonHandler}
        information={props.information}
        handleRepeatClick={handleRepeatClick}
        selectedDevice={props.selectedDevice}
        setSelectedDevice={props.setSelectedDevice}
        configuration={props.configuration}
        settings={props.settings}
        setSettings={props.setSettings}
      />
    </div>
  );
}
export default withNamespaces()(SystemCheck);
