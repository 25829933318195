import React, { useState, useEffect, useContext } from "react";
import { awscontext } from "../../../configs/awscontext";
import { useStudentResult } from "../../../hooks/useStudentResult";
import { useSession } from "../../../hooks/useSession";
import config from "../../../configs/awsconfig.json";
import { FaNotEqual, FaCheckCircle, FaCommentSlash } from "react-icons/fa";
import i18n from "../../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import { Tab, Tabs } from "react-bootstrap-tabs";
import CalculateAiMessage from "./CalculateAiMessage";



function ResultView(props) {
  const [configuredStudentResult, setConfiguredStudentResult] = useState([]);
  const [, setCommentSave] = useStudentResult("");
  const [, setSession] = useSession();
  const context = useContext(awscontext);
  const studentResult = props.studentResult;
  const meetingInformation = props.meetingInformation;

  useEffect(() => {
    if (studentResult && studentResult.comments) {
      const commentedMoments = studentResult.comments;
      commentedMoments.forEach(() => {
        configuredStudentResult.color = findColor(
          configuredStudentResult.result
        );
      });
      setConfiguredStudentResult(commentedMoments);
    }
  }, [studentResult]); // eslint-disable-line react-hooks/exhaustive-deps

  const sendResultResult = async (i, time, confirmationStatus, result) => {

    let prevDesicion = configuredStudentResult[i].confirmationStatus;
    configuredStudentResult[i].confirmationStatus = confirmationStatus;
    const params = {
      meetingId: `${meetingInformation[0].sessionId}${meetingInformation[0].studentId}`,
      time,
      confirmationStatus,
      type: "CONFIRM_COMMENT",
    };


    let studentId = studentResult.meetingId.substring(8);
    const aiParams = {
      sessionId: context.selectedSession.sessionId,
      studentId: studentId,
      instituteId: context.selectedSession.instituteId,
      section: "EVALUATE_AI_COMMENTS",
      comment: result.text,
      desicion: confirmationStatus,
      prevDesicion
    }
    let response = {
      statusCode: 0,
      body: null,
    };
    response = await setSession("patchAIScore", aiParams, "EVALUATE_AI_COMMENTS", response);
    let student = props.selectedStudent;
    await setCommentSave("UPDATE", params);


    props.retrieveDefaultStudentResults();
  };

  const findColor = (result) => {
    if (result === "Accepted") {
      return "text-success";
    } else if (result === "Refused") {
      return "text-danger";
    } else if (result === "No Comment") {
      return "text-warning";
    } else {
      return "";
    }
  };

  const millisToMinutesAndSeconds = (millis) => {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    //ES6 interpolated literals/template literals
    //If seconds is less than 10 put a zero in front.
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const calculateTimeDifference = (time, source, meetingInformation) => {
    let startTime;
    for (const meetingInfo of meetingInformation) {
      if (meetingInfo.meetingId.includes(source)) {
        startTime = meetingInfo.startTime;
        break;
      }
    }
    if (startTime) {
      const difference = parseInt(time) - parseInt(startTime);
      return [difference / 1000, millisToMinutesAndSeconds(difference)];
    } else {
      return [0, 0];
    }
  };

  const getWhoCommented = (result) => {
    if (result.type === "AI") {
      return "AI";
    } else {
      return result.proctorFullName;
    }
  };

  const getCommentText = (result) => {
 
    
    if (result.type === "AI") {
      var ml_finding = result.text
      try {
        ml_finding = JSON.parse(result.text);
      } catch (err) {
        console.log(err);
      }
      var obj = new CalculateAiMessage(ml_finding,result);
   
      return i18n.t(obj.calculateAiMessage())

      // if (ml_finding["DETECTED_FACE"] === "FAILED") {
      //   if (ml_finding["DETECTED_FACE_DESC"] === "0" || ml_finding["DETECTED_FACE_DESC"] === 0) {
      //     return i18n.t("Test_taker_not_found_in_the_current_frame");
      //   } else if (ml_finding["DETECTED_FACE_DESC"] > "1") {
      //     return i18n.t("Someone_unknown_is_with_the_test_taker");
      //   } else if (ml_finding["TEMPLATE_MATCHING"] === "FAILED") {
      //     return i18n.t("Test_taker_does_not_match_with_the_face_photo");
      //   } else if (ml_finding["DETECTED_CELLPHONE"] === "SUCCESS") {
      //     return i18n.t("Usage_of_phone");
      //   }
      // } else if (ml_finding["EXAM_PAGE_LOST"] === "SUCCESS") {
      //   return i18n.t("Exam_page_lost");
      // } else if (ml_finding["VOICE_DETECTED"] === "SUCCESS") {
      //   return i18n.t("Voice_detected");
      // } else if (
      //   ml_finding["LEFT_EYE_GAZE_DIRECTION"] === "FAILED" ||
      //   ml_finding["RIGHT_EYE_GAZE_DIRECTION"] === "FAILED"
      // ) {
      //   return i18n.t("Test_taker_eye_gaze_direction_is_not_correct");
      // }
    } else {
      if (result.text === "Other...") {
        return result.otherText;
      } else {
        return result.text;
      }
    }
  };

  return (
    <Tabs>
      <Tab label={"Comments"}>
        <div className="d-flex flex-column">
          {configuredStudentResult
            .filter((result) => result.type !== "AI")
            .map((result, i) => (
              <div
                key={i}
                style={{
                  border: "1px solid rgb(229, 229, 229)",
                  marginBottom: 5,
                  paddingBottom: 2,
                }}
              >
                {result.image ? (
                  <img
                    onClick={() =>
                      props.event(
                        calculateTimeDifference(
                          result.time,
                          result.source,
                          meetingInformation
                        )[0]
                      )
                    }
                    style={{ width: "100%" }}
                    alt={``}
                    src={`${config.api.streamS3}/${result.image}`}
                  />
                ) : null}
                <span
                  onClick={() =>
                    props.event(
                      calculateTimeDifference(
                        result.time,
                        result.source,
                        meetingInformation
                      )[0]
                    )
                  }
                >
                  {/* <span className="font-weight-bold">{result.type}</span>,{" "} */}
                  {new Date(result.time).toDateString()}, @&nbsp;
                  {
                    calculateTimeDifference(
                      result.time,
                      result.source,
                      meetingInformation
                    )[1]
                  }
                  <div>
                    <span className="font-weight-bold">
                      {getWhoCommented(result)}
                    </span>
                    : {getCommentText(result)}
                  </div>
                </span>
                {result.proctor === context.user.email ? null : (
                  <div className="d-flex flex-row justify-content-center align-items-center text-center  mt-1">
                    <div className="col-md-4 p-0" style={{ margin: "auto" }}>
                      <div
                        className={`btn border ${result.confirmationStatus === "Accepted"
                          ? "btn-danger"
                          : "text-secondary"
                          }`}
                        onClick={() =>
                          sendResultResult(i, result.time, "Accepted", result)
                        }
                      >
                        <FaCheckCircle size={20} />
                      </div>
                    </div>
                    <div
                      className="d-flex justify-content-center align-items-center col-md-4 p-0"
                      style={{ margin: "auto" }}
                    >
                      <div
                        className={`btn border ${result.confirmationStatus === "Refused"
                          ? "btn-success"
                          : "text-secondary"
                          }`}
                        onClick={() =>
                          sendResultResult(i, result.time, "Refused", result)
                        }
                      >
                        <FaNotEqual size={20} />
                      </div>
                    </div>
                    <div
                      className="col-md-4 p-0"
                      style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                        textAlign: "center",
                      }}
                    >
                      {result.type === "Proctor" ? (
                        i18n.t("Escalated_from_Proctor")
                      ) : (
                        <div
                          to="#"
                          className={`btn border ${result.confirmationStatus === "No Comment"
                            ? "btn-warning"
                            : "text-secondary"
                            }`}
                          onClick={() =>
                            sendResultResult(i, result.time, "No Comment", result)
                          }
                        >
                          <FaCommentSlash size={20} />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      </Tab>
      <Tab label={"Incidents"}>
        <div className="d-flex flex-column">
          {configuredStudentResult
            .filter((result) => result.type === "AI")
            .map((result, i) => (
              <div
                key={i}
                style={{
                  border: "1px solid rgb(229, 229, 229)",
                  marginBottom: 5,
                  paddingBottom: 2,
                }}
              >
                {result.image ? (
                  <img
                    onClick={() =>
                      props.event(
                        calculateTimeDifference(
                          result.time,
                          result.source,
                          meetingInformation
                        )[0]
                      )
                    }
                    style={{ width: "100%" }}
                    alt={``}
                    src={`${config.api.streamS3}/${result.image === "voice" ? "AiIcons/voice.png" : result.image === "pagelost" ? "AiIcons/pagechange.webp" : result.image}`}
                  />
                ) : null}
                <span
                  onClick={() =>
                    props.event(
                      calculateTimeDifference(
                        result.time,
                        result.source,
                        meetingInformation
                      )[0]
                    )
                  }
                >
                  {/* <span className="font-weight-bold">{result.type}</span>,{" "} */}
                  {new Date(result.time).toDateString()}, @&nbsp;
                  {
                    calculateTimeDifference(
                      result.time,
                      result.source,
                      meetingInformation
                    )[1]
                  }
                  <div>
                    <span className="font-weight-bold">
                      {getWhoCommented(result)}
                    </span>
                    : {getCommentText(result)}
                  </div>
                </span>
                {result.proctor === context.user.email ? null : (
                  <div className="d-flex flex-row justify-content-center align-items-center text-center  mt-1">
                    <div className="col-md-4 p-0" style={{ margin: "auto" }}>
                      <div
                        className={`btn border ${result.confirmationStatus === "Accepted"
                          ? "btn-danger"
                          : "text-secondary"
                          }`}
                        onClick={() =>
                          sendResultResult(i, result.time, "Accepted", result)
                        }
                      >
                        <FaCheckCircle size={20} />
                      </div>
                    </div>
                    <div
                      className="d-flex justify-content-center align-items-center col-md-4 p-0"
                      style={{ margin: "auto" }}
                    >
                      <div
                        className={`btn border ${result.confirmationStatus === "Refused"
                          ? "btn-success"
                          : "text-secondary"
                          }`}
                        onClick={() =>
                          sendResultResult(i, result.time, "Refused", result)
                        }
                      >
                        <FaNotEqual size={20} />
                      </div>
                    </div>
                    <div
                      className="col-md-4 p-0"
                      style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                        textAlign: "center",
                      }}
                    >
                      {result.type === "Proctor" ? (
                        i18n.t("Escalated_from_Proctor")
                      ) : (
                        <div
                          to="#"
                          className={`btn border ${result.confirmationStatus === "No Comment"
                            ? "btn-warning"
                            : "text-secondary"
                            }`}
                          onClick={() =>
                            sendResultResult(i, result.time, "No Comment", result)
                          }
                        >
                          <FaCommentSlash size={20} />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      </Tab>
    </Tabs>
  );
}
export default withNamespaces()(ResultView);
