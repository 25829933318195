import { useState } from "react";
import axios from "axios";
import i18n from "../../src/configs/i18n";
import { useStorage } from "../hooks/useStorage";
import translationEN from "../configs/en.json";
const config = require("../configs/awsconfig.json");
const configI18 = require("../../src/configs/i18n");
export const useLanguage = () => {
  const [result, setResult] = useState("");
  const [, , , getBaseUrl] = useStorage(config.s3.files);
  const handleChange = async (type, lang) => {
    if (type === "post") {
      const paramsLang = {
        Language: {
          langCode: lang.isoCode,
          langName: lang.Name,
        },
      };
      try {
        await axios.post(`${config.api.invokeUrl}/languages`, paramsLang, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        });
        await axios.get(`${config.api.invokeUrl}/languages`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        });
      } catch (err) {

        configI18.default.options.resources = {};
        configI18.default.store.data = {};
        const params = {
          translation: {},
        }; 
        params.translation = translationEN;
        localStorage.setItem("state", JSON.stringify(params));
        configI18.default.options.resources["en"] = params;
        configI18.default.store.data["en"] = params;
        i18n.changeLanguage("en");
        localStorage.setItem("lang", "en");
        localStorage.setItem("i18nextLng", "en");

        console.log(`Unable to API: ${err}`);
      }
      setResult(result);
    } else if (type === "get") {
      configI18.default.options.resources = {};
      configI18.default.store.data = {};
      const params = {
        translation: {},
      };

      try {
        await axios.get(`${config.api.invokeUrl}/languages`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        });
        let key = localStorage.getItem("i18nextLng");
        if (key == null) {
          key = "en";
        }
        try {
          await axios
            .get(`${getBaseUrl()}/assets/languages/${key}.json`)
            .then((res) => {
              params.translation = res.data;
              localStorage.setItem("state", JSON.stringify(params));
              configI18.default.options.resources[key] = params;
              configI18.default.store.data[key] = params;

              i18n.changeLanguage(key);
              localStorage.setItem("lang", key);
              localStorage.setItem("i18nextLng", key);
            });
        } catch {
          params.translation = translationEN;
          localStorage.setItem("state", JSON.stringify(params));
          configI18.default.options.resources["en"] = params;
          configI18.default.store.data["en"] = params;
          i18n.changeLanguage("en");
          localStorage.setItem("lang", "en");
          localStorage.setItem("i18nextLng", "en");
        }
      } catch (err) {
        console.log(`Unable to API: ${err}`);
      }
      setResult(result);
    }
  };
  return [result, handleChange];
};
