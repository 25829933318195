import { useState, useContext } from "react";
import axios from "axios";
import { awscontext } from "../configs/awscontext";
import { Auth } from "aws-amplify";
import uniqid from "uniqid";
const config = require("../configs/awsconfig.json");

export const useStaff = () => {
  const context = useContext(awscontext);
  const [result, setResult] = useState([]);
  const getData = async (params) => {
    let instituteId = context.userInstituteId;
    if (params) {
      instituteId = params.instituteId;
    }
    try {
      const res = await axios.get(
        `${config.api.invokeUrl}/institutes/${instituteId}/users`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      const staffs = res.data;
      context.setStaff(staffs);
      const proctors = [];
      staffs.forEach((staff) => {
        staff.roles.forEach((role) => {
          if (role.role.includes("Proctor")) {
            proctors.push(staff);
          }
        });
      });

      context.setProctorList(proctors);
      setResult("success");
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };

  const updateStaff = async (params) => {
    try {
      const updateparamsDynamo = {
        name: params.name,
        lastname: params.lastname,
        email: params.email,
        username: params.username,
        status: params.status,
        roles: params.roles,
        institutes: params.institutes,
      };
      await axios.patch(
        `${config.api.invokeUrl}/staffs/${params.username}`,
        updateparamsDynamo,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
    try {
      let UserAttributes = [
        {
          Name: "name",
          Value: `${params.name}`,
        },
        {
          Name: "nickname",
          Value: `${params.lastname}`,
        },
        {
          Name: "profile",
          Value: `${params.role}`,
        },
        {
          Name: "gender",
          Value: `${params.status}`,
        },
        {
          Name: "address",
          Value: `${params.institutes[0].instituteId}`,
        },
        {
          Name: "zoneinfo",
          Value: `${params.institutes[0].instituteName}`,
        },
      ];

      const updateParams = {
        UserAttributes: UserAttributes,
        Username: params.username,
      };
      await axios.patch(
        `${config.api.invokeUrl}/institutes/${context.userInstituteId}/users/${params.username}`,
        updateParams,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      await getData({ instituteId: params.institutes[0].instituteId });
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };
  const addStaff = async (params) => {
    let varMiLang = "en";
    for (let index = 0; index < context.instituteList.length; index++) {
      if (context.instituteList[index].id === params.instituteId) {
        varMiLang = context.instituteList[index].language;
      }
    }
    try {
      await axios
        .get(`${config.api.invokeUrl}/emailcheck/${params.email}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        })
        .then(async (res) => {
          if (!res.data) {
            let newDynamoUser = {};
            newDynamoUser.name = params.name;
            newDynamoUser.lastname = params.lastname;
            newDynamoUser.email = params.email;
            newDynamoUser.username = params.username;
            newDynamoUser.status = "ACTIVE";
            newDynamoUser.roles = params.roles;
            newDynamoUser.institutes = params.institutes;
            const paramNewUser = {
              staff: newDynamoUser,
            };
            await axios.post(`${config.api.invokeUrl}/staffs`, paramNewUser, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  Object.keys(localStorage).find((key) =>
                    key.includes("accessToken")
                  )
                )}`,
              },
            });
            await Auth.signUp({
              username: params.username,
              password: uniqid(),
              attributes: {
                email: params.email,
                name: params.name,
                nickname: params.lastname,
                zoneinfo: params.institutes[0].instituteName,
                address: params.institutes[0].instituteId,
                family_name: params.institutes[0].instituteId,
                gender: params.status,
                profile: params.role,
                locale: varMiLang,
              },
            });
          } else {
            alert(
              "\n The user could not be created!\n \n This email address is already in use."
            );
          }
        });
      await getData({ instituteId: params.institutes[0].instituteId });
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };

  const deleteStaff = async (params) => {
    try {
      await axios.delete(
        `${config.api.invokeUrl}/institutes/${params.institutes[0].instituteId}/users/${params.username}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      await getData({ instituteId: params.institutes[0].instituteId });
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };

  const lang = async (params) => {
    try {
      let UserAttributes = [
        {
          Name: "locale",
          Value: `${params.language}`,
        },
      ];
      const updateParams = {
        UserAttributes: UserAttributes,
        Username: params.username,
      };
      await axios.patch(
        `${config.api.invokeUrl}/institutes/${params.instituteId}/users/${params.username}`,
        updateParams,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
  };
  const handleChange = async (type, params) => {
    switch (type) {
      case "get":
        await getData(params);
        break;
      case "delete":
        await deleteStaff(params);
        break;
      case "post":
        await addStaff(params);
        break;
      case "patch":
        await updateStaff(params);
        break;
      case "lang":
        await lang(params);
        break;
      default:
        break;
    }
  };

  return [result, handleChange];
};
