/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { awscontext } from "../../configs/awscontext";
import { proctorcontext } from "../../configs/proctorContext";
import { useAuth } from "../../hooks/useAuth";
import ScreenPanel from "../components/ScreenPanel";
import SessionTable from "./components/SessionTable";
import { withNamespaces } from "react-i18next";
import LoadingIcon from "../components/LoadingIcon";
import axios from "axios";
import config from "../../configs/awsconfig.json";
import SystemCheck from "../SystemCheck";
import Proctoring from "../proctor/Proctoring";
import ProctoringFinish from "../proctor/ProctoringFinish";
import sessionModel from "../../models/modelSession.json";
import CheckInternetConnection from "./../system-check-steps/CheckInternetConnection";
import CheckWebcam from "./../system-check-steps/CheckWebcam";
import CheckAudio from "./../system-check-steps/CheckAudio";
import CheckNumberOfDisplays from "../system-check-steps/CheckNumberOfDisplays";
import CheckStreaming from "./../system-check-steps/CheckStreaming";

function LiveSessionList(props) {
  const context = useContext(awscontext);
  const proccontext = useContext(proctorcontext);
  const [, setAuth] = useAuth();
  const [isPageReady, setIsPageReady] = useState(true);
  const [rowCount, setRowCount] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [itemCount, setItemCount] = useState(0);
  const [list, setList] = useState([]);
  const [pageState, setPageState] = useState(0);
  const [selectedSession, setSelectedSession] = useState(sessionModel);
  const [pureList, setPureList] = useState();
  const [settings, setSettings] = useState({
    pc: {
      audioVideo: null,
      webcam: { active: false, session: null, recorder: null },
      screenShare: { active: false, session: null, recorder: null },
    },
    phone: { active: true, session: null },
  });
  const [selectedDevice, setSelectedDevice] = useState(null);

  useEffect(async () => {
    context.setAdres("liveSessionList");
    if (!window.location.href.includes("/proctor")) {
      context.setBarGroup("EXAMS");
    }

    if (context.user) {
      proccontext.setUserRole(
        context.user.roles[0].role,
        context.user.username
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const constructSessionList = async () => {
      var liveSessionList = [];

      liveSessionList = await fetchLibrarySessionList(
        localStorage.getItem("instituteId")
      );
      setIsPageReady(true);

      liveSessionList.forEach((element) => {
        element.forProctoring = true;
      });

      if (context.userRole.includes("Proctor")) {
        if (liveSessionList) {
          liveSessionList.forEach((element) => {
            if (element.proctorServiceType === "Virtual_Classroom") {
              element.forProctoring = true;
            } else {
              element.forProctoring = false;
              const studentList = element.studentList;
              studentList.forEach((student) => {
                if (student.proctors) {
                  const proctors = student.proctors;
                  proctors.forEach((proc) => {
                    if (proc.proctorId === context.user.username) {
                      element.forProctoring = true;
                    }
                  });
                }
              });
            }
          });
        }
      }
      const lives = liveSessionList.filter(
        (list) => list.forProctoring === true
      );
      setList(lives);
      setPureList(lives);
      setItemCount(lives.length);
    };

    constructSessionList();
  }, [context.loaded]);

  useEffect(async () => {
    if (!context.user) {
      const param = {
        username: localStorage.getItem("username"),
      };
      await setAuth("updateUser", param);
    }
    proccontext.setUserRole(context.user.roles[0].role, context.user.username);
    const splittedUrl = window.location.href.split("/");
    if (
      splittedUrl[splittedUrl.length - 1] !== "proctor" &&
      splittedUrl[splittedUrl.length - 1] !== "liveSessionList"
    ) {
      const instituteId = localStorage.getItem("instituteId");
      fetchLibrarySessionList(instituteId).then((liveSessionList) => {
        const foundSession = liveSessionList.find(
          (session) => session.sessionId === splittedUrl[splittedUrl.length - 1]
        );
        if (foundSession) {
          setSelectedSession(foundSession);
          proccontext.setProctoringSession(foundSession);
          proccontext.setActiveSession(
            foundSession,
            context.user.roles[0].role
          );
          setPageState(1);
        } else {
          props.history.push(`/admin`);
        }
      });
    }
  }, [context.loaded]);

  const showExamDetail = (selectedSession) => {
    proccontext.setActiveSession(selectedSession, context.userRole);
    proccontext.setProctoringSession(selectedSession);
    if (context.userRole.includes("Proctor")) {
      setSelectedSession(selectedSession);
      setPageState(1);
    } else {
      setPageState(2);
    }
  };

  const fetchLibrarySessionList = async (instituteId) => {
    setIsPageReady(false);
    return await getLiveSessionList(instituteId);
  };

  const getLiveSessionList = async (instituteId) => {
    try {
      var result = await axios.get(
        `${config.api.invokeUrl}/institutes/${instituteId}/sessions/live`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      return result.data;
    } catch (err) {
      console.log(err);
    }
  };

  const getBasicSystemCheckConfiguration = () => {
    const basicSystemCheckConfiguration = [];
    basicSystemCheckConfiguration.push({
      prev: "Terms & Conditions",
      renderName: "Internet Connection Check",
      render: CheckInternetConnection,
      next: "Audio Check",
    });
    basicSystemCheckConfiguration.push({
      prev: `Internet Connection Check`,
      render: CheckAudio,
      renderName: "Audio Check",
    });
    if (selectedSession.proctorRecording) {
      basicSystemCheckConfiguration[
        basicSystemCheckConfiguration.length - 1
      ].next = "Webcam Check";
      // if (selectedSession.systemCheck.additionalDisplayCheck) {
      //   basicSystemCheckConfiguration[
      //     basicSystemCheckConfiguration.length - 1
      //   ].next = "Display Check";
      //   basicSystemCheckConfiguration.push({
      //     next: `Webcam Check`,
      //     prev: `Internet Connection Check`,
      //     renderName: "Additional Display Check",
      //     render: CheckNumberOfDisplays,
      //   });
      // } else {
      //   basicSystemCheckConfiguration[
      //     basicSystemCheckConfiguration.length - 1
      //   ].next = "Webcam Check";
      // }
      basicSystemCheckConfiguration.push({
        next: `Streaming Check`,
        prev: `Audio Check`,
        renderName: "Webcam Check",
        render: CheckWebcam,
      });
      basicSystemCheckConfiguration.push({
        prev: `Webcam Check`,
        render: CheckStreaming,
        renderName: "Streaming Check",
        next: `To Proctoring`,
      });
    } else {
      basicSystemCheckConfiguration[
        basicSystemCheckConfiguration.length - 1
      ].next = "To Proctoring";
    }
    return basicSystemCheckConfiguration;
  };

  useEffect(() => {
    setItemCount(list.length);
  }, [list]);

  const mainContent = () => {
    if (pageState === 0) {
      return (
        <ScreenPanel
          name="Live_Session"
          panels="true"
          pagination="true"
          rowCount={rowCount}
          setRowCount={setRowCount}
          itemCount={itemCount}
          activePage={activePage}
          setActivePage={setActivePage}
          pureList={pureList}
          list={list}
          setList={setList}
        >
          <SessionTable
            Sessions={context.liveSessionList}
            showExamDetail={showExamDetail}
            setRowCount={setRowCount}
            rowCount={rowCount}
            itemCount={itemCount}
            activePage={activePage}
            setActivePage={setActivePage}
            list={list}
          />
        </ScreenPanel>
      );
    } else if (pageState === 1) {
      return (
        <SystemCheck
          selectedSession={selectedSession}
          settings={settings}
          setSettings={setSettings}
          setPageState={setPageState}
          configuration={getBasicSystemCheckConfiguration()}
          selectedDevice={selectedDevice}
          setSelectedDevice={setSelectedDevice}
          {...props}
        />
      );
    } else if (pageState === 2) {
      return (
        <Proctoring
          settings={settings}
          setPageState={setPageState}
          {...props}
        />
      );
    } else if (pageState === 3) {
      return <ProctoringFinish />;
    }
  };

  return isPageReady ? mainContent() : <LoadingIcon />;
}
export default withNamespaces()(LiveSessionList);
