/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect, optionsState } from "react";
import i18n from "../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";

function Paginations(props) {
  const changeRowCount = (e) => {
    props.setRowCount && props.setRowCount(e.target.value);
    setPageCount(Math.ceil(props.itemCount / e.target.value));
  };
  const [pageCount, setPageCount] = useState(
    Math.ceil(props.itemCount / props.rowCount)
  );
  useEffect(() => {
    setPageCount(Math.ceil(props.itemCount / props.rowCount));
  }, [props.itemCount]);
  const pageButtons = [];
  
  for (var k = 1; k < pageCount + 1; k++) {
    pageButtons.push({ id: k });
  }

  return (
    <div className="row w-100">
      {props.table ? props.table : ""}
      <div className="col-auto me-auto">
        &nbsp;&nbsp; {i18n.t("Row_Count")} &nbsp; : &nbsp;&nbsp;
        <select
          className="form-select btn border"
          onChange={changeRowCount}
          value={optionsState}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>
      </div>

      <div className="col-6">
        <ul className="pagination">
          <li
            className={
              props.activePage === 1 ? "page-item disabled" : "page-item"
            }
          >
            <button
              className="page-link"
              onClick={() => {
                props.setActivePage(props.activePage - 1);
              }}
            >
              &lt;
            </button>
          </li>

          {pageButtons.map((button, key) => (
            <li key={key} className="page-item">
              <button
                className="page-link"
                onClick={() => {
                  props.setActivePage(button.id);
                }}
              >
                {button.id}
              </button>
            </li>
          ))}

          <li
            className={
              props.activePage === pageButtons.length ||
              pageButtons.length === 0
                ? "page-item disabled"
                : "page-item"
            }
          >
            <button
              className="page-link"
              onClick={() => {
                props.setActivePage(props.activePage + 1);
              }}
            >
              &gt;
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}
export default withNamespaces()(Paginations);
