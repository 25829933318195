import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./assets/lotec.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications-component/dist/theme.css";
import Login from "./app/auth/Login";
import AdminLayout from "./app/admin";
import Amplify from "aws-amplify";
import AwsConfig from "./configs/awsconfig.json";
import AwsContextProvider from "./configs/awscontext";
import ProctorContextProvider from "./configs/proctorContext";
import routes from "./configs/route";
import i18n from "./configs/i18n";
import axios from "axios";
import translationEN from "./configs/en.json";
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: AwsConfig.cognito.REGION,
    userPoolId: AwsConfig.cognito.USER_POOL_ID,
    userPoolWebClientId: AwsConfig.cognito.APP_CLIENT_ID,
  },
});
window.onpageshow = function (event) {
  if (event.persisted) {
    window.location.reload();
  }
};
const constructInitialLang = async () => {
  let defaulthKey = localStorage.getItem("instituteLanguage")
  let key = localStorage.getItem("i18nextLng");
  if (key === null) {
    key = "en";
  }
  if(defaulthKey === null)
  {
    defaulthKey = "en";
  }
  const params = {
    translation: {},
  };
  try {

    await axios
      .get(`${AwsConfig.api.s3}/assets/languages/${defaulthKey}.json`)
      .then((res) => {
        params.translation = res.data;
        localStorage.setItem("state", JSON.stringify(params));
        i18n.changeLanguage(defaulthKey);
        localStorage.setItem("lang", localStorage.getItem("i18nextLng"));
      });
  } catch {
    params.translation = translationEN;
    localStorage.setItem("state", JSON.stringify(params));
    i18n.changeLanguage("en");
    localStorage.setItem("lang", "en");
    localStorage.setItem("i18nextLng", "en");
  }
};

const getRoutes = (routes) => {
  constructInitialLang();
  return routes.map((prop, key) => {
    if (prop.layout === "/admin") {
      return (
        <Route
          path={prop.path}
          render={(props) => (
            <AdminLayout {...props}>
              <prop.component {...props} />
            </AdminLayout>
          )}
          key={key}
        />
      );
    } else {
      return null;
    }
  });
};

ReactDOM.render(
  <ProctorContextProvider>
    <AwsContextProvider>
      <BrowserRouter>
        <Switch>
          {getRoutes(routes)}
          <Route path="/forgot" render={(props) => <Login {...props} />} />
          <Route path="/first" render={(props) => <Login {...props} />} />
          <Route
            path="/forgotpasswordverification"
            render={(props) => <Login {...props} />}
          />
          <Login />
        </Switch>
      </BrowserRouter>
    </AwsContextProvider>
  </ProctorContextProvider>,
  document.getElementById("root")
);
