/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import SiteNavPanel from "./components/SiteNavPanel";
import SiteSidePanel from "./components/SiteSidePanel";
import { awscontext } from "../configs/awscontext";
import { useAuth } from "../hooks/useAuth";
import { useStart } from "../hooks/useStart";
import LoadingIcon from "./components/LoadingIcon";
import ReactNotification from "react-notifications-component";
import AwsConfig from "../configs/awsconfig.json";
import i18n from "../configs/i18n";
import axios from "axios";
import translationEN from "../configs/en.json";
import config from "../configs/awsconfig.json";
const routesConfig = require("../configs/route");

export default function Admin(props) {
  const context = useContext(awscontext);
  const [, setAuth] = useAuth();
  const [, setStart] = useStart();

  useEffect(async () => {
    const retrieveMeetingInformation = async (sessionId) => {
      try {
        const res = await axios.get(
          `${config.api.invokeUrl}/meetings/session/${sessionId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                Object.keys(localStorage).find((key) =>
                  key.includes("accessToken")
                )
              )}`,
            },
          }
        );
        localStorage.setItem("instituteId", res.data[0].instituteId);
      } catch (err) {
        console.log(err);
      }
    };
    if (window.location.href.includes("/libraryStudentDetailed/")) {
      const splittedUrl = window.location.href.split("/");
      let studentId = splittedUrl[splittedUrl.length - 1];
      let sessionId = splittedUrl[splittedUrl.length - 2];
      if (studentId && studentId.length === 8 && !!sessionId && sessionId.length === 8) {
        await retrieveMeetingInformation(sessionId);
      }
    }
  }, []);

  useEffect(() => {
    if (context.user) {
      let returnURL = props.location.pathname;
      context.setReturnURL(returnURL);
      const activePage = routesConfig.default.filter(
        (page) => page.path === returnURL
      );
      if (activePage[0] && activePage[0].auth) {
        let isAuth = false;
        for (let index = 0; index < activePage[0].auth.length; index++) {
          const part = activePage[0].auth[index];
          if (context.userRole.includes(part)) {
            isAuth = true;
          }
        }
        // console.log(context.userRole);
        if (!isAuth && context.userRole !== null) {
          props.history.push(`/admin`);
        }
      }
    }
  }, [context.user]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (context.loaded) {
      if (context.user) {
        if (!localStorage.getItem("retakeLanguage")) {
          let defaulthKey = localStorage.getItem("instituteLanguage")
          let key = localStorage.getItem("i18nextLng");
          if (key === null) {
            key = "en";
          }
          if (defaulthKey === null) {
            defaulthKey = "en";
          }
          const params = {
            translation: {},
          };
          try {
            await axios
              .get(`${AwsConfig.api.s3}/assets/languages/${defaulthKey}.json`)
              .then((res) => {
                params.translation = res.data;
                localStorage.setItem("state", JSON.stringify(params));

                localStorage.setItem("retakeLanguage", "true");
                i18n.changeLanguage(defaulthKey);
                localStorage.setItem("lang", localStorage.getItem("i18nextLng"));
              });
          } catch {
            params.translation = translationEN;
            localStorage.setItem("state", JSON.stringify(params));
            i18n.changeLanguage("en");
            localStorage.setItem("lang", "en");
            localStorage.setItem("i18nextLng", "en");
          }
        }


      }
    }
  }, [context.loaded])
  useEffect(async () => {
    if (!context.loaded) {
      if (!context.user) {
        if (localStorage.getItem("username")) {
          const param = {
            username: localStorage.getItem("username"),
          };
          await setAuth("updateUser", param);
        } else if (props.history !== "/login") {
          props.history.push("/login")
        }

      }
    }
  }, [context.loaded]);

  useEffect(async () => {
    if (context.user && !context.loaded) {
      const instituteId = localStorage.getItem("instituteId");
      await setStart(instituteId, true);
      context.setLoaded(true);
      await setStart(instituteId, false);
    }
  }, [context.user]);

  const mainContent = () => {
    return (
      <React.Fragment>
        <ReactNotification />
        {!context.isAuthenticated ? (
          <Redirect to="/login" />
        ) : (
          <React.Fragment>
            <SiteNavPanel />
            <div
              className="row bg-white mr-0"
              style={{ minHeight: "calc(100% - 128px)" }}
            >
              {!context.sidePanelVisibilty ? null : (
                <div className="col-md-2">
                  <SiteSidePanel />
                </div>
              )}
              <div className="col">{props.children}</div>
            </div>
            {/* <SiteFooterPanel /> */}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  return context.isAuthenticated ? (
    context.loaded ? (
      mainContent()
    ) : (
      <LoadingIcon />
    )
  ) : (
    <Redirect to={window.location} />
    // <Redirect to="/" />
  );
}
