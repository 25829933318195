class CalculateAiMessage {
    ml_finding;
    result;

    constructor(ml_finding, result) {
        this.ml_finding = ml_finding;
        this.result = result;
    }

    calculateAiMessage() {
        if (this.ml_finding["AI_RESULT_TEST_TAKER_ABSENT"] ) {
            return "Test_taker_not_found_in_the_current_frame";
        } else if (this.ml_finding["AI_RESULT_SECOND_PERSON_VISIBLE"]) {
            return "Someone_unknown_is_with_the_test_taker"
        } else if (this.ml_finding["AI_RESULT_TEST_TAKER_SUBSTITUTED"] ) {
            return "Test_taker_does_not_match_with_the_face_photo";
        } else if (this.ml_finding["AI_RESULT_SMARTPHONE_DETECTED"]) {
            return "Usage_of_phone";
        } else if (this.ml_finding["AI_RESULT_EXAM_PAGE_LOST"] ) {
            return "Exam_page_lost";
        } else if (this.ml_finding["AI_RESULT_VOICE_DETECTED"] ) {
            return "Voice_detected";
        } else if (this.ml_finding["AI_RESULT_LOOKING_AWAY"] ) {
            return "Test_taker_eye_gaze_direction_is_not_correct";
        }else if(this.ml_finding["AI_RESULT_HEADPHONE_DETECTED"]){
            return "Unauthorized_devices_present";
        }

    }

}

export default CalculateAiMessage;