import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";
//import translationEN from "./en.json";
//import translationTR from "./tr.json";
//import translationDE from "./de.json";
// the translations

const resources = {
  //  en: {
  //    translation: translationEN,
  //  }
  // tr: {
  //   translation: translationTR,
  // },
  // de: {
  //   translation: translationDE,
  // },
};

let key = localStorage.getItem("lang");
if (localStorage.getItem("lang") === null) {
  key = "en";
}

// const params = {
//   translation: {},
// };

resources[key] = JSON.parse(localStorage.getItem("state"));

i18n
  .use(detector)
  .use(reactI18nextModule)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
