import React, { Component } from "react";
import ReactLoading from "react-loading";

export default class LoadingIcon extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          height: "40vh",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="container"
      >
        {/* <ReactLoading type="cylon" color="#b2df8a" height={200} width={300} /> */}
        <ReactLoading type="cylon" color="#29377e" height={200} width={300} />
      </div>
    );
  }
}
