import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function RichTextEditor({ editorState, setEditorState }) {
    const onChange = async (value) => {
        setEditorState(value);
    };

    return (
        <div>
            <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(value) => {
                    onChange(value);
                }}
                stripPastedStyles
                ariaLabel="draftEditor"
            />
        </div>
    );
}

export default RichTextEditor;
