import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { awscontext } from "../../configs/awscontext";
import { Dropdown, DropdownButton } from "react-bootstrap";

export function NavLink(props) {
  const context = useContext(awscontext);
  return (
    <li className="nav-item">
      <Link
        style={{ width: context.theme.nav_btn_width }}
        className={`${context.theme.nav_button_class} ${
          props.prop.path === "/" + context.adres ||
          props.prop.overpath === context.barGroup
            ? context.theme.nav_button_text_color_active
            : context.theme.nav_button_text_color
        }`}
        to={`${props.to}`}
      >
        {props.children}
      </Link>
    </li>
  );
}

export function NavLinkEvent(props) {
  const context = useContext(awscontext);
  return (
    <Link
      style={{ width: context.theme.nav_btn_width }}
      className={context.theme.nav_button_text_color}
      onClick={props.onClick}
      to="#"
    >
      {props.children}
    </Link>
  );
}

export function NavLink2(props) {
  const context = useContext(awscontext);
  return (
    <Link
      style={{ width: context.theme.nav_btn_width }}
      className={`${context.theme.nav_button_class} ${
        props.path === "/" + context.adres ||
        props.overpath === context.barGroup
          ? context.theme.nav_button_text_color_active
          : context.theme.nav_button_text_color
      }`}
      to={`${props.link}`}
      onClick={props.onClick}
    >
      {props.name}
    </Link>
  );
}
export function NavDropDown(props) {
  const instList = [];

  for (var i = 0; i <= props.children.length; i++) {
    instList.push(props.children[i]);
  }
  return (
    <DropdownButton title={props.header} variant="white" menuAlign="right">
      {Array.isArray(props.children) ? (
        instList.map((inst, key) => (
          <Dropdown.Item key={key}>
            <li>{inst}</li>
          </Dropdown.Item>
        ))
      ) : (
        <Dropdown.Item>
          <li>{props.children}</li>
        </Dropdown.Item>
      )}
    </DropdownButton>
  );
}
