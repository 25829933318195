import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { awscontext } from "../../configs/awscontext";
import i18n from "../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import { FaCaretRight } from "react-icons/fa";
const routesConfig = require("../../configs/route");

function SiteSidePanel() {
  const context = useContext(awscontext);
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    var routeData = [];
    routesConfig.default.map((m) => routeData.push(m));
    routeData.sort(function(a, b) {
      return a.sort - b.sort;
    });
    setRoutes(routeData);
  }, []);

  return (
    <ul className="d-flex flex-column p-0" style={{ listStyle: "none" }}>
      {routes.map((prop, i) =>
        prop.location.includes("sidebar") &&
        prop.overpath === context.barGroup ? (
          context.userRole ? (
            prop.auth.some((user) => context.userRole.includes(user)) ? (
              <li key={i} className={context.theme.side_nav_container}>
                <Link
                  key={i}
                  className={`${context.theme.side_nav_button_class} ${
                    `/${context.adres}` === prop.path
                      ? context.theme.side_nav_button_text_color_active
                      : context.theme.side_nav_button_text_color
                  }`}
                  to={`${prop.path}`}
                >
                  {prop.renderIcon ? <prop.renderIcon size={14} /> : null}
                  <span className="pl-1 pr-3">{i18n.t(prop.name)}</span>
                  {`/${context.adres}` === prop.path ? (
                    <FaCaretRight color="#29377e" />
                  ) : null}
                </Link>
              </li>
            ) : null
          ) : null
        ) : null
      )}
    </ul>
  );
}
export default withNamespaces()(SiteSidePanel);
