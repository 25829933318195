/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from "react";
import { awscontext } from "../../configs/awscontext";
import { proctorcontext } from "../../configs/proctorContext";
import { withNamespaces } from "react-i18next";
import { useStorage } from "../../hooks/useStorage";
import i18n from "../../../src/configs/i18n";
import OneMeeting from "./components/OneMeeting";
import { Link } from "react-router-dom";
import ChatComp from "../session/components/ChatComp";
import ChatCompForSuperAdmin from "../session/components/ChatCompForSuperAdmin";
import Card from "react-bootstrap/Card";
import { useChat } from "../../hooks/useChat";
import Switch from "react-switch";
import OpenViduConnector from "../../utils/OpenViduConnector";
import ModalForm from "../components/ModalForm";
import { useSession } from "../../hooks/useSession";
import { MdRefresh } from "react-icons/md";
import { FaCheck, FaEyeSlash, FaEye, FaTimes } from "react-icons/fa";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { GrConnect } from "react-icons/gr"
import { BsInfoCircle } from "react-icons/bs";
import { RiArrowDropRightLine } from "react-icons/ri";
import axios from "axios";
import config from "../../configs/awsconfig.json";
import { store } from "react-notifications-component";
import AwsConfig from "../../configs/awsconfig.json";
import translationEN from "../../configs/en.json";
// import ReactLoading from "react-loading";

function Proctoring(props) {
  const context = useContext(awscontext);
  const [sessionRefresh, setSessionRefresh] = useSession({});
  const proccontext = useContext(proctorcontext);
  const [, setChat] = useChat("");
  const [showIdCard, setShowIdCard] = useState(false);
  const [faceSrc, setFaceSrc] = useState("");
  const [idSrc, setIdSrc] = useState("");
  const [isBreak, setIsBreak] = useState(false);
  const [uploadObject, listObjects, headObject, getBaseUrl] = useStorage(
    config.s3.streamFiles
  );
  const [session] = useState(proccontext.proctoringSession);
  const [confirmFinish, setConfirmFinish] = useState(false);
  const [proctoringRulesOpen, setProctoringRulesOpen] = useState(true);
  const [studentListPanelOpen, setStudentListPanelOpen] = useState(true);
  const [meetingSessionList, setMeetingSessionList] = useState({});
  const [messageList, setMessageList] = useState([]);
  const [newChatMessage, setNewChatMessage] = useState(null);
  const [meetingList, setMeetinglist] = useState([]);
  const [currentChatMessages, setCurrentChatMessages] = useState([]);
  const [callInformation, setCallInformation] = useState({});
  const [chatSession, setChatSession] = useState(null);
  const [liveStudentList, setLiveStudentList] = useState([]);
  const [chatPanelVisible, setChatPanelVisible] = useState(false);
  const [studentList, setStudentList] = useState(proccontext.students);
  const [studentCommentMap, setStudentCommentMap] = useState([]);
  const [proctorVoiceSession, setProctorVoiceSession] = useState(null);
  const [showExamInstructions, setShowExamInstructions] = useState(false);
  const [studentMonitoringList, setStudentMonitoringList] = useState([]);
  const [newMonitoringEvent, setNewMonitoringEvent] = useState(null);
  const [trigger, setTrigger] = useState(true);
  const [checkedStudentCounter, setCheckedStudentCounter] = useState(0);
  const [checkedStudentList, setCheckedStudentList] = useState([]);
  const [sendMonitoringIntervalList, setSendMonitoringIntervalList] = useState([]);
  const [callInformations, setCallInformations] = useState([]);
  const [isConnectedList, setIsConnectedList] = useState([]);
  const [connected, setConnected] = useState("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  let screenSharePassed = false;

  useEffect(() => {
    let isSuperAdmin = context.user.roles.some(role => role.role === "Super Admin");
    setIsSuperAdmin(isSuperAdmin);
  });

  useEffect(() => {
    let tempCallInfo = callInformations.find((callInfo) => callInfo.status === "ACCEPTED" || callInfo.status === "WAITING");
    if (tempCallInfo) {
      setCallInformation(tempCallInfo);
    } else {
      setCallInformation({});
    }
  }, [callInformations]);

  // useEffect(() => {
  //   console.log("ttt => callInformations/// => ", callInformations);
  //   console.log("ttt => callInformation/// => ", callInformation);
  // });

  useEffect(async () => {
    if (context.loaded) {
      if (context.user) {
        let defaulthKey = localStorage.getItem("instituteLanguage")
        let key = localStorage.getItem("i18nextLng");
        if (key === null) {
          key = "en";
        }
        if (defaulthKey === null) {
          defaulthKey = "en";
        }
        if (!defaulthKey === key) {
          const params = {
            translation: {},
          };
          try {
            await axios
              .get(`${AwsConfig.api.s3}/assets/languages/${defaulthKey}.json`)
              .then((res) => {
                params.translation = res.data;
                console.log("dosyayı çekti")
                localStorage.setItem("state", JSON.stringify(params));
                i18n.changeLanguage(defaulthKey);
                localStorage.setItem("lang", localStorage.getItem("i18nextLng"));
              });
          } catch {
            console.log("dosyayı çekemedi")
            params.translation = translationEN;
            localStorage.setItem("state", JSON.stringify(params));
            i18n.changeLanguage("en");
            localStorage.setItem("lang", "en");
            localStorage.setItem("i18nextLng", "en");
          }
        }
      }
    }
  }, [context.loaded])

  useEffect(() => {
    if (chatSession && chatPanelVisible) {
      proccontext.setChatAlert(chatSession.sessionId, "reset");
    }
  }, [chatPanelVisible, chatSession]);

  useEffect(async () => {
    console.log("user ******", context.user)
    console.log("session *********", session)
    try {
      const res = await axios.get(
        `${config.api.invokeUrl}/meetings/session/${session.sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      const meetings = res.data;
      setMeetinglist(meetings);
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }, [sessionRefresh])

  useEffect(() => {
    setStudentList(proccontext.students);
    const currentLiveStudentList = [];
    for (const student of studentList) {
      if (student.online === true) {
        currentLiveStudentList.push(student);
      }
    }
    setLiveStudentList(currentLiveStudentList);
  }, [proccontext.students]);

  useEffect(() => {
    liveStudentList.forEach(async (student, index) => {
      if (student.online === false) {
        liveStudentList.splice(index, 1);
        await deleteFromMeetingSessionList(`${student.sessionId}${student.studentId}`);
        proccontext.deleteStudent(`${student.sessionId}${student.studentId}`);
      }
    });
  });

  const deleteFromMeetingSessionList = (meetingId) => {
    let updatedMeetingSessionList = {};
    for (const meetingSession in meetingSessionList) {
      if (!meetingSession.includes(meetingId)) {
        updatedMeetingSessionList[`${meetingSession}`] = meetingSessionList[meetingSession];
      }
    }
    setMeetingSessionList(updatedMeetingSessionList);
  };

  useEffect(() => {
    if (newChatMessage) {
      if (newChatMessage.role === "STUDENT") {
        store.addNotification({
          title: null,
          message: `Message from ${newChatMessage.content.from} `,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
      messageList.push(newChatMessage);
      setMessageList(messageList);
      if (!chatPanelVisible) {
        const chatMeetingId = `${newChatMessage.meetingId}-webcam`;
        setChatSession(meetingSessionList[chatMeetingId].session);
        setChatPanelVisible(true);
      }

      if (chatSession) {
        if (!chatSession.sessionId.includes(newChatMessage.meetingId)) {
          proccontext.setChatAlert(newChatMessage.meetingId, "add");
        } else {
          setCurrentChatMessages(
            messageList.filter(
              (message) => message.meetingId === newChatMessage.meetingId
            )
          );
        }
      }
    }
  }, [newChatMessage]);

  useEffect(() => {
    if (newMonitoringEvent) {
      if (newMonitoringEvent.state === "MONITORING") {
        const foundStudent = studentMonitoringList.find(
          (studentMonitor) =>
            studentMonitor.studentId === newMonitoringEvent.studentId
        );
        if (!foundStudent) {
          studentMonitoringList.push({
            studentId: newMonitoringEvent.studentId,
          });
          setStudentMonitoringList(studentMonitoringList);
        }
      } else if (newMonitoringEvent.state === "NOT_MONITORING") {
        const foundStudentIndex = studentMonitoringList.findIndex(
          (studentMonitor) =>
            studentMonitor.studentId === newMonitoringEvent.studentId
        );
        if (foundStudentIndex > -1) {
          studentMonitoringList.splice(foundStudentIndex, 1);
          setStudentMonitoringList(studentMonitoringList);
        }
      }
      setNewMonitoringEvent(null);
    }
  }, [newMonitoringEvent]);

  const configureMeetingSessionList = async (student) => {
    try {
      // console.log("ttt => 40");
      await configureOpenviduMeetingSessionOverLambda(
        `${session.sessionId}${student.studentId}`,
        "SUBSCRIBER",
        "webcam",
        student
      );
      // console.log("ttt => 41");
      console.log("Student to webcam connection established");
    } catch (err) {
      console.log(
        "ERROR: Student to webcam connection cannot be established"
      );
    }
    try {
      // console.log("ttt => 42");
      await configureOpenviduMeetingSessionOverLambda(
        `${session.sessionId}${student.studentId}`,
        "SUBSCRIBER",
        "screenShare",
        student
      );
      // console.log("ttt => 43");
      console.log("Student to screenShare connection established");
    } catch (err) {
      console.log(
        "ERROR: Student to screenShare connection cannot be established"
      );
    }
    try {
      if (session.proctorServiceType === "Live_Proctoring_Plus") {
        for (let i = 0; i < session.allowedOutsourceCamera; i++) {
          if (student.examRules && student.examRules.mobileCameraUsage) {
            await configureOpenviduMeetingSessionOverLambda(
              `${session.sessionId}${student.studentId}`,
              "SUBSCRIBER",
              `mobile_${i}`,
              student
            );
          }
        }
      } else {
        if (student.examRules && student.examRules.mobileCameraUsage) {
          await configureOpenviduMeetingSessionOverLambda(
            `${session.sessionId}${student.studentId}`,
            "SUBSCRIBER",
            "mobile_0",
            student
          );
        }
      }
    } catch (err) {
      console.log(
        "ERROR: Student to mobile connection cannot be established"
      );
    }
    if (
      context.userRole.includes("Proctor") &&
      appropriateSessionProctoringType(session.proctorServiceType)
    )
      try {
        configureOpenviduVoiceSessionOverLambda(
          `${session.sessionId}${context.user.username}`
        );
      } catch (err) {
        console.log(
          "ERROR: Student to voice connection cannot be established"
        );
      }
    // console.log("ttt => 44");
  };

  const configureOpenviduVoiceSessionOverLambda = async (meetingId) => {
    const openviduConnector = new OpenViduConnector(onlineFunc);
    const sessionProperties = {
      customSessionId: `${meetingId}-voice`,
    };
    await openviduConnector.createSession(sessionProperties);
    const session = openviduConnector.initializeSession();
    const foundSession = proccontext.sessions.find((item) => item.session === session.sessionId);
    if (!foundSession) {
      proccontext.setSessions(session);//finish ekranında kapatılmak için session contexte atılmıştır.
    }
    const connectionProperties = {
      role: "PUBLISHER",
    };
    const connection = await openviduConnector.connectToSession(
      sessionProperties.customSessionId,
      connectionProperties
    );

    const sessionUserRole = context.userRole.includes("Proctor")
      ? "Proctor"
      : JSON.parse(context.userRole)[0].role;

    await openviduConnector.joinSession(
      session,
      connection,
      `${sessionUserRole} | ${context.user.name} ${context.user.lastname}`,
      "voice"
    );
    setProctorVoiceSession(session);
  };

  useEffect(() => {
    context.setAdres("proctoring");
    context.setBarGroup("PROCTOR");

    const retrieveStudentCommentResults = () => {
      const studentCommentMap = {};
      studentList.forEach(async (student) => {
        const studentResult = await getStudentCommentResults(
          `${session.sessionId}${student.studentId}`
        );
        studentCommentMap[student.studentId] = studentResult;
      });
      setStudentCommentMap(studentCommentMap);
    };

    const retrieveChatMessages = () => {
      const messageList = [];
      studentList.forEach(async (student) => {
        const meetingId = `${session.sessionId}${student.studentId}`;
        const chatResult = await getMessageList(meetingId);
        chatResult.sort(function (a, b) {
          return a.Time - b.Time;
        });
        chatResult.forEach((chat) => {
          const chatObject = {
            meetingId: chat.MeetingId,
            type: "text",
          };
          if (chat.Role !== "Student") {
            if (chat.Person === context.userInfo) {
              chatObject.position = "right";
              chatObject.content = {
                text: `${chat.Message}`,
              };
            } else {
              chatObject.content = {
                text: `${chat.Role} | ${chat.Person}: ${chat.Message} `,
              };
            }
          } else {
            chatObject.content = {
              text: `${chat.Person}: ${chat.Message}`,
            };
          }
          messageList.push(chatObject);
        });
      });
      setMessageList(messageList);
    };
    // configureAllMeetingSessionList();
    retrieveStudentCommentResults();
    retrieveChatMessages();

    return () => {
      if (proctorVoiceSession) {
        proctorVoiceSession.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const loadTawkTo = (propertyId, key, configuration) => {
      if (!window) {
        throw new Error("DOM is unavailable");
      }

      window.Tawk_API = window.Tawk_API || {};
      window.Tawk_LoadStart = new Date();
      window.Tawk_API.visitor = configuration;
      // window.Tawk_API.onLoad = function () {
      //   window.Tawk_API.setAttributes(configuration, function (err) {
      //     if (err) {
      //       console.log("Tawk to set attribute error: ", err);
      //     }
      //   });
      // };

      const tawk = document.getElementById("tawkId");
      if (tawk) {
        // Prevent TawkTo to create root script if it already exists
        return window.Tawk_API;
      }

      if (!key) {
        throw new Error(
          "Key not provided. Get key from tawk dashboard - Direct Chat Link"
        );
      }

      const script = document.createElement("script");
      script.id = "tawkId";
      script.async = true;
      script.src = "https://embed.tawk.to/" + propertyId + "/" + key;
      script.charset = "UTF-8";
      script.setAttribute("crossorigin", "*");

      const first_script_tag = document.getElementsByTagName("script")[0];
      if (!first_script_tag || !first_script_tag.parentNode) {
        throw new Error("DOM is unavailable");
      }

      first_script_tag.parentNode.insertBefore(script, first_script_tag);
    };
    const tawkToAtrributes = {
      name: `${context.user.name} ${context.user.lastname}`,
      email: `${context.user.email}`,
    };
    loadTawkTo(config.tawkTo.key, config.tawkTo.secret, tawkToAtrributes);
    if (!context.userRole.includes("Super Admin")) {
      studentList.forEach(function (student, index) {
        setTimeout(function () {
          configureMeetingSessionList(student);
        }, index * 1500);
      });
    }
  }, []);

  const onlineFunc = (result, meetingId) => {
    proccontext.setOnlineStudents(result, meetingId);
    if (result) {
      const currentLiveStudentList = [];
      for (const student of studentList) {
        if (student.online === true) {
          currentLiveStudentList.push(student);
        }
      }
      setLiveStudentList(currentLiveStudentList);
    }
  };

  const updateList = (student) => {
    let updatedList = [];
    for (const student of isConnectedList) {
      updatedList.push(student);
    }
    updatedList.push(student);
    setIsConnectedList(updatedList);
  }


  const configureOpenviduMeetingSessionOverLambda = async (
    meetingId,
    role,
    source,
    student
  ) => {
    const openviduConnector = new OpenViduConnector(onlineFunc);
    const sessionProperties = {
      customSessionId: `${meetingId}-${source}`,
    };
    await openviduConnector.createSession(sessionProperties);
    const OVsession = openviduConnector.initializeSession();
    const foundSession = proccontext.sessions.find((item) => item.session === OVsession.sessionId);
    if (!foundSession) {
      proccontext.setSessions(OVsession);//finish ekranında kapatılmak için session contexte atılmıştır.
    }
    const connectionProperties = {
      role,
    };
    const connection = await openviduConnector.connectToSession(
      sessionProperties.customSessionId,
      connectionProperties
    );
    OVsession.on("streamDestroyed", (event) => {
      if (event.target.options.sessionId.includes("webcam")) {
        onlineFunc(false, event.target.options.sessionId.replace("-webcam", ""));
        screenSharePassed = false;
        proccontext.deleteFromWebcamAndScreenShareList(`${student.sessionId}${student.studentId}`);
      } else if (event.target.options.sessionId.includes("mobile")) {
        setConnected("disconnected");
        proccontext.deleteFromMobileList(`${student.sessionId}${student.studentId}`);
      }
    });
    OVsession.on("signal:streamingContinue", (event) => {
      if (screenSharePassed) {
        onlineFunc(true, meetingId.substring(0, 16));
      }
      // checkPass(meetingId);
    })
    OVsession.on("streamCreated", (event) => {
      let keys = Object.keys(meetingSessionList)
      if (!(sessionProperties.customSessionId in keys)) {
        meetingSessionList[sessionProperties.customSessionId] = {
          session: OVsession,
          stream: event.stream,
        }
      };
      if (event.target.options.sessionId.includes("mobile_0")) {
        setConnected("");
        subscribeAll(student);
        setTrigger(!trigger);//addVideoElement to the DOM over OneMeeting.js
      }
      setMeetingSessionList(meetingSessionList);

      // setMeetingSessionList((prevMeetingSessionList) => ({
      //   ...prevMeetingSessionList,
      //   [sessionProperties.customSessionId]: {
      //     session,
      //     stream: event.stream,
      //   },
      // }));
      // this.isConnected = meetingId;

      var meetingId = event.target.options.sessionId;
      if (student.examRules && student.examRules.mobileCameraUsage) {
        if (session.allowedOutsourceCamera === 1) {
          if (meetingId.includes("mobile_0")) {
            console.log("meetingId => ", meetingId.substring(0, 16));
            onlineFunc(true, meetingId.substring(0, 16));
            updateList(student);
          }
        } else if (session.allowedOutsourceCamera === 2) {
          if (meetingId.includes("mobile_1")) {
            console.log("meetingId => ", meetingId.substring(0, 16));
            onlineFunc(true, meetingId.substring(0, 16));
            updateList(student);
          }
        } else if (session.allowedOutsourceCamera === 3) {
          if (meetingId.includes("mobile_2")) {
            console.log("meetingId => ", meetingId.substring(0, 16));
            onlineFunc(true, meetingId.substring(0, 16));
            updateList(student);
          }
        } else if (session.allowedOutsourceCamera === 4) {
          if (meetingId.includes("mobile_3")) {
            console.log("meetingId => ", meetingId.substring(0, 16));
            onlineFunc(true, meetingId.substring(0, 16));
            updateList(student);
          }
        }
        if (meetingId.includes("screenShare")) {
          screenSharePassed = true;
        }
      } else {
        if (meetingId.includes("screenShare")) {
          console.log("meetingId => ", meetingId.substring(0, 16));
          onlineFunc(true, meetingId.substring(0, 16));
          updateList(student);
        }
      }
    });
    // session.on('publisherStartSpeaking', (event) => {
    //   console.log('Adam konuştu beylerrrrr ///// ' + event.connection.connectionId + ' start speaking');
    // });
    OVsession.on("signal:system", (event) => {
      const data = JSON.parse(event.data);
      if (data.state === "STUDENT_CALL_ENDED") {
        setCallInformations((callInformations) =>
          callInformations.filter((callInfo) => {
            if (callInfo.status !== "FINISHED") {
              callInfo.enabled = false;
              callInfo.status = "FINISHED";
              proccontext.storeMuted(true, callInfo.studentId);
            }
            return callInfo;
          })
        );
        store.addNotification({
          title: null,
          message: `Student Call Ended`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      } else if (data.state === "DEFAULT_STUDENT_CALL_ENDED") {
        setCallInformations((callInformations) =>
          callInformations.filter((callInfo) => {
            if (callInfo.status !== "FINISHED") {
              callInfo.enabled = false;
              callInfo.status = "FINISHED";
              proccontext.storeMuted(true, callInfo.studentId);
            }
            return callInfo;
          })
        );
      } else if (data.state === "CALL_ACCEPTED") {
        setCallInformations((callInformations) =>
          callInformations.filter((callInfo) => {
            if (callInfo.status === "WAITING" && callInfo.meetingId === meetingId) {
              callInfo.status = "ACCEPTED";
            }
            return callInfo;
          })
        );
        store.addNotification({
          title: null,
          message: `Student Call Accepted`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      } else if (
        data.state === "PROCTOR_BREAK_STARTED" &&
        context.userRole.includes("Admin")
      ) {
        store.addNotification({
          title: null,
          message: `Proctor | ${data.fullName} is on a break!`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      } else if (
        data.state === "PROCTOR_BREAK_ENDED" &&
        context.userRole.includes("Admin")
      ) {
        store.addNotification({
          title: null,
          message: `Proctor | ${data.fullName} continues proctoring`,
          type: "info",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    });

    OVsession.on("signal:message", (event) => {
      const messageFrom = JSON.parse(event.from.data).clientData;
      const incomingMessageInfo = JSON.parse(event.data);
      const messageInfo = {
        type: "text",
        content: {
          text: messageFrom + ": " + incomingMessageInfo.text,
          from: messageFrom,
        },
        role: incomingMessageInfo.role,
        meetingId,
      };
      if (!messageFrom.includes(context.userInfo)) {
        setNewChatMessage(messageInfo);
      }
    });

    OVsession.on("signal:monitoring", (event) => {
      const incomingMessageData = JSON.parse(event.data);
      setNewMonitoringEvent(incomingMessageData);
    });

    const currentElement = document.getElementById(
      `${source}_video_div${meetingId}`
    );

    connection && await openviduConnector.joinSession(
      OVsession,
      connection,
      `${context.user.roles[0].role} | ${context.user.name} ${context.user.lastname}`,
      source,
      currentElement
    );
    if (source === "webcam") {
      OVsession
        .signal({
          data: JSON.stringify({
            state: "DEFAULT_PROCTOR_CALL_ENDED",
          }),
          type: "system",
        })
        .then(() => {
          console.log("Proctor call end message sent");
        })
        .catch((error) => {
          console.error("err:", error);
        });
    }
  };

  const appropriateSessionProctoringType = (proctorServiceType) => {
    if (
      proctorServiceType === "Record_and_Review" ||
      proctorServiceType === "Record_and_Review_Lite"
    ) {
      return false;
    } else {
      return true;
    }
  };

  const sendTextMessage = async (messageText) => {
    const messageInfo = {
      text: messageText,
      role: context.userRole,
    };
    if (messageText.trim()) {
      chatSession
        .signal({
          data: JSON.stringify(messageInfo),
          type: "message",
        })
        .then(() => {
          console.log("Message successfully sent");
        })
        .catch((error) => {
          console.error("err:", error);
        });
    }
  };
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  const saveChatMessageToDynamo = async (messageText, meetingId) => {
    const params = {
      messageText,
      meetingId,
      messageFrom: context.userInfo,
      // type: "chat",
      role: context.user.roles[0].role,
    };
    await setChat("post", params);
  };

  const detailCommand = async (studentId) => {
    proccontext.setDetail(studentId, "webcam");
    // await setCommentSave("GET", meetingId);
  };
  const showIdCardInfo = async (student) => {
    let meetingId = meetingList.filter(item => item.studentId === student.studentId)[0].meetingId.split("-")[0]
    setFaceSrc(`${getBaseUrl()}/${meetingId}/images/systemCheck/facephoto.png`);
    setIdSrc(`${getBaseUrl()}/${meetingId}/images/systemCheck/idcard.png`);
    setShowIdCard(!showIdCard)
  }

  const constructProctoringRuleItem = (rule, ruleDesc, text, notText) => {
    let RenderIcon = null;
    let color = null;
    let selectedText = null;
    if (rule) {
      RenderIcon = FaCheck;
      color = "green";
      selectedText = text;
    } else {
      RenderIcon = FaTimes;
      color = "red";
      selectedText = notText;
    }
    let ruleDescArrayElements = [];
    if (ruleDesc) {
      ruleDesc.split(",").forEach((ruleDescItem) => {
        ruleDescArrayElements.push(
          <div className="d-flex flex-row align-items-center">
            <RiArrowDropRightLine size={20} />
            <span onClick={() => openInNewTab('https://' + ruleDescItem)} className="ml-2"> <Link>{ruleDescItem}</Link></span>
          </div>
        );
      });
    }
    return (
      <div style={{ color }}>
        <div
          className="d-flex align-items-center"
          style={{ cursor: "pointer" }}
          onClick={() => {
            const selectedElement = document.getElementById(text);
            if (selectedElement.style.display === "none") {
              selectedElement.style.display = "";
            } else {
              selectedElement.style.display = "none";
            }
          }}
        >
          <RenderIcon />
          <span className="ml-2">{selectedText}</span>
          {ruleDesc && rule ? (
            <IoIosArrowDropdown
              className="ml-1"

            />
          ) : null}
        </div>
        <div id={text} style={{ display: "none" }}>
          {ruleDesc && rule ? ruleDescArrayElements : null}
        </div>
      </div>
    );
  };

  const constructProctoringRules = () => {
    const rules = [];
    if (session) {
      rules.push(
        <div
          style={{ fontWeight: "bold" }}
          onClick={() => {
            setShowExamInstructions(!showExamInstructions);
          }}
        >
          <div
            class="d-flex flex-row justify-content-start align-items-center"
            style={{ cursor: "pointer" }}
          >
            {i18n.t("Exam_Instructions")}
            {showExamInstructions ? (
              <IoIosArrowDropdown style={{ marginLeft: 5 }} />
            ) : (
              <IoIosArrowDropup style={{ marginLeft: 5 }} />
            )}
          </div>
        </div>
      );
      const subRules = [];
      if (showExamInstructions) {
        subRules.push(
          constructProctoringRuleItem(
            session.examRules.internet,
            session.examRules.internetDesc,
            i18n.t("Use_Internet").replaceAll("_", " "),
            i18n.t("Use_Internet").replaceAll("_", " ")
          )
        );
        subRules.push(
          constructProctoringRuleItem(
            session.examRules.calculator,
            session.examRules.calculatorDesc,
            i18n.t("Use_Calculator").replaceAll("_", " "),
            i18n.t("Use_Calculator").replaceAll("_", " ")
          )
        );
        subRules.push(
          constructProctoringRuleItem(
            session.examRules.application,
            session.examRules.applicationDesc,
            i18n.t("External_Application").replaceAll("_", " "),
            i18n.t("External_Application").replaceAll("_", " ")
          )
        );
        subRules.push(
          constructProctoringRuleItem(
            session.examRules.penPapers,
            session.examRules.penPapersDesc,
            i18n.t("Pen_or_Papers").replaceAll("_", " "),
            i18n.t("Pen_or_Papers").replaceAll("_", " ")
          )
        );
        subRules.push(
          constructProctoringRuleItem(
            session.examRules.textbooks,
            session.examRules.textbooksDesc,
            i18n.t("Use_Textbooks").replaceAll("_", " "),
            i18n.t("Use_Textbooks").replaceAll("_", " ")
          )
        );
        subRules.push(
          constructProctoringRuleItem(
            session.examRules.additionalMaterials,
            session.examRules.additionalMaterialsDesc,
            i18n.t("Additional_Materials").replaceAll("_", " "),
            i18n.t("Additional_Materials").replaceAll("_", " ")
          )
        );
      }
      rules.push(subRules);
    }
    return rules;
  };

  const updateSingleStudentCommentMap = async (studentId, meetingId) => {
    const newCommentResult = await getStudentCommentResults(meetingId);
    setStudentCommentMap((prevStudentCommentMap) => ({
      ...prevStudentCommentMap,
      [studentId]: newCommentResult,
    }));
  };

  const getStudentCommentResults = async (meetingId) => {
    try {
      const res = await axios.get(
        `${config.api.invokeUrl}/studentResults/${meetingId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      if (res.data) {
        return res.data;
      }
    } catch (err) {
      console.log(err);
    }
    return { comments: [] };
  };

  const getMessageList = async (meetingId) => {
    try {
      const res = await axios.get(
        `${config.api.invokeUrl}/chats/${meetingId}/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  const retrieveSelectedStudentComments = () => {
    const renderStudentCommentList = [];
    studentList.forEach((student) => {
      if (student.show) {
        const studentComment = studentCommentMap[student.studentId];
        const renderStudentCommentItem = [];
        if (studentComment && studentComment.comments) {
          studentComment.comments.forEach((comment) => {
            if (comment.type !== "AI") {
              renderStudentCommentItem.push(
                <div key={comment.time} className="d-flex flex-row">
                  <div className="font-weight-bold text-center col-md-3">
                    {`${new Date(comment.time).toLocaleTimeString()}`}
                  </div>
                  {comment.text === "Other..." ? (
                    <div className="col-md-9">{comment.otherText}</div>
                  ) : (
                    <div className="col-md-9">{comment.text}</div>
                  )}
                </div>
              );
            }
          });
        }

        renderStudentCommentList.push(
          <div>
            <span
              style={{
                fontStyle: "italic",
                color: renderStudentCommentItem.length > 0 ? "red" : "green",
              }}
            >
              {student.name} {student.lastname}{meetingList.some(item => item.studentId === student.studentId) ? <BsInfoCircle onClick={() => showIdCardInfo(student)} color="gray" /> : null}
            </span>

            {renderStudentCommentItem}
          </div>
        );
      }
    });
    return renderStudentCommentList;
  };

  const ProctoringRules = () => {
    return (
      <React.Fragment>
        <div className="d-flex flex-column">{constructProctoringRules()}</div>
        <hr />
        <div className="d-flex flex-column">
          <div className="font-weight-bold text-center mb-1">
            {i18n.t("Proctor_Incidents")}
          </div>
          {retrieveSelectedStudentComments()}
        </div>
        <hr />
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (sessionRefresh) {
      if (sessionRefresh.studentList) {
        const refreshedStudentList = sessionRefresh.studentList;
        const includedStudents = [];
        refreshedStudentList.forEach((student) => {
          const foundStudent = studentList.find(
            (searchStudent) => searchStudent.studentId === student.studentId
          );
          if (!foundStudent) {
            if (student && student.proctors) {
              const foundProctor = student.proctors.find(
                (proctor) => proctor.proctorId === context.user.username
              );
              if (foundProctor) {
                includedStudents.push(student);
              }
              if (context.user.roles.some((role) => role.role === "Admin" || role.role === "Super Admin")) {
                includedStudents.push(student);
              }
            }
          } else {
            if (foundStudent && foundStudent.proctors) {
              const foundProctor = foundStudent.proctors.find(
                (proctor) => proctor.proctorId === context.user.username
              );
              if (foundProctor) {
                if (
                  meetingSessionList[
                  `${session.sessionId}${student.studentId}-webcam`
                  ]
                ) {
                  if (
                    student.examRules &&
                    student.examRules.mobileCameraUsage
                  ) {
                    const studentMobile =
                      meetingSessionList[
                      `${session.sessionId}${student.studentId}-mobile_0`
                      ];
                    if (!studentMobile) {
                      try {
                        configureOpenviduMeetingSessionOverLambda(
                          `${session.sessionId}${student.studentId}`,
                          "SUBSCRIBER",
                          "mobile_0"
                        );
                      } catch (err) {
                        console.log(
                          "ERROR: Student to mobile connection cannot be established"
                        );
                      }
                    }
                  } else {
                    const studentMobile =
                      meetingSessionList[
                      `${session.sessionId}${student.studentId}-mobile_0`
                      ];
                    if (studentMobile) {
                      studentMobile.session.disconnect();
                      console.log("Debug: Disconnected from student session");
                    }
                  }
                  foundStudent.examRules.mobileCameraUsage =
                    student.examRules.mobileCameraUsage;
                }
              }
            }
          }
        });
        includedStudents.forEach((includedStudent) => {
          try {
            configureOpenviduMeetingSessionOverLambda(
              `${session.sessionId}${includedStudent.studentId}`,
              "SUBSCRIBER",
              "webcam"
            );
          } catch (err) {
            console.log(
              "ERROR: Student to webcam connection cannot be established"
            );
          }
          try {
            configureOpenviduMeetingSessionOverLambda(
              `${session.sessionId}${includedStudent.studentId}`,
              "SUBSCRIBER",
              "screenShare"
            );
          } catch (err) {
            console.log(
              "ERROR: Student to screenShare connection cannot be established"
            );
          }
          try {
            if (session.proctorServiceType === "Live_Proctoring_Plus") {
              for (let i = 0; i < session.allowedOutsourceCamera; i++) {
                if (
                  includedStudent.examRules &&
                  includedStudent.examRules.mobileCameraUsage
                ) {
                  configureOpenviduMeetingSessionOverLambda(
                    `${session.sessionId}${includedStudent.studentId}`,
                    "SUBSCRIBER",
                    `mobile_${i}`
                  );
                }
              }
            } else {
              if (
                includedStudent.examRules &&
                includedStudent.examRules.mobileCameraUsage
              ) {
                configureOpenviduMeetingSessionOverLambda(
                  `${session.sessionId}${includedStudent.studentId}`,
                  "SUBSCRIBER",
                  "mobile_0"
                );
              }
            }
          } catch (err) {
            console.log(
              "ERROR: Student to mobile connection cannot be established"
            );
          }
        });
        proccontext.addNewStudents(includedStudents);
        proccontext.setSize(JSON.parse(JSON.stringify(studentList)));
      }
    }
  }, [sessionRefresh]);

  const refrestStudentList = async () => {
    await setSessionRefresh("getById", {
      instituteId: session.instituteId,
      sessionId: session.sessionId,
    });
  };

  const RenderChatNotificationCount = (chatCount) => {
    if (chatCount > 0) {
      return (
        <div
          style={{
            color: "red",
            fontSize: 10,
            marginLeft: 3,
            fontWeight: "bold",
          }}
        >
          {chatCount}
        </div>
      );
    }
    return null;
  };

  const sendMonitoringEvent = (session, student, event) => {
    session
      .signal({
        data: JSON.stringify({
          state: event,
          studentId: student.studentId,
          meetingId: `${session.sessionId}`,
          proctorId: context.user.username,
        }),
        type: "monitoring",
      })
      .then(() => {
        console.log(`${session.sessionId} is monitoring`);
      })
      .catch((error) => {
        console.error("err:", error);
      });
  };

  const RenderEyeStatus = (student) => {
    const foundStudent = studentMonitoringList.find(
      (studentMonitor) => studentMonitor.studentId === student.studentId
    );
    if (foundStudent) {
      return <FaEye color="red" />;
    } else {
      return <FaEyeSlash color="gray" />;
    }
  };

  const setCallInformationFunc = (student) => {
    if (student.show) {
      setCallInformations([...callInformations, {
        name: student.name,
        lastname: student.lastname,
        studentId: student.studentId,
        sessionId: student.sessionId,
        meetingId: `${student.sessionId}${student.studentId}`,
        enabled: false,
        status: "FINISHED"
      }]);
    } else {
      setCallInformations((callInformations) =>
        callInformations && callInformations.filter((item) =>
          !(item.studentId === student.studentId))
      );
    }
  };

  const calculateCheckedStudents = () => {
    let counter = 0;
    for (const student of studentList) {
      if (student.show) {
        counter++;
      }
    }
    setCheckedStudentCounter(counter);
  }

  const subscribeAll = (student) => {
    let webcamSession;
    let screenShareSession;
    let mobile0Session;
    let mobile1Session;
    let mobile2Session;
    let mobile3Session;

    // console.log("ttt => 5");
    // console.log("ttt => meetingSessionList => ", meetingSessionList);
    Object.entries(meetingSessionList).forEach((item) => {//selected student's sessions found
      if (item[0].includes(student.studentId)) {
        if (item[0].includes("-webcam")) {
          webcamSession = item[1].session;
        } else if (item[0].includes("-screenShare")) {
          screenShareSession = item[1].session;
        } else if (item[0].includes("-mobile_0")) {
          mobile0Session = item[1].session;
        } else if (item[0].includes("-mobile_1")) {
          mobile1Session = item[1].session;
        } else if (item[0].includes("-mobile_2")) {
          mobile2Session = item[1].session;
        } else if (item[0].includes("-mobile_3")) {
          mobile3Session = item[1].session;
        }
      }
    });
    if (webcamSession) {
      let foundWebcamSubscriber = proccontext.webcamSubscriberList.find((webcamSubscriber) =>
        webcamSubscriber.stream.session.sessionId === webcamSession.sessionId);
      if (!foundWebcamSubscriber) {
        var subscriberWebcam = webcamSession.subscribe(
          meetingSessionList[
            `${session.sessionId}${student.studentId}-webcam`
          ].stream,
          undefined
        );
        proccontext.setWebcamSubscriber(subscriberWebcam);
      }
    }
    if (screenShareSession) {
      let foundScreenShareSubscriber = proccontext.screenShareSubscriberList.find((screenShareSubscriber) =>
        screenShareSubscriber.stream.session.sessionId === screenShareSession.sessionId);
      if (!foundScreenShareSubscriber) {
        var subscriberScreenShare = screenShareSession.subscribe(
          meetingSessionList[
            `${session.sessionId}${student.studentId}-screenShare`
          ].stream,
          undefined
        );
        proccontext.setScreenShareSubscriber(subscriberScreenShare);
      }
    }
    if (mobile0Session) {
      let foundMobile0Subscriber = proccontext.mobileSubscriberList.find((mobile0Subscriber) =>
        mobile0Subscriber.stream.session.sessionId === mobile0Session.sessionId);
      if (!foundMobile0Subscriber) {
        var mobile0Subscriber = mobile0Session.subscribe(
          meetingSessionList[
            `${session.sessionId}${student.studentId}-mobile_0`
          ].stream,
          undefined
        );
        proccontext.setMobileSubscriberList(mobile0Subscriber);
      }
    }
    if (mobile1Session) {
      let foundMobile1Subscriber = proccontext.mobileSubscriberList.find((mobile1Subscriber) =>
        mobile1Subscriber.stream.session.sessionId === mobile1Session.sessionId);
      if (!foundMobile1Subscriber) {
        var mobile1Subscriber = mobile1Session.subscribe(
          meetingSessionList[
            `${session.sessionId}${student.studentId}-mobile_1`
          ].stream,
          undefined
        );
        proccontext.setMobileSubscriberList(mobile1Subscriber);
      }
    }
    if (mobile2Session) {
      let foundMobile2Subscriber = proccontext.mobileSubscriberList.find((mobile2Subscriber) =>
        mobile2Subscriber.stream.session.sessionId === mobile2Session.sessionId);
      if (!foundMobile2Subscriber) {
        var mobile2Subscriber = mobile2Session.subscribe(
          meetingSessionList[
            `${session.sessionId}${student.studentId}-mobile_2`
          ].stream,
          undefined
        );
        proccontext.setMobileSubscriberList(mobile2Subscriber);
      }
    }
    if (mobile3Session) {
      let foundMobile3Subscriber = proccontext.mobileSubscriberList.find((mobile3Subscriber) =>
        mobile3Subscriber.stream.session.sessionId === mobile3Session.sessionId);
      if (!foundMobile3Subscriber) {
        var mobile3Subscriber = mobile3Session.subscribe(
          meetingSessionList[
            `${session.sessionId}${student.studentId}-mobile_3`
          ].stream,
          undefined
        );
        proccontext.setMobileSubscriberList(mobile3Subscriber);
      }
    }
  }

  const unsubscribeAll = (student) => {
    let webcamSession;
    let screenShareSession;
    let mobile0Session;
    let mobile1Session;
    let mobile2Session;
    let mobile3Session;

    Object.entries(meetingSessionList).forEach((item, key) => {//selected student's sessions found
      if (item[0].includes(student.studentId)) {
        if (item[0].includes("-webcam")) {
          webcamSession = item[1].session;
          // console.log("test5 unsubscribe olacak webcamSession => ", webcamSession);
        } else if (item[0].includes("-screenShare")) {
          screenShareSession = item[1].session;
          // console.log("test5 unsubscribe olacak screenShareSession => ", screenShareSession);
        } else if (item[0].includes("-mobile_0")) {
          mobile0Session = item[1].session;
          // console.log("test5 unsubscribe olacak mobile-0 => ", mobile0Session);
        } else if (item[0].includes("-mobile_1")) {
          mobile1Session = item[1].session;
          // console.log("test5 unsubscribe olacak mobile-1 => ", mobile1Session);
        } else if (item[0].includes("-mobile_2")) {
          mobile2Session = item[1].session;
          // console.log("test5 unsubscribe olacak mobile-2 => ", mobile2Session);
        } else if (item[0].includes("-mobile_3")) {
          mobile3Session = item[1].session;
          // console.log("test5 unsubscribe olacak mobile-3 => ", mobile3Session);
        }
      }
    })
    proccontext.webcamSubscriberList.forEach((subscriber, index) => {//subscriber object found
      if (subscriber.stream.session.sessionId.includes(student.studentId)) {
        // console.log("test5 unsubscribe oluyor webcam => ", subscriber);
        webcamSession.unsubscribe(subscriber);//webcam is unsubscribed from session
        // console.log("test5 webcamSubscriberList => ", proccontext.webcamSubscriberList);
        proccontext.webcamSubscriberList.splice(index, 1);//delete from subsriber list.
      }
    });
    proccontext.screenShareSubscriberList.forEach((subscriber, index) => {//subscriber object found
      if (subscriber.stream.session.sessionId.includes(student.studentId)) {
        // console.log("test5 unsubscribe oluyor screenshare => ", subscriber)
        screenShareSession.unsubscribe(subscriber);//screenShare is unsubscribed from session
        // console.log("test5 screenShareSubscriberList =>", proccontext.screenShareSubscriberList);
        proccontext.screenShareSubscriberList.splice(index, 1);//deleted from subsriber list.
      }
    });
    const itemsToDelete = [];
    proccontext.mobileSubscriberList.forEach((subscriber, index) => {//subscriber object found
      if (subscriber.stream.session.sessionId.includes(student.studentId)) {
        // console.log("test5 unsubscribe oluyor mobil => ", subscriber);
        if (subscriber.stream.session.sessionId.includes("-mobile_0")) {
          // console.log("test5 unsubscribe oluyor mobil-0 => ", subscriber)
          mobile0Session.unsubscribe(subscriber);//mobile0 is unsubscribed from session
        } else if (subscriber.stream.session.sessionId.includes("-mobile_1")) {
          // console.log("test5 unsubscribe oluyor mobil-1 => ", subscriber)
          mobile1Session.unsubscribe(subscriber);//mobile1 is unsubscribed from session
        } else if (subscriber.stream.session.sessionId.includes("-mobile_2")) {
          // console.log("test5 unsubscribe oluyor mobil-2 => ", subscriber)
          mobile2Session.unsubscribe(subscriber);//mobile2 is unsubscribed from session
        } else if (subscriber.stream.session.sessionId.includes("-mobile_3")) {
          // console.log("test5 unsubscribe oluyor mobil-3 => ", subscriber)
          mobile3Session.unsubscribe(subscriber);//mobile3 is unsubscribed from session
        }
        // console.log("test5 mobileSubscriberList =>", proccontext.mobileSubscriberList);
         itemsToDelete.push(index);
        //proccontext.mobileSubscriberList.splice(index, 1);
      }
    });
    const newMobileSubscriberList = proccontext.mobileSubscriberList.filter(
      (item, index) => !itemsToDelete.includes(index));
    // let newMobileSubscriberList = [];
    // itemsToDelete.forEach((index) => {
    //   const indexToDelete = index - 1 < 0 ? 0 : index - 1;
    //   newMobileSubscriberList = proccontext.mobileSubscriberList.splice(indexToDelete, 1);
    // }

    // );
    // console.log("----------SERKAN-------------",newMobileSubscriberList);
    proccontext.setMobileSubscriberListNew(newMobileSubscriberList);

    webcamSession
      .signal({
        data: JSON.stringify({
          state: 'PROCTOR_CALL_ENDED',
        }),
        type: 'system',
      })
      .then(() => {
        console.log('Proctor call ended');
      })
      .catch((error) => {
        console.error('err:', error);
      });
  }

  const createConnection = async (student) => {
    const foundConnected = isConnectedList.find((item) => item.studentId === student.studentId);
    if (!foundConnected) {
      await configureMeetingSessionList(student);
    }
  }

  const ProctoringStudents = (isProctor) => {
    let visible = "";
    if (isProctor) {
      visible = "none";
      studentList.forEach((element) => {
        if (element.proctor) visible = "";
      });
    }
    return (
      <div
        className="table-responsive"
        style={{
          display: visible,
          height: isProctor ? "250px" : "calc(100vh - 400px)",
        }}
      >
        <table
          className="table table-bordered"
          id="table"
          width="100%"
          cellSpacing="0"
        >
          <thead>
            <tr>
              <th>{isProctor ? i18n.t("Proctors") : i18n.t("Test_takers")}</th>
              {isProctor ? null : <th>AI</th>}
              <th>{i18n.t("Select")}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {studentList.map((student, i) =>
              student.proctor === isProctor ? (
                <tr
                  key={i}
                  //TODOFINISH

                  className={`${student.proctor ? "text-primary" : meetingList.some(item => item.studentId === student.studentId) ? "text-success" : null} `}
                >
                  <td>
                    {student.online ? (
                      <div className="d-flex flex-row">
                        {student.proctor ? (
                          <div classname={"text-primary"}>
                            {student.name} {student.lastname} {meetingList.some(item => item.studentId === student.studentId) ? <BsInfoCircle onClick={() => showIdCardInfo(student)} color="gray" /> : null}
                          </div>
                        ) : (
                          <Link
                            onClick={() => detailCommand(student.studentId)}
                            to="#"
                            className={`${student.proctor ? "text-primary" : null}
                            }`}
                          >
                            {student.name} {student.lastname} {"  "} {meetingList.some(item => item.studentId === student.studentId) ? <BsInfoCircle onClick={() => showIdCardInfo(student)} color="gray" /> : null}
                          </Link>
                        )}

                        {RenderChatNotificationCount(student.chatCount)}
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className={`${meetingList.some(item => item.studentId === student.studentId) ? "text-success" : "text-danger"}`}>
                          {student.name} {student.lastname}{"  "}{meetingList.some(item => item.studentId === student.studentId) ? <BsInfoCircle onClick={() => showIdCardInfo(student)} color="gray" /> : null}
                        </div>
                      </React.Fragment>
                    )}
                  </td>

                  {/* {meetingList.some(item => item.studentId === student.studentId && item.state === "ACTIVE") ? ( */}
                  {student.online ? (
                    <React.Fragment>
                      {isProctor ? null : (
                        <td className={student.color}>{student.aiScore}</td>
                      )}

                      <td align="center">
                        <input
                          type="checkbox"
                          checked={student.show}
                          onChange={async (e) => {
                            let tempChecked = e.target.checked;
                            // console.log("ttt => 3");
                            const maxAllowedTT = 12;//maximum number of TT's that can be subscribed
                            calculateCheckedStudents();
                            if (context.userRole.includes("Super Admin")) {
                              if (!student.show) {//checknox checked
                                console.log("tesst 333");
                                createConnection(student);
                                if (checkedStudentCounter < maxAllowedTT) {
                                  // console.log("ttt => 4");
                                  setCheckedStudentCounter(prevCounter => prevCounter + 1);
                                  subscribeAll(student);
                                  setTrigger(!trigger);//addVideoElement to the DOM over OneMeeting.js
                                } else { //max checked student reached
                                  store.addNotification({
                                    title: null,
                                    message: `Supervisor cannot watch people over ${maxAllowedTT}. Please uncheck at least one people to watch others.`,
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animate__animated", "animate__fadeIn"],
                                    animationOut: ["animate__animated", "animate__fadeOut"],
                                    dismiss: {
                                      duration: 5000,
                                      onScreen: true,
                                    },
                                  });
                                }

                              } else {//checknox unchecked
                                setCheckedStudentCounter(prevCounter => prevCounter - 1);
                                unsubscribeAll(student);
                              }
                              // console.log("ttt => 6");
                              if (!tempChecked)//unchecked always allowed.
                                proccontext.setCheckStudent(i, tempChecked);
                              else if (checkedStudentCounter < maxAllowedTT) {//checked control.
                                proccontext.setCheckStudent(i, tempChecked);
                              }
                            } else {
                              if (!student.show) {
                                subscribeAll(student);
                                setTrigger(!trigger);//addVideoElement to the DOM over OneMeeting.js
                              } else {
                                unsubscribeAll(student);
                              }
                            }
                            // console.log("ttt => 7");
                            if (!context.userRole.includes("Super Admin")) {
                              proccontext.setCheckStudent(i, tempChecked);
                            }
                            const selectedMeeting =
                              meetingSessionList[
                              `${session.sessionId}${student.studentId}-webcam`
                              ];
                            if (selectedMeeting) {
                              setCallInformationFunc(student);
                              if (student.show) {
                                let updatedStudentList = [];
                                checkedStudentList.forEach(item => {
                                  updatedStudentList.push(item);
                                });
                                updatedStudentList.push(student);
                                setCheckedStudentList(updatedStudentList);
                                sendMonitoringEvent(
                                  selectedMeeting.session,
                                  student,
                                  "MONITORING"
                                );
                                const intervalId = setInterval(() => {
                                  sendMonitoringEvent.bind(
                                    null,
                                    selectedMeeting.session,
                                    student,
                                    "MONITORING"
                                  )
                                },
                                  10000
                                );
                                sendMonitoringIntervalList.push({
                                  studentId: student.studentId,
                                  intervalId,
                                });
                                setSendMonitoringIntervalList(
                                  sendMonitoringIntervalList
                                );
                                // sendMonitoringEvent(
                                //   selectedMeeting.session,
                                //   student,
                                //   "MONITORING"
                                // );

                              } else {
                                let updatedStudentList = [];
                                checkedStudentList.forEach(item => {
                                  updatedStudentList.push(item);
                                });
                                updatedStudentList.forEach((element, index) => {
                                  if (element.studentId === student.studentId) {
                                    updatedStudentList.splice(index, 1);
                                  }
                                });
                                setCheckedStudentList(updatedStudentList);
                                proccontext.storeMuted(true, student.studentId);
                                sendMonitoringIntervalList.forEach(
                                  async (monitoringInterval, index) => {
                                    if (monitoringInterval.studentId === student.studentId) {
                                      await clearInterval(monitoringInterval.intervalId);
                                      await sendMonitoringIntervalList.splice(index, 1);
                                    }
                                  }
                                );
                                sendMonitoringEvent(
                                  selectedMeeting.session,
                                  student,
                                  "NOT_MONITORING"
                                );
                              }
                            }
                            // console.log("ttt => 9");
                          }}
                        ></input>
                      </td>
                      <td>{RenderEyeStatus(student)}</td>
                      <td></td>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{meetingList.some(item => item.studentId === student.studentId && item.state === "ACTIVE") && !student.online && !isConnectedList.find((item) => item.studentId === student.studentId) && context.userRole.includes("Super Admin") &&
                        <button onClick={async () => await configureMeetingSessionList(student)}>{<GrConnect />}</button>
                      }</td>
                    </React.Fragment>
                  )}
                </tr>
              ) : null
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const fullScreen = (e) => {
    if (e.target.requestFullscreen) {
      e.target.requestFullscreen();
    } else if (e.target.mozRequestFullScreen) {
      e.target.mozRequestFullScreen();
    } else if (e.target.webkitRequestFullscreen) {
      e.target.webkitRequestFullscreen();
    } else if (e.target.msRequestFullscreen) {
      e.target.msRequestFullscreen();
    }
  };

  const constructMobileOneMeeting = (student) => {
    const mobileOneMeetingArray = [];
    if (session.proctorServiceType === "Live_Proctoring_Plus") {
      if (student.examRules && student.examRules.mobileCameraUsage) {
        for (let i = 0; i < session.allowedOutsourceCamera; i++) {
          mobileOneMeetingArray.push(
            <OneMeeting
              checkedStudentList={checkedStudentList}
              trigger={trigger}
              fullScreen={fullScreen}
              Student={student}
              SessionId={session.sessionId}
              showToProctor={session.showToProctor}
              isAIactive={session.isAIactive}
              col={4}
              cam={`mobile_${i}`}
              mobileIndex={[i]}
              disconnected={connected}
              mobileMeetingInformation={[
                meetingSessionList[
                `${session.sessionId}${student.studentId}-mobile_${i}`
                ],
              ]}
              updateSingleStudentCommentMap={updateSingleStudentCommentMap}
              proctorServiceType={session.proctorServiceType}
              appropriateSessionProctoringType={
                appropriateSessionProctoringType
              }
            />
          );
        }
      }
    } else {
      if (student.examRules && student.examRules.mobileCameraUsage) {
        mobileOneMeetingArray.push(
          <OneMeeting
            checkedStudentList={checkedStudentList}
            trigger={trigger}
            fullScreen={fullScreen}
            Student={student}
            SessionId={session.sessionId}
            showToProctor={session.showToProctor}
            isAIactive={session.isAIactive}
            col={4}
            cam={`mobile_0`}
            disconnected={connected}
            mobileIndex={[0]}
            mobileMeetingInformation={[
              meetingSessionList[
              `${session.sessionId}${student.studentId}-mobile_0`
              ],
            ]}
            updateSingleStudentCommentMap={updateSingleStudentCommentMap}
            proctorServiceType={session.proctorServiceType}
            appropriateSessionProctoringType={appropriateSessionProctoringType}
          />
        );
      }
    }
    return mobileOneMeetingArray;
  };

  const viewDetailProctor = () => {
    return (
      <React.Fragment>
        {studentList.map((student, i) =>
          student.show && student.proctor ? (
            <React.Fragment>
              <div className="row m-0 p-0 w-100" id="detailArea">
                <OneMeeting
                  trigger={trigger}
                  checkedStudentList={checkedStudentList}
                  chatSession={chatSession}
                  setChatSession={setChatSession}
                  chatPanelVisible={chatPanelVisible}
                  setChatPanelVisible={setChatPanelVisible}
                  fullScreen={fullScreen}
                  Student={student}
                  disconnected={connected}
                  SessionId={session.sessionId}
                  showToProctor={session.showToProctor}
                  isAIactive={session.isAIactive}
                  col={
                    student.examRules && student.examRules.mobileCameraUsage
                      ? 4
                      : 6
                  }
                  cam="webcam"
                  webcamMeetingInformation={
                    meetingSessionList[
                    `${session.sessionId}${student.studentId}-webcam`
                    ]
                  }
                  callInformations={callInformations}
                  setCallInformations={setCallInformations}
                  updateSingleStudentCommentMap={updateSingleStudentCommentMap}
                  proctorServiceType={session.proctorServiceType}
                  appropriateSessionProctoringType={
                    appropriateSessionProctoringType
                  }
                />
                <OneMeeting
                  trigger={trigger}
                  checkedStudentList={checkedStudentList}
                  fullScreen={fullScreen}
                  Student={student}
                  SessionId={session.sessionId}
                  disconnected={connected}
                  showToProctor={session.showToProctor}
                  isAIactive={session.isAIactive}
                  col={
                    student.examRules && student.examRules.mobileCameraUsage
                      ? 4
                      : 6
                  }
                  cam="screenShare"
                  screenShareMeetingInformation={
                    meetingSessionList[
                    `${session.sessionId}${student.studentId}-screenShare`
                    ]
                  }
                  updateSingleStudentCommentMap={updateSingleStudentCommentMap}
                  proctorServiceType={session.proctorServiceType}
                  appropriateSessionProctoringType={
                    appropriateSessionProctoringType
                  }
                />
                {constructMobileOneMeeting(student)}

                {/* {student.examRules && student.examRules.mobileCameraUsage ? (
                  <OneMeeting
                    fullScreen={fullScreen}
                    Student={student}
                    SessionId={session.sessionId}
                    col={4}
                    cam="mobile"
                    mobileMeetingInformation={
                      meetingSessionList[
                        `${session.sessionId}${student.studentId}-mobile_0`
                      ]
                    }
                    updateSingleStudentCommentMap={
                      updateSingleStudentCommentMap
                    }
                    proctorServiceType={session.proctorServiceType}
                    appropriateSessionProctoringType={
                      appropriateSessionProctoringType
                    }
                  />
                ) : null} */}
              </div>
            </React.Fragment>
          ) : null
        )}
      </React.Fragment>
    );
  };

  const viewDetail = () => {
    return (
      <React.Fragment>
        {studentList.map((student, i) =>
          student.detail === true ? (
            <React.Fragment>
              <div className="row m-0 p-0 w-100" id="detailArea">
                <OneMeeting
                  trigger={trigger}
                  checkedStudentList={checkedStudentList}
                  chatSession={chatSession}
                  setChatSession={setChatSession}
                  chatPanelVisible={chatPanelVisible}
                  setChatPanelVisible={setChatPanelVisible}
                  fullScreen={fullScreen}
                  Student={student}
                  disconnected={connected}
                  SessionId={session.sessionId}
                  showToProctor={session.showToProctor}
                  isAIactive={session.isAIactive}
                  col={
                    student.examRules && student.examRules.mobileCameraUsage
                      ? 4
                      : 6
                  }
                  cam="webcam"
                  webcamMeetingInformation={
                    meetingSessionList[
                    `${session.sessionId}${student.studentId}-webcam`
                    ]
                  }
                  callInformations={callInformations}
                  setCallInformations={setCallInformations}
                  updateSingleStudentCommentMap={updateSingleStudentCommentMap}
                  proctorServiceType={session.proctorServiceType}
                  appropriateSessionProctoringType={
                    appropriateSessionProctoringType
                  }
                />
                <OneMeeting
                  trigger={trigger}
                  checkedStudentList={checkedStudentList}
                  fullScreen={fullScreen}
                  Student={student}
                  SessionId={session.sessionId}
                  showToProctor={session.showToProctor}
                  isAIactive={session.isAIactive}
                  disconnected={connected}
                  col={
                    student.examRules && student.examRules.mobileCameraUsage
                      ? 4
                      : 6
                  }
                  cam="screenShare"
                  screenShareMeetingInformation={
                    meetingSessionList[
                    `${session.sessionId}${student.studentId}-screenShare`
                    ]
                  }
                  updateSingleStudentCommentMap={updateSingleStudentCommentMap}
                  proctorServiceType={session.proctorServiceType}
                  appropriateSessionProctoringType={
                    appropriateSessionProctoringType
                  }
                />
                {constructMobileOneMeeting(student)}
                {/* {student.examRules && student.examRules.mobileCameraUsage ? (
                  <OneMeeting
                    fullScreen={fullScreen}
                    Student={student}
                    SessionId={session.sessionId}
                    col={4}
                    cam="mobile"
                    mobileMeetingInformation={
                      meetingSessionList[
                      `${session.sessionId}${student.studentId}-mobile_0`
                      ]
                    }
                    updateSingleStudentCommentMap={
                      updateSingleStudentCommentMap
                    }
                    proctorServiceType={session.proctorServiceType}
                    appropriateSessionProctoringType={
                      appropriateSessionProctoringType
                    }
                  />
                ) : null} */}
              </div>
            </React.Fragment>
          ) : null
        )}
      </React.Fragment>
    );
  };

  const constructMobileMeetingInformation = (student) => {
    const mobileMeetingInformation = [];
    const mobileMeetingInformationIndex = [];
    if (session.proctorServiceType === "Live_Proctoring_Plus") {
      if (student.examRules && student.examRules.mobileCameraUsage) {
        for (let i = 0; i < session.allowedOutsourceCamera; i++) {
          const foundMobileSession =
            meetingSessionList[
            `${session.sessionId}${student.studentId}-mobile_${i}`
            ];
          if (foundMobileSession) {
            mobileMeetingInformation.push(foundMobileSession);
            mobileMeetingInformationIndex.push(i);
          }
        }
      }
    } else {
      const foundMobileSession =
        meetingSessionList[`${session.sessionId}${student.studentId}-mobile_0`];
      if (foundMobileSession) {
        mobileMeetingInformation.push(foundMobileSession);
        mobileMeetingInformationIndex.push(0);
      }
    }
    return [mobileMeetingInformationIndex, mobileMeetingInformation];
  };

  const viewMulti = () => {
    return (
      <React.Fragment>
        <div className="row ml-0 p-0 w-100">
          {studentList.map((student, i) =>
            student.detail === false && student.proctor === false ? (
              <OneMeeting
                trigger={trigger}
                checkedStudentList={checkedStudentList}
                chatSession={chatSession}
                setChatSession={setChatSession}
                chatPanelVisible={chatPanelVisible}
                setChatPanelVisible={setChatPanelVisible}
                fullScreen={fullScreen}
                col={6}
                key={i}
                id={student.studentId}
                disconnected={connected}
                videoPlayerNo={i}
                Student={student}
                SessionId={session.sessionId}
                showToProctor={session.showToProctor}
                isAIactive={session.isAIactive}
                webcamMeetingInformation={
                  meetingSessionList[
                  `${session.sessionId}${student.studentId}-webcam`
                  ]
                }
                screenShareMeetingInformation={
                  meetingSessionList[
                  `${session.sessionId}${student.studentId}-screenShare`
                  ]
                }
                mobileIndex={constructMobileMeetingInformation(student)[0]}
                mobileMeetingInformation={
                  constructMobileMeetingInformation(student)[1]
                }
                callInformations={callInformations}
                setCallInformations={setCallInformations}
                updateSingleStudentCommentMap={updateSingleStudentCommentMap}
                proctorServiceType={session.proctorServiceType}
                appropriateSessionProctoringType={
                  appropriateSessionProctoringType
                }
              />
            ) : null
          )}
        </div>
      </React.Fragment>
    );
  };

  const ProctoringMulti = () => {
    return (
      <React.Fragment>
        {viewDetail()}
        {viewMulti()}
      </React.Fragment>
    );
  };

  const sendFinishExamMessage = () => {
    Object.keys(meetingSessionList).forEach((sessionName) => {
      if (sessionName.includes("webcam")) {
        sendMessage(sessionName);
      }
    });
  };

  const sendMessage = (sessionName) => {
    const currentSession = meetingSessionList[sessionName].session;
    if (sessionName.includes(callInformation.meetingId)) {
      currentSession
        .signal({
          data: JSON.stringify({
            state: "PROCTOR_CALL_ENDED",
          }),
          type: "system",
        })
        .then(() => {
          console.log("Proctor call ended");
        })
        .catch((error) => {
          console.error("err:", error);
        });
      setCallInformations((callInformations) =>
        callInformations.filter((callInfo) => {
          if (callInfo.status !== "FINISHED") {
            callInfo.enabled = false;
            callInfo.status = "FINISHED";
          }
          return callInfo;
        })
      );
    }

    currentSession
      .signal({
        data: JSON.stringify({
          state: "EXAM_FINISH_BY_PROCTOR",
          meetingId: `${session.sessionId}${context.user.username}`,
          proctorId: context.user.username,
        }),
        type: "system",
      })
      .then(() => {
        console.log(`${sessionName} session finished`);
      })
      .catch((error) => {
        console.error("err:", error);
      });
  };

  const finishExam = async () => {
    let isThereOnlineStudent = false
    for (const student of studentList) {
      if (student.online) {
        isThereOnlineStudent = true;
        break;
      }
    }
    if (isThereOnlineStudent) {
      console.log("asdf => inexamde olan öğrenci veya öğrenciler bulunmaktadir.");
    }

    if (session.proctorRecording) {
      const data = {
        instituteId: session.instituteId,
        meetingId: `${session.sessionId}${context.user.username}`
      };
      axios.post(
        `${config.api.invokeUrl}${config.openvidu.kms.endpoints.session.context}${config.openvidu.kms.endpoints.session.close.context}/proctor`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
    }
    const settings = props.settings;
    if (settings) {
      if (settings.pc.webcam.active) {
        settings.pc.webcam.session.disconnect();
      }
      if (settings.pc.screenShare.active) {
        settings.pc.screenShare.session.disconnect();
      }
    }
    props.setPageState(3);
    try {
      sendFinishExamMessage();
    } catch (err) {
      console.log(err);
    }
  };

  const sendBreakMessage = () => {
    const currentIsBreak = !isBreak;
    const breakMessage = currentIsBreak
      ? "PROCTOR_BREAK_STARTED"
      : "PROCTOR_BREAK_ENDED";
    const filteredMeetingSessionKeys = Object.keys(
      meetingSessionList
    ).filter((meetingSessionKey) => meetingSessionKey.includes("webcam"));

    for (const meetingSessionKey of filteredMeetingSessionKeys) {
      meetingSessionList[meetingSessionKey].session.signal({
        data: JSON.stringify({
          state: breakMessage,
          meetingId: `${session.sessionId}${context.user.username}`,
          proctorId: context.user.username,
          fullName: `${context.user.name} ${context.user.lastname}`,
        }),
        type: "system",
      });
    }
    setIsBreak(currentIsBreak);
  };

  const buttonList = () => {
    return (
      <div className="d-flex flex-row align-items-center">
        <div className="d-flex flex-row">
          <div
            className="btn btn-light d-flex m-auto flex-column justify-content-center align-items-center p-2"
            style={{
              border: "1px solid green",
              borderRadius: 40,
              height: 40,
              width: 50,
            }}
            onClick={refrestStudentList}
          >
            <MdRefresh color="green" size={20} />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center m-2 ml-5">
            <div>{i18n.t("Test_taker_List")}</div>
            <Switch
              checked={proctoringRulesOpen}
              onChange={() => {
                setProctoringRulesOpen(!proctoringRulesOpen);
                proccontext.setProctoringStudentListPanel();
              }}
            />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center m-2">
            <div>{i18n.t("Instructions")}</div>
            <Switch
              checked={studentListPanelOpen}
              onChange={() => {
                setStudentListPanelOpen(!studentListPanelOpen);
                proccontext.setProctoringRulesPanel();
              }}
            />
          </div>
        </div>
        <div className="d-flex m-auto font-weight-bold">
          {session.sessionName}
        </div>
        <div className="d-flex flex-row">
          {context.userRole.includes("Admin") ? null : (
            <button
              onClick={sendBreakMessage}
              className={
                isBreak === true ? "btn btn-danger m-2" : "btn btn-success m-2"
              }
            >
              {isBreak === true
                ? i18n.t("Return_Proctoring")
                : i18n.t("Take_a_Break")}
            </button>
          )}
          {context.userRole.includes("Proctor") ||
            context.userRole.includes("Super Admin") ? (
            <button onClick={() => {
              setConfirmFinish(!confirmFinish);
            }} className="btn btn-danger m-2">
              {i18n.t("Finish_Exam")}
            </button>
          ) : null}
        </div>
      </div>
    );
  };
  const handleClose = () => {
    setShowIdCard(false);
  };
  const handleCloseCallInformationModal = () => {
    setCallInformations((callInformations) =>
      callInformations.filter((callInfo) => {
        if (callInfo.status !== "FINISHED") {
          callInfo.enabled = false;
          callInfo.status = "FINISHED";
          proccontext.storeMuted(true, callInfo.studentId);
        }
        return callInfo;
      })
    );
  };

  const handleConfirmCallInformationModal = () => {
    const currentSession =
      meetingSessionList[
        `${session.sessionId}${callInformation.studentId}-webcam`
      ].session;
    currentSession
      .signal({
        data: JSON.stringify({
          state: "PROCTOR_CALL",
          meetingId: `${session.sessionId}${context.user.username}`,
          proctorId: context.user.username,
        }),
        type: "system",
      })
      .then(() => {
        console.log("Waiting for student to accept the call");
      })
      .catch((error) => {
        console.error("err:", error);
      });
    setCallInformations((callInformations) =>
      callInformations.filter((callInfo) => {
        if (callInfo.status === "WAITING" || callInfo.status === "ACCEPTED") {
          callInfo.enabled = false;
        }
        return callInfo;
      })
    );
    setCallInformation({
      ...callInformation,
      enabled: false,
    });
  };
  const handleCloseConfirmFinish = () => {
    setConfirmFinish(!confirmFinish);
  }
  return (
    <React.Fragment>
      <div className="container-fluid w-100">
        <div className="row">
          {proccontext.proctoringStudentListPanel ? (
            <div className="col-md-2 p-1" style={{ position: "relative" }}>
              {ProctoringStudents(false)}
              {ProctoringStudents(true)}
            </div>
          ) : null}
          <div className="col">
            {buttonList()}
            {ProctoringMulti()}
            <div
              style={{
                height: 1,
                width: "100%",
                borderBottom: "2px solid #29377e",
                marginBottom: 15,
                marginTop: 15,
              }}
            />
            {viewDetailProctor()}
          </div>
          {proccontext.proctoringRulesPanel ? (
            <div className="col-2">{ProctoringRules()}</div>
          ) : null}
        </div>

        {chatPanelVisible ? (
          <div
            className="card-group"
            style={{
              width: "300px",
              position: "absolute",
              bottom: 0,
              right: 0,
              marginBottom: 0,
            }}
          >
            {
              isSuperAdmin && checkedStudentList.length > 0
                ?
                <>
                  <ChatCompForSuperAdmin
                    // chatSessionRef={chatSessionRef}
                    sendTextMessage={sendTextMessage}
                    checkedStudentList={checkedStudentList}
                    chatSession={chatSession}
                    setChatSession={setChatSession}
                    sessionId={session.sessionId}
                    meetingSessionList={meetingSessionList}
                    setMessageList={setMessageList}
                    messageList={messageList}
                    currentChatMessages={currentChatMessages}
                    setCurrentChatMessages={setCurrentChatMessages}
                    saveChatMessageToDynamo={saveChatMessageToDynamo}
                  />
                </>
                :
                <></>
            }

            {
              !isSuperAdmin && liveStudentList.length > 0
                ?
                <>
                  <ChatComp
                    // chatSessionRef={chatSessionRef}
                    sendTextMessage={sendTextMessage}
                    liveStudentList={liveStudentList}
                    chatSession={chatSession}
                    setChatSession={setChatSession}
                    sessionId={session.sessionId}
                    meetingSessionList={meetingSessionList}
                    setMessageList={setMessageList}
                    messageList={messageList}
                    currentChatMessages={currentChatMessages}
                    setCurrentChatMessages={setCurrentChatMessages}
                    saveChatMessageToDynamo={saveChatMessageToDynamo}
                  />
                </>
                :
                <></>
            }
          </div>
        ) : null}
      </div>
      <ModalForm
        show={showIdCard}
        handleClose={handleClose}
        Title={"Student Details"}
      >
        <div className="row">
          <div style={{
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 5,
          }}>
            <h4 >Face Photo</h4>
            <Card className="col-md-12 p-0">
              <Card.Img
                src={faceSrc}
                variant="bottom"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 5,
                }}
              />
            </Card>
            <h4>Id Photo</h4>
            <Card className="col-md-12 p-0">
              <Card.Img
                src={idSrc}
                variant="bottom"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 5,
                }}
              />
            </Card>
          </div>
        </div>
      </ModalForm>
      <ModalForm
        show={callInformation.enabled}
        handleClose={handleCloseCallInformationModal}
        handleDelete={handleConfirmCallInformationModal}
        Title={`Are you sure do you want to call ${callInformation.name}?`}
        confirmButtonName={"Call"}
        Body={null}
      />
      <ModalForm
        show={confirmFinish}
        handleClose={handleCloseConfirmFinish}
        handleDelete={finishExam}
        Title={`Are you sure do you want to finish exam ?`}
        confirmButtonName={"Finish"}
        Body={null}
      />
    </React.Fragment>
  );
}
export default withNamespaces()(Proctoring);
