import { useState, useContext, useEffect } from "react";
import { awscontext } from "../configs/awscontext";
import { Auth } from "aws-amplify";
import axios from "axios";
import i18n from "../../src/configs/i18n";
const config = require("../configs/awsconfig.json");
export const useAuth = () => {
  const context = useContext(awscontext);
  const [result, setResult] = useState("");

  useEffect(() => {
    const result = localStorage.getItem("instituteLanguage");
    if (result) {
      i18n.changeLanguage(result);
    }
  }, []);
  const getUserDataFromDynamo = async (user) => {
    try {
      const accessToken = Object.keys(localStorage).find((key) =>
        key.includes("accessToken")
      );
      const res = await axios.get(
        `${config.api.invokeUrl}/staffs/${user.username}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(accessToken)}`,
          },
        }
      );
      if (res.data.length > 0) {
        context.setUser(res.data[0]);
        let useDefault = true;
        const storedInstituteId = localStorage.getItem("instituteId");
        if (storedInstituteId) {
          const foundInstitute = res.data[0].institutes.find(
            (institute) => institute.instituteId === storedInstituteId
          );
          if (foundInstitute) {
            useDefault = false;
          }
        }
        
        if (useDefault) {
          localStorage.setItem(
            "instituteId",
            res.data[0].institutes[0].instituteId
          );
          localStorage.setItem(
            "instituteName",
            res.data[0].institutes[0].instituteName
          );
          localStorage.setItem(
            "instituteLanguage",
            res.data[0].institutes[0].instituteLanguage
          );
        }
      }
      return res.data[0];
    } catch (err) {
      
      console.log("institute bilgisi :",err)
      setResult(`An error has occurred: ${err}`);
      console.log(`An error has occurred: ${err}`);
    }
    return null;
  };
 
  const login = async (params) => {
    try {
      
      const user = await Auth.signIn(params.username, params.password);

      localStorage.setItem("username", user.username);
      const param = {
        username: user.username,
      };
      getUserDataFromDynamo(param).then(() => {
        const status = user.status;
        if (status !== "DEACTIVE") {
          context.setAuthStatus(true);
          setResult("success");
          //i18n.changeLanguage(locale);
        } else {
          setResult("DEACTIVE");
        }
      });
    } catch (error) {
      setResult(error.message);
    }
  };
  const forgot = async (params) => {
    try {
      await Auth.forgotPassword(params.forgotemail);
      setResult("success");
    } catch (error) {
      setResult(error.message);
    }
  };
  const confirm = async (params) => {
    await Auth.forgotPasswordSubmit(
      params.confirmemail,
      params.verificationcode,
      params.newpassword
    )
      .then((data) => {
        setResult("success");
      })
      .catch((err) => {
        console.log(err);
        setResult(err.message);
      });
  };
  const logo = async () => {
    try {
      const res = await axios.get(
        `${config.api.invokeUrl}/institutes/${context.user.institutes[0].instituteId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      context.setLogoInstitute(res.data.logos);
    } catch (err) {
      //console.log(`Unable to API: ${err}`);
    }
  };

  // const updateuser = async (params) => {
  //   const updateuserparam = { username: params };
  //   try {

  //     await getUserDataFromDynamo(updateuserparam);
  //     setResult("success");
  //   } catch (error) {
  //     setResult(error.message);
  //   }
  // };

  const handleChange = async (type, params) => {
    switch (type) {
      case "login":
        await login(params);
        break;
      case "forgot":
        await forgot(params);
        break;
      case "confirm":
        await confirm(params);
        break;
      case "logo":
        logo();
        break;
      case "updateUser":
        return await getUserDataFromDynamo(params);
      default:
        break;
    }
  };
  return [result, handleChange];
};
