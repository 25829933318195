import React from "react";
import i18n from "../../configs/i18n";
import { withNamespaces } from "react-i18next";
import { FaSync } from "react-icons/fa";

function SearchBar(props) {
  const searchClear = () => {
    if (props.updateDatas) {
      document.getElementById("search").value = "";
      props.updateDatas();
    }
  };



  const searchInput = (input) => {
    if (props.pureList) {
      if (props.pureList.length === 0) return;
      if (
        props.pureList[0].reportId &&
        props.pureList[0].sessionName &&
        props.pureList[0].userId
      ) {
        props.setList(
          props.pureList.filter(
            (item) =>
              item.reportId
                .toLowerCase()
                .includes(input.target.value.toLowerCase()) ||
              (!!item.sessionName &&
                item.sessionName
                  .toLowerCase()
                  .includes(input.target.value.toLowerCase()))
          )
        );
      }


      else if (props.pureList[0].proctorFullName) {
        props.setList(
          props.pureList.filter(
            (item) =>
              item.proctorFullName.toLowerCase().includes(input.target.value.toLowerCase()) ||
              item.proctorMail.toLowerCase().includes(input.target.value.toLowerCase()) ||
              item.proctorLastName.toLowerCase().includes(input.target.value.toLowerCase())
          )
        );
      }



      else if (props.pureList[0].id && props.pureList[0].instituteName) {
        props.setList(
          props.pureList.filter(
            (item) =>
              item.instituteName
                .toLowerCase()
                .includes(input.target.value.toLowerCase()) ||
              item.id.toLowerCase().includes(input.target.value.toLowerCase())
          )
        );
      } else if (
        props.pureList[0].email &&
        props.pureList[0].name &&
        props.pureList[0].lastname
      ) {
        props.setList(
          props.pureList.filter(
            (item) =>
              item.email
                .toLowerCase()
                .includes(input.target.value.toLowerCase()) ||
              item.name
                .toLowerCase()
                .includes(input.target.value.toLowerCase()) ||
              item.lastname
                .toLowerCase()
                .includes(input.target.value.toLowerCase())
          )
        );
      } else if (props.pureList[0].sessionId && props.pureList[0].sessionName) {
        props.setList(
          props.pureList.filter(
            (item) =>
              item.sessionId
                .toLowerCase()
                .includes(input.target.value.toLowerCase()) ||
              item.sessionName
                .toLowerCase()
                .includes(input.target.value.toLowerCase())
          )
        );
      }
    }
  };
  return (
    <div className="input-group rounded">
      <input
        id="search"
        type="search"
        className="form-control rounded"
        placeholder={i18n.t("Search")}
        aria-label="Search"
        onChange={(event) => searchInput(event)}
        aria-describedby="search-addon"
      />
      <span
        className="input-group-text border-0"
        style={{ backgroundColor: "#29377e", color: "white" }}
        id="search-addon"
        onClick={searchClear}
      >
        <FaSync style={{ cursor: "pointer" }} />
      </span>
    </div>
  );
}
export default withNamespaces()(SearchBar);
