/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { awscontext } from "../../configs/awscontext";
import { useSession } from "../../hooks/useSession";
import LoadingIcon from "../components/LoadingIcon";
import ModalForm from "../components/ModalForm";
import ScreenPanel from "../components/ScreenPanel";
import SessionTable from "./components/SessionTable";

export default function Library(props) {
  const context = useContext(awscontext);
  const [, setSessions] = useSession([]);
  const [show, setShow] = useState(false);
  const [, setinsList] = useState([]);
  const [isPageReady, setIsPageReady] = useState(true);
  const [rowCount, setRowCount] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [itemCount, setItemCount] = useState(context.librarySessionList.length);
  const [list, setList] = useState(context.librarySessionList);
  const [pureList, setPureList] = useState(context.librarySessionList);

  useEffect(() => {
    context.setAdres("library");
    if (!window.location.href.includes("/reviewer")) {
      context.setBarGroup("EXAMS");
    }

    const institutes = [];
    context.archivedsessionList.forEach((element) => {
      const ins = {
        instituteName: element.instituteName,
        instituteId: element.instituteId,
      };
      const findIns = institutes.find(
        (i) => i.instituteId === element.instituteId
      );
      if (findIns) {
        institutes.push(ins);
      }
    });

    setinsList(institutes);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setShow(false);
  };

  const showExamDetail = async (selectedSession) => {
    // const selectedSessionWithStudent = await setSessions("getById", {
    //   instituteId: selectedSession.instituteId,
    //   sessionId: selectedSession.sessionId,
    // });
    context.setSelectedSession(selectedSession);
    props.history.push({
      pathname: `/libraryDetailed/${selectedSession.sessionId}`,
    });
  };

  const fetchLibrarySessionList = async () => {
    setIsPageReady(false);
    await setSessions("librarySessions", {
      isArchived: true,
      sessionName: "",
      instituteId: context.userInstituteId,
    });
    setIsPageReady(true);
  };

  useEffect(() => {
    fetchLibrarySessionList();
  }, [context.userInstituteId]);

  useEffect(() => {
    setItemCount(context.librarySessionList.length);
    setList(context.librarySessionList);
    setPureList(context.sessionList);
    setRowCount(10);
  }, [context.librarySessionList]);

  useEffect(() => {
    setItemCount(list.length);
  }, [list]);

  const mainContent = () => {
    return (
      <React.Fragment>
        <ScreenPanel
          name="Library"
          panels="true"
          pagination="true"
          rowCount={rowCount}
          setRowCount={setRowCount}
          itemCount={itemCount}
          activePage={activePage}
          setActivePage={setActivePage}
          pureList={pureList}
          list={list}
          setList={setList}
        >
          <SessionTable
            Sessions={context.librarySessionList}
            showExamDetail={showExamDetail}
            setRowCount={setRowCount}
            rowCount={rowCount}
            itemCount={itemCount}
            activePage={activePage}
            setActivePage={setActivePage}
            list={list}
          />
        </ScreenPanel>
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      {isPageReady ? mainContent() : <LoadingIcon />}
      <ModalForm show={show} handleClose={handleClose} />
    </React.Fragment>
  );
}
