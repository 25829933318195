/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import FormInput from "../../components/FormInput";
import { useInput } from "../../../hooks/useInput";
import { useSession } from "../../../hooks/useSession";
import { useInstitute } from "../../../hooks/useInstitute";
import { awscontext } from "../../../configs/awscontext";
import i18n from "../../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import { store } from "react-notifications-component";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { input } from "aws-amplify";

function SessionThreshold(props) {
  const context = useContext(awscontext);
  const defaulthAiSettings={
    cheaterThreshold: 0,
        secondPerson: 0,
        studentReplaced: 0,
        studentAbsent: 0,
        gazeDirection: 0,
        windowChange: 0,
        forribenHardware: 0,
        voiceDetected: 0,
        phoneIsUse: 0
  }
  const [inputs, setInputs] = useInput(props.SelectedSession ? { ...props.SelectedSession } : props.InstitueInput.aiSettings? { ...props.InstitueInput.aiSettings }:{score:defaulthAiSettings});
  const [AIstate, setAIstate] = useState(inputs.isAIactive||false);
  const [showToStudent, setShowToStudent] = useState(inputs.showToStudent||false);
  const [showToProctor, setShowToProctor] = useState(inputs.showToProctor||false);
  const [, setInstitutes] = useInstitute([]);
  const [, setSession] = useSession();
  // useEffect(() => {
  //   if (!props.InstitueInput) {
  //     if(!props.InstitueInput.aiSettings)
  //     {
  //       setInputs(defaulthAiSettings)
  //       setAIstate(false)
  //       setShowToStudent(false)
  //       setShowToProctor(false)
  //     }
  //   }

  // }, [])

  const saveChangeInfo = async (e) => {
    e.preventDefault();
    const params = {
      isArchived: false,
      sessionId: inputs.sessionId,
      instituteId: inputs.instituteId,
      sessionName: inputs.sessionName,
      isAIactive: AIstate,
      showToStudent: showToStudent,
      showToProctor: showToProctor,
      score: {
        cheaterThreshold: inputs.score.cheaterThreshold,
        secondPerson: inputs.score.secondPerson,
        studentReplaced: inputs.score.studentReplaced,
        studentAbsent: inputs.score.studentAbsent,
        gazeDirection: inputs.score.gazeDirection,
        windowChange: inputs.score.windowChange,
        forribenHardware: inputs.score.forribenHardware,
        voiceDetected: inputs.score.voiceDetected,
        phoneIsUse: inputs.score.phoneIsUse,
      },
    };
    const response = {
      statusCode: 0,
      body: null,
    };
    await setSession("patch", params, "score", response);
    if (response.statusCode === 204) {
      store.addNotification({
        title: null,
        message: `Session ${inputs.sessionName}'s severity threshold has successfully updated`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } else if (response.statusCode === 210) {
      store.addNotification({
        title: null,
        message: `${response.body}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  const saveChangeInstitueInfo = async (e) => {
    e.preventDefault();
    const params = {
      isArchived: false,
      sessionId: inputs.sessionId,
      instituteId: inputs.instituteId,
      sessionName: inputs.sessionName,
      isAIactive: AIstate,
      showToStudent: showToStudent,
      showToProctor: showToProctor,
      score: {
        cheaterThreshold: inputs.score.cheaterThreshold,
        secondPerson: inputs.score.secondPerson,
        studentReplaced: inputs.score.studentReplaced,
        studentAbsent: inputs.score.studentAbsent,
        gazeDirection: inputs.score.gazeDirection,
        windowChange: inputs.score.windowChange,
        forribenHardware: inputs.score.forribenHardware,
        voiceDetected: inputs.score.voiceDetected,
        phoneIsUse: inputs.score.phoneIsUse,
      },
    };
    const response = {
      statusCode: 0,
      body: null,
    };
    let institueInput = props.InstitueInput;
    institueInput.aiSettings = params;
    institueInput.section = "aiSettings";
    await setInstitutes("aiSettings", institueInput, response);
    if (response.statusCode === 200) {
      store.addNotification({
        title: null,
        message: `Institute's AI severity threshold has successfully updated`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } else {
      store.addNotification({
        title: null,
        message: `${response.body}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };


  const handleSwitchCheck = () => {
    if (!AIstate) {
      setShowToStudent(false);
      setShowToProctor(false);
    }
    setAIstate(!AIstate);
  };

  const handleShowToStudent = () => {
    setShowToStudent(!showToStudent);
  };

  const handleShowToProctor = () => {
    setShowToProctor(!showToProctor);
  };

  const checkAIActive = () => {
    if (AIstate === false)
      return true;
    return false;
  };
  const checkAIActiveAndRecordAndReviewLite = () => {
    if(AIstate === false || inputs.proctorServiceType === "Record_and_Review_Lite" || inputs.proctorServiceType === "Record_and_Review")
      return true;
    return false;
  };

  useEffect(() => {
    if(!AIstate){
      setShowToProctor(false);
      setShowToStudent(false);
    }
  },[AIstate]);

  useEffect(() => {
    if(props.SelectedSession){
      setInputs({ ...props.SelectedSession});
      setAIstate(props.SelectedSession.isAIactive);
      setShowToProctor(props.SelectedSession.showToProctor);
      setShowToStudent(props.SelectedSession.showToStudent);
    }
    // return () => setInputs("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.SelectedSession]);
  return (
    <div className="container col-12 pt-4 row">
      <div className="row">
        <div
          className="form-group col-1 d-flex flex-column justify-content-center align-items-center"
          id="isAIactive"
        >
          <label>{i18n.t("AI")}</label>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={inputs.proctorServiceType === "Record_and_Review_Lite" ? true : AIstate}
                onChange={inputs.proctorServiceType === "Record_and_Review_Lite" ? null : handleSwitchCheck}
                name="AIstate"
                disabled={false}
              />
            }
            labelPlacement="top"
          />
        </div>
        <div
          className="form-group col-2 d-flex flex-column justify-content-center align-items-center"
          id="showAIToStudent"
        >
          <label>{i18n.t("Show_To_Student")}</label>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={showToStudent}
                onChange={handleShowToStudent}
                name="showToStudent"
                disabled={inputs.proctorServiceType === "Record_and_Review_Lite" ? false : checkAIActive()}
              />
            }
            labelPlacement="top"
          />
        </div>
        <div
          className="form-group col-2 d-flex flex-column justify-content-center align-items-center"
          id="showAIToProctor"
        >
          <label>{i18n.t("Show_To_Proctor")}</label>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={showToProctor}
                onChange={handleShowToProctor}
                name="showToStudent"
                disabled={checkAIActiveAndRecordAndReviewLite()}
              />
            }
            labelPlacement="top"
          />
        </div>
        <FormInput
          col="7"
          id="cheaterThreshold"
          name="score.cheaterThreshold"
          text={i18n.t("Severity_Threshold") + "  " + inputs.score.cheaterThreshold}
          value={inputs.score.cheaterThreshold}
          onChange={setInputs}
          type="range"
          disabled={!AIstate}
        />
        <FormInput
          col="3"
          name="score.secondPerson"
          text={i18n.t("Second_Person_Visible")}
          value={inputs.score.secondPerson}
          onChange={setInputs}
          type="range"
          min={0}
          max={10}
          step="0.1"
          disabled={!AIstate}
        />
        <FormInput
          col="3"
          name="score.studentReplaced"
          text={i18n.t("Test_taker_substituted")}
          value={inputs.score.studentReplaced}
          onChange={setInputs}
          type="range"
          min={0}
          max={10}
          step="0.1"
          disabled={!AIstate}
        />
        <FormInput
          col="3"
          name="score.studentAbsent"
          text={i18n.t("Test_taker_absent")}
          value={inputs.score.studentAbsent}
          onChange={setInputs}
          type="range"
          min={0}
          max={10}
          step="0.1"
          disabled={!AIstate}
        />
        <FormInput
          col="3"
          name="score.gazeDirection"
          text={i18n.t("Looking_away_from_the_screen")}
          value={inputs.score.gazeDirection}
          onChange={setInputs}
          type="range"
          min={0}
          max={10}
          step="0.1"
          disabled={!AIstate}
        />
        <FormInput
          col="3"
          name="score.windowChange"
          text={i18n.t("Exam_Page_Lost")}
          value={inputs.score.windowChange}
          onChange={setInputs}
          type="range"
          min={0}
          max={10}
          step="0.1"
          disabled={!AIstate}
        />
        <FormInput
          col="3"
          name="score.forribenHardware"
          text={i18n.t("Unauthorized_devices_present")}
          value={inputs.score.forribenHardware}
          onChange={setInputs}
          type="range"
          min={0}
          max={10}
          step="0.1"
          disabled={!AIstate}
        />
        <FormInput
          col="3"
          name="score.voiceDetected"
          text={i18n.t("Microphone_Voice")}
          value={inputs.score.voiceDetected}
          onChange={setInputs}
          type="range"
          min={0}
          max={10}
          step="0.1"
          disabled={!AIstate}
        />
        <FormInput
          col="3"
          name="score.phoneIsUse"
          text={i18n.t("Use_of_smartphone")}
          value={inputs.score.phoneIsUse}
          onChange={setInputs}
          type="range"
          min={0}
          max={10}
          step="0.1"
          disabled={!AIstate}
        />
      </div>
      {!props.thisIsInstitue ? <div className="form-group w-100">
        <button onClick={saveChangeInfo} className={context.theme.btn_save}>
          {i18n.t("Save")}
        </button>
      </div> : <div className="form-group w-100">
        <button onClick={saveChangeInstitueInfo} className={context.theme.btn_save}>
          {i18n.t("Save")}
        </button>
      </div>}


    </div>
  );
}
export default withNamespaces()(SessionThreshold);
