/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useInstitute } from "../../hooks/useInstitute";
import { awscontext } from "../../configs/awscontext";
import ModalForm from "../components/ModalForm";
import FormInput from "../components/FormInput";
import ScreenPanel from "../components/ScreenPanel";
import i18n from "../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import LoadingIcon from "../components/LoadingIcon";
import { store } from "react-notifications-component";
const WarningsModel = {
  langName: "",
  secondPerson: "",
  studentReplaced: "",
  studentAbsent: "",
  gazeDirection: "",
  windowChange: "",
  forbiddenHardware: "",
  voiceDetected: "",
  phoneIsUse: "",
  customField: "",
};

function AIWarnings(props) {
  const context = useContext(awscontext);
  const [, setInstitutes] = useInstitute([]);
  const [show, setShow] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [warnings, setWarnings] = useState({ ...WarningsModel });
  const [defaultWarning,setDefaultWarning] = useState (false)
  const [language, setLanguage] = useState("");
  const [activetab, setActivetab] = useState("");

  useEffect(() => {
    context.setAdres("warnings");
    context.setBarGroup("SETTINGS");
    if (
      context.currentInstitute &&
      context.currentInstitute.warnings.length > 0
    ) {
      onSelectTab(0);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onSelectTab(0);
  }, [context.currentInstitute]);

  useEffect(()=>{
    console.log(context.currentInstitute.defaultWarning)
    console.log(activetab)
    if(context.currentInstitute.defaultWarning === activetab)
    {
      setDefaultWarning(true)
    }else{
      setDefaultWarning(false)
    }
  },[activetab])

  const onSelectTab = (select) => {
    if (context.currentInstitute) {
      setActivetab(select);

      setWarnings({ ...context.currentInstitute.warnings[select] });
    }
  };

  const onSelectTabNew = (e) => {
    e.preventDefault();
    setShowNew(true);
  };

  const handleClose = (select) => {
    if (select === "new") {
      onSelectTab("new");
      setWarnings({ ...WarningsModel, langName: language });
      setActivetab("new");
      setLanguage("");
    }
    setShow(false);
    setShowNew(false);
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    const newWarnings = context.currentInstitute.warnings;
    console.log("newWarnings",warnings);
    if (activetab === "new") {
      newWarnings.push(warnings);
    } else {
      newWarnings[activetab] = warnings;
    }
    let params;
    if(defaultWarning)
    {
      context.currentInstitute.defaultWarning = activetab;
      params = {
        section: "warnings",
        id: context.currentInstitute.id,
        defaultWarning:activetab,
        warnings: newWarnings,
      }
    }else{
      params = {
        section: "nowarnings",
        id: context.currentInstitute.id,
        warnings: newWarnings,
      };
    }
    
    await setInstitutes("patch", params);
    store.addNotification({
      title: null,
      //message: `Institute ${context.currentInstitute.instituteName} has successfully updated`,
      message: i18n.t("Institute_has_successfully_updated"),
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  };

  const handleDelete = async (e) => {
    if (e) e.preventDefault();
    const Warnings = context.currentInstitute.warnings;
    const newWarnings = [];
    if (activetab !== "new") {
      for (let index = 0; index < Warnings.length; index++) {
        if (activetab !== index) newWarnings.push(Warnings[index]);
      }
      let params = {
        section: "warnings",
        id: context.currentInstitute.id,
        warnings: newWarnings,
      };
      await setInstitutes("patch", params);
      store.addNotification({
        title: null,
        //message: `Institute ${context.currentInstitute.instituteName} has successfully updated`,
        message: i18n.t("Institute_has_successfully_updated"),
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      if (newWarnings.length > 0) setWarnings({ ...newWarnings[0] });
      else setWarnings({ ...WarningsModel });
      await setInstitutes("getCurrent", context.currentInstitute.id);
    }
  };

  const constructLangNames = () => {
    const langNames = [];
    if (context.currentInstitute) {
      context.currentInstitute.warnings.map((lang, i) =>
        langNames.push(
          <button
            className={`buttonOriginal ml-1 rounded`}
            onClick={() => onSelectTab(i)}
            key={i}
          >
            {lang.langName}
          </button>
        )
      );
    }
    return langNames;
  };

  const mainContent = () => {
    return (
      <ScreenPanel name="Cyber_Proctor_Warnings" col="10">
        <nav className="navbar navbar-expand-lg navbar-light bg-white pl-0 pr-0">
          <div
            className="collapse navbar-collapse "
            id="navbarSupportedContent"
          >
            <div className="d-flex flex-fill mr-auto">
              {constructLangNames()}
            </div>
            <button
              className={`${context.theme.btn_save} ml-1`}
              onClick={(e) => onSelectTabNew(e)}
            >
              {i18n.t("Add_New_Language")}
            </button>
          </div>
        </nav>
        <form className="row">
          <FormInput
            col="6"
            name="langName"
            text={i18n.t("Language_Name")}
            value={warnings.langName}
            onChange={(e) =>
              setWarnings({ ...warnings, langName: e.target.value })
            }
            type="text"
          />
          <FormInput
            col="6"
            name="secondPerson"
            text={i18n.t("Second_Person_Visible")}
            value={warnings.secondPerson}
            onChange={(e) =>
              setWarnings({ ...warnings, secondPerson: e.target.value })
            }
            type="text"
          />
          <FormInput
            col="6"
            name="studentReplaced"
            text={i18n.t("Test_taker_substituted")}
            value={warnings.studentReplaced}
            onChange={(e) =>
              setWarnings({ ...warnings, studentReplaced: e.target.value })
            }
            type="text"
          />
          <FormInput
            col="6"
            name="studentAbsent"
            text={i18n.t("Test_taker_absent")}
            value={warnings.studentAbsent}
            onChange={(e) =>
              setWarnings({ ...warnings, studentAbsent: e.target.value })
            }
            type="text"
          />
          <FormInput
            col="6"
            name="gazeDirection"
            text={i18n.t("Looking_away_from_the_screen")}
            value={warnings.gazeDirection}
            onChange={(e) =>
              setWarnings({ ...warnings, gazeDirection: e.target.value })
            }
            type="text"
          />
          <FormInput
            col="6"
            name="windowChange"
            text={i18n.t("Exam_Page_Lost")}
            value={warnings.windowChange}
            onChange={(e) =>
              setWarnings({ ...warnings, windowChange: e.target.value })
            }
            type="text"
          />
          <FormInput
            col="6"
            name="forbiddenHardware"
            text={i18n.t("Unauthorized_devices_present")}
            value={warnings.forbiddenHardware}
            onChange={(e) =>
              setWarnings({ ...warnings, forbiddenHardware: e.target.value })
            }
            type="text"
          />
          <FormInput
            col="6"
            name="voiceDetected"
            text={i18n.t("Microphone_Voice")}
            value={warnings.voiceDetected}
            onChange={(e) =>
              setWarnings({ ...warnings, voiceDetected: e.target.value })
            }
            type="text"
          />
          <FormInput
            col="6"
            name="phoneIsUse"
            text={i18n.t("Use_of_smartphone")}
            value={warnings.phoneIsUse}
            onChange={(e) =>
              setWarnings({ ...warnings, phoneIsUse: e.target.value })
            }
            type="text"
          />
          <FormInput
            col="6"
            name="CustomField"
            text={i18n.t("Custom_Field")}
            value={warnings.customField}
            onChange={(e) =>
              setWarnings({ ...warnings, customField: e.target.value })
            }
            type="text"
          />
          <div style={{padding:"15px",margin:"5px",flex:"2",flexDirection:"row",justifyContent:'space-between'}}>
            <label> {i18n.t("Use_this_ai_warnings")} &nbsp;</label>
            <input
            style={{padding:10}}
            type="checkbox"
            checked={defaultWarning}
            onChange={(e) => {setDefaultWarning(!defaultWarning)}}
          ></input>
          </div>
          
        </form>
        <div className="d-flex flex-row justify-content-between">
          <button onClick={handleSubmit} className={context.theme.btn_save}>
            {i18n.t("Save")}
          </button>
          {activetab !== "new" ? (
            <button onClick={handleDelete} className={context.theme.btn_delete}>
              {i18n.t("Delete")}
            </button>
          ) : null}
        </div>
      </ScreenPanel>
    );
  };

  return (
    <React.Fragment>
      {context.loaded ? mainContent() : <LoadingIcon />}
      <ModalForm show={show} handleClose={handleClose} />
      <ModalForm show={showNew} handleClose={handleClose}>
        <React.Fragment>
          <FormInput
            name="language"
            text="Language Name"
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            type="text"
          />
          <button
            className={context.theme.btn_save}
            onClick={() => handleClose("new")}
          >
            Add Name
          </button>
          <button className={context.theme.btn_cancel} onClick={handleClose}>
            {i18n.t("Close")}
          </button>
        </React.Fragment>
      </ModalForm>
    </React.Fragment>
  );
}
export default withNamespaces()(AIWarnings);
