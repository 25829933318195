/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { awscontext } from "../../configs/awscontext";
import DonutBar from "./components/DonutBar";
import Statistics from "./components/Statistics";
import i18n from "../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import { FaClipboardCheck, FaFileAlt, FaUsers } from "react-icons/fa";

function DashboardA(props) {
  const context = useContext(awscontext);
  const [liveSessionCount, setLiveSessionCount] = useState(0);
  const [liveTestTakerCount, setLiveTestTakerCount] = useState(0);
  const [librarySessionCount, setLibrarySessionCount] = useState(0);

  useEffect(() => {
    context.setAdres("admin");
    context.setBarGroup("HOME");
  }, []);

  useEffect(() => {
    if (context.loaded) {
      if (
        context.userRole.includes("Admin") &&
        !context.userRole.includes("Super Admin")
      ) {
        setLiveSessionCount(context.liveSessionList.length);
      } else {
      }
    }
  }, [context.loaded, context.liveSessionList]);

  useEffect(() => {
    if (context.loaded) {
      if (
        context.userRole.includes("Admin") &&
        !context.userRole.includes("Super Admin")
      ) {
        setLiveTestTakerCount(context.instituteOnlineTestTakerList.length);
      }
    }
  }, [context.loaded, context.instituteOnlineTestTakerList]);

  useEffect(() => {
    if (context.loaded) {
      if (
        context.userRole.includes("Admin") &&
        !context.userRole.includes("Super Admin")
      ) {
        setLibrarySessionCount(context.librarySessionList.length);
      }
    }
  }, [context.loaded, context.librarySessionList]);

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="d-flex flex justify-content-around">
          <Statistics
            key="0"
            name={i18n.t("LIVE_SESSIONS")}
            value={liveSessionCount}
            renderIcon={FaFileAlt}
            onClick={() => {
              props.history.push({
                pathname: `/liveSessionList`,
              });
            }}
          />
          <Statistics
            key="1"
            name={i18n.t("ONLINE_Test_takers")}
            value={liveTestTakerCount}
            renderIcon={FaUsers}
            onClick={() => {
              props.history.push({
                pathname: `/liveSessionList`,
              });
            }}
          />
          <Statistics
            key="2"
            name={i18n.t("COMPLETED_SESSIONS")}
            value={librarySessionCount}
            renderIcon={FaClipboardCheck}
            onClick={() => {
              props.history.push({
                pathname: `/library`,
              });
            }}
          />
        </div>
        <DonutBar />
      </div>
    </React.Fragment>
  );
}
export default withNamespaces()(DashboardA);
