import React, { useContext, useState } from "react";
import { awscontext } from "../../../configs/awscontext";
import i18n from "../../../configs/i18n";
import { withNamespaces } from "react-i18next";
import axios from "axios";
import { useSession } from "../../../hooks/useSession";
import config from "../../../configs/awsconfig.json";
import { store } from "react-notifications-component";
function SessionMail(props) {
  const context = useContext(awscontext);
  const [, setSession] = useSession();
  const [content, setContent] = useState("");
  const sendEmails = async () => {
    store.addNotification({
      title: null,
      message: i18n.t("Email_sent_started"),
      type: "info",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });

    try {
      let response = await axios.post(
        `${config.api.invokeUrl}/sendemails`,
        {
          sessionId: props.SelectedSession.sessionId,
          instituteId: props.SelectedSession.instituteId,
          content: content,
          timezoneOffset: new Date().getTimezoneOffset(),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      const mailResponseStatus = response.data;
      mailResponseStatus.forEach((statu) => {
        if (statu.hasOwnProperty("mailError")) throw new Error(statu.mailError);
      });

      await setSession("getById", {
        instituteId: props.SelectedSession.instituteId,
        sessionId: props.SelectedSession.sessionId,
      }).then((foundSession) => {
        const foundIndex = context.sessionList.findIndex(
          (session) => session.sessionId === foundSession.sessionId
        );
        context.sessionList[foundIndex] = foundSession;
        context.setSelectedSession(foundSession);
        props.setSelectedSession(foundSession);
      });

      let mailCount = mailResponseStatus.length;
      store.addNotification({
        title: null,
        message: `${mailCount + " " + i18n.t("Emails_are_sent")}`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } catch (error) {
      console.log(error);
      store.addNotification({
        title: null,
        message: `${error.message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  return (
    <React.Fragment>
      <div className="m-3">{i18n.t("Instructions_for_Test_Takers")}</div>
      {/* <textarea
        className="form-control mb-3"
        name="mailMessage"
        value={content}
        onChange={(e) => {
          setContent(e.target.value);
        }}
        rows="6"
      ></textarea> */}
      <div className="pl-3">
        <div className="mb-4">
          <button
            hidden={() => { }}
            disabled={() => { }}
            onClick={() => { }}
            className={context.theme.btn_save_small}
          >
            System Check Reminder Email
          </button>
        </div>
        <div className="mb-4">
          <button
            hidden={() => { }}
            disabled={() => { }}
            onClick={() => { }}
            className={context.theme.btn_save_small}
          >
            Pre-system Check Email
          </button>
        </div>
        <div className="mb-4">
          <button
            hidden={() => { }}
            disabled={() => { }}
            onClick={() => { }}
            className={context.theme.btn_save_small}
          >
            Exam Information Email
          </button>
        </div>
      </div>
      <div className="form-group ml-2 mt-3 float-right">
        <button
          hidden={!props.SelectedSession.mail}
          disabled={
            props.SelectedSession.instanceState === "FINISHED" ||
            props.SelectedSession.studentList.length === 0
          }
          // onClick={() => console.log("props", context)}
          onClick={sendEmails}
          className={context.theme.btn_save_small}
        >
          {i18n.t("Send_Mails")}
        </button>
      </div>
    </React.Fragment>
  );
}
export default withNamespaces()(SessionMail);
