/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { awscontext } from "../../configs/awscontext";
import ScreenPanel from "../components/ScreenPanel";
import { withNamespaces } from "react-i18next";
import i18n from "../../configs/i18n";
import LoadingIcon from "../components/LoadingIcon";

function StaffList(props) {
  const context = useContext(awscontext);
  const [rowCountLocalTable, setRowCountLocalTable] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [itemCount, setItemCount] = useState(context.staffList.length);
  const [list, setList] = useState(context.staffList);
  const [pureList] = useState(context.staffList);

  useEffect(() => {
    let filteredStaffList = context.staffList;
    if (!context.userRole.includes("Super Admin")) {
      filteredStaffList = context.staffList.filter(
        (staff) => !JSON.stringify(staff.roles).includes("Super Admin")
      );
    }
    setItemCount(filteredStaffList.length);
    if (filteredStaffList)
      filteredStaffList.forEach((element) => {
        element.roleName = arrayToString(element.roles, "role");
      });
    setList(filteredStaffList);
  }, [context.staffList]);

  useEffect(() => {
    context.setAdres("staffList");
    context.setBarGroup("STAFF");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (selectedStaff, type, source) => {
    if (source) {
      selectedStaff.role = source;
    }
    props.history.push({
      pathname: `/${type}`,
      state: { selectedStaff: selectedStaff },
    });
  };

  const arrayToString = (array, property) => {
    let result = "";
    if (array) {
      array.forEach((element) => {
        result += element[property] + ", ";
      });
    }
    if (result.length > 0) {
      result = result.substr(0, result.length - 2);
    }
    return result;
  };
  const mainContent = () => {
    return (
      <React.Fragment>
        <ScreenPanel
          name={`${context.userInstituteName} ${i18n.t("User_List")}`}
          panels="true"
          pagination="true"
          setRowCount={setRowCountLocalTable}
          rowCount={rowCountLocalTable}
          itemCount={itemCount}
          activePage={activePage}
          setActivePage={setActivePage}
          pureList={pureList}
          list={list}
          setList={setList}
        >
          <table className={context.theme.table_style} id="table">
            <thead>
              <tr className={context.theme.color}>
                <th onClick={() => context.sortTableData(list, "name")}>
                  {i18n.t("Fullname")}
                </th>
                <th onClick={() => context.sortTableData(list, "roleName")}>
                  {i18n.t("Role")}
                </th>
                <th onClick={() => context.sortTableData(list, "email")}>
                  {i18n.t("Email")}
                </th>
                <th onClick={() => context.sortTableData(list, "status")}>
                  {i18n.t("Email_Status")}
                </th>
                <th onClick={() => context.sortTableData(list, "status")}>
                  {i18n.t("User_Status")}
                </th>
              </tr>
            </thead>
            <tbody>
              {list
                .slice(
                  (activePage - 1) * rowCountLocalTable,
                  activePage * rowCountLocalTable
                )
                .map((staff, i) => (
                  <tr key={i} onClick={() => handleClick(staff, "updateStaff")}>
                    <td>
                      {staff.name} {staff.lastname}
                    </td>
                    <td>{arrayToString(staff.roles, "role")}</td>
                    <td>{staff.email}</td>
                    <td>{i18n.t(staff.status)}</td>
                    <td>{i18n.t(staff.status)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </ScreenPanel>
      </React.Fragment>
    );
  };
  return context.loaded ? mainContent() : <LoadingIcon />;
}
export default withNamespaces()(StaffList);
