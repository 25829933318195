import { useState } from "react";

export const useInput = (params) => {
  const [inputs, setInputs] = useState(params);

  const handleChange = (event, defaultValue) => {
    if (event.target) {
      let name = event.target.name;
      let value = event.target.value;
      if (event.target.type === "checkbox") {
        value = event.target.checked;
      }
      if (defaultValue) {
        value = defaultValue;
      }
      let data = inputs;
      var parca = name.split(".");
      if (parca.length === 1) {
        data[name] = value;
      }
      if (parca.length === 2) {
        data[parca[0]][parca[1]] = value;
      }
      if (parca.length === 3) {
        data[parca[0]][parca[1]][parca[2]] = value;
      }
      if (parca.length === 4) {
        data[parca[0]][parca[1]][parca[2]][parca[3]] = value;
      }
      if (parca.length === 5) {
        data[parca[0]][parca[1]][parca[2]][parca[3]][parca[4]] = value;
      }
      setInputs({ ...data });
    } else {
      setInputs(event);
    }
  };
  return [inputs, handleChange];
};
