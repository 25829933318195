/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { awscontext } from "../../configs/awscontext";
import { Link, Redirect } from "react-router-dom";
import { useInput } from "../../hooks/useInput";
import { useAuth } from "../../hooks/useAuth";
import { Form } from "react-bootstrap";
import FormInput from "../components/FormInput";
import i18n from "../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import { useStart } from "../../hooks/useStart";

function Login(props) {
  const context = useContext(awscontext);
  const [gotoPageLogin, setGotoPageLogin] = useState(false);
  const [result, setAuth] = useAuth();
  const [, setStart] = useStart();
  const [inputs, setInputs] = useInput({
    username: "",
    password: "",
    forgotemail: "",
    confirmemail: "",
    verificationcode: "",
    newpassword: "",
  });

  useEffect(() => {
    const constructInitialData = async () => {
      const instituteId = localStorage.getItem("instituteId");
      if (instituteId) {
        context.setLoaded(false);
        await setStart(instituteId, true);
        context.setLoaded(true);
        await setStart(instituteId, false);
      }
    };
    if (context.isAuthenticated) {
      constructInitialData();
    }
  }, [context.isAuthenticated]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // context.setLoaded(false);
    await setAuth("login", inputs);

    // const instituteId = localStorage.getItem("instituteId");
    // await setStart(instituteId);
    // context.setLoaded(true);
    ResultConfirm();
  };
  const forgotPasswordHandler = () => {
    setAuth("forgot", inputs).then((result) => ResultConfirm(result));
  };
  const passwordVerificationHandler = () => {
    setAuth("confirm", inputs).then((result) => ResultConfirm(result));
  };

  const ResultConfirm = () => {
    // if (Auth === "success") {
    if (props.location.pathname.includes("forgot")) {
      props.history.push("/changepasswordconfirmation");
    }
    if (props.location.pathname.includes("first")) {
      props.history.push("/changepasswordconfirmation");
    }
    if (props.location.pathname.includes("changepasswordconfirmation")) {
      setGotoPageLogin(true);
    }
    if (props.location.pathname.includes("forgotpasswordverification")) {
      setGotoPageLogin(true);
    }
  };

  const loginForm = () => {
    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit}>
          <FormInput
            name="username"
            text={i18n.t("Email")}
            value={inputs.username}
            onChange={setInputs}
            type="text"
          />
          <FormInput
            name="password"
            text={i18n.t("Password")}
            value={inputs.password}
            onChange={setInputs}
            type="password"
          />
          <button
            type="submit"
            className="btn btn-secondary btn-user btn-block"
            onClick={handleSubmit}
          >
            {i18n.t("LOGIN")}
          </button>
        </Form>
      </React.Fragment>
    );
  };

  const forgotPasswordPanel = () => {
    return (
      <React.Fragment>
        <FormInput
          name="forgotemail"
          text={i18n.t("Email")}
          value={inputs.forgotemail}
          onChange={setInputs}
          type="text"
        />
        <button
          onClick={forgotPasswordHandler}
          className="btn btn-secondary btn-user btn-block"
        >
          {i18n.t("Reset_Password", "Reset Password")}
        </button>
      </React.Fragment>
    );
  };
  const firstTimeUser = () => {
    return (
      <React.Fragment>
        <FormInput
          name="forgotemail"
          text={i18n.t("Email")}
          value={inputs.forgotemail}
          onChange={setInputs}
          type="text"
        />
        <button
          onClick={forgotPasswordHandler}
          className="btn btn-secondary btn-user btn-block"
        >
          {i18n.t("Change_Password", "Change Password")}
        </button>
      </React.Fragment>
    );
  };
  const confirmPanel = () => {
    return (
      <React.Fragment>
        <FormInput
          name="verificationcode"
          text={i18n.t("Enter_Verification_Code", "Enter Verification Code")}
          value={inputs.verificationcode}
          onChange={setInputs}
          type="text"
          required={true}
        />
        <FormInput
          name="confirmemail"
          text={i18n.t("Email")}
          value={inputs.confirmemail}
          onChange={setInputs}
          type="text"
          required={true}
        />
        <FormInput
          name="newpassword"
          text={i18n.t("Password")}
          value={inputs.newpassword}
          onChange={setInputs}
          type="password"
          required={true}
        />
        <button
          type="button"
          className="btn btn-secondary btn-user btn-block"
          onClick={() => passwordVerificationHandler()}
        >
          {i18n.t("Change_Password", "Change Password")}
        </button>
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      {context.isAuthenticated ? (
        <Redirect
          to={context.returnURL !== "" ? context.returnURL : "/admin"}
        />
      ) : (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-8 m-auto">
                      <div className="p-5 ">
                        {/* <div className="text-center"> */}
                        {/* <h1 className="h2 text-gray-900 mb-4"> */}
                        <br></br>
                        <div style={{ fontWeight: "bold", fontSize: 20 }}>
                          {i18n.t("Welcome_to", "Welcome To")}
                        </div>
                        <div style={{ fontWeight: "bold", fontSize: 50 }}>
                          {i18n.t("Smartest_Invigilate", "Smartest Invigilate")}
                        </div>
                        {/* <div style={{ fontWeight: "bold", fontSize: 50 }}>
                              {i18n.t("Invigilate")}
                            </div> */}
                        <br></br>
                        {/* </h1> */}
                        {/* </div> */}
                        <div>{result}</div>
                        {props.location.pathname.includes("f") === false
                          ? loginForm()
                          : props.location.pathname.includes(
                              "changepasswordconfirmation"
                            )
                          ? confirmPanel()
                          : props.location.pathname.includes("forgot")
                          ? forgotPasswordPanel()
                          : props.location.pathname.includes("first")
                          ? firstTimeUser()
                          : props.location.pathname.includes(
                              "forgotpasswordverification"
                            )
                          ? confirmPanel()
                          : ""}
                        <div className="text-center pt-4">
                          {props.location.pathname.includes("f") === false ? (
                            <React.Fragment>
                              <Link
                                className="small text-secondary"
                                to="/forgot"
                              >
                                {i18n.t("Forgot_Password", "Forgot Password")}?
                              </Link>
                              <div className="pt-4"></div>
                              <Link
                                className="small text-secondary"
                                to="/first"
                              >
                                {i18n.t(
                                  "For_First_Time_Users",
                                  "For First Time Users"
                                )}
                              </Link>
                            </React.Fragment>
                          ) : (
                            <Link className="small text-secondary" to="/">
                              {i18n.t(
                                "Back_to_Login_Page",
                                "Back To Login Page"
                              )}
                            </Link>
                          )}
                          {
                            // <div className="pt-4"></div>
                            // <img
                            //   src="assets/lotec.png"
                            //   alt="logo"
                            //   width="150px"
                            // ></img>
                            // <br />
                            // {i18n.t("Developed_by_LOTEC")} &copy; 2021
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {gotoPageLogin === true ? <Redirect to="/" /> : ""}
    </React.Fragment>
  );
}
export default withNamespaces()(Login);
