import React, { useEffect, useContext } from "react";
import { awscontext } from "../../configs/awscontext";
export default function Supports() {
  const context = useContext(awscontext)

  useEffect(() => {
    context.setAdres("supports");
  })

  return (
    <div>
      Supports...
    </div>
  )
}
