import React, { Component, createContext } from "react";
import modelSession from "../models/modelSession.json";
export const proctorcontext = createContext();
class proctorprovider extends Component {
  state = {
    viewDetail: false,
    viewFocus: false,
    activeSession: "",
    students: [],
    proctoringStudentListPanel: true,
    proctoringRulesPanel: true,
    proctorShow: null,
    sessionActiveProctors: [],
    userRole: "",
    chatComponent: false,
    proctoringChatPanel: false,
    studentPanel: false,
    chatStudent: { lastname: "", name: "" },
    proctoringSession: modelSession,
    commentList: [],
    commentDetail: [],
    chats: [],
    appendMsg: null,
    setTyping: null,
    username: "",
    sessions: [],
    cameras: 2,

    webcamSubscriberList: [],
    screenShareSubscriberList: [],
    mobileSubscriberList: [],

    stream: {
      audio: "",
      webcam: ""
    }
  };

  deleteStudent = (sessionId) => {
    let updatedWebcamSubscriberList = this.state.webcamSubscriberList.filter((webcamSubscriber) => webcamSubscriber.stream.session.sessionId !== `${sessionId}-webcam`);
    this.setState({webcamSubscriberList: updatedWebcamSubscriberList});

    let updatedScreenShareSubscriberList = this.state.screenShareSubscriberList.filter((screenShareSubscriber) => screenShareSubscriber.stream.session.sessionId !== `${sessionId}-screenShare`);
    this.setState({screenShareSubscriberList: updatedScreenShareSubscriberList})

    let updatedMobileSubscriberList = this.state.mobileSubscriberList.filter((mobileSubscriber) => mobileSubscriber.stream.session.sessionId !== `${sessionId}-mobile_0`)
      .filter((mobileSubscriber) => mobileSubscriber.stream.session.sessionId !== `${sessionId}-mobile_1`)
      .filter((mobileSubscriber) => mobileSubscriber.stream.session.sessionId !== `${sessionId}-mobile_2`)
      .filter((mobileSubscriber) => mobileSubscriber.stream.session.sessionId !== `${sessionId}-mobile_3`);
    this.setState({mobileSubscriberList: updatedMobileSubscriberList})
  };

  deleteFromMobileList = (sessionId) => {
    let updatedMobileSubscriberList = this.state.mobileSubscriberList.filter((mobileSubscriber) => mobileSubscriber.stream.session.sessionId !== `${sessionId}-mobile_0`)
      .filter((mobileSubscriber) => mobileSubscriber.stream.session.sessionId !== `${sessionId}-mobile_1`)
      .filter((mobileSubscriber) => mobileSubscriber.stream.session.sessionId !== `${sessionId}-mobile_2`)
      .filter((mobileSubscriber) => mobileSubscriber.stream.session.sessionId !== `${sessionId}-mobile_3`);
    this.setState({mobileSubscriberList: updatedMobileSubscriberList})
  }

  deleteFromWebcamAndScreenShareList = (sessionId) => {
	let updatedWebcamSubscriberList = this.state.webcamSubscriberList.filter((webcamSubscriber) => webcamSubscriber.stream.session.sessionId !== `${sessionId}-webcam`);
    this.setState({webcamSubscriberList: updatedWebcamSubscriberList});

    let updatedScreenShareSubscriberList = this.state.screenShareSubscriberList.filter((screenShareSubscriber) => screenShareSubscriber.stream.session.sessionId !== `${sessionId}-screenShare`);
    this.setState({screenShareSubscriberList: updatedScreenShareSubscriberList})
  }

  setMobileSubscriberList = (val) => {
    this.setState({mobileSubscriberList: [...this.state.mobileSubscriberList, val]});
  };

  setMobileSubscriberListNew = (val) => {
    this.setState({
      mobileSubscriberList: val,
    });
  }

  setWebcamSubscriber = (val) => {
	let found = this.state.webcamSubscriberList.find((item) => item.stream.session.sessionId === val.stream.session.sessionId)
	if (!found) {
		this.setState({webcamSubscriberList: [...this.state.webcamSubscriberList, val]});
	}
  };

  setScreenShareSubscriber = (val) => {
	let found = this.state.screenShareSubscriberList.find((item) => item.stream.session.sessionId === val.stream.session.sessionId)
	if (!found) {
		this.setState({screenShareSubscriberList: [...this.state.screenShareSubscriberList, val]});
	}
  };

  setSessions = (val) => {
    this.setState({ sessions: [...this.state.sessions, val] });
  };

  setUserRole = (userRole, username) => {
    this.setState({ userRole: userRole, username: username });
  };
  setStream = (stream) => {
    this.setState({ stream: stream });
  };
  setStudentAIScore = (student) => {
    const newStudents = [];
    this.state.students.map(obj => {
      if (obj.studentId === student.studentId) {
        // let obije = { ...obj, aiScore: student.aiScore }
        return newStudents.push({ ...obj, aiScore: student.aiScore });
      } else {
        return newStudents.push(obj);
      }
    });
    this.setState({ students: newStudents });
  }

  addNewStudents = (students) => {
    const oldStudents = this.state.students;
    students.forEach((student) => {
      student.chat = false;
      student.detail = false;
      student.focus = false;
      student.muted = true;
      student.icon = "muted"
      student.showForce = true;
      if (!student.prvctorId) student.proctor = false;
      student.online = false;
      student.show = false;
      student.camera = "webcam";
      oldStudents.push(student);
    });
    this.setProctoringStudent(oldStudents);
  };

  setAppendMsg = (appendMsg, setTyping) => {
    this.setState({ appendMsg: appendMsg, setTyping: setTyping });
  };

  getAppendMsg = (messageFrom, messageText) => {
    const setTyping = this.state.setTyping;
    setTyping(true);

    const addMessage = this.state.appendMsg;
    addMessage({
      type: "text",
      content: { text: messageFrom + ": " + messageText },
      //position: "right",
    });
  };

  setChats = (chats) => {
    this.setState({ chats: chats });
  };

  setAddCommentList = (command) => {
    this.setState({
      commentList: this.state.commentList.push({
        time: new Date(),
        text: command,
      }),
    });
  };

  setCommentList = (commentList, commentDetail) => {
    this.setState({ commentList, commentDetail });
  };

  setOnlineStudents = (result, meetingId) => {
    let studentList = this.state.students;
    studentList.forEach((student) => {
      if (
        this.state.proctoringSession.sessionId + student.studentId ===
        meetingId
      ) {
        if (result === true) {
          student.isJoin = true;
        } else if (result === false) {
          student.show = false;
        }
        student.online = result;
        student.chatCount = 0;
      }
    });
    this.setProctoringStudent(studentList);
  };

  setProctoringSession = (session) => {
    this.setState({
      proctoringSession: session,
    });
  };

  setActiveSession = (activeSession, userRole) => {
    var procs = [];
    if (
      JSON.stringify(userRole).includes("Admin") ||
      JSON.stringify(userRole).includes("Super Admin")
    )
      procs = this.findProctors(activeSession);
    this.setState({ activeSession: activeSession });
    let updatedStudentList=[]
    if(JSON.stringify(userRole).includes("Proctor") )
    {
      activeSession?.studentList?.map(student =>{
        student?.proctors?.map(proctor => {
          if(proctor?.proctorId === this.state.username)
          {
            updatedStudentList.push(student);
          }
        })
      })
      activeSession.studentList=updatedStudentList;
    }
    this.setShow(0, activeSession.studentList, procs);
  };

  setCheckStudent = (studentKey, value) => {
    console.log("SetCheckStudent", studentKey, value)
    let stdnt = this.state.students;
    stdnt[studentKey].show = value;
    if (value === false) {
      stdnt[studentKey].focus = value;
      stdnt[studentKey].detail = value;
    }
    this.setSize(stdnt);
  };

  setFocus = (studentId) => {
    let stdnt = this.state.students;
    stdnt.forEach((element) => {
      if (element.studentId === studentId) {
        element.focus = !element.focus;
      } else {
        element.focus = false;
      }
    });
    this.setSize(stdnt);
  };

  setDetail = (studentId, camera) => {
    const studentList = this.state.students;
    studentList.forEach((student) => {
      if (student.studentId === studentId) {
        if (!student.proctor) {
          student.detail = !student.detail;
          student.camera = camera;
        } else {
          if (this.state.cameras === 2) {
            this.setCameras(1);
            if (camera === "webcam") {
              document.getElementById(`div_screenShare${this.state.proctoringSession.sessionId}${studentId}`).style.display = "none";
            } else if (camera === "screenShare") {
              document.getElementById(`div_webcam${this.state.proctoringSession.sessionId}${studentId}`).style.display = "none";
            }
          } else if (this.state.cameras === 1) {
            this.setCameras(2);
            if (camera === "webcam") {
              document.getElementById(`div_screenShare${this.state.proctoringSession.sessionId}${studentId}`).style.display = "block";
            } else if (camera === "screenShare") {
              document.getElementById(`div_webcam${this.state.proctoringSession.sessionId}${studentId}`).style.display = "block";
            }
          }
        }
      }
    });
    this.setSize(studentList);
  };

  storeMuted = (mute, studentId) => {
    const studentList = this.state.students;
    studentList.forEach((student) => {
      if (student.studentId === studentId) {
        student.muted = mute;
        if(mute)
          student.icon = "muted";
        else
          student.icon = "";
      }
    });
  }
  // setIsChat = (value) => {
  //   this.setState({
  //     isChat: value,
  //   });
  // };

  setChatComponent = (value) => {
    this.setState({
      chatComponent: value,
    });
  };

  setFocus = (studentId) => {
    let stdnt = this.state.students;
    stdnt.forEach((student) => {
      if (student.studentId === studentId) {
        student.focus = !student.focus;
      } else {
        student.focus = false;
      }
    });
    this.setSize(stdnt);
  };
  // Burası
  setShow = (count, studentList, procs) => {
    console.log("Student Show True", count, studentList, procs)
    if (!studentList) studentList = this.state.students;
    let counter = 0;
    console.log("Student", studentList);
    studentList.forEach((student, i) => {
      student.chat = false;
      student.detail = false;
      student.focus = false;
      student.camera = "webcam";
      if (!student.proctorId) student.proctor = false;
      student.showForce = false;
      if (counter < count) {
        if (student.online === true) {
          counter++;
          student.show = true;
          if (count === 1) {
            student.showForce = true;
            student.detail = true;
          }
        } else {
          student.show = false;
        }
      } else {
        student.show = false;
      }
    });
    if (!procs) procs = this.state.sessionActiveProctors;

    procs.forEach((proctor, i) => {
      proctor.chat = false;
      proctor.show = false;
      proctor.detail = false;
      proctor.focus = false;
      proctor.studentId = proctor.proctorId;
      proctor.name = proctor.proctorName;
      proctor.lastname = proctor.proctorLastName;
      proctor.proctor = true;
      proctor.puan = "(P)";
      proctor.showForce = false;
      if (proctor.chatCount === undefined) proctor.chatCount = 0;
      var control = studentList.filter(
        (s) => s.studentId === proctor.studentId
      );
      if (control.length === 0) studentList.push(proctor);
    });

    this.setSize(studentList);
  };

  setSize = (stdnt) => {
    let count = 0;
    stdnt.forEach((element, i) => {
      if (element.show === true) count = count + 1;
    });
    if (count < 2) {
      stdnt.row = 12;
    } else if (count <= 4) {
      stdnt.row = 6;
    } else if (count <= 9) {
      stdnt.row = 4;
    } else if (count <= 12) {
      stdnt.row = 3;
    } else {
      stdnt.row = 4;
    }
    this.setProctoringStudent(stdnt);
  };

  setChatAlert = (sessionId, type) => {
    const stdnt = this.state.students;
    const findStudent = stdnt.find((str) => sessionId.includes(str.studentId));
    if (findStudent) {
      if (type && type === "reset") {
        findStudent.chatCount = 0;
      } else {
        findStudent.chatCount += 1;
      }
    }
    this.setProctoringStudent(stdnt);
  };

  setProctoringStudentListPanel = () => {
    this.setState({
      proctoringStudentListPanel: !this.state.proctoringStudentListPanel,
    });
  };

  setProctoringRulesPanel = () => {
    this.setState({
      proctoringRulesPanel: !this.state.proctoringRulesPanel,
    });
  };

  findProctors = (selectedSession) => {
    let addedproctor = [];
    selectedSession.studentList.forEach((element) => {
      if (element.proctors) {
        const proctorList = element.proctors;
        proctorList.forEach((p) => {
          if (
            !addedproctor.find((c) => c.proctorFullName === p.proctorFullName)
          ) {
            addedproctor.push(p);
          }
        });
      }
    });
    return addedproctor;
  };

  setRulePanel = () => {
    this.setState({
      rulePanel: !this.state.rulePanel,
    });
  };

  setProctoringChatPanel = (value) => {
    this.setState({
      proctoringChatPanel: value,
    });
  };

  setRulePanel = () => {
    this.setState({
      rulePanel: !this.state.rulePanel,
    });
  };

  setProctoringChatPanel = (value) => {
    this.setState({
      proctoringChatPanel: value,
    });
  };

  setProctoringStudent = (students) => {
    let allStudents = students;
    if (this.state.userRole.includes("Proctor")) {
      allStudents = allStudents.filter((student) =>
        student.proctors.find(
          (proctor) => proctor.proctorId === this.state.username
        )
      );
    }

    this.setState({ students: allStudents });
  };

  setCameras = (val) => {
    this.setState({cameras: val});
  }

  render() {
    return (
      <proctorcontext.Provider
        value={{
          ...this.state,
          setCheckStudent: this.setCheckStudent,
          setShow: this.setShow,
          setActiveSession: this.setActiveSession,
          setFocus: this.setFocus,
          setDetail: this.setDetail,
          storeMuted: this.storeMuted,
          setProctoringStudent: this.setProctoringStudent,
          setProctoringStudentListPanel: this.setProctoringStudentListPanel,
          setProctoringRulesPanel: this.setProctoringRulesPanel,
          setChatComponent: this.setChatComponent,
          setProctoringChatPanel: this.setProctoringChatPanel,
          setProctoringSession: this.setProctoringSession,
          setOnlineStudents: this.setOnlineStudents,
          setCommentList: this.setCommentList,
          setAddCommentList: this.setAddCommentList,
          setChats: this.setChats,
          setAppendMsg: this.setAppendMsg,
          getAppendMsg: this.getAppendMsg,
          addNewStudents: this.addNewStudents,
          setChatAlert: this.setChatAlert,
          setUserRole: this.setUserRole,
          setSize: this.setSize,
          setStudentAIScore: this.setStudentAIScore,
          setStream: this.setStream,
          setSessions: this.setSessions,
          setWebcamSubscriber: this.setWebcamSubscriber,
          setScreenShareSubscriber: this.setScreenShareSubscriber,
          setMobileSubscriberList: this.setMobileSubscriberList,
          deleteStudent: this.deleteStudent,
          setCameras: this.setCameras,
          deleteFromMobileList: this.deleteFromMobileList,
          deleteFromWebcamAndScreenShareList: this
            .deleteFromWebcamAndScreenShareList,
          setMobileSubscriberListNew: this.setMobileSubscriberListNew,
        }}
      >
        {this.props.children}
      </proctorcontext.Provider>
    );
  }
}
export default proctorprovider;
