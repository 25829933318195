import { useState, useContext } from "react";
import axios from "axios";
import { awscontext } from "../configs/awscontext";
import uniqid from "uniqid";
const config = require("../configs/awsconfig.json");

export const useChat = () => {
  const context = useContext(awscontext);
  const [result, setResult] = useState([]);

  const getChats = async (params) => {
    try {
      const resProctorts = await axios.get(
        `${config.api.invokeUrl}/chats/${params}/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      setResult(
        resProctorts.data.sort(function(a, b) {
          return new Date(a.Time) - new Date(b.Time);
        })
      );
      //setResult(resProctorts.data);
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
    }
  };

  const postChat = async (params) => {
    try {
      const time = new Date().getTime();
      const databaseParams = {
        ChatId: uniqid(),
        MeetingId: params.meetingId,
        Message: params.messageText,
        Time: time,
        Person: params.messageFrom,
        Role: params.role,
        Type: params.type,
        Staff: context.user.username,
      };
      await axios.post(`${config.api.invokeUrl}/chats`, databaseParams, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Object.keys(localStorage).find((key) => key.includes("accessToken"))
          )}`,
        },
      });
    } catch (err) {
      setResult(`An error has occurred: ${err}`);
    }
  };

  const handleChange = async (type, params) => {
    if (type === "get") {
      await getChats(params);
    } else if (type === "post") {
      postChat(params);
    }
  };
  return [result, handleChange];
};
