/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useInstitute } from "../../hooks/useInstitute";
import { awscontext } from "../../configs/awscontext";
import { useStorage } from "../../hooks/useStorage";
import ModalForm from "../components/ModalForm";
import UploadDetail from "./components/UploadDetail";
import ScreenPanel from "../components/ScreenPanel";
import i18n from "../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import config from "../../configs/awsconfig.json";
import { store } from "react-notifications-component";

function InstituteLogo() {
  const context = useContext(awscontext);
  const [, setInstitute] = useInstitute([]);
  const [uploadObject] = useStorage(config.s3.files);
  const [show, setShow] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    context.setAdres("branding");
    context.setBarGroup("SETTINGS");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFileUpload = async () => {
    try {
      await uploadObject(
        file,
        context.userInstituteId,
        "institutes/" + context.userInstituteId + "/logos/"
      );
      // let paramsLogos = {
      //   section: "logos",
      //   logos: "institutes/" + context.userInstituteId + "/logos/" + file.name,
      // };
      // await setInstitute("post", "patch", paramsLogos);
      store.addNotification({
        title: null,
        message: `${i18n.t("Institute_logo_has_been_updated_successfully")}`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } catch (error) {
      console.log(error);
      store.addNotification({
        title: null,
        message: `${error.message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };
  // const onFileUpload = () => {
  //   uploadObject(
  //     file,
  //     file.name,
  //     "institutes/" + context.userInstituteId + "/logos/"
  //   );
  //   let paramsLogos = {
  //     section: "logos",
  //     logos: "institutes/" + context.userInstituteId + "/logos/" + file.name,
  //   };
  //   setInstitute("post", "patch", paramsLogos);
  // };

  const onFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <React.Fragment>
      <ScreenPanel name={i18n.t("Institute_Logo")} col="6">
        <div className="d-flex flex-column">
          <div style={{ marginBottom: 20 }}>
            {i18n.t("choose_branding_logo")}
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center col-md-12">
            <label className={context.theme.btn_save} htmlFor="filePicker">
              {i18n.t("Choose_File")}
            </label>
            <input
              type="file"
              id="filePicker"
              style={{ visibility: "hidden" }}
              onChange={onFileChange}
              className={context.theme.btn_save}
            />
            <button
              onClick={onFileUpload}
              className={context.theme.btn_save}
              disabled={file ? false : true}
            >
              {i18n.t("Upload_File")}
            </button>
          </div>
        </div>

        <hr />
        <div className="col">{file ? <UploadDetail file={file} /> : ""}</div>
      </ScreenPanel>
      <ModalForm show={show} handleClose={handleClose} />
    </React.Fragment>
  );
}
export default withNamespaces()(InstituteLogo);
