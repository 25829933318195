import StaffList from "../app/staff/StaffList";
import Dashboard from "../app/dashboard/Dashboard";
import SessionList from "../app/session/SessionList";
import LiveSessionList from "../app/session/LiveSessionList";
import Library from "../app/session/Library";
import LibraryDetailed from "../app/session/components/LibraryDetailed";
import LibraryStudentDetailed from "../app/session/components/LibraryStudentDetailed";
import Supports from "../app/support/Supports";
import InstituteTask from "../app/institute/InstituteTask";
import SessionInformation from "../app/session/SessionInformation";
import Report from "../app/reports/Report";
import InstituteList from "../app/institute/InstituteList";
import Proctoring from "../app/proctor/Proctoring";
import AIWarnings from "../app/settings/AIWarnings";
import UploadLanguage from "../app/settings/UploadLanguage";
import StaffTask from "../app/staff/StaffTask";
import InstituteLogo from "../app/settings/InstituteLogo";
import DashboardSA from "../app/dashboard/DashboardSA";
import DashboardA from "../app/dashboard/DashboardA";
import SessionTask from "../app/session/SessionTask";
import ProctoringFinish from "../app/proctor/ProctoringFinish";
import SystemCheck from "../app/SystemCheck";
import CreateStudent from "../app/session/components/CreateStudent";
import {
  FaExclamationCircle,
  FaImages,
  FaLanguage,
  FaListAlt,
  FaPhotoVideo,
  FaPlusCircle,
  FaQuestionCircle,
  FaSlidersH,
  FaUser,
  FaVideo,
} from "react-icons/fa";
import { HiStatusOnline } from "react-icons/hi";
import LiveStudents from "../app/session/LiveStudents";

const dashboardRoutes = [
  {
    path: "/liveStudentList",
    name: "Live_Test_Takers",
    icon: " fas fa-chalkboard-teacher",
    renderIcon: HiStatusOnline,
    component: LiveStudents,
    layout: "/admin",
    location: "sidebar",
    overpath: "EXAMS",
    auth: ["Super Admin", "Admin"],
    sort: "3",
    sidePanelVisible: true,
  },
  {
    path: "/createDemoStudent",
    name: "Crate Demo Student",
    icon: "fas fa-chalkboard-teacher",
    component: CreateStudent,
    layout: "/admin",
    location: "",
    overpath: "EXAMS",
    auth: ["Super Admin", "Admin"],
    sort: "2",
    sidePanelVisible: false,
  },
  {
    path: "/sessionList",
    name: "Session",
    icon: " fas fa-chalkboard-teacher",
    component: SessionList,
    layout: "/admin",
    location: "navbar",
    overpath: "EXAMS",
    auth: ["Super Admin", "Admin"],
    sort: "2",
    sidePanelVisible: true,
  },
  {
    path: "/sessionList",
    name: "Session_List",
    renderIcon: FaUser,
    component: SessionList,
    layout: "/admin",
    location: "sidebar",
    overpath: "EXAMS",
    auth: ["Super Admin", "Admin"],
    sort: "0",
    sidePanelVisible: true,
  },
  {
    path: "/report",
    name: "Reports",
    icon: "fas fa-clipboard-list",
    component: Report,
    layout: "/admin",
    location: "navbar",
    overpath: "REPORT",
    auth: ["Super Admin", "Admin"],
    sort: "4",
    sidePanelVisible: true,
  },

  {
    path: "/addStaff",
    name: "Create_User",
    renderIcon: FaPlusCircle,
    component: StaffTask,
    layout: "/admin",
    location: "sidebar",
    overpath: "STAFF",
    auth: ["Super Admin", "Admin"],
    sort: "1",
    sidePanelVisible: true,
  },
  {
    path: "/staffList",
    name: "User",
    icon: "fas fa-user-friends",
    component: StaffList,
    layout: "/admin",
    location: "navbar",
    overpath: "STAFF",
    auth: ["Super Admin", "Admin"],
    sort: "3",
    sidePanelVisible: true,
  },
  {
    path: "/staffList",
    name: "User_List",
    renderIcon: FaListAlt,
    component: StaffList,
    layout: "/admin",
    location: "sidebar",
    overpath: "STAFF",
    auth: ["Super Admin", "Admin"],
    sort: "0",
    sidePanelVisible: true,
  },
  {
    path: "/updateStaff",
    name: "Staff_Edit",
    icon: "fas fa-university",
    component: StaffTask,
    layout: "/admin",
    location: "bos",
    overpath: "STAFF",
    auth: ["Super Admin", "Admin"],
    sort: "1",
    sidePanelVisible: true,
  },
  {
    path: "/deleteStaff",
    name: "Staff_Delete",
    icon: "fas fa-university",
    component: StaffTask,
    layout: "/admin",
    location: "bos",
    overpath: "STAFF",
    auth: ["Super Admin", "Admin"],
    sort: "1",
    sidePanelVisible: true,
  },
  {
    path: "/sessionInformation",
    name: "Session_Information",
    icon: "fas fa-university",
    component: SessionInformation,
    layout: "/admin",
    location: "bos",
    overpath: "EXAMS",
    auth: ["Super Admin", "Admin"],
    sort: "1",
    sidePanelVisible: true,
  },
  {
    path: "/liveSessionList",
    name: "Live_Session",
    renderIcon: FaVideo,
    component: LiveSessionList,
    layout: "/admin",
    location: "sidebar",
    overpath: "EXAMS",
    auth: ["Super Admin", "Admin"],
    sort: "2",
    sidePanelVisible: true,
  },
  {
    path: "/reviewer",
    name: "Library",
    renderIcon: FaPhotoVideo,
    component: Library,
    layout: "/admin",
    location: "navbar",
    overpath: "REVIEWER",
    auth: ["Reviewer"],
    sort: "4",
    sidePanelVisible: true,
  },
  {
    path: "/library",
    name: "Library",
    renderIcon: FaPhotoVideo,
    component: Library,
    layout: "/admin",
    location: "sidebar",
    overpath: "EXAMS",
    auth: ["Super Admin", "Admin"],
    sort: "4",
    sidePanelVisible: true,
  },
  {
    path: "/libraryDetailed",
    name: "LibraryDetailed",
    icon: "fas fa-university",
    component: LibraryDetailed,
    layout: "/admin",
    location: "bos",
    overpath: "",
    auth: ["Super Admin", "Admin", "Reviewer"],
    sort: "1",
    sidePanelVisible: true,
  },
  {
    path: "/libraryStudentDetailed",
    name: "LibraryStudentDetailed",
    icon: "fas fa-university",
    component: LibraryStudentDetailed,
    layout: "/admin",
    location: "bos",
    overpath: "",
    auth: ["Super Admin", "Admin", "Reviewer"],
    sort: "1",
    sidePanelVisible: false,
  },
  {
    path: "/supports",
    name: "Supports",
    renderIcon: FaQuestionCircle,
    component: Supports,
    layout: "/admin",
    location: "sidebar",
    overpath: "",
    auth: ["Super Admin", "Admin"],
    sort: "1",
    sidePanelVisible: true,
  },
  {
    path: "/createInstitute",
    name: "Create_Institute",
    renderIcon: FaPlusCircle,
    component: InstituteTask,
    layout: "/admin",
    location: "sidebar",
    overpath: "INSTITUTES",
    auth: ["Super Admin"],
    sort: "1",
    sidePanelVisible: true,
  },
  {
    path: "/updateInstitute",
    name: "Create_Institute",
    icon: "fas fa-plus-circle",
    component: InstituteTask,
    layout: "/admin",
    location: "",
    overpath: "INSTITUTES",
    auth: ["Super Admin"],
    sort: "1",
    sidePanelVisible: true,
  },
  {
    path: "/deleteInstitute",
    name: "Delete_Institute",
    icon: "fas fa-plus-circle",
    component: InstituteTask,
    layout: "/admin",
    location: "",
    overpath: "INSTITUTES",
    auth: ["Super Admin"],
    sort: "1",
    sidePanelVisible: true,
  },
  {
    path: "/proctor",
    name: "Proctoring",
    icon: "fas fa-eye",
    component: LiveSessionList,
    layout: "/admin",
    location: "navbar",
    overpath: "PROCTOR",
    auth: ["Proctor"],
    sort: "6",
    sidePanelVisible: false,
  },
  {
    path: "/proctoringFinish",
    name: "Proctor",
    icon: "fas fa-university",
    component: ProctoringFinish,
    layout: "/admin",
    location: "",
    overpath: "PROCTOR",
    auth: ["Super Admin", "Admin", "Proctor"],
    sort: "7",
    sidePanelVisible: true,
  },
  {
    path: "/instituteList",
    name: "Institute",
    icon: "fas fa-university",
    component: InstituteList,
    layout: "/admin",
    location: "navbar",
    overpath: "INSTITUTES",
    auth: ["Super Admin"],
    sort: "1",
    sidePanelVisible: true,
  },
  {
    path: "/instituteList",
    name: "Institute_List",
    renderIcon: FaListAlt,
    component: InstituteList,
    layout: "/admin",
    location: "sidebar",
    overpath: "INSTITUTES",
    auth: ["Super Admin"],
    sort: "0",
    sidePanelVisible: true,
  },
  {
    path: "/instituteAdmin",
    name: "Institute_Admin",
    icon: "fas fa-user-shield",
    component: StaffTask,
    layout: "/admin",
    location: "",
    overpath: "INSTITUTES",
    auth: ["Super Admin"],
    sort: "1",
    sidePanelVisible: true,
  },
  {
    path: "/createSession",
    name: "Create_Session",
    renderIcon: FaPlusCircle,
    component: SessionTask,
    layout: "/admin",
    location: "sidebar",
    overpath: "EXAMS",
    auth: ["Super Admin", "Admin"],
    sort: "1",
    sidePanelVisible: true,
  },
  {
    path: "/deleteSession",
    name: "Delete_Session",
    icon: "fas fa-plus-circle",
    component: SessionTask,
    layout: "/admin",
    location: "",
    overpath: "EXAMS",
    auth: ["Super Admin", "Admin"],
    sort: "1",
    sidePanelVisible: true,
  },
  {
    path: "/proctoring",
    name: "Exam_Proctor",
    icon: "fas fa-university",
    component: Proctoring,
    layout: "/admin",
    location: "bos",
    overpath: "",
    auth: ["Proctor"],
    sort: "1",
    sidePanelVisible: false,
  },
  {
    path: "/warnings",
    name: "Cyber_Proctor_Warnings",
    renderIcon: FaExclamationCircle,
    component: AIWarnings,
    layout: "/admin",
    location: "sidebar",
    overpath: "SETTINGS",
    auth: ["Super Admin", "Admin"],
    sort: "0",
    sidePanelVisible: true,
  },
  {
    path: "/branding",
    name: "Logo_Upload",
    renderIcon: FaImages,
    component: InstituteLogo,
    layout: "/admin",
    location: "sidebar",
    overpath: "SETTINGS",
    auth: ["Super Admin", "Admin"],
    sort: "4",
    sidePanelVisible: true,
  },
  {
    path: "/warnings",
    name: "Settings",
    icon: "fas fa-tools",
    component: AIWarnings,
    layout: "/admin",
    location: "navbar",
    overpath: "SETTINGS",
    auth: ["Super Admin", "Admin"],
    sort: "5",
    sidePanelVisible: true,
  },
  {
    path: "/generalSettings",
    name: "General_Settings",
    renderIcon: FaSlidersH,
    component: InstituteTask,
    layout: "/admin",
    location: "sidebar",
    overpath: "SETTINGS",
    auth: ["Super Admin", "Admin"],
    sort: "1",
    sidePanelVisible: true,
  },
  {
    path: "/uploadLanguage",
    name: "Languages",
    renderIcon: FaLanguage,
    component: UploadLanguage,
    layout: "/admin",
    location: "sidebar",
    overpath: "SETTINGS",
    auth: ["Super Admin"],
    sort: "2",
    sidePanelVisible: true,
  },
  {
    path: "/dashboarda",
    name: "Dasboard",
    icon: "fas fa-home",
    component: DashboardA,
    layout: "/admin",
    location: "",
    overpath: "",
    auth: ["Admin"],
    sort: "0",
    sidePanelVisible: true,
  },
  {
    path: "/dashboardsa",
    name: "Dasboard",
    icon: "fas fa-home",
    component: DashboardSA,
    layout: "/admin",
    location: "",
    overpath: "",
    auth: ["Super Admin"],
    sort: "0",
    sidePanelVisible: true,
  },
  {
    path: "/systemCheck",
    name: "Institute_Reviewers",
    icon: "fas fa-university",
    component: SystemCheck,
    layout: "/admin",
    location: "",
    overpath: "",
    auth: ["Super Admin", "Admin", "Proctor"],
    sort: "12",
    sidePanelVisible: false,
  },
  {
    path: "/admin",
    name: "Home",
    icon: "fas fa-home",
    component: Dashboard,
    layout: "/admin",
    location: "navbar",
    overpath: "",
    auth: ["Super Admin", "Admin"],
    sort: "0",
    sidePanelVisible: false,
  },
];

export default dashboardRoutes;
