import React from "react";
import { FaArrowRight } from "react-icons/fa";

export default function Statistics(props) {
  return (
    <div
      className=" py-1 mt-5 bg-grey col-2 rounded buttons"
      onClick={props.onClick}
    >
      <div className="card-body p-0 h-100  ">
        <div className="d-flex flex-row h-100 m-2 ">
          <div className="d-flex flex-column col-8 content-wrap text-center">
            <div
              style={{
                height: 50,
                fontSize: 20,
                fontFamily: "",
                color: "#29377e",
              }}
            >
              {props.name}
            </div>
            <div className="" style={{ fontSize: "40pt", color: "#29377e" }}>
              {parseInt(props.value) < 10 ? "0" + props.value : props.value}
            </div>
          </div>
          <div className="d-flex flex-column col-4 font-size py-2 content-wrap  ">
            <div className="justify-content-start">
              <FaArrowRight color={"#29377e"} size={20} />
            </div>
            <div className="mb-3" style={{ color: "#29377e" }}>
              <props.renderIcon size={50} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
