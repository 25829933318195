/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext } from "react";
import { useInput } from "../../../hooks/useInput";
import { useSession } from "../../../hooks/useSession";
import { awscontext } from "../../../configs/awscontext";
import i18n from "../../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import { store } from "react-notifications-component";

function SessionRules(props) {
  const context = useContext(awscontext);
  const [inputs, setInputs] = useInput({ ...props.SelectedSession });
  const [oldSession, setOldSession] = useInput({});
  const [, setSession] = useSession();

  useEffect(() => {
    setOldSession({ ...JSON.parse(JSON.stringify(props.SelectedSession)) });
  }, []);

  const saveChangeInfo = async (e) => {
    e.preventDefault();

    const params = {
      isArchived: false,
      instituteId: inputs.instituteId,
      sessionId: inputs.sessionId,
      sessionName: "",
      examRules: {
        internet: inputs.examRules.internet,
        calculator: inputs.examRules.calculator,
        application: inputs.examRules.application,
        penPapers: inputs.examRules.penPapers,
        textbooks: inputs.examRules.textbooks,
        additionalMaterials: inputs.examRules.additionalMaterials,
        internetDesc: inputs.examRules.internetDesc,
        calculatorDesc: inputs.examRules.calculatorDesc,
        applicationDesc: inputs.examRules.applicationDesc,
        penPapersDesc: inputs.examRules.penPapersDesc,
        textbooksDesc: inputs.examRules.textbooksDesc,
        additionalMaterialsDesc: inputs.examRules.additionalMaterialsDesc,
      },
    };
    const response = {
      statusCode: 0,
      body: null,
    };
    await setSession("patch", params, "examRules", response);
    if (response.statusCode === 204) {
      store.addNotification({
        title: null,
        //message: `Session ${inputs.sessionName}'s exam rules has successfully updated`,
        message: i18n.t("Session_exam_rules_has_successfully_updated"),
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      await catchChangedProp();
    } else if (response.statusCode === 210) {
      store.addNotification({
        title: null,
        message: `${response.body}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  const catchChangedProp = async () => {
    try {
      if (
        inputs.examRules.internet !== oldSession.examRules.internet ||
        inputs.examRules.internetDesc !== oldSession.examRules.internetDesc
      ) {
        inputs.studentList.forEach((student) => {
          student.examRules.internet = inputs.examRules.internet;
          student.examRules.internetDesc = inputs.examRules.internetDesc;
        });
      }
      if (
        inputs.examRules.calculator !== oldSession.examRules.calculator ||
        inputs.examRules.calculatorDesc !== oldSession.examRules.calculatorDesc
      ) {
        inputs.studentList.forEach((student) => {
          student.examRules.calculator = inputs.examRules.calculator;
          student.examRules.calculatorDesc = inputs.examRules.calculatorDesc;
        });
      }
      if (
        inputs.examRules.application !== oldSession.examRules.application ||
        inputs.examRules.applicationDesc !==
        oldSession.examRules.applicationDesc
      ) {
        inputs.studentList.forEach((student) => {
          student.examRules.application = inputs.examRules.application;
          student.examRules.applicationDesc = inputs.examRules.applicationDesc;
        });
      }
      if (
        inputs.examRules.penPapers !== oldSession.examRules.penPapers ||
        inputs.examRules.penPapersDesc !== oldSession.examRules.penPapersDesc
      ) {
        inputs.studentList.forEach((student) => {
          student.examRules.penPapers = inputs.examRules.penPapers;
          student.examRules.penPapersDesc = inputs.examRules.penPapersDesc;
        });
      }
      if (
        inputs.examRules.textbooks !== oldSession.examRules.textbooks ||
        inputs.examRules.textbooksDesc !== oldSession.examRules.textbooksDesc
      ) {
        inputs.studentList.forEach((student) => {
          student.examRules.textbooks = inputs.examRules.textbooks;
          student.examRules.textbooksDesc = inputs.examRules.textbooksDesc;
        });
      }
      if (
        inputs.examRules.additionalMaterials !==
        oldSession.examRules.additionalMaterials ||
        inputs.examRules.additionalMaterials !==
        oldSession.examRules.additionalMaterials
      ) {
        inputs.studentList.forEach((student) => {
          student.examRules.additionalMaterials =
            inputs.examRules.additionalMaterials;
          student.examRules.additionalMaterialsDesc =
            inputs.examRules.additionalMaterialsDesc;
        });
      }
      const params = {
        isArchived: false,
        sessionName: "",
        sessionId: inputs.sessionId,
        studentList: inputs.studentList,
        instituteId: inputs.instituteId,
      };
      const response = {
        statusCode: 0,
        body: null,
      };
      await setSession("patch", params, "studentExamRules", response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setInputs({ ...props.SelectedSession });
    return () => setInputs("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.SelectedSession]);

  return (
    <React.Fragment>
      <FormGroup style={{ marginTop: 15 }}>
        <div className="row">
          <div className="col-4">
            <FormControlLabel
              name="examRules.internet"
              control={
                <Switch
                  checked={inputs.examRules.internet}
                  onChange={(e) => setInputs(e, !inputs.examRules.internet)}
                />
              }
              label={i18n.t("Use_Internet")}
              color="primary"
            />
          </div>
          {inputs.examRules.internet === true ? (
            <div className="col-8">
              <input
                className="form-control"
                name="examRules.internetDesc"
                value={inputs.examRules.internetDesc}
                onChange={setInputs}
                type="text"
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <div className="col-4">
            <FormControlLabel
              name="examRules.calculator"
              control={
                <Switch
                  checked={inputs.examRules.calculator}
                  onChange={(e) => setInputs(e, !inputs.examRules.calculator)}
                />
              }
              label={i18n.t("Use_Calculator")}
              color="primary"
            />
          </div>
          {inputs.examRules.calculator === true ? (
            <div className="col-8">
              <input
                className="form-control"
                name="examRules.calculatorDesc"
                value={inputs.examRules.calculatorDesc}
                onChange={setInputs}
                type="text"
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <div className="col-4">
            <FormControlLabel
              name="examRules.application"
              control={
                <Switch
                  checked={inputs.examRules.application}
                  onChange={(e) => setInputs(e, !inputs.examRules.application)}
                />
              }
              label={i18n.t("External_Application")}
              color="primary"
            />
          </div>
          {inputs.examRules.application === true ? (
            <div className="col-8">
              <input
                className="form-control"
                name="examRules.applicationDesc"
                value={inputs.examRules.applicationDesc}
                onChange={setInputs}
                type="text"
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <div className="col-4">
            <FormControlLabel
              name="examRules.penPapers"
              control={
                <Switch
                  checked={inputs.examRules.penPapers}
                  onChange={(e) => setInputs(e, !inputs.examRules.penPapers)}
                />
              }
              label={i18n.t("Pen_or_Papers")}
              color="primary"
            />
          </div>
          {inputs.examRules.penPapers === true ? (
            <div className="col-8">
              <input
                className="form-control"
                name="examRules.penPapersDesc"
                value={inputs.examRules.penPapersDesc}
                onChange={setInputs}
                type="text"
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <div className="col-4">
            <FormControlLabel
              name="examRules.textbooks"
              control={
                <Switch
                  checked={inputs.examRules.textbooks}
                  onChange={(e) => setInputs(e, !inputs.examRules.textbooks)}
                />
              }
              label={i18n.t("Use_Textbooks")}
              color="primary"
            />
          </div>
          {inputs.examRules.textbooks === true ? (
            <div className="col-8">
              <input
                className="form-control"
                name="examRules.textbooksDesc"
                value={inputs.examRules.textbooksDesc}
                onChange={setInputs}
                type="text"
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <div className="col-4">
            <FormControlLabel
              name="examRules.additionalMaterials"
              control={
                <Switch
                  checked={inputs.examRules.additionalMaterials}
                  onChange={(e) =>
                    setInputs(e, !inputs.examRules.additionalMaterials)
                  }
                />
              }
              label={i18n.t("Additional_Materials")}
              color="primary"
            />
          </div>
          {inputs.examRules.additionalMaterials === true ? (
            <div className="col-8">
              <input
                className="form-control"
                name="examRules.additionalMaterialsDesc"
                value={inputs.examRules.additionalMaterialsDesc}
                onChange={setInputs}
                type="text"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </FormGroup>
      <div className="d-flex flex-fill">
        <button onClick={saveChangeInfo} className={context.theme.btn_save}>
          {i18n.t("Save")}
        </button>
      </div>
    </React.Fragment>
  );
}
export default withNamespaces()(SessionRules);
