/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import SystemCheckItem from "./SystemCheckItem";
import OpenViduConnector from "../../utils/OpenViduConnector";
import config from "../../configs/awsconfig.json";
import { FaStream } from "react-icons/fa";
import { awscontext } from "../../configs/awscontext";
import i18n from "../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import { proctorcontext } from "../../configs/proctorContext";
import axios from "axios";
import { store } from "react-notifications-component";

const TYPE = "pc";

function CheckStreaming(props) {
  const context = useContext(awscontext);
  const proccontext = useContext(proctorcontext);
  const informationCurrent = useRef(proccontext.proctoringSession);
  const instituteId = informationCurrent.current.instituteId;
  const meetingId = `${informationCurrent.current.sessionId}${context.user.username}`;
  const username = `${context.user.name} ${context.user.lastname}`;
  const [info, setInfo] = useState({
    title: i18n.t("Check_Streaming"),
    ready: i18n.t("WAITING"),
    waitMessage: i18n.t("StreamDesc"),
    infoCover: "col-6",
    description: i18n.t(
      "This_step_will_check_whether_there_are_a_webcam_microphone_and_display_connected_and_working"
    ),
    descriptionCover: "col-6",
    buttonText: i18n.t("Next_Step"),
    repeatButtonText: i18n.t("NONE"),
  });
  const [settings, setSettings] = useState({
    pc: {
      audioVideo: null,
      webcam: { active: false, session: null, recorder: null },
      screenShare: { active: false, session: null, recorder: null },
    },
    phone: { active: true, session: null },
  });

  useEffect(() => {
    const constructOpenViduRecordingSessionOverLambda = async (
      role,
      source
    ) => {
      const openviduConnector = new OpenViduConnector();
      const sessionProperties = {
        customSessionId: `${meetingId}-${source}`,
      };
      await openviduConnector.createSession(sessionProperties);
      const session = await openviduConnector.initializeSession();
      const connectionProperties = {
        role,
      };
      const connection = await openviduConnector.connectToSession(
        sessionProperties.customSessionId,
        connectionProperties
      );
      const publisher = await openviduConnector.joinSession(
        session,
        connection,
        username,
        source
      );

      let pc = publisher.stream.getRTCPeerConnection();
      let sender = await pc.getSenders().find(s => s.track?.kind === "video");
      if (source === "webcam") {
        let parameters = sender.getParameters();
        parameters.degradationPreference = "balanced";
        sender.setParameters(parameters);
        sender.track.contentHint = 'motion';
      } else if (source === "screenShare") {
        let parameters = sender.getParameters();
        parameters.degradationPreference = "maintain-resolution";
        sender.setParameters(parameters);
        sender.track.contentHint = 'detail';
      }
      
      session.on("recordingStarted", (event) => {
        setSettings((prevSettings) => ({
          ...prevSettings,
          [TYPE]: {
            ...prevSettings[TYPE],
            [source]: {
              active: true,
              session,
              recorder: event.target,
            },
          },
        }));
      });

      publisher.on("streamCreated", (event) => {});

      const meetingInformation = {
        meetingId: sessionProperties.customSessionId,
        instituteId,
        archive: false,
        sessionId: informationCurrent.current.sessionId,
        studentId: context.user.username,
        state: "ACTIVE",
        startTime: new Date().getTime(),
      };
      updateMeetingInformation(meetingInformation);
      const recordingProperties = {
        session: sessionProperties.customSessionId,
        name: meetingId,
        outputMode: "INDIVIDUAL",
        frameRate: 20,
        resolution: "640x480",
      };
      await openviduConnector.startRecordingOnSession(recordingProperties);
      return publisher;
    };
    const updateMeetingInformation = async (meeting) => {
      try {
        await axios.post(
          `${config.api.invokeUrl}${config.aws.gateway.endpoints.meetings.context}`,
          meeting,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                Object.keys(localStorage).find((key) =>
                  key.includes("accessToken")
                )
              )}`,
            },
          }
        );
        console.log("Meeting has successfully updated");
      } catch (err) {
        throw err;
      }
    };
    const retriableOpenviduRecordingSessionOverLambda = async (
      role,
      source
    ) => {
      try {
        await constructOpenViduRecordingSessionOverLambda(role, source);
      } catch (err) {
        if (err.name === "SCREEN_CAPTURE_DENIED") {
          store.addNotification({
            title: i18n.t("Screen_Capture_Denied"),
            message: i18n.t(
              "You_cannot_deny_the_screen_capture_Please_wait_and_accept"
            ),
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          retriableOpenviduRecordingSessionOverLambda(role, source);
        }
        console.log("Error Occurred: ", err);
      }
    };
    const configureMeetingSession = async () => {
      retriableOpenviduRecordingSessionOverLambda("PUBLISHER", "webcam");
      retriableOpenviduRecordingSessionOverLambda("PUBLISHER", "screenShare");
    };
    configureMeetingSession();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (settings[TYPE].webcam.active && settings[TYPE].screenShare.active) {
      setInfo((info) => ({
        ...info,
        ready: "OK",
        waitMessage: i18n.t("StreamWaitMessageSuccess"),
      }));
    }
  }, [settings]);

  useEffect(() => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      const webcamData = {
        instituteId: informationCurrent.current.instituteId,
        sessionId: `${informationCurrent.current.sessionId}${context.user.username}-webcam`,
      };
      axios.post(
        `${config.api.invokeUrl}${config.openvidu.kms.endpoints.session.context}${config.openvidu.kms.endpoints.session.close.context}`,
        webcamData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      const screenShareData = {
        instituteId: informationCurrent.current.instituteId,
        sessionId: `${informationCurrent.current.sessionId}${context.user.username}-screenShare`,
      };
      axios.post(
        `${config.api.invokeUrl}${config.openvidu.kms.endpoints.session.context}${config.openvidu.kms.endpoints.session.close.context}`,
        screenShareData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      return (ev.returnValue = "Are you sure you want to close?");
    });
  }, []);

  const buttonHandler = () => {
    props.setSettings(settings);
    props.buttonHandler();
  };

  return (
    <React.Fragment>
      <SystemCheckItem
        Icon={
          <FaStream
            size={150}
            color={info.ready === "OK" ? "#29377e" : "gray"}
          />
        }
        info={info}
        buttonHandler={buttonHandler}
        next={props.next}
        prev={props.prev}
        configuration={props.configuration}
        currentStep={props.currentStep}
        repeatableButton={false}
      />
    </React.Fragment>
  );
}
export default withNamespaces()(CheckStreaming);
