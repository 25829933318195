/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { awscontext } from "../../configs/awscontext";
import ScreenPanel from "../components/ScreenPanel";
import i18n from "../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import axios from "axios";
import config from "../../configs/awsconfig.json";
import { useInstitute } from "../../hooks/useInstitute";
import { store } from "react-notifications-component";
const configI18 = require("../../../src/configs/i18n");
function InstituteList(props) {
  const context = useContext(awscontext);
  const [rowCount, setRowCount] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [itemCount, setItemCount] = useState(context.instituteList.length);
  const [list, setList] = useState(context.instituteList);
  const [pureList, setPureList] = useState(context.instituteList);
  const [userMap, setUserMap] = useState({});
  const [, setInstitutes] = useInstitute([]);


  useEffect(() => {
    context.setAdres("instituteList");
    context.setBarGroup("INSTITUTES");

    const getUserInformation = async (instituteId) => {
      try {
        const result = await axios.get(
          `${config.api.invokeUrl}/institutes/${instituteId}/users`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                Object.keys(localStorage).find((key) =>
                  key.includes("accessToken")
                )
              )}`,
            },
          }
        );
        return result.data;
      } catch (err) {
        console.log(
          `Institute with institute id ${instituteId} cannot be found`,
          err
        );
      }
    };

    const getAllUserCount = async () => {
      const userMap = {};
      for (const institute of list) {
        const count = await getUserInformation(institute.id);
        userMap[institute.id] = count;
        if (userMap[institute.id]) institute.count = count.length;
        else institute.count = 0;
      }
      setUserMap(userMap);
    };

    getAllUserCount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (selectedInstitute, type) => {
    props.history.push({
      pathname: `/${type}`,
      state: { selectedInstitute: selectedInstitute },
    });
  };

  useEffect(() => {
    setItemCount(context.instituteList.length);
    setList(context.instituteList);
    setPureList(context.instituteList);
    setRowCount(10);
  }, [context.instituteList]);

  useEffect(() => {
    if (props.location.state && props.location.state.status === "CREATED_NEW") {
      store.addNotification({
        title: null,
        //message: `Institute ${props.location.state.instituteName} has successfully updated`,
        message: i18n.t("Institute_has_successfully_updated"),
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.state]);

  useEffect(() => {
    setItemCount(list.length);
  }, [list]);

  const updateDatas = () => {
    setInstitutes("get", {
      instituteId: context.userInstituteId,
    });
  };

  return (
    <ScreenPanel
      name="Institute_List"
      panels="true"
      pagination="true"
      rowCount={rowCount}
      setRowCount={setRowCount}
      itemCount={itemCount}
      activePage={activePage}
      setActivePage={setActivePage}
      pureList={pureList}
      list={list}
      setList={setList}
      updateDatas={updateDatas}
    >
      <table className={context.theme.table_style} id="table">
        <thead>
          <tr className={context.theme.color}>
            <th onClick={() => context.sortTableData(list, "id")}>
              {i18n.t("Id")}
            </th>
            <th onClick={() => context.sortTableData(list, "instituteName")}>
              {i18n.t("Institute_Name")}
            </th>
            <th onClick={() => context.sortTableData(list, "language", "int")}>
              {i18n.t("Languages")}
            </th>
            <th onClick={() => context.sortTableData(list, "count", "int")}>
              {i18n.t("User_Count")}
            </th>
            <th
              onClick={() => context.sortTableData(list, "sessionCount", "int")}
            >
              {i18n.t("Session_Count")}
            </th>
            <th
              onClick={() => context.sortTableData(list, "studentCount", "int")}
            >
              {i18n.t("TT_Count")}
            </th>
          </tr>
        </thead>
        <tbody>
          {list
            .slice((activePage - 1) * rowCount, activePage * rowCount)
            .map((ins, i) => (
              <tr key={i} onClick={() => handleClick(ins, "updateInstitute")}>
                <td>{ins.id}</td>
                <td>{ins.instituteName}</td>
                <td>{ins.language}</td>
                <td>{userMap[ins.id] ? userMap[ins.id].length : 0}</td>
                <td>{ins.sessionCount}</td>
                <td>{ins.studentCount}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </ScreenPanel>
  );
}
export default withNamespaces()(InstituteList);
