import React, { useEffect, useState } from "react";
import SystemCheckItem from "./SystemCheckItem";
import { FaWifi } from "react-icons/fa";
import NetworkSpeedCheck from "network-speed";
import config from "../../configs/awsconfig.json";

export default function CheckInternetConnection(props) {
  const MIN_LIMIT = 0;
  const [info, setInfo] = useState({
    title: "Checking Internet Connection",
    ready: "WAITING",
    waitMessage: "Please wait. We are checking your connection",
    infoCover: "col-6",
    description:
      "A stable internet connection with at least 1 MBps (~8 Mbps) speed is required to take your exam via SmarTest Inviligate. We need to check your connection before you proceed.",
    descriptionCover: "col-6",
    buttonText: "Next Step",
  });

  useEffect(() => {
    const getNetworkDownloadSpeed = async () => {
      const checkNetworkSpeed = new NetworkSpeedCheck();
      const baseUrl = `https://${config.s3.files}.s3-eu-west-1.amazonaws.com/system-check/10MB.zip`;
      const fileSizeInBytes = 10000000;
      const speed = await checkNetworkSpeed.checkDownloadSpeed(
        baseUrl,
        fileSizeInBytes
      );
      if (parseFloat(speed.mbps) > MIN_LIMIT) {
        setInfo((info) => ({
          ...info,
          ready: "OK",
          waitMessage: `Success! Please click "Next Step" to proceed`,
        }));
      } else {
        setInfo((info) => ({
          ...info,
          waitMessage: `Slow/Unstable connection detected. Please check your connection and repeat the system check`,
        }));
      }
    };
    const checkNetworkDownloadSpeed = async () => {
      await getNetworkDownloadSpeed();
    };
    checkNetworkDownloadSpeed();
  }, []);

  return (
    <SystemCheckItem
      Icon={
        <FaWifi size={200} color={info.ready === "OK" ? "#29377e" : "gray"} />
      }
      info={info}
      buttonHandler={props.buttonHandler}
      next={props.next}
      prev={props.prev}
      handleRepeatClick={props.handleRepeatClick}
      repeatableButton={true}
      configuration={props.configuration}
      currentStep={props.currentStep}
    />
  );
}
