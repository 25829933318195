/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import { useInput } from "../../../hooks/useInput";
import FormSelect from "../../components/FormSelect";
/* eslint-disable no-unused-vars */
import FormInput from "../../components/FormInput";
import { useSession } from "../../../hooks/useSession";
import { awscontext } from "../../../configs/awscontext";
import i18n from "../../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ModalForm from "../../components/ModalForm";
import { store } from "react-notifications-component";
const variables = require("../../../models/variables.json");

function SessionInfo(props) {
  const context = useContext(awscontext);
  const selectedSession = props.selectedSession;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [inputs, setInputs] = useInput({ ...selectedSession });
  const [sessions, setSession] = useSession();

  useEffect(() => {
    setInputs({ ...selectedSession });
  }, [selectedSession]);

  const saveChangeInfo = async (e) => {
    e.preventDefault();
    if (!inputs.startDateTimeUnix) {
      store.addNotification({
        title: null,
        message: i18n.t("Please_enter_a_valid_start_date_and_time"),
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } else if (!inputs.endDateTimeUnix) {
      store.addNotification({
        title: null,
        message: i18n.t("Please_enter_a_valid_end_date_and_time"),
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } else if (!inputs.lastStartDateTimeUnix) {
      store.addNotification({
        title: null,
        message: i18n.t("Please_enter_a_valid_last_start_date_and_time"),
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } else if (
      inputs.instanceState !== "ON_GOING" &&
      inputs.startDateTimeUnix - new Date().getTime() / 60000 < 5
    ) {
      store.addNotification({
        title: null,
        message: i18n.t("The_start_date_has_to_be_5_minutes_ahead"),
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } else if (inputs.endDateTimeUnix <= inputs.startDateTimeUnix) {
      store.addNotification({
        title: null,
        message: i18n.t(
          "Please_enter_a_valid_end_time_The_provided_input_cannot_be_less_than_the_start_time"
        ),
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } else if (inputs.endDateTimeUnix <= inputs.lastStartDateTimeUnix) {
      store.addNotification({
        title: null,
        message: i18n.t(
          "Please_enter_a_valid_end_time_The_provided_input_cannot_be_less_than_the_last_start_time"
        ),
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } else if (inputs.lastStartDateTimeUnix < inputs.startDateTimeUnix) {
      store.addNotification({
        title: null,
        message: i18n.t(
          "Please_enter_a_valid_last_start_time_The_provided_input_cannot_be_less_than_the_last_start_time"
        ),
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } else {
      const params = {
        isArchived: false,
        instituteId: selectedSession.instituteId,
        sessionId: inputs.sessionId,
        sessionName: inputs.sessionName.trim(),
        examUrl: inputs.examUrl.trim(),
        examDuration: inputs.examDuration,
        examExtraDuration: inputs.examExtraDuration,
        examExtraDurationDesc: inputs.examExtraDurationDesc,
        proctorServiceType: inputs.proctorServiceType,
        proctorRecording: inputs.proctorRecording,
        iframe:
          inputs.iframe === "true" || inputs.iframe === true ? true : false,
        supervisor: "-",
        startDateTimeUnix: inputs.startDateTimeUnix,
        lastStartDateTimeUnix: inputs.lastStartDateTimeUnix,
        endDateTimeUnix: inputs.endDateTimeUnix,
        onboardingProcess: props.appropriateSessionProctoringType()
          ? inputs.onboardingProcess
          : variables.onboardingProcess[0].value,
        useLdb: inputs.useLdb,
        mail: inputs.mail,
        allowedOutsourceCamera: parseInt(inputs.allowedOutsourceCamera),
      };

      if (inputs.useLdb) params.iframe = true;
      // if (inputs.proctorServiceType === "Record_and_Review_Lite") {
      //   params.isAIactive = false;
      //   params.showToProctor = false;
      //   params.showToStudent = false;
      // } else {
      //   params.isAIactive = inputs.isAIactive;
      //   params.showToProctor = inputs.showToProctor;
      //   params.showToStudent = inputs.showToStudent;
      // }
      params.isAIactive = inputs.isAIactive;
      params.showToProctor = inputs.showToProctor;
      params.showToStudent = inputs.showToStudent;
      
      const response = {
        statusCode: 0,
        body: null,
      };
      await setSession("patch", params, "sessionInformation", response);
      if (response.statusCode === 204) {
        props.setSelectedSession({ ...inputs, ...params });
        store.addNotification({
          title: null,
          //message: `Session ${params.sessionName} has successfully updated`,
          message: i18n.t("Session_has_successfully_updated"),
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      } else if (response.statusCode === 210) {
        store.addNotification({
          title: null,
          message: `${response.body}`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    }
  };

  const calculateLocalDateTime = (timestamp) => {
    const currentDate = new Date(timestamp ? timestamp : new Date().getTime());

    let currentMonth;
    if (currentDate.getMonth() + 1 < 10) {
      currentMonth = `0${currentDate.getMonth() + 1}`;
    } else {
      currentMonth = `${currentDate.getMonth() + 1}`;
    }
    let currentDay;
    if (currentDate.getDate() < 10) {
      currentDay = `0${currentDate.getDate()}`;
    } else {
      currentDay = currentDate.getDate();
    }
    let currentHour;
    if (currentDate.getHours() < 10) {
      currentHour = `0${currentDate.getHours()}`;
    } else {
      currentHour = currentDate.getHours();
    }
    let currentMinute;
    if (currentDate.getMinutes() < 10) {
      currentMinute = `0${currentDate.getMinutes()}`;
    } else {
      currentMinute = currentDate.getMinutes();
    }
    return [
      `${currentDate.getFullYear()}-${currentMonth}-${currentDay}`,
      `${currentHour}:${currentMinute}`,
    ];
  };

  const calculateUnixTimeFromTime = (inputDate, inputTime) => {
    const dateArray = inputDate.split("-");
    const timeArray = inputTime.split(":");
    const offsetHourDifference = parseInt(new Date().getTimezoneOffset() / 60);
    const offsetMinuteDifference =
      new Date().getTimezoneOffset() - offsetHourDifference * 60;
    const timeHour = parseInt(timeArray[0]) + offsetHourDifference;
    const timeMinute = parseInt(timeArray[1]) + offsetMinuteDifference;
    return new Date(
      Date.UTC(
        dateArray[0],
        parseInt(dateArray[1]) - 1,
        dateArray[2],
        timeHour,
        timeMinute,
        0
      )
    ).getTime();
  };

  const timeOnChange = (timestamp, value, inputType, inputKey) => {
    let unix;
    if (inputType === "date") {
      const currentTime = calculateLocalDateTime(timestamp)[1];
      unix = calculateUnixTimeFromTime(value, currentTime);
    } else if (inputType === "time") {
      const currentDate = calculateLocalDateTime(timestamp)[0];
      unix = calculateUnixTimeFromTime(currentDate, value);
    }
    let examDuration = inputs.examDuration;
    if (inputKey === "endDateTimeUnix" || inputKey === "sessionEndDate") {
      examDuration = calculateDuration(inputs.startDateTimeUnix, unix);
    } else if (inputKey === "startDateTimeUnix" || inputKey === "sessionDate") {
      examDuration = calculateDuration(unix, inputs.endDateTimeUnix);
    }
    if (
      inputKey === "startDateTimeUnix" &&
      !inputs.lastStartDateTimeUnix &&
      !inputs.endDateTimeUnix
    ) {
      setInputs({
        ...inputs,
        examDuration,
        [inputKey]: unix,
        lastStartDateTimeUnix: unix,
        endDateTimeUnix: unix,
      });
    } else {
      setInputs({ ...inputs, examDuration, [inputKey]: unix });
    }
  };

  const durationOnChange = (value) => {
    const unixDifference = value * 60 * 1000;
    const endDateTimeUnix = inputs.startDateTimeUnix + unixDifference;
    setInputs({ ...inputs, endDateTimeUnix, examDuration: value });
  };

  const calculateDuration = (startUnix, endUnix) => {
    let unixStartMinBase = Math.floor(startUnix / 60 / 1000);
    let unixEndMinBase = Math.floor(endUnix / 60 / 1000);
    return unixEndMinBase - unixStartMinBase;
  };

  const handleDeleteSession = async () => {
    await setSession("delete", {
      instituteId: inputs.instituteId,
      sessionId: inputs.sessionId,
    });
    props.setSelectedSession(null);
    store.addNotification({
      title: null,
      //message: `Session ${selectedSession.sessionName} has successfully deleted`,
      message: i18n.t("Session_has_successfully_deleted"),
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  return (
    <React.Fragment>
      <form
        className="w-100 mx-auto p-3 mt-1 
        pt-4 row"
        onSubmit={(e) => saveChangeInfo(e)}
      >
        <FormInput
          col="4"
          name="sessionName"
          text={i18n.t("Session_Name")}
          value={inputs.sessionName}
          onChange={setInputs}
          type="text"
        />
        <FormSelect
          col="4"
          name="proctorServiceType"
          label={i18n.t("Proctor_Mode")}
          onChange={setInputs}
          value={inputs.proctorServiceType}
          data={variables.ProctorServiceTypes}
          // dataValue={setInputs.proctorServiceType}
          dataValue="value"
          dataText="value"
          required={true}
        />
        <div className="form group col-1 ml-2">
          <label>LDB</label>
          <br></br>
          <FormControlLabel
            value="end"
            control={
              <Switch
                color="primary"
                checked={inputs.useLdb}
                onChange={setInputs}
                name="useLdb"
              />
            }
            labelPlacement="end"
          />
        </div>
        
        {/* <div className="form group col-1">
          <label>Mail</label>
          <br></br>
          <FormControlLabel
            value="end"
            control={
              <Switch
                color="primary"
                checked={inputs.mail}
                onChange={setInputs}
                name="mail"
              />
            }
            labelPlacement="end"
          />
        </div> */}
        {inputs.proctorServiceType === "Live_Proctoring_Plus" ? (
          <FormSelect
            col="2"
            name="allowedOutsourceCamera"
            label={i18n.t("Number_Of_Mobile_Cam")}
            onChange={setInputs}
            value={inputs.allowedOutsourceCamera}
            data={variables.allowedOutsourceCamera}
            dataValue="value"
            dataText="text"
            required={true}
          />
        ) : null}

        <FormInput
          col="3"
          name="sessionDate"
          text={i18n.t("Date")}
          value={calculateLocalDateTime(inputs.startDateTimeUnix)[0]}
          onChange={(e) =>
            timeOnChange(
              inputs.startDateTimeUnix,
              e.target.value,
              "date",
              "startDateTimeUnix"
            )
          }
          type="date"
        />
        <FormInput
          col="3"
          name="sessionEndDate"
          text={i18n.t("End_Date")}
          value={calculateLocalDateTime(inputs.endDateTimeUnix)[0]}
          onChange={(e) =>
            timeOnChange(
              inputs.endDateTimeUnix,
              e.target.value,
              "date",
              "endDateTimeUnix"
            )
          }
          type="date"
          required={true}
          disabled={inputs.startDateTimeUnix ? false : true}
        />
        {inputs.useLdb ? null : (
          <FormSelect
            col="2"
            name="iframe"
            label={i18n.t("Select_Frame_Type")}
            onChange={setInputs}
            value={inputs.iframe}
            data={variables.IFrame}
            dataValue="value"
            dataText="text"
            required={true}
          />
        )}

        {props.appropriateSessionProctoringType(inputs.proctorServiceType) ? (
          <FormSelect
            col="3"
            name="onboardingProcess"
            label={i18n.t("Onboarding_Process")}
            onChange={setInputs}
            value={inputs.onboardingProcess}
            data={variables.onboardingProcess}
            dataValue="value"
            dataText="text"
            required={true}
          />
        ) : null}

        {props.appropriateSessionProctoringType(inputs.proctorServiceType) ? (
          <div className="form-group col-1 text-center" id="proctorRecording">
            <div>{i18n.t("Proctor_Recording")}</div>
            <FormControlLabel
              value="end"
              control={
                <Switch
                  color="primary"
                  checked={inputs.proctorRecording}
                  onChange={setInputs}
                  name="proctorRecording"
                />
              }
              labelPlacement="end"
            />
          </div>
        ) : null}

        <div className="col-md-12 row">
          <FormInput
            col="3"
            name="startTime"
            text={i18n.t("Start_Time")}
            value={calculateLocalDateTime(inputs.startDateTimeUnix)[1]}
            onChange={(e) =>
              timeOnChange(
                inputs.startDateTimeUnix,
                e.target.value,
                "time",
                "startDateTimeUnix"
              )
            }
            type="time"
            required={true}
          />
          <FormInput
            col="3"
            name="lastStartTime"
            text={i18n.t("Last_Start_Time")}
            value={calculateLocalDateTime(inputs.lastStartDateTimeUnix)[1]}
            onChange={(e) =>
              timeOnChange(
                inputs.startDateTimeUnix,
                e.target.value,
                "time",
                "lastStartDateTimeUnix"
              )
            }
            type="time"
            disabled={inputs.startDateTimeUnix ? false : true}
          />
          <FormInput
            col="3"
            name="endTime"
            text={i18n.t("End_Time")}
            value={calculateLocalDateTime(inputs.endDateTimeUnix)[1]}
            onChange={(e) =>
              timeOnChange(
                inputs.startDateTimeUnix,
                e.target.value,
                "time",
                "endDateTimeUnix"
              )
            }
            type="time"
            disabled={inputs.startDateTimeUnix ? false : true}
            required={true}
          />
          <FormInput
            col="2"
            name="examDuration"
            text={i18n.t("Duration_Min")}
            value={inputs.examDuration}
            onChange={(e) => durationOnChange(e.target.value)}
            type="number"
            disabled={inputs.startDateTimeUnix ? false : true}
          />
        </div>

        {/* <FormSelect
          col="2"
          name="examExtraDuration"
          label={i18n.t("Extra_Time")}
          onChange={setInputs}
          value={inputs.examExtraDuration}
          data={variables.ExamExtraDuration}
          dataValue="value"
          dataText="value"
        />
        <FormInput
          col="10"
          name="examExtraDurationDesc"
          text={i18n.t("Reason")}
          value={inputs.examExtraDurationDesc}
          onChange={setInputs}
          type="text"
        /> */}
        <FormInput
          col="12"
          name="examUrl"
          text={i18n.t("Exam_URL")}
          value={inputs.examUrl}
          onChange={setInputs}
          type="text"
        />

        <div className="d-flex flex-fill justify-content-between">
          <button className={context.theme.btn_save} type="submit">
            {i18n.t("Save")}
          </button>
          {context.userRole.includes("Super Admin") ? (
            <button
              className={context.theme.btn_delete}
              onClick={() => {
                setShowDeleteModal(true);
              }}
              type="button"
            >
              {i18n.t("Delete")}
            </button>
          ) : null}
        </div>
      </form>
      <ModalForm
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDeleteSession}
        Title={i18n.t("Confirm_Delete")}
        Body={i18n.t("Are_you_sure_you_want_to_delete_this_item?")}
      />
    </React.Fragment>
  );
}
export default withNamespaces()(SessionInfo);
