/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import FormInput from '../components/FormInput';
import { useInput } from '../../hooks/useInput';
import ModalForm from '../components/ModalForm';
import FormSelect from '../components/FormSelect';
import { useStaff } from '../../hooks/useStaff';
import { awscontext } from '../../configs/awscontext';
import ScreenPanel from '../components/ScreenPanel';
import i18n from '../../configs/i18n';
import { withNamespaces } from 'react-i18next';
import uniqid from 'uniqid';
import { store } from 'react-notifications-component';
const staffModel = require('../../models/modelStaff.json');
const variables = require('../../models/variables.json');

function StaffTask(props) {
  const context = useContext(awscontext);
  const [inputs, setInputs] = useInput(
    props.location.state
      ? {
          ...props.location.state.selectedStaff,
          institutes: props.location.state.selectedStaff.institutes
            ? props.location.state.selectedStaff.institutes
            : [],

          roles: props.location.state.selectedStaff.roles
            ? props.location.state.selectedStaff.roles
            : [],
        }
      : {
          ...staffModel.staff,
          role: '',
          userInstituteName: '',
          userInstituteId: '',
          instituteId: '',
          username: uniqid(),
          institutes: [],
          roles: [],
        }
  );
  const [title, setTitle] = useState('');
  const [, setStaff] = useStaff([]);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [btnSaveTitle, setBtnSaveTitle] = useState('');
  const [btnDeleteTitle, setBtnDeleteTitle] = useState('');
  const [btnDeleteVisible, setBtnDeleteVisible] = useState(false);
  const [institutes, setInstitutes] = useState('');
  const [roles, setRoles] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    if (props.location.pathname.includes('addStaff')) {
      setPage('addStaff');
      context.setAdres('addStaff');
      context.setBarGroup('STAFF');
      setTitle('Create_User');
      setBtnSaveTitle('Create_User');
    }
    if (props.location.pathname.includes('updateStaff')) {
      setIsUpdate(true);
      setPage('updateStaff');
      context.setAdres('updateStaff');
      context.setBarGroup('STAFF');
      setTitle('Update');
      setBtnDeleteVisible(true);
      setBtnDeleteTitle('Delete');
      setBtnSaveTitle('Update');
    }
    if (props.location.pathname.includes('deleteStaff')) {
      setIsUpdate(true);
      setPage('deletestaff');
      context.setAdres('deletestaff');
      context.setBarGroup('STAFF');
      setTitle('Delete');
      setBtnSaveTitle('Delete');
    }
    if (props.location.pathname.includes('instituteAdmin')) {
      setPage('instituteAdmin');
      context.setAdres('instituteAdmin');
      context.setBarGroup('INSTITUTES');
      setTitle('Create_User');
      setBtnSaveTitle('Create_User');
    }
    showInstitutes();
    showRoles();
    return () => setInputs('');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEvent = async (e) => {
    e.preventDefault();

    if (inputs.roles.length === 0) {
      store.addNotification({
        title: null,
        message: i18n.t(
          'Please_press_Add_Role_button_to_include_the_user_roles'
        ),
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      return;
    } else if (checkUserRole('Super Admin') && inputs.institutes.length === 0) {
      store.addNotification({
        title: null,
        message: i18n.t(
          'Please_press_Add_Institute_button_to_include_the_user_roles'
        ),
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      return;
    } else if (checkInputRole('Super Admin') && inputs.roles.length > 1) {
      store.addNotification({
        title: null,
        message: i18n.t(
          'You_cannot_assign_more_than_one_role_to_an_Super_Admin_user'
        ),
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      return;
    } else if (checkInputRole('Admin') && inputs.institutes.length > 1) {
      store.addNotification({
        title: null,
        message: i18n.t(
          'You_cannot_add_more_than_one_institute_to_an_Admin_user'
        ),
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      return;
    } else if (checkInputRole('Admin') && inputs.roles.length > 1) {
      store.addNotification({
        title: null,
        message: i18n.t('You_cannot_assign_more_than_one_role_to_a_Admin_user'),
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      return;
    }
    if (page === 'updateStaff') {
      await setStaff('patch', inputs).then(() => {
        props.history.push(`/staffList`);
        store.addNotification({
          title: null,
          //message: `Staff ${inputs.name}  ${inputs.lastname} has successfully updated`,
          message: i18n.t('Staff_has_successfully_updated'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      });
    } else if (page === 'addStaff') {
      if (checkUserRole('Super Admin')) {
        var selectItemElement = document.getElementsByName('instituteId');
        var instituteNameSelect =
          selectItemElement[0].options[selectItemElement[0].selectedIndex].text;
        inputs.instituteName = instituteNameSelect;
      } else if (context.userRole.includes('Admin')) {
        inputs.instituteId = localStorage.getItem('instituteId');
        inputs.instituteName = localStorage.getItem('instituteName');
        const instList = context.instituteList;
        const findInstitute = instList.filter(
          (i) => i.id === inputs.instituteId
        );
        inputs.institutes.push({
          instituteName: findInstitute[0].instituteName,
          instituteId: findInstitute[0].id,
          language: findInstitute[0].language,
        });
      }

      await setStaff('post', inputs).then(() => {
        props.history.push(`/staffList`);
        store.addNotification({
          title: null,
          //message: `Staff ${inputs.name}  ${inputs.lastname} has successfully created`,
          message: i18n.t('Staff_has_successfully_created'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      });
    } else if (page === 'instituteAdmin') {
      await setStaff('post', inputs).then(() => {
        store.addNotification({
          title: null,
          //message: `Staff ${inputs.name}  ${inputs.lastname} has successfully created`,
          message: i18n.t('Staff_has_successfully_created'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      });
    }
    setShow(true);
  };

  const handleEventDelete = (e) => {
    setShowDelete(true);
  };

  const handleClose = () => {
    setShow(false);
    setShowDelete(false);
  };

  const handleDeleteConfirm = async () => {
    await setStaff('delete', inputs);
    props.history.push(`/staffList`);
    store.addNotification({
      title: null,
      //message: `${inputs.role} ${inputs.name}  ${inputs.lastname} has successfully deleted`,
      message: i18n.t('Staff_has_successfully_deleted'),
      type: 'success',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
    setShowDelete(false);
  };
  const updateInstituteHandler = async (type, institute) => {
    if (!institute && type === 'remove') {
      store.addNotification({
        title: null,
        message: i18n.t('Please_select_an_institute'),
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      return;
    }
    if (type === 'add') {
      const instList = context.instituteList;
      const findInstitute = instList.filter((i) => i.id === inputs.instituteId);
      const existingInstitute = inputs.institutes.filter(
        (i) => i.instituteId === inputs.instituteId
      );
      if (existingInstitute.length === 0) {
        inputs.institutes.push({
          instituteName: findInstitute[0].instituteName,
          instituteId: findInstitute[0].id,
        });
        setInputs(inputs);
      }
    } else if (type === 'remove') {
      const selected = inputs.institutes.filter(
        (i) => i.instituteId !== institute.instituteId
      );
      inputs.institutes = selected;
      setInputs(inputs);
    }
    showInstitutes();
  };

  const updateRoleHandler = async (type, role) => {
    if (!role && type === 'remove') {
      store.addNotification({
        title: null,
        message: i18n.t('Please_select_a_role'),
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      return;
    }
    if (type === 'add') {
      const result = inputs.roles.find((r) => r.role === inputs.role);
      if (!result) {
        inputs.roles.push({
          role: inputs.role,
        });
        setInputs(inputs);
      }
    } else if (type === 'remove') {
      const selected = inputs.roles.filter((i) => i.role !== role);
      inputs.roles = selected;
      setInputs(inputs);
    }
    showRoles();
  };

  const showInstitutes = () => {
    setInstitutes(
      <React.Fragment>
        {inputs.institutes.map((inst, i) => (
          <span key={i} className="mr-3">
            <i
              className={`${context.theme.icon_delete} mr-1`}
              onClick={(e) => {
                e.preventDefault();
                updateInstituteHandler('remove', inst);
              }}
            />
            {inst.instituteName}
          </span>
        ))}
      </React.Fragment>
    );
  };

  const showRoles = () => {
    setRoles(
      <React.Fragment>
        {inputs.roles.map((role, i) => (
          <span key={i} className="mr-3">
            <i
              className={`${context.theme.icon_delete} mr-1`}
              onClick={(e) => {
                e.preventDefault();
                updateRoleHandler('remove', role.role);
              }}
            ></i>
            {role.role}
          </span>
        ))}
      </React.Fragment>
    );
  };

  const checkUserRole = (role) => {
    const userRole = context.userRole;
    if (userRole) {
      const parsedUserRole = JSON.parse(userRole);
      const foundRole = parsedUserRole.find(
        (singleRole) => singleRole.role === role
      );
      if (foundRole) {
        return true;
      }
    }
    return false;
  };

  const checkInputRole = (role) => {
    if (inputs.roleName) {
      const userRole = inputs.roleName.split(',');
      if (userRole.length > 0) {
        const foundRole = userRole.find((singleRole) => singleRole === role);
        if (foundRole) {
          return true;
        }
      }
    }

    return false;
  };

  const checkCanShowUpdateButton = () => {
    if (checkUserRole('Super Admin')) {
      return true;
    }
    if (checkUserRole('Admin') && !checkInputRole('Super Admin')) {
      return true;
    }
    return false;
  };

  const checkCanShowDeleteButton = () => {
    if (checkUserRole('Super Admin') && context.user.email !== inputs.email) {
      return true;
    } else if (
      checkUserRole('Admin') &&
      context.user.email !== inputs.email &&
      !checkInputRole('Super Admin') &&
      !checkInputRole('Admin')
    ) {
      return true;
    }
    // if (!checkUserRole("Admin") || context.user.email !== inputs.email) {
    //   return true;
    // }
    // if (checkUserRole("Admin") && context.user.email !== inputs.email) {
    //   return false;
    // }
    return false;
  };

  return (
    <React.Fragment>
      <ScreenPanel name={title} col="6">
        <form className="w-100 mx-auto p-3" onSubmit={handleEvent}>
          <FormInput
            name="email"
            text={i18n.t('Email_Address')}
            value={inputs.email}
            onChange={setInputs}
            type="email"
            required={true}
            disabled={isUpdate}
          />
          {!context.userRole.includes('Admin') ||
          context.user.email !== inputs.email ? (
            <React.Fragment>
              <FormSelect
                name="role"
                label={i18n.t('Role')}
                onChange={setInputs}
                value={inputs.role}
                data={
                  checkUserRole('Super Admin')
                    ? variables.rolesSuperAdmin
                    : variables.roles
                }
                dataValue="value"
                dataText="value"
              />
              <button
                type="button"
                className="btn btn-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  updateRoleHandler('add');
                }}
                style={{ marginBottom: 10 }}
              >
                {i18n.t('Add_Role')}
              </button>

              {inputs.roles.length > 0 ? (
                <div className="mt-1 p-2 mb-1">{roles}</div>
              ) : null}
            </React.Fragment>
          ) : null}

          {checkUserRole('Super Admin') ? (
            <React.Fragment>
              <FormSelect
                name="instituteId"
                label={i18n.t('Institute')}
                onChange={setInputs}
                value={inputs.instituteId}
                data={context.instituteList}
                dataValue="id"
                dataText="instituteName"
              />
              <button
                type="button"
                className="btn btn-secondary"
                style={{ marginBottom: 10 }}
                onClick={(e) => {
                  e.preventDefault();
                  updateInstituteHandler('add');
                }}
              >
                {i18n.t('Add_Institute')}
              </button>
              {inputs.institutes.length > 0 ? (
                <div className="mt-1 p-2 mb-1">{institutes}</div>
              ) : null}
            </React.Fragment>
          ) : null}

          <FormInput
            name="name"
            text={i18n.t('First_Name')}
            value={inputs.name}
            onChange={setInputs}
            type="text"
            required={true}
          />
          <FormInput
            name="lastname"
            text={i18n.t('Last_Name')}
            value={inputs.lastname}
            onChange={setInputs}
            type="text"
            required={true}
          />
          <FormSelect
            name="status"
            label={i18n.t('User_Status')}
            onChange={setInputs}
            value={inputs.status}
            data={variables.ACT}
            dataValue="value"
            dataText="value"
            required={true}
          />
          <div className="d-flex flex-row justify-content-between">
            {checkCanShowUpdateButton() ? (
              <button type="submit" className={context.theme.btn_save}>
                {i18n.t(btnSaveTitle)}
              </button>
            ) : null}

            {checkCanShowDeleteButton() ? (
              btnDeleteVisible === true ? (
                <button
                  className={context.theme.btn_delete}
                  onClick={(e) => handleEventDelete(e)}
                  type="button"
                >
                  {i18n.t(btnDeleteTitle)}
                </button>
              ) : null
            ) : null}
          </div>
        </form>
      </ScreenPanel>
      <ModalForm show={show} handleClose={handleClose} />
      <ModalForm
        show={showDelete}
        handleClose={handleClose}
        handleDelete={handleDeleteConfirm}
        Title={i18n.t('Confirm_Delete')}
        Body={i18n.t('Are_you_sure_you_want_to_delete_this_item?')}
      />
    </React.Fragment>
  );
}
export default withNamespaces()(StaffTask);
