import React, { useContext, useEffect, useState } from "react";
import ScreenPanel from "../components/ScreenPanel";
import { awscontext } from "../../configs/awscontext";
import i18n from "../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import { useSession } from "../../hooks/useSession";
import LoadingIcon from "../components/LoadingIcon";

function LiveStudents() {
  const context = useContext(awscontext);
  const [testTakerList, setTestTakerList] = useState(
    context.instituteLiveStudents
  );
  const [, setSession] = useSession();
  const [isPageReady, setIsPageReady] = useState(true);

  useEffect(() => {
    context.setAdres("liveStudentList");
    context.setBarGroup("EXAMS");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    context.setAdres("liveStudentList");
    context.setBarGroup("EXAMS");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const constructTestTakerList = async () => {
      setIsPageReady(false);
      const liveStudents = await setSession("instituteLiveStudents", {
        instituteId: context.currentInstitute.id,
      });
      const retrievedSessionList = [];
      const currentTestTakerList = [];
      if (liveStudents) {
        for (const liveStudent of liveStudents) {
          let foundSession;
          const alreadyRetrievedSessionIndex = retrievedSessionList.findIndex(
            (retrievedSession) =>
              retrievedSession.sessionId === liveStudent.sessionId
          );
          if (alreadyRetrievedSessionIndex === -1) {
            foundSession = await setSession("getById", {
              sessionId: liveStudent.sessionId,
              instituteId: context.currentInstitute.id,
            });
            retrievedSessionList.push(foundSession);
          } else {
            foundSession = retrievedSessionList[alreadyRetrievedSessionIndex];
          }
          const foundStudent =
            foundSession &&
            foundSession.studentList.find(
              (student) => student.studentId === liveStudent.studentId
            );
          if (foundStudent) {
            currentTestTakerList.push({
              ...foundStudent,
              sessionName: foundSession.sessionName,
            });
          }

          if (
            foundStudent &&
            foundStudent.proctors &&
            foundStudent.proctors.length > 0
          ) {
            foundStudent.proctors.forEach((proctor) => {
              const existingProctor = currentTestTakerList.findIndex(
                (testTaker) =>
                  testTaker.studentId === proctor.proctorId &&
                  testTaker.sessionName === foundSession.sessionName
              );
              if (existingProctor === -1) {
                currentTestTakerList.push({
                  studentId: proctor.proctorId,
                  name: `${proctor.proctorName} (Proctor)`,
                  lastname: proctor.proctorLastName,
                  sessionName: foundSession.sessionName,
                });
              }
            });
          }
        }
        setTestTakerList(currentTestTakerList);
        setIsPageReady(true);
      }
    };
    constructTestTakerList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const mainContent = () => {
    return (
      <ScreenPanel
        name={i18n.t("Live_Test_Taker_List")}
        panels="true"
        pagination="true"
      >
        <table className={context.theme.table_style} id="table">
          <thead>
            <tr className={context.theme.color}>
              <th
                onClick={() =>
                  context.sortTableData(
                    context.instituteLiveStudents,
                    "studentId"
                  )
                }
              >
                {i18n.t("Test_Taker_Id")}
              </th>
              <th onClick={() => context.sortTableData(testTakerList, "name")}>
                {i18n.t("Test_Taker_Name")}
              </th>
              <th
                onClick={() => context.sortTableData(testTakerList, "lastname")}
              >
                {i18n.t("Test_Taker_Surname")}
              </th>
              <th
                onClick={() =>
                  context.sortTableData(testTakerList, "sessionName")
                }
              >
                {i18n.t("Session")}
              </th>
            </tr>
          </thead>
          <tbody>
            {testTakerList && testTakerList.map((tt) => (
              <tr>
                <td>{tt.studentId} </td>
                <td>{tt.name}</td>
                <td>{tt.lastname}</td>
                <td>{tt.sessionName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </ScreenPanel>
    );
  };

  return isPageReady ? mainContent() : <LoadingIcon />;
}
export default withNamespaces()(LiveStudents);
