import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import { awscontext } from "../../configs/awscontext";
import i18n from "../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";

function ModalForm(props) {
  const context = useContext(awscontext);
  return (
    <Modal show={props.show} onHide={props.handleClose} size={props.size}>
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: "center" }}>
          {props.Title ? props.Title : i18n.t("Result")}
        </Modal.Title>
      </Modal.Header>

      {props.children ? (
        <Modal.Body>{props.children}</Modal.Body>
      ) : props.Body ? (
        <Modal.Body>{props.Body}</Modal.Body>
      ) : null}

      {props.children ? (
        ""
      ) : (
        <Modal.Footer>
          {!props.handleDelete ? (
            <React.Fragment>
              <button
                className={context.theme.btn_save}
                onClick={props.handleClose}
              >
                {i18n.t("Close")}
              </button>
            </React.Fragment>
          ) : (
            <div className="d-flex flex-fill justify-content-between">
              <button
                className={context.theme.btn_delete}
                onClick={props.handleDelete}
                type="button"
              >
                {props.confirmButtonName
                  ? props.confirmButtonName
                  : i18n.t("Delete")}
              </button>
              <button
                className={context.theme.btn_save}
                onClick={props.handleClose}
                type="button"
              >
                {i18n.t("Cancel")}
              </button>
            </div>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
}
export default withNamespaces()(ModalForm);
