/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import { useInput } from "../../../hooks/useInput";
import { useSession } from "../../../hooks/useSession";
import StudentPrivateRules from "./StudentPrivateRules";
import { awscontext } from "../../../configs/awscontext";
import i18n from "../../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import ModalForm from "../../components/ModalForm";
import { FaCheckCircle, FaCheck, FaTimes } from "react-icons/fa";
import CreateStudent from "./CreateStudent";
import { Tooltip } from "reactstrap";
import ScreenPanel from "../../components/ScreenPanel";
import axios from "axios";
import config from "../../../configs/awsconfig.json";
import { store } from "react-notifications-component";
const modelStudent = require("../../../models/modelStudent.json");

function SessionStudents(props) {
  const context = useContext(awscontext);
  const [inputs, setInputs] = useInput({ ...props.SelectedSession });
  const [, setSession] = useSession();
  const [selectedStudent, setSelectedStudent] = useState(modelStudent);
  const [show, setShow] = useState(false);
  const [showStudent, setShowStudent] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState({});
  const [rowCountLocalTable, setRowCountLocalTable] = useState(10);
  const [itemCount, setItemCount] = useState(
    props.SelectedSession.studentList.length
  );
  const [activePage, setActivePage] = useState(1);
  const [pureList, setPureList] = useState(props.SelectedSession.studentList);
  const [list, setList] = useState(props.SelectedSession.studentList);
  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    setList(props.SelectedSession.studentList);
    setItemCount(props.SelectedSession.studentList.length);
    setPureList(props.SelectedSession.studentList);
  }, [props.SelectedSession]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    constructStudentMeetingInformationList(props.SelectedSession);
  }, [props.SelectedSession]); // eslint-disable-line react-hooks/exhaustive-deps

  const constructStudentMeetingInformationList = async (selectedSession) => {
    const result = await axios.post(
      `${config.api.invokeUrl}/meetings/query`,
      {
        sessionId: selectedSession.sessionId,
        studentId: "",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Object.keys(localStorage).find((key) => key.includes("accessToken"))
          )}`,
        },
      }
    );
    const testTakerMeetingInformationList = result.data;
    const filteredMeetingInformation = testTakerMeetingInformationList.filter(
      (meetingInformation) => meetingInformation.meetingId.includes("webcam")
    );
    selectedSession.studentList.forEach((student) => {
      const studentFound = filteredMeetingInformation.find(
        (tt) => tt.studentId === student.studentId
      );
      if (studentFound) {
        if (studentFound.state === "KICKED") {
          student.meetingStatus = "KICKED";
        } else if (studentFound.endTime) {
          student.meetingStatus = "FINISHED";
        } else {
          student.meetingStatus = "IN EXAM";
        }
      } else if (student.preSystemCheck) {
        let correctSystemChecks = 0;
        for (const key in student.preSystemCheck) {
          if (student.preSystemCheck[key]) correctSystemChecks++;
        }
        let numberOfKeys = Object.keys(student.preSystemCheck).length;

        if (correctSystemChecks === numberOfKeys) {
          student.meetingStatus = "NOT STARTED";
        } else {
          student.meetingStatus = "REGISTERED";
        }
      }
    });
    setInputs({ ...props.SelectedSession });
  };

  const createRandomStudent = async (e) => {
    setIsDisable(true);
    //await props.retrieveSessionInformation(false);
    const response = {
      statusCode: 0,
      body: null,
    };
    await setSession("random", inputs, null, response);
    setIsDisable(false);
    if (response.statusCode === 204) {
      inputs.studentList.push(...response.value);
      props.setSelectedSession(inputs);
      constructStudentMeetingInformationList(inputs);
      store.addNotification({
        title: null,
        message: i18n.t(
          "Randomly_generated_student_has_successfully_created_and_added_to_the_session"
        ),
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } else if (response.statusCode === 210) {
      constructStudentMeetingInformationList(inputs);
      store.addNotification({
        title: null,
        message: `${response.body}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  const sendEmails = async () => {
    store.addNotification({
      title: null,
      message: i18n.t("Email_sent_started"),
      type: "info",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });

    try {
      let response = await axios.post(
        `${config.api.invokeUrl}/sendemails`,
        {
          sessionId: props.SelectedSession.sessionId,
          instituteId: props.SelectedSession.instituteId,
          content: "",
          timezoneOffset: new Date().getTimezoneOffset(),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Object.keys(localStorage).find((key) =>
                key.includes("accessToken")
              )
            )}`,
          },
        }
      );
      const mailResponseStatus = response.data;
      mailResponseStatus.forEach((statu) => {
        if (statu.hasOwnProperty("mailError")) throw new Error(statu.mailError);
      });

      await setSession("getById", {
        instituteId: props.SelectedSession.instituteId,
        sessionId: props.SelectedSession.sessionId,
      }).then((foundSession) => {
        const foundIndex = context.sessionList.findIndex(
          (session) => session.sessionId === foundSession.sessionId
        );
        context.sessionList[foundIndex] = foundSession;
        context.setSelectedSession(foundSession);
        props.setSelectedSession(foundSession);
      });

      let mailCount = mailResponseStatus.length;
      store.addNotification({
        title: null,
        message: `${mailCount + " " + i18n.t("Emails_are_sent")}`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } catch (error) {
      console.log(error);
      store.addNotification({
        title: null,
        message: `${error.message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  const randomSession1000 = async (e) => {
    //await props.retrieveSessionInformation(false);
    const response = {
      statusCode: 0,
      body: null,
    };
    await setSession("randomSession1000", inputs, null, response);
    if (response.statusCode === 204) {
      inputs.studentList.push(...response.value);
      props.setSelectedSession(inputs);
      constructStudentMeetingInformationList(inputs);
      store.addNotification({
        title: null,
        message: i18n.t(
          "Randomly_generated_student_has_successfully_created_and_added_to_the_session"
        ),
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    } else if (response.statusCode === 210) {
      constructStudentMeetingInformationList(inputs);
      store.addNotification({
        title: null,
        message: `${response.body}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  const handleClose = () => {
    constructStudentMeetingInformationList(props.SelectedSession);
    setShow(false);
    setShowStudent(false);
  };

  const showDetail = (student) => {
    setSelectedStudent(student);
    setShow(true);
  };

  const getPreSystemCheckStatus = (student) => {
    const preSystemCheck = student.preSystemCheck;
    if (preSystemCheck) {
      let correctSystemChecks = 0;
      for (const key in preSystemCheck) {
        if (preSystemCheck[key]) correctSystemChecks++;
      }
      let numberOfKeys = Object.keys(preSystemCheck).length;

      return numberOfKeys === correctSystemChecks ? (
        <div className="d-flex align-items-center justify-content-center">
          <FaCheck color="green" size={16} />
        </div>
      ) : (
        <div className="d-flex flex-row align-items-center justify-content-center">
          <FaTimes color="red" size={16} />
          <div className="ml-1">
            {correctSystemChecks}/{numberOfKeys}
          </div>
        </div>
      );
    }
    return null;
  };

  const privateRulesShow = (student) => {
    const privateRules = [];
    if (student.examRules && student.examRules.mobileCameraUsage) {
      privateRules.push(
        <React.Fragment>
          <div
            className="d-flex flex-row align-items-center"
            key={`${student.studentId}_private_rule_mobile_camera`}
          >
            {i18n.t("Mobile_Camera_Usage")}
            <FaCheckCircle color="green" style={{ marginLeft: 5 }} />
          </div>
        </React.Fragment>
      );
    }
    if (student.examRules && student.examRules.internet) {
      privateRules.push(
        <React.Fragment>
          <div
            className="d-flex flex-row align-items-center"
            key={`${student.studentId}_private_rule_internet`}
          >
            {i18n.t("Use_Internet")}
            <FaCheckCircle color="green" style={{ marginLeft: 5 }} />
          </div>
        </React.Fragment>
      );
    }
    if (student.examRules && student.examRules.calculator) {
      privateRules.push(
        <React.Fragment>
          <div
            className="d-flex flex-row align-items-center"
            key={`${student.studentId}_private_rule_calculator`}
          >
            {i18n.t("Use_Calculator")}
            <FaCheckCircle color="green" style={{ marginLeft: 5 }} />
          </div>
        </React.Fragment>
      );
    }
    if (student.examRules && student.examRules.application) {
      privateRules.push(
        <React.Fragment>
          <div
            className="d-flex flex-row align-items-center"
            key={`${student.studentId}_private_rule_application`}
          >
            {i18n.t("External_Application")}
            <FaCheckCircle color="green" style={{ marginLeft: 5 }} />
          </div>
        </React.Fragment>
      );
    }
    if (student.examRules && student.examRules.penPapers) {
      privateRules.push(
        <React.Fragment>
          <div
            className="d-flex flex-row align-items-center"
            key={`${student.studentId}_private_rule_pen_papers`}
          >
            {i18n.t("Pen_or_Papers")}
            <FaCheckCircle color="green" style={{ marginLeft: 5 }} />
          </div>
        </React.Fragment>
      );
    }
    if (student.examRules && student.examRules.textbooks) {
      privateRules.push(
        <React.Fragment>
          <div
            className="d-flex flex-row align-items-center"
            key={`${student.studentId}_private_rule_textbooks`}
          >
            {i18n.t("Use_Textbooks")}
            <FaCheckCircle color="green" style={{ marginLeft: 5 }} />
          </div>
        </React.Fragment>
      );
    }
    if (student.examRules && student.examRules.additionalMaterials) {
      privateRules.push(
        <React.Fragment>
          <div
            className="d-flex flex-row align-items-center"
            key={`${student.studentId}_private_rule_additional_materials`}
          >
            {i18n.t("Additional_Materials")}
            <FaCheckCircle color="green" style={{ marginLeft: 5 }} />
          </div>
        </React.Fragment>
      );
    }

    return privateRules;
  };

  const renderPreSystemCheckTooltip = (student) => {
    const render = [];
    const preSystemCheck = student.preSystemCheck;
    if (preSystemCheck) {
      for (const key in preSystemCheck) {
        let keyString;
        let valueRender;
        if (key === "audioShareable") {
          keyString = "Audio";
        } else if (key === "screenShareable") {
          keyString = "Screen Share";
        } else if (key === "webcamShareable") {
          keyString = "Webcam";
        } else if (key === "internetConnectionStatus") {
          keyString = "Internet Connection";
        } else if (key === "numberOfDisplaysValidity") {
          keyString = "Additional Displays";
        } else if (key === "preExamRoomScan") {
          keyString = "Exam Environment";
        } else if (key === "preExamMaterialCheck") {
          keyString = "Materials";
        } else if (key === "toiletBreakPermission") {
          keyString = "Toilet Break";
        }

        if (preSystemCheck[key]) {
          valueRender = <FaCheck color="green" size={14} />;
        } else {
          valueRender = <FaTimes color="red" size={14} />;
        }
        render.push(
          <div
            className="d-flex flex-row align-items-center justify-content-flex-start"
            key={`${student.studentId}_${key}`}
          >
            <div>{keyString}</div>
            <div className="ml-2">{valueRender}</div>
          </div>
        );
      }
    }
    return render;
  };

  const toggleTooltip = (e, student) => {
    const newTooltipOpen = {};
    for (const key in tooltipOpen) {
      newTooltipOpen[key] = false;
    }
    if (student.preSystemCheck && e.type !== "mouseout") {
      newTooltipOpen[student.studentId] = true;
    }
    setTooltipOpen((prevTooltipOpen) => ({
      ...prevTooltipOpen,
      ...newTooltipOpen,
    }));
  };

  const renderStudentMeetingStatus = (meetingStatus) => {
    switch (meetingStatus) {
      case "FINISHED":
        return <span style={{ color: "#28a745" }}>{i18n.t("FINISHED")}</span>;
      case "KICKED":
        return <span style={{ color: "#dc3545" }}>{i18n.t("DISQUALIFIED")}</span>;
      case "REGISTERED":
        return <span style={{ color: "#777777" }}>{i18n.t("REGISTERED")}</span>;
      case "NOT STARTED":
        return <span style={{ color: "#ffc107" }}>{i18n.t("Not_started")}</span>;
      case "IN EXAM":
        return <span style={{ color: "#007bff" }}>{i18n.t("In_exam")}</span>;
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <div>
        <ScreenPanel
          panels="true"
          pagination="true"
          setRowCount={setRowCountLocalTable}
          rowCount={rowCountLocalTable}
          itemCount={itemCount}
          activePage={activePage}
          setActivePage={setActivePage}
          pureList={pureList}
          list={list}
          setList={setList}
        >
          <table className="table table-bordered" id="table" cellSpacing="0">
            <thead>
              <tr>
                <th onClick={() => context.sortTableData(list, "meetingStatus")}>
                  {i18n.t("Status")}
                </th>
                <th onClick={() => context.sortTableData(list, "studentId")}>
                  {i18n.t("Test_Taker_Id")}
                </th>
                <th onClick={() => context.sortTableData(list, "name")}>
                  {i18n.t("Name_Lastname")}
                </th>
                <th onClick={() => context.sortTableData(list, "email")}>
                  {i18n.t("Email")}
                </th>
                <th>{i18n.t("PSC")}</th>
                <th>{i18n.t("Personal_Settings")}</th>
                {props.appropriateSessionProctoringType(
                  inputs.proctorServiceType
                ) ? (
                  <th>{i18n.t("Proctor")}</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {list
                .slice(
                  (activePage - 1) * rowCountLocalTable,
                  activePage * rowCountLocalTable
                )
                .map((student, i) => (
                  student.isArchived === true ? <tr key={i}><td>{renderStudentMeetingStatus(student.meetingStatus)}</td>
                    <td>*****</td>
                    <td>
                      Student Deleted (
                      {(activePage - 1) * rowCountLocalTable + i + 1})
                    </td>
                    <td
                      style={
                        student.mailSent ? { color: "green" } : { color: "black" }
                      }
                    >
                      *****@*****
                    </td>

                    <td
                      className="pl-0 pr-0"
                      id={`pre-system-check-${student.studentId}`}
                    >
                      {getPreSystemCheckStatus(student)}
                      <Tooltip
                        placement="auto"
                        isOpen={tooltipOpen[student.studentId]}
                        target={`pre-system-check-${student.studentId}`}
                        toggle={(e) => toggleTooltip(e, student)}
                        style={{ backgroundColor: "#fff", color: "black" }}
                      >
                        {renderPreSystemCheckTooltip(student)}
                      </Tooltip>
                    </td>
                    <td>{privateRulesShow(student)}</td>
                    {props.appropriateSessionProctoringType(
                      inputs.proctorServiceType
                    ) ? (
                      <td>
                        <div className="row align-items-start">
                          <div className="col-md-auto">
                            <div>
                              {student.proctors
                                ? student.proctors.map((s, index) => (
                                  <div key={index}>{s.proctorFullName}</div>
                                ))
                                : null}
                            </div>
                          </div>
                        </div>
                      </td>
                    ) : null}</tr> :
                    <tr key={i} onClick={() => showDetail(student)}>
                      <td>{renderStudentMeetingStatus(student.meetingStatus)}</td>
                      <td>{student.studentId}</td>
                      <td>
                        {student.name} {student.lastname} (
                        {(activePage - 1) * rowCountLocalTable + i + 1})
                      </td>
                      <td
                        style={
                          student.mailSent ? { color: "green" } : { color: "black" }
                        }
                      >
                        {student.email}
                      </td>

                      <td
                        className="pl-0 pr-0"
                        id={`pre-system-check-${student.studentId}`}
                      >
                        {getPreSystemCheckStatus(student)}
                        <Tooltip
                          placement="auto"
                          isOpen={tooltipOpen[student.studentId]}
                          target={`pre-system-check-${student.studentId}`}
                          toggle={(e) => toggleTooltip(e, student)}
                          style={{ backgroundColor: "#fff", color: "black" }}
                        >
                          {renderPreSystemCheckTooltip(student)}
                        </Tooltip>
                      </td>
                      <td>{privateRulesShow(student)}</td>
                      {props.appropriateSessionProctoringType(
                        inputs.proctorServiceType
                      ) ? (
                        <td>
                          <div className="row align-items-start">
                            <div className="col-md-auto">
                              <div>
                                {student.proctors
                                  ? student.proctors.map((s, index) => (
                                    <div key={index}>{s.proctorFullName}</div>
                                  ))
                                  : null}
                              </div>
                            </div>
                          </div>
                        </td>
                      ) : null}
                    </tr>
                ))}
            </tbody>
          </table>
        </ScreenPanel>
        <div className="d-flex justify-content-around">
          <div className="form-group ml-2">
            <button
              onClick={createRandomStudent}
              className={context.theme.btn_save_small}
              disabled={isDisable ? true : false}
            >
              {i18n.t("Generate_Demo_Student")}
            </button>
          </div>
          {/* <div className="form-group ml-2">
          <button
            onClick={randomSession1000}
            className={context.theme.btn_save_small}
          >
            {i18n.t("Generate 100 Student")}
          </button>
        </div> */}
          <div className="form-group ml-2">
            <button
              onClick={() => setShowStudent(true)}
              className={context.theme.btn_save_small}
            >
              {i18n.t("Create_Demo_Student")}
            </button>
          </div>

          <div className="form-group ml-2">
            <button
              hidden={!props.SelectedSession.mail}
              disabled={
                props.SelectedSession.instanceState === "FINISHED" ||
                props.SelectedSession.studentList.length === 0
              }
              onClick={sendEmails}
              className={context.theme.btn_save_small}
            >
              {i18n.t("Send_Mails")}
            </button>
          </div>
        </div>
      </div>

      <ModalForm
        show={show}
        handleClose={handleClose}
        Title={`${i18n.t("Private_Setting")} ${selectedStudent.name} ${selectedStudent.lastname
          } `}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <StudentPrivateRules
          Student={selectedStudent}
          Session={inputs}
          handleClose={handleClose}
          retrieveSessionInformation={props.retrieveSessionInformation}
          {...props}
        />
      </ModalForm>
      <ModalForm
        show={showStudent}
        handleClose={handleClose}
        Title={`${i18n.t("New_Student")}`}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CreateStudent
          selectedSession={inputs}
          setSelectedSession={props.setSelectedSession}
          handleClose={handleClose}
          retrieveSessionInformation={props.retrieveSessionInformation}
          constructStudentMeetingInformationList={
            constructStudentMeetingInformationList
          }
          {...props}
        />
      </ModalForm>
    </React.Fragment>
  );
}
export default withNamespaces()(SessionStudents);
