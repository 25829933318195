/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { useInput } from "../../../hooks/useInput";
import FormInput from "../../components/FormInput";
import { awscontext } from "../../../configs/awscontext";
import ScreenPanel from "../../components/ScreenPanel";
import i18n from "../../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import { useSession } from "../../../hooks/useSession";
import uniqid from "uniqid";
import { store } from "react-notifications-component";
const studentModel = require("../../../models/modelStudent.json");

function CreateStudent(props) {
  const context = useContext(awscontext);
  const [selectedSession] = useInput(props.selectedSession);
  const [inputs, setInputs] = useInput({
    ...studentModel,
    name: "",
    lastname: "",
    email: "",
  });
  const [, setSession] = useSession();

  const handleEvent = async (e) => {
    
    e.preventDefault();
    //await props.retrieveSessionInformation(false);
    const copySelectedSession = JSON.parse(JSON.stringify(selectedSession));
    
    const copyStudentList = copySelectedSession.studentList;
    inputs.name = inputs.name.trim();
    inputs.lastname = inputs.lastname.trim();
    inputs.email = inputs.email.trim();
    const newStudent = inputs;
    newStudent.studentId = uniqid();
    newStudent.sessionId = selectedSession.sessionId;
    newStudent.instituteId = selectedSession.instituteId;
    newStudent.sessionIncludeTime = new Date().getTime();
    newStudent.systemCheckLinkStatus = "ACTIVE";
    newStudent.mobileSystemCheckLinkStatus = "ACTIVE";
    newStudent.examRules = copySelectedSession.examRules;
    newStudent.examRules.mobileCameraUsage =
      copySelectedSession.systemCheck.mobileCameraUsage;
    newStudent.preSystemCheck = {
      internetConnectionStatus: false,
      numberOfDisplaysValidity: false,
      webcamShareable: false,
      screenShareable: false,
      audioShareable: false,
      preExamRoomScan: false,
      preExamMaterialCheck: false,
      toiletBreakPermission: false,
    };
    
    copyStudentList.push(newStudent);
    
    let newStudentList=[];
    newStudentList.push(newStudent)
    const response = {
      statusCode: 0,
      value: null,
      body: null,
    };
    
    let apiselectedsession = copySelectedSession;
    
    apiselectedsession.studentList=newStudentList
    await setSession("random", apiselectedsession, "manuel", response);
    if (response.statusCode === 204) {
      let newSelectedSession = JSON.parse(JSON.stringify(selectedSession));
      newSelectedSession.studentList.push(...response.value)
      props.setSelectedSession(newSelectedSession);
      props.constructStudentMeetingInformationList(newSelectedSession);
      store.addNotification({
        title: null,
        //message: `Session ${copySelectedSession.sessionName} has successfully updated`,
        message: i18n.t("Session_has_successfully_updated"),
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      props.handleClose();
    } else if (response.statusCode === 210) {
      props.constructStudentMeetingInformationList(copySelectedSession);
      store.addNotification({
        title: null,
        message: `${response.body}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };
  return (
    <React.Fragment>
      <ScreenPanel name={i18n.t("Create_Student")} col="6">
        <form onSubmit={(e) => handleEvent(e)}>
          <React.Fragment>
            <FormInput
              name="name"
              text={i18n.t("Name")}
              value={inputs.name}
              onChange={setInputs}
              type="text"
              required={true}
            />
            <FormInput
              name="lastname"
              text={i18n.t("Lastname")}
              value={inputs.lastname}
              onChange={setInputs}
              type="text"
              required={true}
            />
            <FormInput
              name="email"
              text={i18n.t("Email")}
              value={inputs.email}
              onChange={setInputs}
              type="email"
              required={true}
            />
          </React.Fragment>
          <div className="d-flex flex-fill justify-content-between">
            <button className={context.theme.btn_save_small} type="submit">
              {i18n.t("Create_Student")}
            </button>
          </div>
        </form>
      </ScreenPanel>
    </React.Fragment>
  );
}
export default withNamespaces()(CreateStudent);
