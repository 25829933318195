import React, { Component, createContext } from "react";
import { Auth } from "aws-amplify";
import routes from "../configs/route";
const theme = require("./theme.json");
const sessionModel = require("../models/modelSession.json");

export const awscontext = createContext();
class awscontextprovider extends Component {
  state = {
    logoError: false,
    isAuthenticated: false,
    loaded: false,
    adres: "",
    theme: theme.Blue,
    barGroup: "",
    logoInstitute: "certify_logo.png",
    languageCode: "en",
    proctorMeetingName: "",
    sidePanelVisibilty: true,
    returnURL: "",
    user: null,
    userRole: "",
    userInfo: "",
    userInstituteName: "",
    userInstituteId: "",
    currentInstitute: "",
    languages: [],
    liveSessionList: [],
    proctorList: [],
    sessionList: [],
    librarySessionList: [],
    archivedsessionList: [],
    unarchivedsessionList: [],
    meetingArchivedSessionList: [],
    onlineTestTakerList: [],
    instituteOnlineTestTakerList: [],
    instituteList: [],
    reportList: [],
    staffList: [],
    selectedSession: sessionModel,
    availbleProctors: [],
    sortedRow: -1,
    sortedDesc: true,
    instituteLiveStudents: [],
    dashboardData: [],
    base_url: window.location.origin,
  };

  setDashboardData = (dashboardData) => {
    this.setState({ dashboardData });
  };

  setLogoError = (logoError) => {
    this.setState({ logoError });
  };

  setInstituteLiveStudents = (instituteLiveStudents) => {
    const list = [];
    const sessions = this.state.sessionList;
    instituteLiveStudents.forEach((element) => {
      const session = sessions.find((s) => s.sessionId === element.sessionId);
      if (session) {
        const sessionStudents = session.studentList;
        const findStudent = sessionStudents.find(
          (std) => std.studentId === element.studentId
        );
        if (findStudent) {
          const newStudent = {
            name: element.name,
            lastname: element.lastname,
            studentId: element.studentId,
            sessionId: element.sessionId,
            sessionName: session.sessionName,
            proctors: element.proctors,
          };
          list.push(newStudent);
        }
      }
    });
    this.setState({ instituteLiveStudents: list });
  };

  setReturnURL = (returnURL) => {
    this.setState({ returnURL });
  };

  setLoaded = (loaded) => {
    this.setState({ loaded });
  };

  setArchivedSessionList = (archivedsessionList) => {
    this.setState({ archivedsessionList });
  };

  setUnArchivedSessionList = (unarchivedsessionList) => {
    this.setState({ unarchivedsessionList });
  };

  setMeetingArchivedSessionList = (meetingArchivedSessionList) => {
    this.setState({ meetingArchivedSessionList });
  };

  setOnlineTestTakerList = (onlineTestTakerList) => {
    this.setState({ onlineTestTakerList });
  };

  setInstituteOnlineTestTakerList = (instituteOnlineTestTakerList) => {
    this.setState({ instituteOnlineTestTakerList });
  };

  setSelectedSession = (selectedSession) => {
    this.setState({ selectedSession });
  };

  setSessionList = (sessionList) => {
    this.setState({ sessionList });
  };

  setLibrarySessionList = (librarySessionList) => {
    this.setState({ librarySessionList });
  };

  setStaff = (staffList) => {
    this.setState({ staffList });
  };

  setBarGroup = (barGroup) => {
    this.setState({ barGroup });
  };

  setLanguages = (languages) => {
    this.setState({ languages });
  };

  setInstituteList = (instituteList) => {
    this.setState({ instituteList });
  };

  setReportList = (reportList) => {
    this.setState({ reportList });
  };

  setAuthStatus = (isAuthenticated) => {
    this.setState({ isAuthenticated });
  };

  setInstitute = (userInstituteName, userInstituteId) => {
    this.setState({ userInstituteName, userInstituteId });
    localStorage.setItem("instituteName", userInstituteName);
    localStorage.setItem("instituteId", userInstituteId);
  };

  setTheme = () => {
    this.setState({ theme: theme.Blue });
  };

  setCurrentInstitute = (data) => {
    this.setState({
      currentInstitute: data,
      theme: theme.Blue,
      languageCode: data.language,
    });
  };

  setLogoInstitute = (logoInstitute) => {
    this.setState({
      logoInstitute:
        logoInstitute !== undefined && logoInstitute !== ""
          ? logoInstitute
          : "certify_logo.png",
    });
  };

  setProctorList = (proctorList) => {
    this.setState({ proctorList }, () => this.setAvailbleProctors());
  };

  setAdres = (adres) => {
    let sidePanelVisibilty = routes.filter((rt) => rt.path === "/" + adres);
    if (sidePanelVisibilty.length === 0) sidePanelVisibilty = true;
    else sidePanelVisibilty = sidePanelVisibilty[0].sidePanelVisible;
    this.setState({
      adres,
      sidePanelVisibilty,
    });
  };
  setAvailbleProctors = () => {
    const proctors = [];
    this.state.proctorList.forEach((proctor) => {
      proctors.push({
        proctorFullName: `${proctor.name} ${proctor.lastname}`,
        proctorName: proctor.name,
        proctorLastName: proctor.lastname,
        proctorMail: proctor.email,
        proctorId: proctor.username,
      });
    });

    this.setState({
      availbleProctors: proctors,
    });
  };
  setUser = (user) => {
    const localInsId = localStorage.getItem("instituteId");
    const localInsName = localStorage.getItem("instituteName");
    if (user) {
      this.setState({
        user: user,
        userInfo: user.name + " " + user.lastname,
        userRole: JSON.stringify(user.roles),
        userInstituteName: localInsName
          ? localInsName
          : user.institutes[0].instituteName,
        userInstituteId: localInsId
          ? localInsId
          : user.institutes[0].instituteId,
      });
    }
  };

  sortTable = (tableId, rowNumber, dataType) => {
    let directionx = true;
    if (this.state.sortedRow === rowNumber) {
      directionx = !this.state.sortedDesc;
    }
    this.setState({
      sortedRow: rowNumber,
      sortedDesc: directionx,
    });
    var table, rows, switching, i, x, y, shouldSwitch;
    table = document.getElementById(tableId);
    switching = true;
    while (switching) {
      switching = false;
      rows = table.rows;
      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i].getElementsByTagName("TD")[rowNumber];
        y = rows[i + 1].getElementsByTagName("TD")[rowNumber];

        switch (dataType) {
          case "int":
            x = parseInt(x.innerHTML);
            y = parseInt(y.innerHTML);
            break;
          case "float":
            x = parseFloat(x.innerHTML);
            y = parseFloat(y.innerHTML);
            break;
          case "date":
            x = Date.parse(x.innerHTML);
            y = Date.parse(y.innerHTML);
            break;
          default:
            x = x.innerHTML.toLowerCase();
            y = y.innerHTML.toLowerCase();
        }
        if (directionx) {
          if (x < y) {
            shouldSwitch = true;
            break;
          }
        } else {
          if (x > y) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
      }
    }
  };

  sortTableData = (table, column, type) => {
    let directionx = true;
    if (this.state.sortedRow === column) {
      directionx = !this.state.sortedDesc;
    }
    this.setState({
      sortedRow: column,
      sortedDesc: directionx,
    });

    function compare(a, b) {
      if (!a[column]) return 0;
      if (!b[column]) return 0;

      if (a[column].toLowerCase() < b[column].toLowerCase()) {
        return directionx ? -1 : 1;
      }
      if (a[column].toLowerCase() > b[column].toLowerCase()) {
        return directionx ? 1 : -1;
      }
      return 0;
    }
    function compareInt(a, b) {
      if (a[column] < b[column]) {
        return directionx ? -1 : 1;
      }
      if (a[column] > b[column]) {
        return directionx ? 1 : -1;
      }
      return 0;
    }
    if (type === "int") {
      table.sort(compareInt);
    } else {
      table.sort(compare);
    }
  };

  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.setAuthStatus(true);
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        // localStorage.setItem("user", JSON.stringify(user));
        // const localValue = localStorage.getItem("instituteId");
        // if (!localValue) {
        // localStorage.setItem("instituteId", user.institutes[0].instituteId);
        // localStorage.setItem("instituteName", user.institutes[0].instituteName);
        // }
        this.setUser(this.state.user);
      }
    } catch (error) {
      if (error !== "No current user") {
        console.log(error);
      }
    }
  }

  logout = async () => {
    this.setReturnURL("/admin");
    // this.setLoaded(false);
    this.setAdres("admin");
    this.setState({
      user: null,
      userInfo: null,
      userRole: "",
      userInstituteName: null,
      userInstituteId: null,
      barGroup: "HOME",
    });
    localStorage.removeItem("instituteId");
    localStorage.removeItem("instituteName");
    localStorage.removeItem("username");
    await Auth.signOut();
    this.setAuthStatus(false);
    // this.setLoaded(true);
    if (this.setAuthStatus) {
      window.location.href = this.state.base_url + "/login";
    }
  };

  setLiveSessionList = (liveSessionList) => {
    this.setState({ liveSessionList });
  };

  render() {
    return (
      <awscontext.Provider
        value={{
          ...this.state,
          setAuthStatus: this.setAuthStatus,
          setUser: this.setUser,
          logout: this.logout,
          setInstituteList: this.setInstituteList,
          setAdres: this.setAdres,
          setLanguages: this.setLanguages,
          setLoaded: this.setLoaded,
          setLogoError: this.setLogoError,
          setTheme: this.setTheme,
          setBarGroup: this.setBarGroup,
          setStaff: this.setStaff,
          setCurrentInstitute: this.setCurrentInstitute,
          setLogoInstitute: this.setLogoInstitute,
          setSessionList: this.setSessionList,
          setSelectedSession: this.setSelectedSession,
          setProctorList: this.setProctorList,
          setInstitute: this.setInstitute,
          setArchivedSessionList: this.setArchivedSessionList,
          setUnArchivedSessionList: this.setUnArchivedSessionList,
          setReportList: this.setReportList,
          setLibrarySessionList: this.setLibrarySessionList,
          setMeetingArchivedSessionList: this.setMeetingArchivedSessionList,
          setOnlineTestTakerList: this.setOnlineTestTakerList,
          setInstituteOnlineTestTakerList: this.setInstituteOnlineTestTakerList,
          setReturnURL: this.setReturnURL,
          setAvailbleProctors: this.setAvailbleProctors,
          setLiveSessionList: this.setLiveSessionList,
          sortTable: this.sortTable,
          sortTableData: this.sortTableData,
          setInstituteLiveStudents: this.setInstituteLiveStudents,
          setDashboardData: this.setDashboardData,
        }}
      >
        {this.props.children}
      </awscontext.Provider>
    );
  }
}
export default awscontextprovider;
