/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from "react";
import { awscontext } from "../../configs/awscontext";
import { useStart } from "../../hooks/useStart";
import { NavLink, NavLinkEvent, NavDropDown } from "./NavLink";
import i18n from "../../../src/configs/i18n";
import { withNamespaces } from "react-i18next";
import { useStaff } from "../../hooks/useStaff";
import { proctorcontext } from "../../configs/proctorContext";
import defaultLogo from "../../../src/assets/smartest_invigilate_logo.png";
import { FaGlobe, FaSignOutAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useStorage } from "../../hooks/useStorage";
import { useLanguage } from "../../hooks/useLanguage";
const routesConfig = require("../../configs/route");
const config = require("../../configs/awsconfig.json");
const configI18 = require("../../../src/configs/i18n");
function SiteNavPanel(props) {
  const proccontext = useContext(proctorcontext);
  const context = useContext(awscontext);
  const imgRef = useRef();
  const [routes, setRoutes] = useState([]);
  const [, setStart] = useStart();
  const [, handleChange] = useStaff();
  const [imgLoaded, setImgLoaded] = useState(false);
  const [, , , getBaseUrl] = useStorage(config.s3.files);
  const [langList, setLangList] = useState([]);
  useEffect(() => {
    languageList();
    var routeData = [];
    routesConfig.default.map((m) => routeData.push(m));
    routeData.sort(function(a, b) {
      return a.sort - b.sort;
    });
    setRoutes(routeData);
  }, []);

  const languageList = async () => {
    const result = await axios.get(`${config.api.invokeUrl}/languages`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          Object.keys(localStorage).find((key) => key.includes("accessToken"))
        )}`,
      },
    });
    setLangList(result.data);
  };
  const changeLanguage = async (e, lng, id) => {
    e.preventDefault();
    configI18.default.options.resources = {};
    configI18.default.store.data = {};
    let key = lng;
    const params = {
      translation: {},
    };
    const result = await axios.get(`${config.api.invokeUrl}/languages`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          Object.keys(localStorage).find((key) => key.includes("accessToken"))
        )}`,
      },
    });
    for (let i = 0; i < result.data.length; i++) {
      if (result.data[i].langCode === lng) {
        await axios
          .get(
            `${getBaseUrl()}/assets/languages/${result.data[i].langCode}.json`
          )
          .then((res) => {
            params.translation = res.data;
            localStorage.setItem("state", JSON.stringify(params));
          });
        configI18.default.options.resources[key] = params;
        configI18.default.store.data[key] = params;

        i18n.changeLanguage(lng);
        localStorage.setItem("lang", lng);

        handleChange("lang", {
          lang: lng,
          username: context.user.username,
          instituteId: id,
        });
      }
    }
  };


  let history = useHistory();
  const changeInstitute = async (e, name, id, lng) => {
    changeLanguage(e, lng, id);
    context.setLoaded(false);
    context.setInstitute(name, id);
    let returnURL = context.returnURL;
    try {
      if (returnURL) {
        if (returnURL.includes("/libraryDetailed"))
          returnURL = history.push("/library");
        if (returnURL.includes("/libraryStudentDetailed"))
          returnURL = history.push("/library");
        if (returnURL.includes("/sessionInformation"))
          returnURL = history.push("/sessionList");
      }
    } catch (err) {}
    context.setLoaded(true);
    await setStart(id, true);
    setStart(id, false);
  };

  useEffect(() => {
    if (imgLoaded) {
      imgRef.current.style.display = "block";
    }
  }, [imgLoaded]);

  const logoPanel = () => {
    var logo = "";
    let currentInstId = context.currentInstitute.id;
    // let logo = `https://rps-files.s3.eu-west-1.amazonaws.com/institutes/${currentInstId}/logos/${currentInstId}`;
    if (!context.logoError && !!currentInstId) {
      logo = `${getBaseUrl()}/institutes/${currentInstId}/logos/${currentInstId}?v=${new Date().getTime()}`;
    } else {
      logo = defaultLogo;
    }
    return (
      <div
        className="m-0 p-0 d-flex justify-content-center "
        style={{ width: 290, height: 90, maxWidth: 290, maxHeight: 90 }}
      >
        <a href="/">
          <img
            src={logo}
            onError={(error) => {
              //console.log("logo error", error);
              context.setLogoError(true);
            }}
            alt=""
            width={"100%"}
            height={"90%"}
          />
        </a>
      </div>
    );
  };

  const menusPanel = () => {
    return context.adres !== "proctoring" ? (
      <ul className="navbar-nav mr-auto ">
        {routes.map((prop, key) => (
          <React.Fragment key={key}>
            {prop.location.includes("navbar") ? (
              prop.auth.some((user) => context.userRole.includes(user)) ? (
                <NavLink prop={prop} to={`${prop.path}`}>
                  {i18n.t(prop.name)}
                </NavLink>
              ) : null
            ) : null}
          </React.Fragment>
        ))}
      </ul>
    ) : null;
  };

  const institutePanel = () => {
    if (!context.userRole) return "";
    const instituteList = context.userRole.includes("Super Admin")
      ? context.instituteList
      : context.user.institutes;
    return instituteList.length === 1 ? (
      <li className="mt-auto mb-auto" style={{ fontSize: "12pt" }}>
        {instituteList[0].instituteName}
      </li>
    ) : (
      <NavDropDown id="instituteDropdown" header={context.userInstituteName}>
        {instituteList.map((institute, i) => (
          <button
            key={i}
            onClick={(e) => {
              context.setLogoError(false);
              changeInstitute(
                e,
                institute.instituteName,
                institute.instituteId ? institute.instituteId : institute.id,
                localStorage.getItem("i18nextLng")
              );
             
            }}
            className="dropdown-item"
          >
            {institute.instituteName}
          </button>
        ))}
      </NavDropDown>
    );
  };

  const proctorPanel = () => {
    return context.adres === "proctoring" ? (
      <ul className="navbar-nav mr-auto ">
        {!context.userRole.includes("Super Admin") ? 
        <React.Fragment>
          <NavLinkEvent onClick={() => proccontext.setShow(1)}>
            {i18n.t("Standard_View")}
          </NavLinkEvent>
          <NavLinkEvent onClick={() => proccontext.setShow(4)}>
            4 {i18n.t("Screen_View")}
          </NavLinkEvent>
          <NavLinkEvent onClick={() => proccontext.setShow(6)}>
            6 {i18n.t("Screen_View")}
          </NavLinkEvent>
        </React.Fragment>
        :null}
      </ul>
    ) : (
      ""
    );
  };

  const languagePanel = () => {
    return (
      <NavDropDown id="alertsDropdown" header={<FaGlobe />}>
        {langList.map((prop, i) => (
          <button
            key={i}
            onClick={(e) => changeLanguage(e, prop.langCode)}
            className="dropdown-item d-flex align-items-center"
          >
            {prop.langCode}
          </button>
        ))}
      </NavDropDown>
    );
  };

  const userPanel = () => {
    return (
      <NavDropDown id="userDropdown" header={`${context.userInfo}`}>
        <button
          onClick={() => context.logout()}
          className="dropdown-item p-0 h-50 w-100 text-gray-400"
        >
          <FaSignOutAlt className="mr-2" />
          {i18n.t("Logout")}
        </button>
      </NavDropDown>
    );
  };

  return (
    <React.Fragment>
      <nav
        // className={`navbar navbar-expand navbar-light ${context.theme.nav_panel_background} topbar static-top  .border-bottom-success  m-0 p-0 mb-3 `}
        className={`navbar navbar-expand-lg ${context.theme.nav_panel_background} mb-3 p-0`}
      >
        {logoPanel()}
        <button
          className="navbar-toggler "
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon "></span>
        </button>
        <div
          className="collapse navbar-collapse mr-auto border-bottomLine m-4 mt-5"
          id="navbarSupportedContent"
        >
          {menusPanel()}
          {proctorPanel()}
          <ul className="navbar-nav">
            {institutePanel()}
            {languagePanel()}
            {userPanel()}
          </ul>
        </div>
      </nav>
    </React.Fragment>
  );
}
export default withNamespaces()(SiteNavPanel);
